import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: 30,
  },
  bodyMain: {

  },
  form: {

  },
  inputItem: {
    width: '100%',
    margin: 0,
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  btnAddOrSave: {
    zIndex: 1,
    paddingLeft: 25,
    paddingRight: 25,
    color: 'white',
    width: 'auto',
    backgroundColor: theme.palette.greenC.green4,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green5,
    },
  },
  btnRemove: {
    marginLeft: -18,
    paddingLeft: 25,
    paddingRight: 25,
    color: theme.palette.greyC.darkGrey,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: 'none',
    },
  },
  makeAdminContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 26,
  },
  makeAdminStr: {
    marginBottom: 4,
  },
  switch: {

  },
}));

export default {
  useStyles,
};
