import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllTariffsPage from 'components/pages/settings/tariffs/AllTariffsPage';
import OneTariffPage from 'components/pages/settings/tariffs/OneTariffPage';

import { PATH_PAGES } from 'config/constants';

function TariffsPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
  } = currentFacilityState || {};

  const urlActions = PATH_PAGES.allTariffSettings.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.allTariffSettings} component={AllTariffsPage} />
      <PrivateRoute exact path={PATH_PAGES.newTariffSettings} component={OneTariffPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTariffSettings} component={OneTariffPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedTariffsPage = connect(
  mapStateToProps,
  {
  },
)(TariffsPage);

export default ConnectedTariffsPage;
