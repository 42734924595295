import React from 'react';

function LaurentIcon({
  size = 24,
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="#57BE7A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="#57BE7A" />
      <path
        d="M18.0568 12V3.27273H19.1136V7.60227H19.2159L23.1364 3.27273H24.517L20.8523 7.21023L24.517 12H23.2386L20.2045 7.94318L19.1136 9.17045V12H18.0568ZM28.0124 12.1364C27.3817 12.1364 26.8377 11.9972 26.3803 11.7188C25.9258 11.4375 25.5749 11.0455 25.3278 10.5426C25.0835 10.0369 24.9613 9.44886 24.9613 8.77841C24.9613 8.10795 25.0835 7.51705 25.3278 7.00568C25.5749 6.49148 25.9187 6.09091 26.359 5.80398C26.8022 5.5142 27.3192 5.36932 27.9102 5.36932C28.2511 5.36932 28.5877 5.42614 28.9201 5.53977C29.2525 5.65341 29.555 5.83807 29.8278 6.09375C30.1005 6.34659 30.3178 6.68182 30.4798 7.09943C30.6417 7.51705 30.7227 8.03125 30.7227 8.64205V9.06818H25.6772V8.19886H29.6999C29.6999 7.82955 29.6261 7.5 29.4783 7.21023C29.3335 6.92045 29.1261 6.69176 28.8562 6.52415C28.5891 6.35653 28.2738 6.27273 27.9102 6.27273C27.5096 6.27273 27.163 6.37216 26.8704 6.57102C26.5806 6.76705 26.3576 7.02273 26.2013 7.33807C26.0451 7.65341 25.967 7.99148 25.967 8.35227V8.93182C25.967 9.42614 26.0522 9.84517 26.2227 10.1889C26.396 10.5298 26.636 10.7898 26.9428 10.9688C27.2496 11.1449 27.6062 11.233 28.0124 11.233C28.2766 11.233 28.5153 11.196 28.7283 11.1222C28.9442 11.0455 29.1303 10.9318 29.2866 10.7812C29.4428 10.6278 29.5636 10.4375 29.6488 10.2102L30.6204 10.483C30.5181 10.8125 30.3462 11.1023 30.1048 11.3523C29.8633 11.5994 29.565 11.7926 29.2099 11.9318C28.8548 12.0682 28.4556 12.1364 28.0124 12.1364Z"
        fill="white"
      />
    </svg>
  );
}

export default LaurentIcon;
