import { callAPI, downloadFileContentDispositionApi } from '@parkly/shared/api';

async function validGetOpenVpnClients({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  return curData.map((item) => {
    const {
      id,
      username,
      ip,
      canBeDeleted,
    } = item;

    return {
      id,
      username,
      ip,
      canBeDeleted,
    };
  });
}

export async function getAllOpenVpnClients({ facilityId }) {
  return callAPI(`facilities/${facilityId}/openvpn-clients`)
    .then((res) => validGetOpenVpnClients({
      res,
    }));
}

async function validCreateOpenVpnClient({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const {
    id,
    ip,
    name,
  } = curData || [];

  return {
    id,
    ip,
    name,
  };
}

export async function createOpenVpnClient({ facilityId }) {
  return callAPI(`facilities/${facilityId}/openvpn-clients`, 'POST')
    .then((res) => validCreateOpenVpnClient({
      res,
    }));
}

export async function deleteOpenVpnClient({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/openvpn-clients/${id}`, 'DELETE')
    .then((res) => validCreateOpenVpnClient({
      res,
    }));
}

export async function downloadOpenVpnConfig({
  facilityId,
  id,
}) {
  return downloadFileContentDispositionApi(`facilities/${facilityId}/openvpn-clients/${id}/config`);
}

