import { createMutation, createQuery } from 'react-query-kit';

import {
  getActiveTariffPlans, getFacilityPaySetting, getTariffPlansBulk, setFacilityPaySetting,
} from 'api/tariffs';

export const useActiveTariffPlans = createQuery({
  primaryKey: 'active-tariff-plans',
  queryFn: ({ queryKey: [_, { facilityId, withTechnical }] }) => getActiveTariffPlans({ facilityId, withTechnical }),
  retry: false,
});

export const useTariffPlansBulk = createQuery({
  primaryKey: 'tariff-plans-bulk',
  queryFn: ({ queryKey: [_, { facilityIds, withTechnical }] }) => getTariffPlansBulk({ facilityIds, withTechnical }),
  retry: false,
});

export const useWelcomeTariffPlan = createQuery({
  primaryKey: 'welcome-tariff-plan',
  queryFn: ({ queryKey: [_, { facilityId }] }) => getFacilityPaySetting({ facilityId }),
  retry: false,
});

export const useSetWelcomeTariffPlanMutation = createMutation({
  mutationFn: async ({ facilityId, welcomeTariffPlanId }) => setFacilityPaySetting({ facilityId, welcomeTariffPlanId }),
});
