import React from 'react';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

import { useStyles } from './styles';

function AccountBalance({
  containerClassName = '',
  currentBalance,
  isLoading = false,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const currency = t('others.currency');

  const currentBalanceStr = `${(+currentBalance || 0).toLocaleString()} ${currency}`;

  return (
    <div className={`${styles.container} ${containerClassName}`}>
      <div className={styles.iconContainer}>
        <AccountBalanceWalletOutlinedIcon />
      </div>
      <div className={styles.balanceContainer}>
        {isLoading ? (
          <CircularProgress size={30} />
        ) : (
          <>
            <Typography>
              Баланс:
            </Typography>
            <Typography className={styles.balanceStr}>
              {currentBalanceStr}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

export default AccountBalance;
