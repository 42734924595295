import { updateStoreReqRes } from '@parkly/shared/helpers';
import { CLEAR_CUSTOMERS_CACHE, GET_CUSTOMERS_SELECT } from 'actions/types';

const INITIAL_STATE = {
  allCustomersSelect: {
    loading: false,
    list: [],
  },
};

export default function customers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CUSTOMERS_SELECT:
      return updateStoreReqRes(
        state,
        'allCustomersSelect',
        action,
      );

    case CLEAR_CUSTOMERS_CACHE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
