import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import { FAST_LOGOUT, LOGOUT } from '@parkly/shared/actions/types';
import { LOCALSTORAGE_KEYS } from '@parkly/shared/config/constants';
import auth from '@parkly/shared/reducers/auth';
import contractor from '@parkly/shared/reducers/contractor';
import operators from 'reducers/operators';
import companies from 'reducers/companies';
import facilities from 'reducers/facilities';
import currentFacility from 'reducers/currentFacility';
import customers from 'reducers/customers';
import tariffs from 'reducers/tariffs';
import blackLists from 'reducers/blackLists';
import analytics from 'reducers/analytics';
import devices from 'reducers/devices';
import controllers from 'reducers/controllers';
import licenses from 'reducers/licenses';
import passes from 'reducers/passes';

const appReducer = combineReducers({
  auth,
  operators,
  companies,
  facilities,
  currentFacility,
  contractor,
  customers,
  tariffs,
  blackLists,
  analytics,
  devices,
  controllers,
  licenses,
  passes,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    storage.removeItem(`persist:${LOCALSTORAGE_KEYS.persist}`);
    storage.removeItem(`persist:${LOCALSTORAGE_KEYS.currentFacility}`);

    return appReducer(undefined, action);
  }

  if (action.type === FAST_LOGOUT) {
    storage.removeItem(`persist:${LOCALSTORAGE_KEYS.persist}`);
    storage.removeItem(`persist:${LOCALSTORAGE_KEYS.currentFacility}`);

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
