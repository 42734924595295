import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';

import P360LogoIcon from '@parkly/shared/components/atoms/icons/P360LogoIcon';
import NavigationLink from '@parkly/shared/components/atoms/NavigationLink';

import UserAgreementMDX from './user-agreement.mdx';

export default function UserAgreement() {
  return (
    <Container disableGutters maxWidth={false}>
      <AppBar position="relative">
        <Toolbar>
          <NavigationLink to="/">
            <P360LogoIcon />
          </NavigationLink>
        </Toolbar>
      </AppBar>
      <Container style={{ fontSize: 14 }}>
        <UserAgreementMDX />
      </Container>
    </Container>
  );
}
