import React from 'react';

function GuestPassesIconActive({
  fill = '#fff',
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.25C3.48122 5.25 2.25 6.48122 2.25 8V24C2.25 25.5188 3.48122 26.75 5 26.75H27C28.5188 26.75 29.75 25.5188 29.75 24V8C29.75 6.48122 28.5188 5.25 27 5.25H5ZM9.25 11.5C9.25 9.70507 10.7051 8.25 12.5 8.25C14.2949 8.25 15.75 9.70507 15.75 11.5C15.75 13.2949 14.2949 14.75 12.5 14.75C10.7051 14.75 9.25 13.2949 9.25 11.5ZM5.75 20.25C5.75 17.7199 7.92298 15.75 10.5 15.75H14.5C17.077 15.75 19.25 17.7199 19.25 20.25V21C19.25 22.2875 18.1515 23.25 16.9 23.25H8.1C6.84846 23.25 5.75 22.2875 5.75 21V20.25ZM20.25 11C20.25 10.5858 20.5858 10.25 21 10.25H25C25.4142 10.25 25.75 10.5858 25.75 11C25.75 11.4142 25.4142 11.75 25 11.75H21C20.5858 11.75 20.25 11.4142 20.25 11ZM20.25 15C20.25 14.5858 20.5858 14.25 21 14.25H25C25.4142 14.25 25.75 14.5858 25.75 15C25.75 15.4142 25.4142 15.75 25 15.75H21C20.5858 15.75 20.25 15.4142 20.25 15Z"
        fill={fill}
      />
    </svg>
  );
}

export default GuestPassesIconActive;
