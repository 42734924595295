import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { setCurrentFacilityAction } from 'actions/currentFacility';
import SitesIcon from '@parkly/shared/components/atoms/icons/SitesIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from 'react-router-dom';
import { PATH_PAGES } from 'config/constants';

export const CustomListItemMenu = withStyles({
  root: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: props => props.isCollapsed ? 'center' : 'flex-start',
    gap: 8,
    paddingLeft: props => props.isCollapsed ? 0 : 24,
    alignItems: 'center',
    borderRadius: 12,
    '& .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary': {
      fontSize: 14,
    },
    padding: 0,
    textAlign: 'center',
  },

})(ListItem);

function CurrentFacilitySelect({
  isCollapsed,

  allFacilitiesState,
  setCurrentFacilityReq,
  currentFacilityState,
  currentOperatorState,
}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { data: allFacilitiesData } = allFacilitiesState;

  const {
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    id: currentFacilityId,
    title: currentFacilityTitle,
  } = currentFacilityData || {};

  const { data: currentOperatorData } = currentOperatorState || {};

  const { hasAnotherPartners } = currentOperatorData || {};

  const handleClickListItem = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuItemClick = useCallback((event, index, value) => {
    setAnchorEl(null);

    const facility = allFacilitiesData.find(({ id }) => id === value);
    setCurrentFacilityReq(facility, true);
  }, [allFacilitiesData, setCurrentFacilityReq]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const facilitiesItems = useMemo(
    () => (allFacilitiesData || []).map(({ id, title }) => ({ id, title })),
    [allFacilitiesData],
  );

  const selectedIndex = useMemo(
    () => (allFacilitiesData || []).findIndex(({ id }) => id === currentFacilityId),
    [allFacilitiesData, currentFacilityId],
  );

  if (facilitiesItems.length <= 1 && !hasAnotherPartners) {
    return null;
  }

  return (
    <>
      <CustomListItemMenu isCollapsed={isCollapsed} button onClick={handleClickListItem}>
        <ListItemIcon style={{ minWidth: 'unset' }}>
          <SitesIcon size={24} />
        </ListItemIcon>

        {!isCollapsed && (
          <ListItemText
            primaryTypographyProps={{
              style: {
                paddingRight: '32px',
                textAlign: 'unset',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }}
            style={{ flex: '0 1 auto' }}
            primary={currentFacilityTitle}
          />
        )}
        <ListItemSecondaryAction>
          <IconButton edge="end" color="secondary" onClick={handleClickListItem}>
            <ExpandMoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </CustomListItemMenu>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {facilitiesItems.map(({ id, title }, index) => (
          <MenuItem
            key={id}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, id)}
          >
            {title}
          </MenuItem>
        ))}
        {hasAnotherPartners && (
          <MenuItem
            key="fast-login"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setAnchorEl(null);

              history.push(PATH_PAGES.fastLogin);
            }}
          >
            Сменить партнера
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

function mapStateToProps(state) {
  const { facilities, currentFacility, operators } = state || {};
  const { currentOperator: currentOperatorState } = operators || {};
  const {
    allFacilities: allFacilitiesState,
  } = facilities || {};

  const {
    currentFacility: currentFacilityState,
  } = currentFacility || {};

  return {
    currentOperatorState,
    allFacilitiesState,
    currentFacilityState,
  };
}

const CurrentFacilitySelectPage = connect(
  mapStateToProps,
  {
    setCurrentFacilityReq: setCurrentFacilityAction,
  },
)(CurrentFacilitySelect);

export default CurrentFacilitySelectPage;
