import React from 'react';

function HelpInactiveIcon({
  size = 32,
  color = 'white',
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23V23.05"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.8969 19.5V18.3325C15.8969 17.7802 16.3515 17.3451 16.887 17.2103C18.6748 16.7605 20 15.1205 20 13.1662C20 10.8653 18.163 9 15.8969 9C14.0807 9 12.5402 10.1981 12 11.8584"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HelpInactiveIcon;
