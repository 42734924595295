import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  formContainer: {
    width: '100%',
  },
  form: {
    marginTop: 16,
  },
  inputItem: {
    margin: 0,
  },
  multilineInputItem: {
    margin: 0,

    '& .MuiFilledInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
  subLabel: {
    color: theme.palette.greyC.darkGrey,
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  subTitle: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  btnSubmit: {
    marginTop: 8,
    width: '100%',
    height: 48,
    display: 'block',
  },
}));

export default {
  useStyles,
};
