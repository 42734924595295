import { createQuery } from 'react-query-kit';
import { getGatewayEvents, getLastErrorsGatewayEvents } from 'api/gatewayEvents';

export const useGatewayEvents = createQuery({
  primaryKey: 'gateway-events',
  queryFn: ({ queryKey: [_, params] }) => getGatewayEvents(params),
  retry: false,
});

export const useLastErrorGatewayEvents = createQuery({
  primaryKey: 'gateway-events-last-errors',
  queryFn: ({ queryKey: [_, params] }) => getLastErrorsGatewayEvents(params),
  retry: false,
});
