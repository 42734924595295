import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  devicesLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  addDevicesLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: theme.palette.greenC.green5,
    '&:hover': {
      color: theme.palette.greenC.green2,
    },
  },
  paper: {

  },
  modalBody: {

  },
  form: {

  },
  selectorsForm: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  inputItem: {
    margin: 0,
  },
  submitBtn: {
    width: '100%',
    height: 48,
  },
}));

export default {
  useStyles,
};
