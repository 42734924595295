import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useFormFields } from '@parkly/shared/helpers';
import Typography from '@material-ui/core/Typography';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import TariffPlanSchedules from 'components/organisms/tariffPlanSchedules/TariffPlanSchedules';
import clone from 'lodash/clone';

import { getActiveTariffsAction, getOneTariffPlanAction, setTariffPlanAction } from 'actions/tariffs';
import {
  PATH_PAGES,
  RIGHT_KEYS,
  TARIFF_PLAN_CHANGE_TYPES,
  TARIFF_PLAN_STATUS,
  TARIFF_PLAN_TYPES,
} from 'config/constants';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useStyles } from './styles';
import { useCheckFacilityRights } from '../../../../../helpers/hooks';

const TARIFF_PLAN_TYPE_LIST = [
  { id: TARIFF_PLAN_TYPES.detail, title: 'Детальный' },
  // { id: TARIFF_PLAN_TYPES.week, title: 'Недельный' },
  { id: TARIFF_PLAN_TYPES.month, title: 'Месячный' },
];

const TARIFF_PLAN_CHANGE_TYPE_LIST = [
  { id: TARIFF_PLAN_CHANGE_TYPES.new, title: 'Новый' },
  { id: TARIFF_PLAN_CHANGE_TYPES.old, title: 'Прежний' },
  { id: TARIFF_PLAN_CHANGE_TYPES.combo, title: 'Комбинированный' },
  { id: TARIFF_PLAN_CHANGE_TYPES.auto, title: 'Авто' },
];

const DEFAULT_DETAIL_MATRIX = [
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
  [null, null, null, null, null, null, null],
];

function getOneTariffPlan({
  oneTariffPlanState,
  match,
}) {
  const { params } = match || {};
  const { tariffPlanId } = params || {};

  const { list } = oneTariffPlanState || {};
  const currentTariffPlan = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + tariffPlanId);

    return isNeeded;
  });

  return {
    tariffPlanId,
    currentTariffPlan,
  };
}

function getCurrentActiveTariffs({
  activeTariffsState,
  facilityId,
}) {
  const activeTariffsList = (activeTariffsState || {}).list || [];
  const preparedReqParam = { facilityId };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const activeTariffs = activeTariffsList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    activeTariffs,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function OneTariffPlanPage({
  match,

  oneTariffPlanState,
  getOneTariffPlanReq,

  setTariffPlanReq,
  setTariffPlanState,

  getActiveTariffsReq,
  activeTariffsState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.tariffPlanSettings);

  const { params, path } = match || {};
  const {
    tariffPlanId,
    facilityId,
  } = params || {};

  const isNewMode = !tariffPlanId && path === PATH_PAGES.newTariffPlanSettings;

  const hasTariffUpdateRight = useCheckFacilityRights(RIGHT_KEYS.tariffUpdate, facilityId);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      description: '',
      trial: 0,
      exitTime: 15,
      changeType: TARIFF_PLAN_CHANGE_TYPES.old,
      type: TARIFF_PLAN_TYPES.detail,
      status: TARIFF_PLAN_STATUS.draft,
      schedules: clone(DEFAULT_DETAIL_MATRIX),
    },
  });

  useEffect(() => {
    const {
      tariffPlanId: requestedId,
      currentTariffPlan,
    } = getOneTariffPlan({
      oneTariffPlanState,
      match,
    });

    const { loading: isTariffLoading } = oneTariffPlanState || {};
    if (!isTariffLoading && !currentTariffPlan && requestedId && !isNewMode) {
      getOneTariffPlanReq({
        id: tariffPlanId,
        facilityId,
      });
    }

    if (currentTariffPlan && !isNewMode) {
      updateState({
        ...currentTariffPlan,
      });
    }
  }, [facilityId, getOneTariffPlanReq, isNewMode, match, oneTariffPlanState, tariffPlanId, updateState]);

  useEffect(() => {
    const error = (setTariffPlanState || {}).error;
    if (error) {
      const { response } = error || {};
      const { data } = response || {};
      const backErrors = (data || {}).errors || {};

      setErrors({
        ...backErrors,
      });
    }
  }, [setErrors, setTariffPlanState]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      activeTariffs,
    } = getCurrentActiveTariffs({
      activeTariffsState,
      facilityId,
    });

    const {
      loading,
    } = activeTariffsState || {};

    if (!activeTariffs && !loading) {
      getActiveTariffsReq({
        facilityId,
      });
    }
  }, [facilityId, activeTariffsState, getActiveTariffsReq]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (isNewMode) {
      setTariffPlanReq(
        {
          facilityId,
          name: fields.name,
          description: fields.description,
          trial: fields.trial,
          exitTime: fields.exitTime,
          changeType: fields.changeType,
          type: fields.type,
          status: fields.status,
          schedule: fields.schedules,
        },
        history,
      );
      return;
    }

    setTariffPlanReq(
      {
        facilityId,
        id: tariffPlanId,
        name: fields.name,
        description: fields.description,
        trial: fields.trial,
        exitTime: fields.exitTime,
        changeType: fields.changeType,
        type: fields.type,
        status: fields.status,
        schedule: fields.schedules,
      },
      history,
    );
  }, [facilityId, fields, history, isNewMode, setTariffPlanReq, tariffPlanId]);

  const {
    loading: isSaveLoading,
  } = setTariffPlanState || {};

  const {
    loading: isTariffPlanLoading,
  } = oneTariffPlanState || {};

  const {
    loading: isActiveTariffsLoading,
  } = activeTariffsState || {};

  const {
    currentTariffPlan,
  } = getOneTariffPlan({
    oneTariffPlanState,
    match,
  });

  const {
    activeTariffs,
  } = getCurrentActiveTariffs({
    activeTariffsState,
    facilityId,
  });

  const { data: activeTariffsData } = activeTariffs || {};

  const { status } = currentTariffPlan || {};

  const isDisabledUpdate = !(isNewMode || status === TARIFF_PLAN_STATUS.draft);

  const availableStatuses = useMemo(() => {
    if (isNewMode) {
      return [
        { id: TARIFF_PLAN_STATUS.draft, title: 'Черновик' },
        { id: TARIFF_PLAN_STATUS.active, title: 'Активный' },
        { id: TARIFF_PLAN_STATUS.archive, title: 'Архивный' },
      ];
    }

    switch (status) {
      case TARIFF_PLAN_STATUS.active:
        return [
          { id: TARIFF_PLAN_STATUS.active, title: 'Активный' },
          { id: TARIFF_PLAN_STATUS.archive, title: 'Архивный' },
        ];
      case TARIFF_PLAN_STATUS.archive:
        return [
          { id: TARIFF_PLAN_STATUS.archive, title: 'Архивный' },
        ];
      case TARIFF_PLAN_STATUS.draft:
      default:
        return [
          { id: TARIFF_PLAN_STATUS.draft, title: 'Черновик' },
          { id: TARIFF_PLAN_STATUS.active, title: 'Активный' },
          { id: TARIFF_PLAN_STATUS.archive, title: 'Архивный' },
        ];
    }
  }, [isNewMode, status]);

  const handleChangeType = useCallback((event) => {
    const { target } = event || {};
    const { value } = target || {};

    if (value === TARIFF_PLAN_TYPES.detail) {
      updateState({
        schedules: clone(DEFAULT_DETAIL_MATRIX),
        type: value,
      });
    }

    if (value === TARIFF_PLAN_TYPES.month) {
      updateState({
        schedules: [[null, null, null, null, null, null, null, null, null, null, null, null]],
        type: value,
      });
    }
  }, [updateState]);

  const handleSchedulesChange = useCallback((schedules) => {
    updateState({ schedules: clone(schedules) });
  }, [updateState]);

  if (isTariffPlanLoading || isActiveTariffsLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <div>
      <HeaderWithBackBtn
        title={isNewMode ? t('tariffPlans.newTariffPlan') : t('tariffPlans.tariffPlan')}
      />
      <Container className={styles.container} maxWidth="sm">
        <form noValidate className={styles.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputItem}
                type="secondary"
                required
                error={errors.name}
                name="name"
                label={t('tariffPlans.name')}
                value={fields.name}
                autoComplete="off"
                disabled={!hasTariffUpdateRight}
                inputProps={{
                  maxLength: 250,
                }}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputDescriptionItem}
                type="secondary"
                multiline
                minRows={4}
                error={errors.description}
                name="description"
                label={t('tariffPlans.description')}
                value={fields.description}
                disabled={!hasTariffUpdateRight}
                autoComplete="off"
                onChange={onChange}
              />
            </Grid>
            <Grid item container xs={12} alignItems="flex-start">
              <Grid item xs={10}>
                <Typography className={styles.label}>
                  {t('tariffPlans.freePeriod')}
                </Typography>
                <Typography className={styles.subLabel}>
                  {t('tariffPlans.freePeriodInfo')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  type="secondary"
                  disabled={isDisabledUpdate || !hasTariffUpdateRight}
                  autoComplete="off"
                  inputProps={{
                    type: 'number',
                    min: 0,
                  }}
                  error={errors.trial}
                  name="trial"
                  value={fields.trial}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="flex-start">
              <Grid item xs={10}>
                <Typography className={styles.label}>
                  {t('tariffPlans.freeExitPeriod')}
                </Typography>
                <Typography className={styles.subLabel}>
                  {t('tariffPlans.freeExitPeriodInfo')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  type="secondary"
                  disabled={isDisabledUpdate || !hasTariffUpdateRight}
                  autoComplete="off"
                  inputProps={{
                    type: 'number',
                    min: 0,
                  }}
                  error={errors.exitTime}
                  name="exitTime"
                  value={fields.exitTime}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="flex-start">
              <Grid item xs={9}>
                <Typography className={styles.label}>
                  {t('tariffPlans.changeTypePeriod')}
                </Typography>
                <Typography className={styles.subLabel}>
                  {t('tariffPlans.changeTypePeriodInfo')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <ItemSelectors
                  selectorsType="secondary"
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  items={TARIFF_PLAN_CHANGE_TYPE_LIST}
                  disabled={isDisabledUpdate || !hasTariffUpdateRight}
                  name="changeType"
                  onChange={onChange}
                  currentValue={fields.changeType}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={8}>
                <Typography className={styles.label}>
                  {t('tariffPlans.typeSelect')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <ItemSelectors
                  selectorsType="secondary"
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  items={TARIFF_PLAN_TYPE_LIST}
                  disabled={isDisabledUpdate || !hasTariffUpdateRight}
                  name="type"
                  onChange={handleChangeType}
                  currentValue={fields.type}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Container className={styles.container} maxWidth="md">
        <Grid container item spacing={2} className={styles.pricesContainer}>
          <Grid item xs={12}>
            <TariffPlanSchedules
              disabled={isDisabledUpdate || !hasTariffUpdateRight}
              type={fields.type}
              schedules={fields.schedules}
              activeTariffs={activeTariffsData}
              onChange={handleSchedulesChange}
            />
          </Grid>
        </Grid>
      </Container>
      <Container className={styles.container} maxWidth="sm">
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <ItemSelectors
                selectorsType="secondary"
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={availableStatuses}
                disabled={!hasTariffUpdateRight}
                name="status"
                onChange={onChange}
                currentValue={fields.status}
              />
            </Grid>
            {hasTariffUpdateRight && (
              <Grid item xs={3}>
                <CustomBtn
                  className={styles.saveBtn}
                  btnType="primaryBlue"
                  onClick={onSubmit}
                  disabled={isSaveLoading}
                >
                  {t('tariffs.save')}
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  const { tariffs } = state || {};
  const {
    oneTariffPlan: oneTariffPlanState,
    setTariffPlan: setTariffPlanState,
    activeTariffs: activeTariffsState,
  } = tariffs || {};

  return {
    oneTariffPlanState,
    setTariffPlanState,
    activeTariffsState,
  };
}

const ConnectedOneTariffPage = connect(
  mapStateToProps,
  {
    getOneTariffPlanReq: getOneTariffPlanAction,
    getActiveTariffsReq: getActiveTariffsAction,
    setTariffPlanReq: setTariffPlanAction,
  },
)(OneTariffPlanPage);

export default ConnectedOneTariffPage;
