import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllTariffPlansPage from 'components/pages/settings/tariffPlans/AllTariffPlansPage';
import OneTariffPlanPage from 'components/pages/settings/tariffPlans/OneTariffPlanPage';

import { PATH_PAGES } from 'config/constants';

function TariffPlansPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
  } = currentFacilityState || {};

  const urlActions = PATH_PAGES.allTariffPlansSettings.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.allTariffPlansSettings} component={AllTariffPlansPage} />
      <PrivateRoute exact path={PATH_PAGES.newTariffPlanSettings} component={OneTariffPlanPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTariffPlanSettings} component={OneTariffPlanPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedTariffsPage = connect(
  mapStateToProps,
  {
  },
)(TariffPlansPage);

export default ConnectedTariffsPage;
