import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useQueryClient } from '@tanstack/react-query';
import {
  NumberParam, ObjectParam, StringParam, withDefault,
} from 'use-query-params';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { useActivePassTypes, usePasses } from 'api/query/passes';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import {
  PASSES_STATUSES, PASSES_STATUSES_MAP, PASSES_TYPES, PATH_PAGES, RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useDebounce } from 'helpers/hooks';
import {
  createEnumDelimitedNumericArrayParam, PageParam, withParams, withQueryParams,
} from 'helpers/queryParams';

import PassesContent from './PassesContent';
import { useStyles } from './styles';

const STATUS_ITEMS = [
  { id: PASSES_STATUSES.draft, title: PASSES_STATUSES_MAP[PASSES_STATUSES.draft] },
  { id: PASSES_STATUSES.awaitingPayment, title: PASSES_STATUSES_MAP[PASSES_STATUSES.awaitingPayment] },
  { id: PASSES_STATUSES.active, title: PASSES_STATUSES_MAP[PASSES_STATUSES.active] },
  { id: PASSES_STATUSES.canceled, title: PASSES_STATUSES_MAP[PASSES_STATUSES.canceled] },
  { id: PASSES_STATUSES.finished, title: PASSES_STATUSES_MAP[PASSES_STATUSES.finished] },
];

function getInitParams(initParams) {
  return {
    sorting: {},
    tabValue: 0,
    search: '',
    place: '',
    status: [],
    passType: 'unselect',
    dateFrom: '',
    dateTo: '',
    page: 1,
    ...initParams,
  };
}

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

const TABS = [
  { id: '', label: 'Все' },
  { id: PASSES_TYPES.active, label: 'Активные' },
  { id: PASSES_TYPES.overdue, label: 'Просроченные' },
  { id: PASSES_TYPES.archive, label: 'Завершенные' },
];

function prepareParam({
  params,
}) {
  const {
    sorting,
    page,
    tabValue,
    search: searchValue,
    place: placeValue,
    dateTo,
    dateFrom,
    status,
    passType,
    forceType,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  const currentTab = TABS[tabValue || 0] || {};
  const type = forceType || currentTab.id;

  const search = (searchValue || '').trim();
  const place = (placeValue || '').trim();

  return {
    page,
    type,
    tabValue,
    search,
    place,
    status,
    passType,
    dateTo,
    dateFrom,
    ...sortParam,
  };
}

function Passes({
  showControls = false,
  showPagination = false,
  facilityId,

  title,
  showTitleIfNotEmpty = false,
  showContentIfEmpty = true,

  // From HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();

  const variables = useDebounce(params, 300);

  const { data: passes, isLoading } = usePasses({
    variables: { ...variables, facilityId },
    refetchInterval: 1000 * 60 * 30, // 30 min
    refetchIntervalInBackground: true,
  });

  const {
    data: activePassTypesData,
    isLoading: isActivePassTypesLoading,
  } = useActivePassTypes({
    variables: { facilityId, simple: true },
  });

  const hasPassUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passUpdate, facilityId);

  const activePassTypesItems = useMemo(() => (activePassTypesData || []).map(({ id, name }) => ({
    id,
    title: name,
  })), [activePassTypesData]);

  const {
    passesData,
    passesMeta,
  } = (passes || {});

  const {
    sorting,
    tabValue,
    search,
    place,
    status,
    passType,
    dateFrom,
    dateTo,
  } = params;

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params]);

  function sortingSettingsChange({ headerNameId }) {
    const nextSortStatus = getNextSortStatus(sorting[headerNameId]);
    if (nextSortStatus === null) {
      handleParamChange('sorting', {});
      return;
    }

    handleParamChange('sorting', {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    });
  }

  const handleDateChange = ({ value, name }) => {
    handleParamChange(name, toNativeTimeFormat(value));
  };

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;

    handleParamChange(propName, value);
  }, [handleParamChange]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  return (
    <Grid container spacing={2}>
      {showControls && (
        <FiltersContainer>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomTextInput
                  style={{ margin: 0 }}
                  name="search"
                  label="Поиск"
                  value={search}
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <DateTimeInput
                  className={[
                    styles.datePicker,
                    // (errors || {}).startDate ? styles.datePickerWithError : '',
                  ].join(' ')}
                  name="dateFrom"
                  placeholder="Не выбрано"
                  label="От"
                  initStartDayTime
                  rightIcon={(
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleParamChange('dateFrom', '');
                        }}
                      >
                        <CloseIcon htmlColor="#7E8AA7" />
                      </IconButton>
                      <CalendarIcon />
                    </>
                  )}
                  onChange={handleDateChange}
                  value={dateFrom || undefined}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <DateTimeInput
                  className={[
                    styles.datePicker,
                    // (errors || {}).startDate ? styles.datePickerWithError : '',
                  ].join(' ')}
                  name="dateTo"
                  placeholder="Не выбрано"
                  label="До"
                  initStartDayTime
                  rightIcon={(
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleParamChange('dateTo', '');
                        }}
                      >
                        <CloseIcon htmlColor="#7E8AA7" />
                      </IconButton>
                      <CalendarIcon />
                    </>
                  )}
                  onChange={handleDateChange}
                  value={dateTo || undefined}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="passType"
                  currentValue={passType}
                  items={activePassTypesItems}
                  defaultItem="Тип не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomTextInput
                  style={{ margin: 0 }}
                  name="place"
                  label="Место"
                  value={place}
                  onChange={handleChanges}
                />
              </Grid>
              {tabValue === 0 && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <MultiItemSelectors
                    classNameForm={styles.statusSelectForm}
                    className={styles.statusSelect}
                    name="status"
                    currentValue={status}
                    items={STATUS_ITEMS}
                    unselectText="Статус не выбран"
                    onChange={handleChanges}
                  />
                </Grid>
              )}
              {hasPassUpdateRight && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <CustomBtn
                        className={styles.btn}
                        btnType="primaryGreen"
                        onClick={() => history.push(PATH_PAGES.addPass.replace(':facilityId', facilityId))}
                      >
                        Добавить абонемент
                      </CustomBtn>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTabBar
              tabs={TABS}
              tabValue={tabValue}
              handleParamChange={(paramName, newValue) => {
                if (newValue !== 0) {
                  handleParamChange('status', []);
                }

                handleParamChange(paramName, newValue);
              }}
            />
          </Grid>
        </FiltersContainer>
      )}
      {title && ((passesData || []).length !== 0 || !showTitleIfNotEmpty) && (
        <Grid item xs={12}>
          <Typography className={styles.title}>{title}</Typography>
        </Grid>
      )}
      <PassesContent
        showIfNotEmpty={showContentIfEmpty}
        facilityId={facilityId}
        isLoading={isLoading}
        passesData={passesData}
        passesMeta={passesMeta}
        showPagination={showPagination}
        onPageChange={onPageChange}
        sortingSettingsChange={sortingSettingsChange}
        sorting={sorting}
        onNeedUpdate={() => {
          queryClient.invalidateQueries({ queryKey: ['passes'] });
        }}
      />
    </Grid>
  );
}

export const PassesWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(Passes);

const StatusArrayEnumParam = createEnumDelimitedNumericArrayParam([
  PASSES_STATUSES.draft,
  PASSES_STATUSES.awaitingPayment,
  PASSES_STATUSES.active,
  PASSES_STATUSES.canceled,
  PASSES_STATUSES.finished,
]);

const PassesWithQueryParams = withQueryParams({
  queryParams: {
    dateTo: withDefault(StringParam, ''),
    dateFrom: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    place: withDefault(StringParam, ''),
    tabValue: withDefault(NumberParam, 0),
    status: withDefault(StatusArrayEnumParam, []),
    passType: withDefault(StringParam, 'unselect'),
    page: PageParam,
    sorting: withDefault(ObjectParam, {}),
  },
  prepareParam,
})(Passes);

export default PassesWithQueryParams;
