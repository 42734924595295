import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';

import { useFormFields } from '@parkly/shared/helpers';
import { setControllerAction } from 'actions/controllers';
import { setController, testNewController } from 'api/controllers';

import { CONTROLLER_TYPES } from 'config/constants';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { useStyles } from './styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useOpenVpnClients } from 'api/query/openvpn';

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeZKTecoCameraControllerModalContent({
  id,
  facilityId,
  onClose = () => {},

  allControllersState,

  testNewControllerApi = testNewController,
  setControllerApi = setController,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [isTestLoading, setIsTestLoading] = useState(false);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [base64Image, setBase64Image] = useState('');
  const [cameraSettings, setCameraSettings] = useState([]);

  const { data: vpnClients } = useOpenVpnClients({ variables: { facilityId } });
  const vpnItems = useMemo(() => (vpnClients || []).map(({ id, ip }) => ({ id, title: ip })), [vpnClients]);


  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: 'ZKTeco',
      host: '',
      httpPort: '80',
      login: '',
      password: '',
      rtspPort: '554',
      vpn: vpnItems.length === 1 ? vpnItems[0].id : '',
      isVpn: true,
    },
  });

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        name: controller.name || 'ZKTeco',
        login: controller.settings.login || '',
        password: controller.settings.password || '',
        host: controller.settings.host || '',
        rtspPort: controller.settings.rtspPort || '',
        httpPort: controller.settings.httpPort || '',
        vpn: controller.settings.vpn || '',
        isVpn: !!fields.vpn,
      });
    }
  }, [id, facilityId, updateState, allControllersState]);

  const handleNextStep = useCallback(async () => {
    const isVpn = fields.isVpn;
    const isLoginValid = fields.login.length > 0;
    const isPasswordChecked = fields.password.length > 0;
    const isHostValid = isVpn ? true : fields.host.length > 0;
    const isRtspPortValid = fields.rtspPort.length > 0;
    const isHttpPortValid = fields.httpPort.length > 0;

    if (!isLoginValid || !isPasswordChecked || !isHostValid || !isRtspPortValid) {
      setErrors({
        login: !isLoginValid,
        password: !isPasswordChecked,
        host: !isHostValid,
        rtspPort: !isRtspPortValid,
        httpPort: !isHttpPortValid,
      });
      return;
    }

    setIsTestLoading(true);

    testNewControllerApi({
      facilityId,
      type: CONTROLLER_TYPES.zktecoCamera,
      settings: {
        login: fields.login,
        password: fields.password,
        host: !fields.isVpn ? fields.host : undefined,
        rtspPort: fields.rtspPort,
        httpPort: fields.httpPort,
        vpn: fields.isVpn ? fields.vpn : undefined,
      },
    }).then(({ data, success }) => {
      if (!success) {
        setErrors({
          login: true,
          password: true,
          host: true,
          rtspPort: true,
          httpPort: true,
        });
        setIsTestLoading(false);
        return;
      }

      const {
        image,
      } = data || {};

      setBase64Image(image);
      setIsTestLoading(false);
      setStep(1);
    }).catch((error) => {
      setIsTestLoading(false);
    });
  }, [facilityId, fields, setErrors, testNewControllerApi]);

  const handleSetController = useCallback(() => {
    setIsSetLoading(true);
    setControllerApi({
      id,
      facilityId,
      type: CONTROLLER_TYPES.zktecoCamera,
      name: fields.name ? fields.name : 'ZKTeco',
      settings: {
        host: !fields.isVpn ? fields.host : undefined,
        login: fields.login,
        password: fields.password,
        rtspPort: fields.rtspPort,
        httpPort: fields.httpPort,
        vpn: fields.isVpn ? fields.vpn : undefined,
      },
    }).then(({ success, data }) => {
      setIsSetLoading(false);
      if (success) {
        if(data.length) {
          setStep(2);
          setCameraSettings(data);
        } else {
          onClose();
        }
      }
    }).catch(() => {
      setIsSetLoading(false);
    });
  }, [facilityId, fields, id, setControllerApi]);

  return (
    <CustomModalContentWrapper
      title="Подключение ZKTeco"
      onClose={onClose}
      paperClassname={[step === 2 ? styles.zktecoSettings : ''].join(' ')}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Введите данные
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.name}
              name="name"
              label="Имя контроллера"
              value={fields.name}
              onChange={onChange}
            />
          </Grid>
          {fields.isVpn ? (
            <Grid item xs={9}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                error={errors.vpn}
                name="vpn"
                label="Хост"
                items={vpnItems}
                value={fields.vpn}
                onChange={onChange}
              />
            </Grid>
          ) : (
            <Grid item xs={9}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.host}
                name="host"
                label="Хост"
                value={fields.host}
                onChange={onChange}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={fields.isVpn}
                  name="isVpn"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label="VPN"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.httpPort}
              name="httpPort"
              label="Порт"
              value={fields.httpPort}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.login}
              name="login"
              label="Логин"
              value={fields.login}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputPasswordItem}
              error={errors.password}
              type="passwordMightVisible"
              name="password"
              label="Пароль"
              value={fields.password}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputPasswordItem}
              error={errors.rtspPort}
              name="rtspPort"
              label="RTSP порт"
              value={fields.rtspPort}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleNextStep}
                  disabled={isTestLoading}
                >
                  Проверить
                  {isTestLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.subTitle}>
              Фото
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              src={base64Image}
              width="100%"
              alt="Image from camera"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="secondary"
                  className={styles.btn}
                  onClick={() => setStep(0)}
                >
                  Назад
                </CustomBtn>
              </Grid>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleSetController}
                  disabled={isSetLoading}
                >
                  {id ? 'Сохранить' : 'Добавить'}
                  {isSetLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.subTitle}>
              Проверьте настройки камеры
            </Typography>
          </Grid>
          <Grid className={styles.gridContainer} container item spacing={1} xs={12}>
            {(cameraSettings || []).map(({ title, value }, index) => (
              <GridTitleBase
                key={index}
                classNameContainer={styles.itemContainer}
                title={title}
                titleSm={4}
              >
                <Typography>
                  {value}
                </Typography>
              </GridTitleBase>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={onClose}
                >
                  Готово
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController: setControllerState,
    allControllers,
  } = controllers || {};

  return {
    setControllerState,
    allControllersState: allControllers,
  };
}

const ConnectedMakeZKTecoCameraControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
  },
)(MakeZKTecoCameraControllerModalContent);

export default ConnectedMakeZKTecoCameraControllerModalContent;
