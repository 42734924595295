import React, { useState } from 'react';

import Modal from '@material-ui/core/Modal';

const REASON_BACKDROP_CLICK = 'backdropClick';

export function useModal({
  content,
  onClose = () => {},
  disableBackdropClick = false,
  enablePortal = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [additionalProps, setAdditionalProps] = useState({});

  const openModal = (props) => {
    setIsOpen(true);
    setAdditionalProps(props || {});
  };
  const hideModal = (_, reason) => {
    if (disableBackdropClick && reason === REASON_BACKDROP_CLICK) {
      return;
    }

    setIsOpen(false);
    onClose();
  };

  const contentWithAdditionalProps = React.isValidElement(content)
    ? React.cloneElement(content, additionalProps)
    : content;

  const node = (
    <Modal
      open={isOpen}
      onClose={hideModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      disablePortal={!enablePortal}
    >
      {contentWithAdditionalProps}
    </Modal>
  );

  return [
    node,
    openModal,
    hideModal,
    isOpen,
  ];
}

export default {
  useModal,
};
