import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import AnalyticBarChart from 'components/organisms/AnalyticBarChart';

import {
  getAnalyticsAvgCheckStatsAction,
  getAnalyticsAvgSessionDurationStatsAction,
  getAnalyticsRevenueStatsAction,
  getAnalyticsSessionsStatsAction,
} from 'actions/analytics';

import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { ANALYTIC_TYPES, MODULES_KEYS } from 'config/constants';

import { useHasModule } from '../../../helpers/hooks';
import { useStyles } from './styles';

const TABS_MEASUREMENT_UNITS = {
  [ANALYTIC_TYPES.revenue]: '₽',
  [ANALYTIC_TYPES.avgCheck]: '₽',
  [ANALYTIC_TYPES.sessions]: 'шт.',
  [ANALYTIC_TYPES.avgSessionDuration]: 'мин.',
};

function getCurrentStats({ statsListState, facilityId, period }) {
  const { loading: isLoading, list: statsList, error: statsError } = statsListState || {};
  const currentStrParam = JSON.stringify({
    facilityId,
    period,
  });

  const statsRes = statsList.find((currentItem) => {
    const { strParam } = currentItem || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    statsRes,
    statsError,
  };
}

function AnalyticsPage({
  match,

  getAnalyticsRevenueStatsReq,
  statsRevenueListState,

  getAnalyticsAvgCheckStatsReq,
  statsAvgCheckListState,

  getAnalyticsSessionsStatsReq,
  statsSessionsListState,

  getAnalyticsAvgSessionDurationStatsReq,
  statsAvgSessionDurationListState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  useCurrentFacilityMatch();
  const [selPeriod, setSelPeriod] = useState('week');
  const { params } = match || {};
  const { facilityId } = params || {};

  const hasPaymentModule = useHasModule(facilityId, MODULES_KEYS.payments);

  useEffect(() => {
    if (!hasPaymentModule) {
      return;
    }

    const { statsRes, isLoading, statsError } = getCurrentStats({
      statsListState: statsAvgCheckListState,
      facilityId,
      period: selPeriod,
    });

    const nowTime = new Date().getTime();
    const { timeStamp = nowTime } = statsRes || {};
    const isUpdTime = (nowTime - timeStamp) / 1000 < 60 * 15;
    if (isLoading || (statsRes && isUpdTime) || statsError) {
      return;
    }

    getAnalyticsAvgCheckStatsReq({
      facilityId,
      period: selPeriod,
    });
  }, [facilityId, getAnalyticsAvgCheckStatsReq, selPeriod, statsAvgCheckListState, hasPaymentModule]);

  useEffect(() => {
    if (!hasPaymentModule) {
      return;
    }

    const { statsRes, isLoading, statsError } = getCurrentStats({
      statsListState: statsRevenueListState,
      facilityId,
      period: selPeriod,
    });

    const nowTime = new Date().getTime();
    const { timeStamp = nowTime } = statsRes || {};
    const isUpdTime = (nowTime - timeStamp) / 1000 < 60 * 15;
    if (isLoading || (statsRes && isUpdTime) || statsError) {
      return;
    }

    getAnalyticsRevenueStatsReq({
      facilityId,
      period: selPeriod,
    });
  }, [facilityId, getAnalyticsRevenueStatsReq, selPeriod, statsRevenueListState, hasPaymentModule]);

  useEffect(() => {
    const { statsRes, isLoading, statsError } = getCurrentStats({
      statsListState: statsSessionsListState,
      facilityId,
      period: selPeriod,
    });

    const nowTime = new Date().getTime();
    const { timeStamp = nowTime } = statsRes || {};
    const isUpdTime = (nowTime - timeStamp) / 1000 < 60 * 15;
    if (isLoading || (statsRes && isUpdTime) || statsError) {
      return;
    }

    getAnalyticsSessionsStatsReq({
      facilityId,
      period: selPeriod,
    });
  }, [facilityId, getAnalyticsSessionsStatsReq, selPeriod, statsSessionsListState]);

  useEffect(() => {
    const { statsRes, isLoading, statsError } = getCurrentStats({
      statsListState: statsAvgSessionDurationListState,
      facilityId,
      period: selPeriod,
    });

    const nowTime = new Date().getTime();
    const { timeStamp = nowTime } = statsRes || {};
    const isUpdTime = (nowTime - timeStamp) / 1000 < 60 * 15;
    if (isLoading || (statsRes && isUpdTime) || statsError) {
      return;
    }

    getAnalyticsAvgSessionDurationStatsReq({
      facilityId,
      period: selPeriod,
    });
  }, [facilityId, getAnalyticsAvgSessionDurationStatsReq, selPeriod, statsAvgSessionDurationListState]);

  const handleRangeChange = useCallback((event, newValue) => {
    setSelPeriod(newValue || 'week');
  }, []);

  const { statsRes: statsRevenueRes, isLoading: isRevenueLoading } = getCurrentStats({
    statsListState: statsRevenueListState,
    facilityId,
    period: selPeriod,
  });
  const { data: statsRevenueData = [] } = statsRevenueRes || {};

  const { statsRes: statsAvgCheckRes, isLoading: isAvgCheckLoading } = getCurrentStats({
    statsListState: statsAvgCheckListState,
    facilityId,
    period: selPeriod,
  });
  const { data: statsAvgCheckData = [] } = statsAvgCheckRes || {};

  const { statsRes: statsSessionsRes, isLoading: isSessionsLoading } = getCurrentStats({
    statsListState: statsSessionsListState,
    facilityId,
    period: selPeriod,
  });
  const { data: statsSessionsData = [] } = statsSessionsRes || {};

  const { statsRes: statsAvgSessionDurationRes, isLoading: isAvgSessionDurationLoading } = getCurrentStats({
    statsListState: statsAvgSessionDurationListState,
    facilityId,
    period: selPeriod,
  });
  const { data: statsAvgSessionDurationData = [] } = statsAvgSessionDurationRes || {};

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn title={t('analytics.analytics')} isBackBtn={false} />
      <ToggleButtonGroup className={styles.rangeContainer} value={selPeriod} exclusive onChange={handleRangeChange}>
        <ToggleButton value="week">
          <Typography>7 дней</Typography>
        </ToggleButton>
        <ToggleButton value="month">
          <Typography>30 дней</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      {hasPaymentModule && (
        <div style={{ position: 'relative' }}>
          <Typography className={styles.title}>{t('analytics.revenue')}</Typography>
          <div className={styles.chartContainer}>
            <AnalyticBarChart
              barChartData={statsRevenueData}
              measurementUnit={TABS_MEASUREMENT_UNITS[ANALYTIC_TYPES.revenue]}
            />
          </div>
          {isRevenueLoading && statsRevenueData.length < 1 && (
            <CircularIndeterminate
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: 400,
                width: 1060,
              }}
            />
          )}
        </div>
      )}
      {hasPaymentModule && (
        <div style={{ position: 'relative' }}>
          <Typography className={styles.title}>{t('analytics.avg-check')}</Typography>
          <div className={styles.chartContainer}>
            <AnalyticBarChart
              barChartData={statsAvgCheckData}
              measurementUnit={TABS_MEASUREMENT_UNITS[ANALYTIC_TYPES.avgCheck]}
            />
          </div>
          {isAvgCheckLoading && statsAvgCheckData.length < 1 && (
            <CircularIndeterminate
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: 400,
                width: 1060,
              }}
            />
          )}
        </div>
      )}
      <div style={{ position: 'relative' }}>
        <Typography className={styles.title}>{t('analytics.sessions')}</Typography>
        <div className={styles.chartContainer}>
          <AnalyticBarChart
            barChartData={statsSessionsData}
            measurementUnit={TABS_MEASUREMENT_UNITS[ANALYTIC_TYPES.sessions]}
          />
        </div>
        {isSessionsLoading && statsSessionsData.length < 1 && (
          <CircularIndeterminate
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 400,
              width: 1060,
            }}
          />
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <Typography className={styles.title}>{t('analytics.avg-session-duration')}</Typography>
        <div className={styles.chartContainer}>
          <AnalyticBarChart
            barChartData={statsAvgSessionDurationData}
            measurementUnit={TABS_MEASUREMENT_UNITS[ANALYTIC_TYPES.avgSessionDuration]}
          />
        </div>
        {isAvgSessionDurationLoading && statsAvgSessionDurationData.length < 1 && (
          <CircularIndeterminate
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 400,
              width: 1060,
            }}
          />
        )}
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  const { analytics } = state || {};
  const { statsRevenueList, statsAvgCheckList, statsSessionsList, statsAvgSessionDurationList } = analytics || {};

  return {
    statsRevenueListState: statsRevenueList,
    statsAvgCheckListState: statsAvgCheckList,
    statsSessionsListState: statsSessionsList,
    statsAvgSessionDurationListState: statsAvgSessionDurationList,
  };
}

const ConnectedAnalyticsPage = connect(mapStateToProps, {
  getAnalyticsRevenueStatsReq: getAnalyticsRevenueStatsAction,
  getAnalyticsAvgCheckStatsReq: getAnalyticsAvgCheckStatsAction,
  getAnalyticsSessionsStatsReq: getAnalyticsSessionsStatsAction,
  getAnalyticsAvgSessionDurationStatsReq: getAnalyticsAvgSessionDurationStatsAction,
})(AnalyticsPage);

export default ConnectedAnalyticsPage;
