import React, { useState } from 'react';
import { CircularProgress, Modal } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

export default function ImageViewer({
  url,
  open,
  onHide = () => {},
  isLoading,
  notFound,
}) {
  const styles = useStyles();

  return (
    <Modal open={open} onClose={onHide}>
      <div
        className={styles.wrapper}
        onClick={onHide}
      >
        <IconButton
          onClick={onHide}
          size="medium"
          className={styles.close}
        >
          <CloseIcon color="secondary" fontSize="large" />
        </IconButton>
        <div className={styles.content}>
          <div className={styles.slide}>
            {(isLoading) && (
              <CircularProgress color="secondary" />
            )}
            {notFound && !url && (
              <Typography color="secondary" style={{ fontSize: '1.5rem' }}>Не удалось получить изображение</Typography>
            )}
            {url && (
              <img
                src={url}
                alt=""
                className={styles.image}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function useImageViewer() {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);

  const handleOpen = (url) => {
    setUrl(url);
    setOpen(true);
  };

  const handleHide = () => {
    setOpen(false);
    setUrl(null);
  };

  const node = (
    <ImageViewer
      url={url}
      open={open}
      onHide={handleHide}
    />
  );

  return [node, handleOpen];
}
