import { updateStoreReqRes } from '@parkly/shared/helpers';
import {
  CLEAR_TARIFFS_CACHE, GET_ACTIVE_TARIFF_PLANS, GET_ACTIVE_TARIFFS, GET_ALL_TARIFF_PLANS,
  GET_ALL_TARIFFS,
  GET_ONE_TARIFF, GET_ONE_TARIFF_PLAN,
  SET_ONE_TARIFF, SET_ONE_TARIFF_PLAN,
} from 'actions/types';

const INITIAL_STATE = {
  allTariffs: {
    loading: false,
    list: [],
  },
  activeTariffs: {
    loading: false,
    list: [],
  },
  oneTariff: {
    loading: false,
    list: [],
  },
  setTariff: {
    loading: false,
  },
  activeTariffPlans: {
    loading: false,
    list: [],
  },
  allTariffPlans: {
    loading: false,
    list: [],
  },
  oneTariffPlan: {
    loading: false,
    list: [],
  },
  setTariffPlan: {
    loading: false,
  },
};

function updateOneList({
  list,
  newData,
  error,
}) {
  const { id } = newData || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newData || error,
  ];

  return newList;
}

function getSetState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function tariffs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_TARIFFS:
      return updateStoreReqRes(
        state,
        'allTariffs',
        action,
      );

    case GET_ACTIVE_TARIFFS:
      return updateStoreReqRes(
        state,
        'activeTariffs',
        action,
      );

    case GET_ONE_TARIFF:
      return {
        ...state,
        oneTariff: {
          ...state.oneTariff,
          loading: action.payload.loading,
          list: updateOneList({
            list: state.oneTariff.list,
            newData: action.payload.tariff,
            error: action.payload.error,
          }),
        },
      };

    case SET_ONE_TARIFF:
      return {
        ...state,
        setTariff: getSetState({
          prevState: state.setTariff,
          payload: action.payload,
        }),
      };

    case GET_ACTIVE_TARIFF_PLANS:
      return updateStoreReqRes(
        state,
        'activeTariffPlans',
        action,
      );

    case GET_ALL_TARIFF_PLANS:
      return updateStoreReqRes(
        state,
        'allTariffPlans',
        action,
      );

    case GET_ONE_TARIFF_PLAN:
      return {
        ...state,
        oneTariffPlan: {
          ...state.oneTariffPlan,
          loading: action.payload.loading,
          list: updateOneList({
            list: state.oneTariffPlan.list,
            newData: action.payload.tariffPlan,
            error: action.payload.error,
          }),
        },
      };

    case SET_ONE_TARIFF_PLAN:
      return {
        ...state,
        setTariffPlan: getSetState({
          prevState: state.setTariffPlan,
          payload: action.payload,
        }),
      };

    case CLEAR_TARIFFS_CACHE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
