import React from 'react';

function ArrowChevronIcon({
  className = '',
  size = 24,
  isRight = false,
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d={isRight ? 'M10 17l5-5-5-5' : 'M14 17l-5-5 5-5'}
        stroke="#72809F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowChevronIcon;
