import React, { useCallback, useState } from 'react';

import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useFormFields } from '@parkly/shared/helpers';
import { addPassPayment } from 'api/passes';
import { useDispatch } from 'react-redux';
import { clearPassesCacheAction } from 'actions/passes';
import { useStyles } from './styles';


function ManualPayPassModalContent({
  passId,
  facilityId,
  price,
  onClose = () => {},
  onComplete = () => {},
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      amount: price || 0,
    },
  });
  const styles = useStyles();

  const handlePay = useCallback(() => {
    if (!passId || !facilityId) {
      return;
    }

    if (fields.amount <= 0) {
      setErrors({
        amount: true,
      });
      return;
    }

    setIsLoading(true);
    addPassPayment({ facilityId, id: passId, amount: fields.amount })
      .then(({ id, success }) => {
        setIsLoading(false);
        if (success) {
          onComplete();
          dispatch(clearPassesCacheAction());
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [dispatch, facilityId, fields.amount, onComplete, passId, setErrors]);

  return (
    <CustomModalContentWrapper
      title="Оплата абонемента"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Grid container item spacing={2}>
          <Grid item xs={7}>
            <CustomTextInput
              required
              className={styles.inputItem}
              error={errors.amount}
              name="amount"
              label="Сумма"
              type="number"
              value={fields.amount}
              autoComplete="off"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={5}>
            <CustomBtn
              className={styles.saveBtn}
              btnType="primaryGreen"
              onClick={handlePay}
              disabled={isLoading}
            >
              Оплатить
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      </div>
    </CustomModalContentWrapper>
  );
}

export default ManualPayPassModalContent;
