import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SECTIONS } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'grid',
    [theme.breakpoints.down('xs')]: {
      gridTemplateAreas: `
        "navbar"
        "topbar"
        "body"
      `,
      gridTemplateRows: '56px min-content 1fr',
      gridTemplateColumns: '1fr',
      width: '100%',
    },
    gridTemplateAreas: `
      "navbar topbar topbar"
      "navbar filters right-panel"
      "navbar body right-panel"
    `,
    gridTemplateRows: 'min-content min-content 1fr',
    gridTemplateColumns: 'min-content 1fr min-content',
    flex: 'auto',
    height: '100dvh',
  },
  nav: {
    gridArea: 'navbar',
  },
  topbar: {
    gridArea: 'topbar',
    overflowX: 'auto',
  },
  filters: {
    gridArea: 'filters',
  },
  content: {
    gridArea: 'body',
    overflow: 'hidden',
    height: '100%',
  },
  contentContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
  },
  rightPanel: {
    gridArea: 'right-panel',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
}));

const propTypes = {
  navigation: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function PageContainer({
  navigation,
  children,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <nav className={classes.nav}>
        {navigation}
      </nav>
      <section className={classes.topbar} id={SECTIONS.topBar} />
      <section className={classes.toolbar} />
      <section className={classes.filters} id={SECTIONS.filters} />
      <main className={classes.content}>
        <div className={classes.contentContainer}>
          {children}
        </div>
      </main>
      <section className={classes.rightPanel} id={SECTIONS.right} />
    </div>
  );
}

PageContainer.propTypes = propTypes;

export default PageContainer;
