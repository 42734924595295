import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CameraAltTwoToneIcon from '@material-ui/icons/CameraAltTwoTone';
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import { formatPhoneNumberInput } from '@parkly/shared/helpers';

import { useAccessImageViewer } from 'components/molecules/AccessImageViewer';
import Gate from 'components/molecules/Gate';
import CustomerFastActions from 'components/organisms/CustomerFastActions';
import {
  GATEWAY_EVENTS_TYPES,
  MAP_GATEWAY_EVENTS_STATUS_COLOR,
  MAP_GATEWAY_EVENTS_STATUS_NAME,
  MAP_GATEWAY_EVENTS_TYPES_NAME,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { GatewayEventImage } from 'helpers/authImage';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';

import { useImageViewer } from '../../atoms/ImageViewer';

import { useStyles } from './styles';

/* help function */

export const getSessionDateContent = (
  sessionData,
  facilityId,
  history,
  hasCustomerShowRight,
  hasPassShowRight,
) => {
  const {
    type,
    linkToType,
    linkToId,
  } = sessionData || {};

  if (!sessionData) {
    return (
      <Typography>-</Typography>
    );
  }

  let link = '';
  if (linkToId !== null) {
    switch (linkToType) {
      case 'pass':
        if (hasPassShowRight) {
          link = PATH_PAGES.onePassInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        }
        break;
      case 'ticket':
        if (hasPassShowRight) {
          link = PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        }
        break;
      case 'customer':
        if (hasCustomerShowRight) {
          link = PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        }
        break;
    }
  }

  let paylableName = '-';
  switch (type) {
    case 'pass':
      paylableName = 'Абонемент';
      break;
    case 'ticket':
      paylableName = 'Пропуск';
      break;
    case 'customer':
      paylableName = '';
      break;
    case 'session':
      paylableName = 'Сессия';
      break;
    default:
  }

  if (link) {
    return (
      <CustomLink
        href={link}
        onClick={(e) => {
          e.preventDefault();
          history.push(link);
        }}
      >
        {paylableName}
      </CustomLink>
    );
  }

  return (
    <Typography>
      {paylableName}
    </Typography>
  );
};

const getTokenContent = (token, eventType) => {
  if ([GATEWAY_EVENTS_TYPES.callAppeared, GATEWAY_EVENTS_TYPES.callDtmf, GATEWAY_EVENTS_TYPES.parkly, GATEWAY_EVENTS_TYPES.callOffline, GATEWAY_EVENTS_TYPES.laurentPhoneCall].includes(eventType)) {
    return formatPhoneNumberInput(token);
  }

  if (GATEWAY_EVENTS_TYPES.wiegand === eventType) {
    return (
      <>
        {token}
        <ContactlessOutlinedIcon htmlColor="grey" />
      </>
    );
  }

  return token;
};

function getHeaders({
  t,
  hideCustomer,
  hideRelation,
  hasFastActionsShowRight,
  hideControllers,
}) {
  const headers = [
    {
      id: 'gate',
      label: 'Проезд',
    },
    {
      id: 'eventAt',
      label: 'Время',
    },
    {
      id: 'token',
      label: 'Токен',
    },
    {
      id: 'photo',
      label: 'Фото',
    },
    !hideCustomer
      ? {
        id: 'customer',
        label: 'Клиент',
      } : null,
    !hideRelation ? {
      id: 'relation',
      label: 'Связь',
    } : null,
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'operator',
      label: 'Оператор',
    },

    !hideControllers
      ? {
        id: 'controller',
        label: 'Контроллер',
      } : null,
    hasFastActionsShowRight ? {
      id: 'actions',
      label: 'Действия',
    } : null,
  ];

  return headers.filter((item) => !!item);
}

function getRows(gatewayEventsData = []) {
  return gatewayEventsData.map((item) => item);
}

const propTypes = {};

function GatewayEventsTable({
  facilityId,
  gatewayEventsData,
  hideCustomer = false,
  hideRelation = false,
  sorting = {},
  sortingSettingsChange = () => {},
  hideControllers = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const rows = getRows(gatewayEventsData);
  const isGatewayEventsData = gatewayEventsData && (gatewayEventsData || []).length > 0;

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, facilityId);
  const hasFastActionsShowRight = useCheckFacilityRights(RIGHT_KEYS.customerSessionGateControl, facilityId);
  const hasOperatorShowRight = useCommonRights(RIGHT_KEYS.admin);

  const [imageViewerNode, openImageViewer] = useImageViewer();

  const headers = getHeaders({
    t,
    hideCustomer,
    hideRelation,
    hasFastActionsShowRight,
    hideControllers,
  });

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  return (
    <Paper className={classes.root}>
      {!isGatewayEventsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            Пока журнал проездов пуст
          </Typography>
        </div>
      )}
      {isGatewayEventsData && (
        <TableContainer className={classes.container}>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = sorting[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? classes.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={classes.headerContainer}>
                        <Typography className={classes.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={classes.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={classes.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  status,
                  type,
                  eventAt,
                  gates,
                  gatewayEventId,
                  photo,
                  customerName,
                  sessionData,
                  gatewayToken: gatewayTokens,
                  operator,
                  fastActions,
                  description,
                  controller,
                  port,
                  isFakeSession,
                } = row || {};

                const key = id;

                const eventTimeStr = eventAt ? moment(eventAt).format('HH:mm:ss') : '-';
                const eventDateStr = eventAt ? moment(eventAt).format('DD.MM.yyyy') : '-';

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      {(gates || []).map((gate) => (
                        <Gate key={gate.id} gate={gate} />
                      ))}
                      {(gates || []).length === 0 && (
                        <Typography>
                          -
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.timeStr}>
                        {eventTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {eventDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        {(gatewayTokens || []).length > 0 && photo && (
                          <IconButton size="small" onClick={() => openImageViewer(photo.url)}>
                            <CameraAltTwoToneIcon />
                          </IconButton>
                        )}
                        <div>
                          {(gatewayTokens || []).map((token, index) => (
                            <Typography key={`${index}_token`} style={{ display: 'flex', alignItems: 'center' }}>
                              {getTokenContent(token, type)}
                            </Typography>
                          ))}
                          {(gatewayTokens || []).length === 0 && (
                            <Typography>
                              -
                            </Typography>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {photo ? (
                        <Grid container spacing={1}>
                          <Grid item>
                            <img
                              className={classes.photo}
                              src={photo.thumbUrl}
                              onClick={() => openImageViewer(photo.url)}
                              />
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </TableCell>
                    {!hideCustomer && (
                      <TableCell align="left">
                        <Typography>
                          {customerName || '-'}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {getSessionDateContent(sessionData, facilityId, history, hasCustomerShowRight, hasPassShowRight)}
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip title={description || ''}>
                        <Typography className={classes.statusStr}>
                          <CircleIcon color={MAP_GATEWAY_EVENTS_STATUS_COLOR[status]} />
                          {description
                            ? `${MAP_GATEWAY_EVENTS_STATUS_NAME[status]}: ${description.charAt(0).toLowerCase() + description.slice(1)}`
                            : MAP_GATEWAY_EVENTS_STATUS_NAME[status]}
                          {isFakeSession && ' (cвободный выезд)'}
                        </Typography>
                      </Tooltip>
                      <Typography className={classes.typeStr}>{MAP_GATEWAY_EVENTS_TYPES_NAME[type]}</Typography>

                    </TableCell>
                    <TableCell align="left">
                      {operator ? (
                        <>
                          {hasOperatorShowRight ? (
                            <CustomLink
                              className={classes.operatorStr}
                              href={PATH_PAGES.oneOperator.replace(':id', operator.id)}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(PATH_PAGES.oneOperator.replace(':id', operator.id));
                              }}
                            >
                              {operator.name.replaceAll(' ', ' ')}
                            </CustomLink>
                          ) : (
                            <Typography className={classes.operatorStr}>
                              {operator.name.replaceAll(' ', ' ')}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </TableCell>
                    {!hideControllers && (
                      <TableCell align="left">
                        {port ? (
                          <>
                            <Typography className={classes.operatorStr}>
                              {(controller.name || controller.type || '').replaceAll(' ', ' ')}
                            </Typography>
                            <Typography className={classes.operatorStr}>
                              {port.name.replaceAll(' ', ' ')}
                            </Typography>
                          </>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </TableCell>
                    )}
                    {hasFastActionsShowRight && (
                      <TableCell align="center">
                        {(fastActions || []).length > 0 ? (
                          <CustomerFastActions
                            facilityId={facilityId}
                            gatewayEventId={gatewayEventId}
                            fastActions={fastActions}
                            asMenu
                          />
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {imageViewerNode}
    </Paper>
  );
}

GatewayEventsTable.propTypes = propTypes;

export default GatewayEventsTable;
