import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',

    width: 346,
    boxShadow: '0px 2px 12px rgba(29, 50, 98, 0.2)',
    borderRadius: '0 8px 8px 8px',
    marginTop: 3,
    background: '#FFFFFF',
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    height: 'auto',
    width: 1,
    margin: '16px 8px',

    '& div': {
      height: '100%',
      width: 1,
      background: theme.palette.greyC.gray2,
    },
  },

/* Day selector start */

  calendarBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    width: 240,
    padding: 8,
  },
  calendarMonthTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    height: 24,
    marginBottom: 12,
  },
  calendarMonthTitleText: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
  },

  weekDayNames: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginBottom: 4,
  },
  weekDayNameText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 32,
    height: 16,

    color: theme.palette.greyC.gray2,
  },

  weekRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  dayItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: 32,
    minWidth: 'unset',
    height: 32,
    borderRadius: '50%',
    background: '#FFFFFF',
    color: theme.palette.text.primary,

    fontSize: 13,
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.greyC.lightGrey,
    },
  },
  dayItemSelected: {
    background: theme.palette.blueC.blue,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.blueC.lightGrey,
    },

    '&.dayItemDisabled': {
      color: '#FFFFFF',
      '&:hover': {
        background: theme.palette.blueC.blue,
      },
    },
  },
  dayItemDisabled: {
    color: theme.palette.greyC.gray2,
    cursor: 'default',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  dayItemEmpty: {
    width: 32,
    height: 32,
    cursor: 'default',
  },

  /* Day selector finish */

  /* Time selector start */

  timesContainer: {
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',

    maxHeight: 230,
  },

  timesBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    height: '100%',
    paddingRight: 5,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  timesItem: {
    width: 56,
    height: 32,
    color: theme.palette.text.primary,
    borderRadius: 8,
    background: '#FFFFFF',

    fontWeight: 'bold',
    fontSize: 12,

    '&:hover': {
      background: theme.palette.greyC.lightGrey, // $greyUltraLight,
    },
  },
  timesItemSelected: {
    background: theme.palette.blueC.blue,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.blueC.blue,
    },
  },
}));

export default {
  useStyles,
};
