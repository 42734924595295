import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllBlackListPage from 'components/pages/blackList/AllBlackListPage';
import OneBlackListPage from 'components/pages/blackList/OneBlackListPage';

import { PATH_PAGES } from 'config/constants';

function BlackListPage() {
  useEffect(() => {
    moment.tz.setDefault();
  }, []);

  const urlActions = PATH_PAGES.allBlackList;

  return (
    <ParentPageContainer isLoading={false}>
      <PrivateRoute exact path={PATH_PAGES.allBlackList} component={AllBlackListPage} />
      <PrivateRoute exact path={PATH_PAGES.newBlackList} component={OneBlackListPage} />
      <PrivateRoute exact path={PATH_PAGES.oneBlackList} component={OneBlackListPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedBlackListPage = connect(
  mapStateToProps,
  {
  },
)(BlackListPage);

export default ConnectedBlackListPage;
