import { updateStoreReqRes } from '@parkly/shared/helpers';
import { CLEAR_DEVICES_CACHE, GET_DEVICES, OPEN_DEVICE } from 'actions/types';

const INITIAL_STATE = {
  allDevices: {
    loading: false,
    list: [],
  },
  openDevice: {
    loading: false,
  },
};

function getSetState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function devices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DEVICES:
      return updateStoreReqRes(
        state,
        'allDevices',
        action,
      );

    case OPEN_DEVICE:
      return {
        ...state,
        openDevice: getSetState({
          prevState: state.openDevice,
          payload: action.payload,
        }),
      };

    case CLEAR_DEVICES_CACHE:
      return {
        ...state,
        allDevices: INITIAL_STATE.allDevices,
        openDevice: INITIAL_STATE.openDevice,
      };

    default:
      return state;
  }
}
