import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 250;
export const collapsedDrawerWidth = 100;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    zIndex: 1150,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    [theme.breakpoints.up('sm')]: {
      width: (props) => (props.isCollapsed ? collapsedDrawerWidth : drawerWidth),
      flexShrink: 0,
    },
    zIndex: 998,
  },

}));

export default {
  drawerWidth,
  useStyles,
};
