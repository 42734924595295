import React from 'react';

function LedMatrixIcon({
                         size = 24,
                       }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z"
        fill="#1D3262"/>
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H5C5.55228 3 6 3.44772 6 4V5C6 5.55228 5.55228 6 5 6H4C3.44772 6 3 5.55228 3 5V4Z"
        fill="white"/>
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H5C5.55228 3 6 3.44772 6 4V5C6 5.55228 5.55228 6 5 6H4C3.44772 6 3 5.55228 3 5V4Z"
        fill="white"/>
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H5C5.55228 3 6 3.44772 6 4V5C6 5.55228 5.55228 6 5 6H4C3.44772 6 3 5.55228 3 5V4Z"
        fill="white"/>
      <path
        d="M18 4C18 3.44772 18.4477 3 19 3H20C20.5523 3 21 3.44772 21 4V5C21 5.55228 20.5523 6 20 6H19C18.4477 6 18 5.55228 18 5V4Z"
        fill="white"/>
      <path
        d="M18 4C18 3.44772 18.4477 3 19 3H20C20.5523 3 21 3.44772 21 4V5C21 5.55228 20.5523 6 20 6H19C18.4477 6 18 5.55228 18 5V4Z"
        fill="white"/>
      <path
        d="M18 4C18 3.44772 18.4477 3 19 3H20C20.5523 3 21 3.44772 21 4V5C21 5.55228 20.5523 6 20 6H19C18.4477 6 18 5.55228 18 5V4Z"
        fill="white"/>
      <path
        d="M8 4C8 3.44772 8.44772 3 9 3H10C10.5523 3 11 3.44772 11 4V5C11 5.55228 10.5523 6 10 6H9C8.44772 6 8 5.55228 8 5V4Z"
        fill="white"/>
      <path
        d="M8 4C8 3.44772 8.44772 3 9 3H10C10.5523 3 11 3.44772 11 4V5C11 5.55228 10.5523 6 10 6H9C8.44772 6 8 5.55228 8 5V4Z"
        fill="white"/>
      <path
        d="M8 4C8 3.44772 8.44772 3 9 3H10C10.5523 3 11 3.44772 11 4V5C11 5.55228 10.5523 6 10 6H9C8.44772 6 8 5.55228 8 5V4Z"
        fill="white"/>
      <path
        d="M18 19C18 18.4477 18.4477 18 19 18H20C20.5523 18 21 18.4477 21 19V20C21 20.5523 20.5523 21 20 21H19C18.4477 21 18 20.5523 18 20V19Z"
        fill="white"/>
      <path
        d="M18 19C18 18.4477 18.4477 18 19 18H20C20.5523 18 21 18.4477 21 19V20C21 20.5523 20.5523 21 20 21H19C18.4477 21 18 20.5523 18 20V19Z"
        fill="white"/>
      <path
        d="M18 19C18 18.4477 18.4477 18 19 18H20C20.5523 18 21 18.4477 21 19V20C21 20.5523 20.5523 21 20 21H19C18.4477 21 18 20.5523 18 20V19Z"
        fill="white"/>
      <path
        d="M13 19C13 18.4477 13.4477 18 14 18H15C15.5523 18 16 18.4477 16 19V20C16 20.5523 15.5523 21 15 21H14C13.4477 21 13 20.5523 13 20V19Z"
        fill="white"/>
      <path
        d="M13 19C13 18.4477 13.4477 18 14 18H15C15.5523 18 16 18.4477 16 19V20C16 20.5523 15.5523 21 15 21H14C13.4477 21 13 20.5523 13 20V19Z"
        fill="white"/>
      <path
        d="M13 19C13 18.4477 13.4477 18 14 18H15C15.5523 18 16 18.4477 16 19V20C16 20.5523 15.5523 21 15 21H14C13.4477 21 13 20.5523 13 20V19Z"
        fill="white"/>
      <path
        d="M3 14C3 13.4477 3.44772 13 4 13H5C5.55228 13 6 13.4477 6 14V15C6 15.5523 5.55228 16 5 16H4C3.44772 16 3 15.5523 3 15V14Z"
        fill="white"/>
      <path
        d="M3 14C3 13.4477 3.44772 13 4 13H5C5.55228 13 6 13.4477 6 14V15C6 15.5523 5.55228 16 5 16H4C3.44772 16 3 15.5523 3 15V14Z"
        fill="white"/>
      <path
        d="M3 14C3 13.4477 3.44772 13 4 13H5C5.55228 13 6 13.4477 6 14V15C6 15.5523 5.55228 16 5 16H4C3.44772 16 3 15.5523 3 15V14Z"
        fill="white"/>
      <path
        d="M18 14C18 13.4477 18.4477 13 19 13H20C20.5523 13 21 13.4477 21 14V15C21 15.5523 20.5523 16 20 16H19C18.4477 16 18 15.5523 18 15V14Z"
        fill="white"/>
      <path
        d="M18 14C18 13.4477 18.4477 13 19 13H20C20.5523 13 21 13.4477 21 14V15C21 15.5523 20.5523 16 20 16H19C18.4477 16 18 15.5523 18 15V14Z"
        fill="white"/>
      <path
        d="M18 14C18 13.4477 18.4477 13 19 13H20C20.5523 13 21 13.4477 21 14V15C21 15.5523 20.5523 16 20 16H19C18.4477 16 18 15.5523 18 15V14Z"
        fill="white"/>
      <path
        d="M8 14C8 13.4477 8.44772 13 9 13H10C10.5523 13 11 13.4477 11 14V15C11 15.5523 10.5523 16 10 16H9C8.44772 16 8 15.5523 8 15V14Z"
        fill="white"/>
      <path
        d="M8 14C8 13.4477 8.44772 13 9 13H10C10.5523 13 11 13.4477 11 14V15C11 15.5523 10.5523 16 10 16H9C8.44772 16 8 15.5523 8 15V14Z"
        fill="white"/>
      <path
        d="M8 14C8 13.4477 8.44772 13 9 13H10C10.5523 13 11 13.4477 11 14V15C11 15.5523 10.5523 16 10 16H9C8.44772 16 8 15.5523 8 15V14Z"
        fill="white"/>
      <path
        d="M13 9C13 8.44772 13.4477 8 14 8H15C15.5523 8 16 8.44772 16 9V10C16 10.5523 15.5523 11 15 11H14C13.4477 11 13 10.5523 13 10V9Z"
        fill="white"/>
      <path
        d="M13 9C13 8.44772 13.4477 8 14 8H15C15.5523 8 16 8.44772 16 9V10C16 10.5523 15.5523 11 15 11H14C13.4477 11 13 10.5523 13 10V9Z"
        fill="white"/>
      <path
        d="M13 9C13 8.44772 13.4477 8 14 8H15C15.5523 8 16 8.44772 16 9V10C16 10.5523 15.5523 11 15 11H14C13.4477 11 13 10.5523 13 10V9Z"
        fill="white"/>
      <path
        d="M8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9Z"
        fill="white"/>
      <path
        d="M8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9Z"
        fill="white"/>
      <path
        d="M8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9Z"
        fill="white"/>
    </svg>
  );
}

export default LedMatrixIcon;
