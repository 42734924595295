import { callAPI } from '@parkly/shared/api';

export async function validGetAllControllersRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const devicesData = curData.map((item) => {
    const {
      id,
      name,
      type,
      settings = {},
      ports,
    } = item;

    return {
      id,
      name,
      type,
      settings,
      ports,
    };
  });

  return devicesData;
}

export async function getControllers({ facilityId }) {
  return callAPI(`facilities/${facilityId}/parking-controllers`)
    .then((res) => validGetAllControllersRes({
      res,
    }));
}

export async function getConditionalControllers({ facilityId }) {
  return callAPI(`facilities/${facilityId}/conditional-parking-controllers`)
    .then((res) => validGetAllControllersRes({
      res,
    }));
}

export function validCreate(result) {
  const { res } = result || {};
  const { data: dataRes } = res || {};

  const {
    id,
    success,
    data,
  } = dataRes;

  return {
    id,
    success,
    data,
  };
}

export async function setController({
  facilityId,
  id,
  type,
  name,
  settings,
}) {
  return callAPI(
    `facilities/${facilityId}/parking-controllers`,
    'POST',
    {
      id,
      type,
      name,
      settings,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function removeController({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/parking-controllers/${id}`,
    'DELETE',
  )
    .then((res) => validCreate({
      res,
    }));
}

function validTestNewCreate(result) {
  const { res } = result || {};
  const { data: resData } = res || {};

  const {
    data,
    success,
    message,
  } = resData;

  return {
    data,
    message,
    success,
  };
}

export async function syncGatePortsControllers({
  facilityId,
  data,
}) {
  return callAPI(
    `facilities/${facilityId}/gates/ports/sync`,
    'POST',
    {
      data,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function testNewController({
  facilityId,
  type,
  settings,
}) {
  return callAPI(
    `facilities/${facilityId}/parking-controllers/test`,
    'POST',
    {
      type,
      settings,
    },
  )
    .then((res) => validTestNewCreate({
      res,
    }));
}

export async function testExistsController({
  facilityId,
  controllerId,
  portId,
  gateId,
}) {
  return callAPI(
    `facilities/${facilityId}/parking-controllers/${controllerId}/ports/${portId}/test`,
    'POST',
    {
      gateId,
    },
  )
    .then((res) => validTestNewCreate({
      res,
    }));
}

function validGSMSettingsRes(result) {
  const { res } = result || {};
  const { data: resData } = res || {};

  const {
    data,
  } = resData;

  if (!data) {
    throw Error('Empty response');
  }

  return data;
}

export async function getGSMSettings({
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/parking-controllers/gsm-settings`,
    'GET',
  )
    .then((res) => validGSMSettingsRes({
      res,
    }));
}

function validCameraUUIDSRes(result) {
  const { res } = result || {};
  const { data: resData } = res || {};

  const {
    data = [],
  } = resData;

  return data;
}

export async function getCameraUUIDS({
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/parking-controllers/camera-uuids`,
    'GET',
  )
    .then((res) => validCameraUUIDSRes({
      res,
    }));
}
