/* eslint-disable prefer-const */
import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ArrowChevronIcon from '@parkly/shared/components/atoms/icons/ArrowChevronIcon';
import {
  checkMinMaxTimeAndGetValid,
  getPopoverPosition,
  roundDateTime,
  useDidUpdateEffect,
} from '@parkly/shared/helpers';

import { useStyles } from './styles';

/* Help functions */

function getStartTime({
  value,
  min,
}) {
  if (!min) {
    return value.clone().startOf('day');
  }

  const isSameDay = value.isSame(min, 'day');
  if (isSameDay) {
    return roundDateTime(min);
  }

  return value.clone().startOf('day');
}
function getFinishTime({
  value,
  max,
}) {
  if (!max) {
    return value.clone().endOf('day');
  }

  const isSameDay = value.isSame(max, 'day');
  if (isSameDay) {
    return roundDateTime(max, true);
  }

  return value.clone().endOf('day');
}

function getTimeItems({
  value = moment(),
  min,
  max,
}) {
  if (min && value.isBefore(min) && !value.isSame(min, 'day')) {
    return [
      value,
    ];
  }

  const dayStart = getStartTime({
    value,
    min,
  });
  const finishTime = getFinishTime({
    value,
    max,
  });

  const maxI = Math.trunc(finishTime.diff(dayStart, 'minutes') / 15);

  let timeItems = [
    dayStart,
  ];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= maxI; i++) {
    const nextTimeItem = dayStart.clone().add(i * 15, 'minutes');
    timeItems.push(nextTimeItem);
  }

  return timeItems;
}

function getCalendarWeeks({
  month = moment(),
  value,
  min,
  max,
}) {
  const currentDay = moment();
  const monthStart = month.clone().startOf('month');
  const numberOfDay = month.daysInMonth();
  const monthStartWeekDay = monthStart.isoWeekday(); // 1-7
  const draftDays1 = numberOfDay + monthStartWeekDay - 1;
  const draftDays2 = 7 * Math.ceil(draftDays1 / 7);
  const draftTableDays = new Array(draftDays2).fill(0);

  const weeks = draftTableDays.reduce(
    (accum, current, index) => {
      const dayNumber = index + 1 - (monthStartWeekDay - 1);
      const isEmpty = dayNumber < 1 || dayNumber > numberOfDay;
      const momentDay = moment(monthStart).add((dayNumber - 1), 'days');
      const isCurrentDay = momentDay.isSame(currentDay, 'd');
      const isPast = momentDay.isBefore(currentDay) && !isCurrentDay;
      const isSelectedDay = value
        ? momentDay.isSame(value, 'd')
        : false;
      const isMinRule = min
        ? momentDay.clone().startOf('day').isBefore(min.clone().startOf('day'))
        : false;
      const isMaxRule = max
        ? momentDay.clone().startOf('day').isAfter(max.clone().startOf('day'))
        : false;

      const day = {
        isEmpty,
        isPast,
        isCurrentDay,
        isSelectedDay,
        isMinRule,
        isMaxRule,
        dayNumber,
        momentDay,
      };

      const currentWeek = accum[accum.length - 1];

      if (currentWeek.length >= 7) {
        const newWeek = [day];

        return [
          ...accum,
          newWeek,
        ];
      }

      return [
        ...accum.slice(0, -1),
        [
          ...currentWeek,
          day,
        ],
      ];
    },
    [[]],
  );

  return weeks;
}

/* Components */

function CalendarDay({
  index,
  isLastRow,
  isEmpty,
  isPast,
  isCurrentDay,
  isSelectedDay,
  isMinRule,
  isMaxRule,
  dayNumber,
  momentDay,
  onClick = () => {},
}) {
  const styles = useStyles();

  if (isEmpty) {
    return (
      <div className={styles.dayItemEmpty} />
    );
  }

  const disabled = isMinRule || isMaxRule;

  const classes = [
    styles.dayItem,
    isSelectedDay ? styles.dayItemSelected : '',
    disabled ? styles.dayItemDisabled : '',
  ].join(' ');

  return (
    <Button
      className={classes}
      disabled={disabled}
      onClick={onClick}
    >
      {dayNumber}
    </Button>
  );
}

function CustomCalendar({
  value,
  min,
  max,
  onDateChange = () => {},
}) {
  const styles = useStyles();
  const [month, setMonth] = useState(value);

  const calendarWeeks = getCalendarWeeks({
    month,
    value,
    min,
    max,
  });

  const monthName = month.format('MMMM, YYYY');

  const isAllowNextMonth = true;
  const isAllowPrevMonth = true;

  function onNextMonthClick() {
    const newMonth = month.clone().add(1, 'months');
    setMonth(newMonth);
  }
  function onPrevMonthClick() {
    const newMonth = month.clone().add(-1, 'months');
    setMonth(newMonth);
  }

  return (
    <div className={styles.calendarBody}>
      <div className={styles.calendarMonthTitle}>
        <IconButton
          color="inherit"
          size="small"
          disabled={!isAllowPrevMonth}
          onClick={onPrevMonthClick}
        >
          <ArrowChevronIcon />
        </IconButton>
        <Typography className={styles.calendarMonthTitleText}>
          {monthName}
        </Typography>
        <IconButton
          color="inherit"
          size="small"
          disabled={!isAllowNextMonth}
          onClick={onNextMonthClick}
        >
          <ArrowChevronIcon isRight />
        </IconButton>
      </div>
      <div className={styles.weekDayNames}>
        {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((weekDayName) => (
          <Typography
            key={weekDayName}
            className={styles.weekDayNameText}
          >
            {weekDayName}
          </Typography>
        ))}
      </div>
      {(calendarWeeks || []).map((weekDays, indexRow, arrayRow) => {
        const key = `week${indexRow}`;
        const isLastRow = (arrayRow.length - 1) === indexRow;

        return (
          <div
            key={key}
            className={styles.weekRow}
          >
            {weekDays.map((day = {}, index) => (
              <CalendarDay
                key={day.dayNumber}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...{
                  index,
                  isLastRow,
                  onClick: () => { onDateChange(day.momentDay); },
                  ...day,
                }}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
}

function CustomTime({
  value,
  min,
  max,
  onTimeChange = () => {},
}) {
  const styles = useStyles();
  const timeItems = getTimeItems({
    value,
    min,
    max,
  });

  const format = 'YYYY-MM-DDTHH:mm';
  const valueFormatted = value.format(format);

  const containerRef = useRef(null);
  const selectedButtonRef = useRef(null);

  // Effect to scroll the list to the selected button only when `timeItems` change
  useEffect(() => {
    if (selectedButtonRef.current && containerRef.current) {
      const selectedButton = selectedButtonRef.current;
      const container = containerRef.current;

      // Scroll the container to the selected button only if the button is not in view
      if (container.scrollTop > selectedButton.offsetTop - container.offsetTop ||
        container.scrollTop < selectedButton.offsetTop + selectedButton.clientHeight - container.offsetTop - container.clientHeight) {
        container.scrollTop = selectedButton.offsetTop - container.offsetTop;
      }
    }
  }, [timeItems]);


  return (
    <div
      className={styles.timesContainer}
    >
      <div className={styles.timesBody} ref={containerRef}>
        {(timeItems || []).map((timeItem) => {
          const formatStr = timeItem.format('HH:mm');
          const isSelected = valueFormatted === timeItem.format(format);

          const classes = [
            styles.timesItem,
            isSelected ? styles.timesItemSelected : '',
          ].join(' ');

          function onClick() {
            onTimeChange(timeItem);
          }

          return (
            <Button
              key={formatStr}
              ref={isSelected ? selectedButtonRef : null}
              className={classes}
              onClick={onClick}
            >
              {formatStr}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

/* -- Main component -- */

function CustomDateTimePicker({
  // isOpen = false,
  anchorEl,
  id,
  value,
  min,
  max,
  popoverPosition = 'left',
  onChange = () => {},
  onClose = () => {},
  initStartDayTime = false,
}) {
  const styles = useStyles();
  const [dateTime, setDateTime] = useState(
    moment(value || moment(initStartDayTime ? moment().startOf('day') : roundDateTime(moment()))),
  );
  const isOpen = Boolean(anchorEl);

  const popoverId = isOpen ? id : undefined;

  useDidUpdateEffect(() => {
    setDateTime(moment(value || moment(initStartDayTime ? moment().startOf('day') : roundDateTime(moment()))),);
  }, [value]);

  useDidUpdateEffect(
    () => {
      if (initStartDayTime) {
        setDateTime(
          moment(value || moment(initStartDayTime ? moment().startOf('day') : roundDateTime(moment()))),
        );
      }
    },
    [(value || '').toString(), initStartDayTime],
  );

  const {
    anchorOrigin,
    transformOrigin,
  } = getPopoverPosition(popoverPosition);

  function onDateChange(day) {
    const newDateTime = dateTime.clone();

    const newYear = day.get('year');
    const newMonth = day.get('month');
    const newDate = day.get('date');

    newDateTime.set('year', newYear);
    newDateTime.set('month', newMonth);
    newDateTime.set('date', newDate);

    const {
      isValid,
      nearestValidValue,
    } = checkMinMaxTimeAndGetValid({
      value: newDateTime,
      min,
      max,
    });

    const nDT = isValid
      ? newDateTime
      : nearestValidValue;

    setDateTime(nDT);
    onChange(nDT);
  }
  function onTimeChange(time) {
    const newDateTime = dateTime.clone();

    const newHour = time.get('hour');
    const newMinute = time.get('minute');

    newDateTime.set('hour', newHour);
    newDateTime.set('minute', newMinute);

    const {
      isValid,
      nearestValidValue,
    } = checkMinMaxTimeAndGetValid({
      value: newDateTime,
      min,
      max,
    });

    const nDT = isValid
      ? newDateTime
      : nearestValidValue;

    setDateTime(nDT);
    onChange(nDT);
  }

  return (
    <Popover
      open={isOpen}
      id={popoverId}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <div className={styles.calendarContainer}>
        <CustomCalendar
          value={dateTime}
          min={min}
          max={max}
          onDateChange={onDateChange}
        />
        <div className={styles.divider}>
          <div />
        </div>
        <CustomTime
          value={dateTime}
          min={min}
          max={max}
          onTimeChange={onTimeChange}
        />
      </div>
    </Popover>
  );
}

export default CustomDateTimePicker;
