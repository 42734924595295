import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllPassesSettingsPage from 'components/pages/settings/passes/AllPassesSettingsPage';
import OnePassSettingsPage from 'components/pages/settings/passes/OnePassSettingsPage';

import { PATH_PAGES } from 'config/constants';

function PassesSettingsPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
  } = currentFacilityState || {};

  const urlActions = PATH_PAGES.allPassesSettings.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.allPassesSettings} component={AllPassesSettingsPage} />
      <PrivateRoute exact path={PATH_PAGES.newPassSettings} component={OnePassSettingsPage} />
      <PrivateRoute exact path={PATH_PAGES.onePassSettings} component={OnePassSettingsPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedTariffsPage = connect(
  mapStateToProps,
  {
  },
)(PassesSettingsPage);

export default ConnectedTariffsPage;
