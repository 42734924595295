import moment from 'moment-timezone';

const TIMEZONES_MAP = {
  'Africa/Abidjan': 'Африка/Абиджан',
  'Africa/Accra': 'Африка/Аккра',
  'Africa/Addis_Ababa': 'Африка/Аддис-Абеба',
  'Africa/Algiers': 'Африка/Алжир',
  'Africa/Asmara': 'Африка/Асмара',
  'Africa/Bamako': 'Африка/Бамако',
  'Africa/Bangui': 'Африка/Банги',
  'Africa/Banjul': 'Африка/Банджул',
  'Africa/Bissau': 'Африка/Биссау',
  'Africa/Blantyre': 'Африка/Блантайр',
  'Africa/Brazzaville': 'Африка/Браззавиль',
  'Africa/Bujumbura': 'Африка/Бужумбура',
  'Africa/Cairo': 'Африка/Каир',
  'Africa/Casablanca': 'Африка/Касабланка',
  'Africa/Ceuta': 'Африка/Сеута',
  'Africa/Conakry': 'Африка/Конакри',
  'Africa/Dakar': 'Африка/Дакар',
  'Africa/Dar_es_Salaam': 'Африка/Дар-эс-Салам',
  'Africa/Djibouti': 'Африка/Джибути',
  'Africa/Douala': 'Африка/Дуала',
  'Africa/El_Aaiun': 'Айрика/Эль-Аюн',
  'Africa/Freetown': 'Африка/Фритаун',
  'Africa/Gaborone': 'Африка/Габороне',
  'Africa/Harare': 'Африка/Хараре',
  'Africa/Johannesburg': 'Африка/Йоханнесбург',
  'Africa/Kampala': 'Африка/Кампала',
  'Africa/Khartoum': 'Африка/Хартум',
  'Africa/Kigali': 'Африка/Кигали',
  'Africa/Kinshasa': 'Африка/Киншаса',
  'Africa/Lagos': 'Африка/Лагос',
  'Africa/Libreville': 'Африка/Либревиль',
  'Africa/Lome': 'Африка/Ломе',
  'Africa/Luanda': 'Африка/Луанда',
  'Africa/Lubumbashi': 'Африка/Лубумбаши',
  'Africa/Lusaka': 'Африка/Лусака',
  'Africa/Malabo': 'Африка/Малабо',
  'Africa/Maputo': 'Африка/Мапуто',
  'Africa/Maseru': 'Африку/Масеру',
  'Africa/Mbabane': 'Африка/Мбабане',
  'Africa/Mogadishu': 'Африка/Могадишо',
  'Africa/Monrovia': 'Африка/Монровия',
  'Africa/Nairobi': 'Африка/Найроби',
  'Africa/Ndjamena': 'Африка/Нджамена',
  'Africa/Niamey': 'Африка/Ниамей',
  'Africa/Nouakchott': 'Африка/Нуакшот',
  'Africa/Ouagadougou': 'Африка/Уагадугу',
  'Africa/Porto-Novo': 'Африка/Порто-Ново',
  'Africa/Sao_Tome': 'Африка/Сан-Томе',
  'Africa/Tripoli': 'Африка/Триполи',
  'Africa/Tunis': 'Африка/Тунис',
  'Africa/Windhoek': 'Африка/Виндхук',
  'America/Adak': 'Африка/Адак',
  'America/Anchorage': 'Америка/Анкоридж',
  'America/Anguilla': 'Америка/Ангилья',
  'America/Antigua': 'Америка/Антигуа',
  'America/Araguaina': 'Америка/Арагвайна',
  'America/Argentina/Buenos_Aires': 'Америка/Аргентина/Буенос-Айрес',
  'America/Argentina/Catamarca': 'Америка/Аргентина/Катамарка',
  'America/Argentina/Cordoba': 'Америка/Аргентина/Кордова',
  'America/Argentina/Jujuy': 'Америка/Аргентина/Жужуй',
  'America/Argentina/La_Rioja': 'Америка/Аргентина/Ла-Риоха',
  'America/Argentina/Mendoza': 'Америка/Аргентина/Мендоза',
  'America/Argentina/Rio_Gallegos': 'Америка/Аргентина/Рио-Галлегос',
  'America/Argentina/San_Juan': 'Америка/Аргентина/Сан-Жуан',
  'America/Argentina/Tucuman': 'Америка/Аргентина/Тукуман',
  'America/Argentina/Ushuaia': 'Америка/Аргентина/Ушуайя',
  'America/Aruba': 'Америка/Аруба',
  'America/Asuncion': 'Америка/Асунсьон',
  'America/Atikokan': 'Америка/Атикокан',
  'America/Bahia': 'Америка/Бахия',
  'America/Barbados': 'Америка/Барбадос',
  'America/Belem': 'Америка/Белем',
  'America/Belize': 'Америка/Белиз',
  'America/Blanc-Sablon': 'Америка/Бланк-Саблон',
  'America/Boa_Vista': 'Америка/Боа-Виста',
  'America/Bogota': 'Америка/Богота',
  'America/Boise': 'Америка/Бойсе',
  'America/Cambridge_Bay': 'Америка/Кембридж-Бэй',
  'America/Campo_Grande': 'Америка/Кампо-Гранде',
  'America/Cancun': 'Америка/Канкун',
  'America/Caracas': 'Америка/Каракас',
  'America/Cayenne': 'Америка/Кайенна',
  'America/Cayman': 'Америка/Кайман',
  'America/Chicago': 'Америка/Чикаго',
  'America/Chihuahua': 'Америка/Чихуахуа',
  'America/Costa_Rica': 'Америка/Коста-Рика',
  'America/Cuiaba': 'Америка/Куяба',
  'America/Curacao': 'Америка/Кюрасао',
  'America/Danmarkshavn': 'Америка/Денмарксхавн',
  'America/Dawson': 'Америка/Доусон',
  'America/Dawson_Creek': 'Америка/Доусон-Крик',
  'America/Denver': 'Америка/Денвер',
  'America/Detroit': 'Америка/Детройт',
  'America/Dominica': 'Америка/Доминика',
  'America/Edmonton': 'Америка/Эдмонтон',
  'America/Eirunepe': 'Америка/Эйрунеп',
  'America/El_Salvador': 'Америка/Сальвадор',
  'America/Fortaleza': 'Америка/Форталеза',
  'America/Glace_Bay': 'Америка/Глейс-Бей',
  'America/Godthab': 'Америка/Готхоб',
  'America/Goose_Bay': 'Америка/Гус-бэй',
  'America/Grand_Turk': 'Америка/Гранд-Терк',
  'America/Grenada': 'Америка/Гренада',
  'America/Guadeloupe': 'Америка/Гваделупа',
  'America/Guatemala': 'Америка/Гватемала',
  'America/Guayaquil': 'Америка/Гуаякиль',
  'America/Guyana': 'Америка/Гайана',
  'America/Halifax': 'Америка/Галифакс',
  'America/Havana': 'Америка/Гавана',
  'America/Hermosillo': 'Америка/Эрмосильо',
  'America/Indiana/Indianapolis': 'Америка/Индиана/Индианаполис',
  'America/Indiana/Knox': 'Америка/Индиана/Нокс',
  'America/Indiana/Marengo': 'Америка/Индиана/Маренго',
  'America/Indiana/Petersburg': 'Америка/Индиана/Петербург',
  'America/Indiana/Vevay': 'Америка/Индиана/Вивэй',
  'America/Indiana/Vincennes': 'Америка/Индиана/Венсенн',
  'America/Inuvik': 'Америка/Инувик',
  'America/Iqaluit': 'Америка/Икалит',
  'America/Jamaica': 'Америка/Ямайка',
  'America/Juneau': 'Америка/Джуно',
  'America/Kentucky/Louisville': 'Америка/Кентукки/Луисвиль',
  'America/Kentucky/Monticello': 'Америка/Кентукки/Монтиселло',
  'America/La_Paz': 'Америка/Ла-Пас',
  'America/Lima': 'Америка/Лима',
  'America/Los_Angeles': 'Америка/Лос-Анджелес',
  'America/Maceio': 'Америка/Масейо',
  'America/Managua': 'Америка/Манагуа',
  'America/Manaus': 'Америка/Манаус',
  'America/Martinique': 'Америка/Мартиник',
  'America/Mazatlan': 'Америка/Мазатлан',
  'America/Menominee': 'Америка/Меномини',
  'America/Merida': 'Америка/Мерида',
  'America/Mexico_City': 'Америка/Мехико-Сити',
  'America/Miquelon': 'Америка/Микелон',
  'America/Moncton': 'Америка/Монктон',
  'America/Monterrey': 'Америка/Монтерей',
  'America/Montevideo': 'Америка/Монтевидео',
  'America/Montreal': 'Америка/Монреаль',
  'America/Montserrat': 'Америка/Монсерат',
  'America/Nassau': 'Америка/Нассау',
  'America/New_York': 'Америка/Нью-Йорк',
  'America/Nipigon': 'Америка/Нипигон',
  'America/Nome': 'Америка/Ном',
  'America/Noronha': 'Америка/Норонха',
  'America/North_Dakota/Center': 'Америка/Северная Дакота/Центр',
  'America/North_Dakota/New_Salem': 'Америка/Северная Дакота/Нью-Салем',
  'America/Panama': 'Америка/Панама',
  'America/Pangnirtung': 'Америка/Пангниртунг',
  'America/Paramaribo': 'Америка/Парамарибо',
  'America/Phoenix': 'Америка/Феникс',
  'America/Port-au-Prince': 'Америка/Порт-о-Пренс',
  'America/Port_of_Spain': 'Америка/Порт-оф-Спейн',
  'America/Porto_Velho': 'Америка/Порто-Вельо',
  'America/Puerto_Rico': 'Америка/Пуэрто-Рико',
  'America/Rainy_River': 'Америка/Рэйни-Ривер',
  'America/Rankin_Inlet': 'Америка/Рэнкин-Инлет',
  'America/Recife': 'Америка/Ресифи',
  'America/Regina': 'Америка/Реджайна',
  'America/Rio_Branco': 'Америка/Рио-Бранко',
  'America/Santiago': 'Америка/Сантьяго',
  'America/Santo_Domingo': 'Америка/Санто-Доминго',
  'America/Sao_Paulo': 'Америка/Сан-Пауло',
  'America/Scoresbysund': 'Америка/Скорсбисанд',
  'America/Shiprock': 'Америка/Шипрок',
  'America/St_Johns': 'Америка/Сент-Джонс',
  'America/St_Kitts': 'Америка/Сент-Китс',
  'America/St_Lucia': 'Америка/Сент-Люсия',
  'America/St_Thomas': 'Америка/Сент-Томас',
  'America/St_Vincent': 'Америка/Сент-Винсент',
  'America/Swift_Current': 'Америка/Свифт-Карэнт',
  'America/Tegucigalpa': 'Америка/Тегусигальпа',
  'America/Thule': 'Америка/Туле',
  'America/Thunder_Bay': 'Америка/Тандер-Бэй',
  'America/Tijuana': 'Америка/Тихуана',
  'America/Toronto': 'Америка/Торонто',
  'America/Tortola': 'Америка/Тортола',
  'America/Vancouver': 'Америка/Ванкувер',
  'America/Whitehorse': 'Америка/Уайтхорс',
  'America/Winnipeg': 'Америка/Виннипег',
  'America/Yakutat': 'Америка/Якутат',
  'America/Yellowknife': 'Америка/Йеллоунайф',
  'Antarctica/Casey': 'Антарктида/Кейси',
  'Antarctica/Davis': 'Антарктида/Дэвис',
  'Antarctica/DumontDUrville': 'Антарктида/Дюмон-Дюрвиль',
  'Antarctica/Mawson': 'Антарктида/Моусон',
  'Antarctica/McMurdo': 'Антарктида/Мак-Мердо',
  'Antarctica/Palmer': 'Антарктида/Пальмер',
  'Antarctica/Rothera': 'Антарктида/Розера',
  'Antarctica/South_Pole': 'Антарктида/Южный Полюс',
  'Antarctica/Syowa': 'Антарктида/Сайова',
  'Antarctica/Vostok': 'Антарктида/Восток',
  'Arctic/Longyearbyen': 'Арктика/Лонгэрбайэн',
  'Asia/Aden': 'Азия/Аден',
  'Asia/Almaty': 'Азия/Алматы',
  'Asia/Amman': 'Азия/Амман',
  'Asia/Anadyr': 'Азия/Анадырь',
  'Asia/Aqtau': 'Азия/Актау',
  'Asia/Aqtobe': 'Азия/Актуб',
  'Asia/Ashgabat': 'Азия/Ашхабад',
  'Asia/Baghdad': 'Азия/Багдад',
  'Asia/Bahrain': 'Азия/Бахрейн',
  'Asia/Baku': 'Азия/Баку',
  'Asia/Bangkok': 'Азия/Бангкок',
  'Asia/Beirut': 'Азия/Бейрут',
  'Asia/Bishkek': 'Азия/Бишкек',
  'Asia/Brunei': 'Азия/Бруней',
  'Asia/Choibalsan': 'Азия/Чойбалсана',
  'Asia/Chongqing': 'Азия/Чунцин',
  'Asia/Colombo': 'Азия/Коломбо',
  'Asia/Damascus': 'Азия/Дамаск',
  'Asia/Dhaka': 'Азия/Дака',
  'Asia/Dili': 'Азия/Дили',
  'Asia/Dubai': 'Азия/Дубай',
  'Asia/Dushanbe': 'Азия/Душанбе',
  'Asia/Gaza': 'Азия/Газа',
  'Asia/Harbin': 'Азия/Харбин',
  'Asia/Hong_Kong': 'Азия/Гонконг',
  'Asia/Hovd': 'Азия/Ховд',
  'Asia/Irkutsk': 'Азия/Иркутск',
  'Asia/Istanbul': 'Азия/Стамбул',
  'Asia/Jakarta': 'Азия/Джакарта',
  'Asia/Jayapura': 'Азия/Джайапура',
  'Asia/Jerusalem': 'Азия/Иерусалим',
  'Asia/Kabul': 'Азия/Кабул',
  'Asia/Kamchatka': 'Азия/Камчатка',
  'Asia/Karachi': 'Азия/Карачи',
  'Asia/Kashgar': 'Азия/Кашгар',
  'Asia/Kathmandu': 'Азия/Катманду',
  'Asia/Krasnoyarsk': 'Азия/Красноярск',
  'Asia/Kuala_Lumpur': 'Азия/Куала-Лумпур',
  'Asia/Kuching': 'Азия/Кучинг',
  'Asia/Kuwait': 'Азия/Кувейт',
  'Asia/Macau': 'Азия/Макао',
  'Asia/Magadan': 'Азия/Магадан',
  'Asia/Makassar': 'Азия/Макасар',
  'Asia/Manila': 'Азия/Манила',
  'Asia/Muscat': 'Азия/Мускат',
  'Asia/Nicosia': 'Азия/Никосия',
  'Asia/Novosibirsk': 'Азия/Новосибирск',
  'Asia/Barnaul': 'Азия/Барнаул',
  'Asia/Chita': 'Азия/Чита',
  'Asia/Khandyga': 'Азия/Хандыга',
  'Asia/Srednekolymsk': 'Азия/Среднеколымск',
  'Asia/Ust-Nera': 'Азия/Усть-Нера',
  'Asia/Omsk': 'Азия/Омск',
  'Asia/Tomsk': 'Азия/Томск',
  'Asia/Oral': 'Азия/Орал',
  'Asia/Phnom_Penh': 'Азия/Пномпень',
  'Asia/Pontianak': 'Азия/Понтианак',
  'Asia/Pyongyang': 'Азия/Пхеньян',
  'Asia/Qatar': 'Азия/Катар',
  'Asia/Qyzylorda': 'Азия/Кызылорда',
  'Asia/Rangoon': 'Азия/Рангун',
  'Asia/Riyadh': 'Азия/Эр-Рияд',
  'Asia/Sakhalin': 'Азия/Сахалин',
  'Asia/Samarkand': 'Азия/Самарканд',
  'Asia/Seoul': 'Азия/Сеул',
  'Asia/Shanghai': 'Азия/Шанхай',
  'Asia/Singapore': 'Азия/Сингапур',
  'Asia/Taipei': 'Азия/Тайпей',
  'Asia/Tashkent': 'Азия/Ташкент',
  'Asia/Tbilisi': 'Азия/Тбилиси',
  'Asia/Tehran': 'Азия/Тегеран',
  'Asia/Thimphu': 'Азия/Тхимпху',
  'Asia/Tokyo': 'Азия/Токио',
  'Asia/Ulaanbaatar': 'Азия/Улан-Батор',
  'Asia/Urumqi': 'Азия/Урумчи',
  'Asia/Vientiane': 'Азия/Вьентьян',
  'Asia/Vladivostok': 'Азия/Владивосток',
  'Asia/Yakutsk': 'Азия/Якутск',
  'Asia/Yekaterinburg': 'Азия/Екатеринбург',
  'Asia/Yerevan': 'Азия/Ереван',
  'Atlantic/Azores': 'Атлантика/Азоры',
  'Atlantic/Bermuda': 'Атлантика/Бермуды',
  'Atlantic/Canary': 'Атлантика/Канары',
  'Atlantic/Cape_Verde': 'Атлантика/Кабо-Верде',
  'Atlantic/Faroe': 'Атлантика/Фареры',
  'Atlantic/Madeira': 'Атлантика/Мадейра',
  'Atlantic/Reykjavik': 'Атлантика/Рейкьявик',
  'Atlantic/South_Georgia': 'Атлантика/Южная Георгия',
  'Atlantic/St_Helena': 'Атлантика/св/ Елена',
  'Atlantic/Stanley': 'Атлантика/Стэнли',
  'Australia/Adelaide': 'Австралия/Аделаида',
  'Australia/Brisbane': 'Австралия/Брисбен',
  'Australia/Broken_Hill': 'Австралия/Брокен-Хилл',
  'Australia/Currie': 'Австралия/Курри',
  'Australia/Darwin': 'Австралия/Дарвин',
  'Australia/Eucla': 'Австралия/Юкла',
  'Australia/Hobart': 'Австралия/Хобарт',
  'Australia/Lindeman': 'Австралия/Линдэман',
  'Australia/Lord_Howe': 'Австралия/Лорд-Гау',
  'Australia/Melbourne': 'Австралия/Мельбурн',
  'Australia/Perth': 'Австралия/Перт',
  'Australia/Sydney': 'Австралия/Сидней',
  'Europe/Amsterdam': 'Европа/Амстердам',
  'Europe/Andorra': 'Европа/Андорра',
  'Europe/Athens': 'Европа/Афины',
  'Europe/Belgrade': 'Европа/Белград',
  'Europe/Berlin': 'Европа/Берлин',
  'Europe/Bratislava': 'Европа/Братислава',
  'Europe/Brussels': 'Европа/Брюссель',
  'Europe/Bucharest': 'Европа/Бухарест',
  'Europe/Budapest': 'Европа/Будапешт',
  'Europe/Chisinau': 'Европа/Кишинев',
  'Europe/Copenhagen': 'Европа/Копенгаген',
  'Europe/Dublin': 'Европа/Дублин',
  'Europe/Gibraltar': 'Европа/Гибралтар',
  'Europe/Guernsey': 'Европа/Гернзи',
  'Europe/Helsinki': 'Европа/Хельсинки',
  'Europe/Isle_of_Man': 'Европа/Остров Мэн',
  'Europe/Istanbul': 'Европа/Стамбул',
  'Europe/Jersey': 'Европа/Джерси',
  'Europe/Kaliningrad': 'Европа/Калининград',
  'Europe/Kiev': 'Европа/Киев',
  'Europe/Lisbon': 'Европа/Лисбон',
  'Europe/Ljubljana': 'Европа/Любляна',
  'Europe/London': 'Европа/Лондон',
  'Europe/Luxembourg': 'Европа/Люксембург',
  'Europe/Madrid': 'Европа/Мадрид',
  'Europe/Malta': 'Европа/Мальта',
  'Europe/Mariehamn': 'Европа/Мариенхамн',
  'Europe/Minsk': 'Европа/Минск',
  'Europe/Monaco': 'Европа/Монако',
  'Europe/Moscow': 'Европа/Москва',
  'Europe/Kirov': 'Европа/Киров',
  'Europe/Astrakhan': 'Европа/Астрахань',
  'Europe/Ulyanovsk': 'Европа/Ульяновск',
  'Europe/Saratov': 'Европа/Саратов',
  'Europe/Nicosia': 'Европа/Никосия',
  'Europe/Oslo': 'Европа/Осло',
  'Europe/Paris': 'Европа/Париж',
  'Europe/Podgorica': 'Европа/Подгорица',
  'Europe/Prague': 'Европа/Прага',
  'Europe/Riga': 'Европа/Рига',
  'Europe/Rome': 'Европа/Рим',
  'Europe/Samara': 'Европа/Самара',
  'Europe/San_Marino': 'Европа/Сан-Марино',
  'Europe/Sarajevo': 'Европа/Сараево',
  'Europe/Simferopol': 'Европа/Симферополь',
  'Europe/Skopje': 'Европа/Скопье',
  'Europe/Sofia': 'Европа/София',
  'Europe/Stockholm': 'Европа/Стокгольм',
  'Europe/Tallinn': 'Европа/Таллин',
  'Europe/Tirane': 'Европа/Тирана',
  'Europe/Uzhgorod': 'Европа/Ужгород',
  'Europe/Vaduz': 'Европа/Вадуц',
  'Europe/Vatican': 'Европа/Ватикан',
  'Europe/Vienna': 'Европа/Вена',
  'Europe/Vilnius': 'Европа/Вильнюс',
  'Europe/Volgograd': 'Европа/Волгоград',
  'Europe/Warsaw': 'Европа/Варшава',
  'Europe/Zagreb': 'Европа/Загреб',
  'Europe/Zaporozhye': 'Европа/Запорожье',
  'Europe/Zurich': 'Европа/Цюрих',
  'Indian/Antananarivo': 'Индийский о-н/Антананариву',
  'Indian/Chagos': 'Индийский о-н/Чагос',
  'Indian/Christmas': 'Индийский о-н/Рождество',
  'Indian/Cocos': 'Индийский о-н/Кокосовы',
  'Indian/Comoro': 'Индийский о-н/Коморские',
  'Indian/Kerguelen': 'Индийский о-н/Кергелен',
  'Indian/Mahe': 'Индийский о-н/Махэ',
  'Indian/Maldives': 'Индийский о-н/Мальдивы',
  'Indian/Mauritius': 'Индийский о-н/Маврикий',
  'Indian/Mayotte': 'Индийский о-н/Майот',
  'Indian/Reunion': 'Индийский о-н/Реюньон',
  'Pacific/Apia': 'Океания/Апия',
  'Pacific/Auckland': 'Океания/Окленд',
  'Pacific/Chatham': 'Океания/Чатем',
  'Pacific/Easter': 'Океания/Пасха',
  'Pacific/Efate': 'Океания/Эфате',
  'Pacific/Enderbury': 'Океания/Эндербери',
  'Pacific/Fakaofo': 'Океания/Факаофо',
  'Pacific/Fiji': 'Океания/Фиджи',
  'Pacific/Funafuti': 'Океания/Фунафути',
  'Pacific/Galapagos': 'Океания/Галапагос',
  'Pacific/Gambier': 'Океания/Гамбир',
  'Pacific/Guadalcanal': 'Океания/Гвадалканал',
  'Pacific/Guam': 'Океания/Гуам',
  'Pacific/Honolulu': 'Океания/Гонолулу',
  'Pacific/Johnston': 'Океания/Джонстон',
  'Pacific/Kiritimati': 'Океания/Киритимати',
  'Pacific/Kosrae': 'Океания/Косрей',
  'Pacific/Kwajalein': 'Океания/Кваджалейн',
  'Pacific/Majuro': 'Океания/Маджуро',
  'Pacific/Marquesas': 'Океания/Маркизы',
  'Pacific/Midway': 'Океания/Мидуэй',
  'Pacific/Nauru': 'Океания/Науру',
  'Pacific/Niue': 'Океания/Ниуэ',
  'Pacific/Norfolk': 'Океания/Норфолк',
  'Pacific/Noumea': 'Океания/Нумеа',
  'Pacific/Pago_Pago': 'Океания/Паго-Паго',
  'Pacific/Palau': 'Океания/Палау',
  'Pacific/Pitcairn': 'Океания/Питкэрн',
  'Pacific/Ponape': 'Океания/Понапе',
  'Pacific/Port_Moresby': 'Океания/Порт-Морсби',
  'Pacific/Rarotonga': 'Океания/Раротонга',
  'Pacific/Saipan': 'Океания/Сайпан',
  'Pacific/Tahiti': 'Океания/Таити',
  'Pacific/Tarawa': 'Океания/Тарава',
  'Pacific/Tongatapu': 'Океания/Тонгатапу',
  'Pacific/Truk': 'Океания/Трук',
  'Pacific/Wake': 'Океания/Уэйк',
  'Pacific/Wallis': 'Океания/Уоллис',
  'America/Indiana/Tell_City': 'Америка/Индиана/Телл-Сити',
  'America/Indiana/Winamac': 'Америка/Индиана/Винамак',
  'America/Marigot': 'Америка/Маригот',
  'America/Resolute': 'Америка/Резолют',
  'America/St_Barthelemy': 'Америка/Сен-Бартельми',
  'America/Argentina/San_Luis': 'Америка/Аргенитина/Сан-Луис',
  'America/Santarem': 'Америка/Сантарем',
  'Asia/Ho_Chi_Minh': 'Азия/Хо-Ши-Мин',
  'Asia/Kolkata': 'Азия/Калькутта',
  'America/Argentina/Salta': 'Америка/Аргентина/Сальта',
  'America/Matamoros': 'Америка/Матаморос',
  'America/Ojinaga': 'Америка/Охинага',
  'America/Santa_Isabel': 'Америка/Санта Изабель',
  'Antarctica/Macquarie': 'Антарктика/Макгуайр',
  'Asia/Novokuznetsk': 'Азия/Новокузнецк',
  'America/Bahia_Banderas': 'Америка/Баия Бандерас',
  'America/North_Dakota/Beulah': 'Америка/Северная Дакота/Бойл',
  'Pacific/Chuuk': 'Океания/Чуук',
  'Pacific/Pohnpei': 'Океания/Понпеи',
  'Africa/Juba': 'Африка/Джуба',
  'America/Kralendijk': 'Америка/Кралендейк',
  'America/Lower_Princes': 'Америка/Ловер Принцес',
  'America/Metlakatla': 'Америка/Метлакатла',
  'America/Sitka': 'Америка/Ситка',
  'Asia/Hebron': 'Азия/Хаброн',
};

export function getOffsetByTz(tz) {
  const zone = moment.tz.zone(tz);

  return zone ? zone.parse((new Date()).getUTCMilliseconds()) : 0;
}

export function formatOffset(offset) {
  const absOffset = Math.abs(offset);
  const offsetMinStr = `${absOffset % 60}`.padStart(2, '0');
  const offsetHoursStr = `${absOffset / 60}`.padStart(2, '0');

  if (offset <= 0) {
    return `(UTC+${offsetHoursStr}:${offsetMinStr})`;
  }

  return `(UTC-${offsetHoursStr}:${offsetMinStr})`;
}

export function translateTimezone(tzName) {
  return TIMEZONES_MAP[tzName] || tzName;
}

export function formatTimezoneName(timezone) {
  const { name, offset } = timezone;

  const tzName = translateTimezone(name);

  const curOffset = offset || getOffsetByTz(name);
  const offsetStr = formatOffset(curOffset);

  return `${offsetStr} ${tzName}`;
}
