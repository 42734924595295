import React from 'react';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';

const helpSrc = process.env.REACT_APP_HELP_SRC;

function HelpPage() {
  const styles = useStyles();

  const anchor = window.location.hash;

  return (
    <Container className={styles.container}>
      <iframe
        title="Help"
        className={styles.iframe}
        height="100%"
        width="100%"
        src={helpSrc + (anchor ? `/${anchor.replace('#', '')}` : '') }
      />
    </Container>
  );
}

export default HelpPage;
