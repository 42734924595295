import { updateStoreReqRes } from '@parkly/shared/helpers';
import {
  CANCEL_ONE_PASS,
  CLEAR_PASSES_CACHE,
  CREATE_ONE_PASS,
  GET_ACTIVE_PASS_SETTINGS,
  GET_ACTIVE_PASS_SETTINGS_PLACES,
  GET_ALL_PASSES,
  GET_ALL_PASSES_SETTINGS,
  GET_ONE_PASS,
  GET_ONE_PASS_INFO,
  GET_ONE_PASS_SETTINGS,
  PAY_ONE_PASS,
  SET_ONE_PASS_SETTINGS,
  UPDATE_ONE_PASS,
} from 'actions/types';

const INITIAL_STATE = {
  allPassesSettings: {
    loading: false,
    list: [],
  },
  onePassSettings: {
    loading: false,
    list: [],
  },
  activePassSettings: {
    loading: false,
    list: [],
  },
  activePassSettingPlaces: {
    loading: false,
    list: [],
  },
  setPassSettings: {
    loading: false,
  },
  allPasses: {
    loading: false,
    list: [],
  },
  onePass: {
    loading: false,
    list: [],
  },
  onePassInfo: {
    loading: false,
    list: [],
  },
  createPass: {
    loading: false,
  },
  setPass: {
    loading: false,
  },
  cancelPass: {
    loading: false,
  },
  payPass: {
    loading: false,
  },
};

function updateOneList({
  list,
  newData,
  error,
}) {
  const { id } = newData || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newData || error,
  ];

  return newList;
}

function getSetState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function tariffs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_PASSES_SETTINGS:
      return updateStoreReqRes(
        state,
        'allPassesSettings',
        action,
      );

    case GET_ACTIVE_PASS_SETTINGS:
      return updateStoreReqRes(
        state,
        'activePassSettings',
        action,
      );

    case GET_ACTIVE_PASS_SETTINGS_PLACES:
      return updateStoreReqRes(
        state,
        'activePassSettingPlaces',
        action,
      );

    case GET_ONE_PASS_SETTINGS:
      return {
        ...state,
        onePassSettings: {
          ...state.onePassSettings,
          loading: action.payload.loading,
          list: updateOneList({
            list: state.onePassSettings.list,
            newData: action.payload.passSettings,
            error: action.payload.error,
          }),
        },
      };

    case SET_ONE_PASS_SETTINGS:
      return {
        ...state,
        setPassSettings: getSetState({
          prevState: state.setPassSettings,
          payload: action.payload,
        }),
      };

    case GET_ALL_PASSES:
      return updateStoreReqRes(
        state,
        'allPasses',
        action,
      );

    case GET_ONE_PASS:
      return {
        ...state,
        onePass: {
          ...state.onePass,
          loading: action.payload.loading,
          list: updateOneList({
            list: state.onePass.list,
            newData: action.payload.pass,
            error: action.payload.error,
          }),
        },
      };

    case GET_ONE_PASS_INFO:
      return {
        ...state,
        onePassInfo: {
          ...state.onePassInfo,
          loading: action.payload.loading,
          list: updateOneList({
            list: state.onePassInfo.list,
            newData: action.payload.pass,
            error: action.payload.error,
          }),
        },
      };

    case CREATE_ONE_PASS:
      return {
        ...state,
        createPass: getSetState({
          prevState: state.createPass,
          payload: action.payload,
        }),
      };

    case UPDATE_ONE_PASS:
      return {
        ...state,
        setPass: getSetState({
          prevState: state.setPass,
          payload: action.payload,
        }),
      };

    case CANCEL_ONE_PASS:
      return {
        ...state,
        cancelPass: getSetState({
          prevState: state.cancelPass,
          payload: action.payload,
        }),
      };

    case PAY_ONE_PASS:
      return {
        ...state,
        payPass: getSetState({
          prevState: state.payPass,
          payload: action.payload,
        }),
      };

    case CLEAR_PASSES_CACHE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
