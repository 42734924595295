import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useStyles } from './styles';

export default function SelectLoginCards({ handleLoginClick, partnersData }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.cardContainer}>
      {(partnersData || []).map((partner) => {
        const { name, userId, facilityName } = partner;

        const onClick = () => {
          handleLoginClick(partner);
        };

        return (
          <Grid key={userId} item xs={12}>
            <Card className={classes.card}>
              <CardActionArea onClick={onClick}>
                <CardContent>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={10}>
                      <Typography className={classes.cardStr}>
                        {name}
                      </Typography>
                      <Typography className={classes.cardSubStr}>
                        {facilityName}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ArrowForwardIcon htmlColor="#004BF6" />
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
