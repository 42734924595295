import React from 'react';
import Typography from '@material-ui/core/Typography';
import TariffStandardPrices from 'components/organisms/tariffPrices/TariffStandardPrices';
import { TARIFF_TYPE } from 'config/constants';
import TariffIntervalPrices from '../TariffIntervalPrices';

function TariffPrices({
  errors,
  prices,
  type,
  interval,
  onChange = () => {},
  disabled = false,
}) {
  if (type === TARIFF_TYPE.standard) {
    return (
      <TariffStandardPrices
        errors={errors}
        prices={prices}
        interval={interval}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (type === TARIFF_TYPE.interval) {
    return (
      <TariffIntervalPrices
        errors={errors}
        prices={prices}
        interval={interval}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (type === TARIFF_TYPE.cumulative) {
    return (
      <TariffIntervalPrices
        errors={errors}
        prices={prices}
        interval={interval}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return (
    <Typography>
      Не реализовано
    </Typography>
  );
}

export default TariffPrices;
