import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  option: {
    '& span': {
      color: 'grey',
    },
  },
}));

export default {
  useStyles,
};
