import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TARIFF_PLAN_TYPES } from 'config/constants';
import TariffPlanDetailSchedules from '../TariffPlanDetailSchedules';
import TariffPlanMonthSchedules from '../TariffPlanMonthSchedules';

function TariffPlanSchedules({
  activeTariffs,
  type,
  schedules,
  onChange = () => {},
  disabled = false,
}) {
  if (type === TARIFF_PLAN_TYPES.detail) {
    return (
      <TariffPlanDetailSchedules
        activeTariffs={activeTariffs}
        schedules={schedules}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (type === TARIFF_PLAN_TYPES.month) {
    return (
      <TariffPlanMonthSchedules
        activeTariffs={activeTariffs}
        schedules={schedules}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return (
    <Typography>
      Не реализовано
    </Typography>
  );
}

export default TariffPlanSchedules;
