import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  searchField: {

  },
  btn: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
  statusSelectForm: {
    height: 48,
    border: 0,
  },
  statusSelect: {
    height: 48,
    borderRadius: 6,
    backgroundColor: '#F6F7FB',
  },
  datePicker: {
    width: '100%',

    '& input': {
      backgroundColor: '#F6F7FB',
    },

    '& input:focus': {

    },

    '& input:placeholder': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  datePickerWithError: {
    '& input': {
      backgroundColor: `${theme.palette.redC.red4} !important`,
    },
  },
}));

export default {
  useStyles,
};
