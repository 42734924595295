import { updateStoreReqRes } from '@parkly/shared/helpers';
import {
  CLEAR_ANALYTICS_CACHE,
  GET_ANALYTICS_AVG_CHECK_STATS,
  GET_ANALYTICS_AVG_SESSION_DURATION_STATS,
  GET_ANALYTICS_REVENUE_STATS,
  GET_ANALYTICS_SESSIONS_STATS,
} from 'actions/types';

const INITIAL_STATE = {
  statsRevenueList: {
    loading: false,
    list: [],
  },
  statsAvgCheckList: {
    loading: false,
    list: [],
  },
  statsSessionsList: {
    loading: false,
    list: [],
  },
  statsAvgSessionDurationList: {
    loading: false,
    list: [],
  },
};

export default function analytics(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ANALYTICS_REVENUE_STATS:
      return updateStoreReqRes(
        state,
        'statsRevenueList',
        action,
      );

    case GET_ANALYTICS_AVG_CHECK_STATS:
      return updateStoreReqRes(
        state,
        'statsAvgCheckList',
        action,
      );

    case GET_ANALYTICS_SESSIONS_STATS:
      return updateStoreReqRes(
        state,
        'statsSessionsList',
        action,
      );

    case GET_ANALYTICS_AVG_SESSION_DURATION_STATS:
      return updateStoreReqRes(
        state,
        'statsAvgSessionDurationList',
        action,
      );

    case CLEAR_ANALYTICS_CACHE:
      return {
        statsRevenueList: INITIAL_STATE.statsRevenueList,
        statsAvgCheckList: INITIAL_STATE.statsAvgCheckList,
        statsSessionsList: INITIAL_STATE.statsSessionsList,
        statsAvgSessionDurationList: INITIAL_STATE.statsAvgSessionDurationList,
      };

    default:
      return state;
  }
}
