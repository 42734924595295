import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  inputItem: {
    margin: 0,
  },
  tableContainer: {
    maxHeight: 500,
    overflow: 'auto',
  },
  head: {
    border: `1px solid white`,
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
    backgroundColor: theme.palette.greyC.gray2,
  },
  cell: {
    padding: '8px 16px',
  },
  divider: {
    marginTop: 16,
    backgroundColor: theme.palette.greyC.gray,
  },
}));

export default {
  useStyles,
};
