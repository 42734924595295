import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import Typography from '@material-ui/core/Typography';
import { useActiveTicketTypes } from 'api/query/tickets';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useStyles } from './styles';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';

function OperatorRightTicketTypeShowLimitModalContent({
  facilityId,
  initData,
  forwardData,

  onClose = () => {},
  onSuccess,
}) {
  const styles = useStyles();
  const [sel, setSel] = useState([]);

  const handleOnSave = useCallback(() => {
    onSuccess({
      ruleData: (sel || []).length > 0 ? [{
        type: 'ids',
        value: (sel || []),
        model: 'pass_type',
      }] : [],
      forwardData,
    });
  }, [onSuccess, sel, forwardData]);

  const {
    data: activeTicketTypesData,
    isLoading: isActivePassTypesLoading,
  } = useActiveTicketTypes({
    variables: { facilityId },
  });

  useEffect(() => {
    if (activeTicketTypesData) {
      const result = [];

      const data = (initData || []).find(({ type, model }) => model === 'pass_type' && type === 'ids');
      const { value } = data || {};

      (value || []).forEach((i) => {
        const ticketType = activeTicketTypesData.find(({ id }) => i === id);

        if (ticketType) {
          result.push(ticketType.id);
        }
      });

      setSel(result);
    }
  }, [activeTicketTypesData, initData]);

  const items = useMemo(() => (activeTicketTypesData || []).map(({ id, name }) => ({
    id,
    title: name,
  })), [activeTicketTypesData]);

  if (isActivePassTypesLoading) {
    return (
      <CustomModalContentWrapper
        title="Настрока ограничений"
        onClose={onClose}
        paperClassname={styles.paper}
        bodyClassname={styles.modalBody}
      >
        <Grid className={styles.container} container alignItems="center" justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Настрока ограничений"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <Grid className={styles.container} container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Типы пропусков, доступные оператору
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MultiItemSelectors
            classNameForm={styles.selectForm}
            className={styles.select}
            name="type"
            currentValue={sel}
            items={items}
            unselectText="Доступные типы"
            onChange={(e) => setSel(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomBtn
            className={styles.btn}
            onClick={onClose}
          >
            Отмена
          </CustomBtn>
        </Grid>
        <Grid item xs={6}>
          <CustomBtn
            className={styles.btn}
            btnType="primaryBlue"
            onClick={handleOnSave}
          >
            Готово
          </CustomBtn>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

export  default OperatorRightTicketTypeShowLimitModalContent;
