import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useFormFields } from '@parkly/shared/helpers';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { cancelPass } from 'api/passes';
import { clearPassesCacheAction } from 'actions/passes';
import { useStyles } from './styles';

function CancelPassModalContent({
  passId,
  facilityId,
  availableCancelPayment = false,
  onClose = () => {},
  onComplete = () => {},
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      withPayment: true,
    },
  });
  const styles = useStyles();

  const handlePassCancel = useCallback(() => {
    if (!passId || !facilityId) {
      return;
    }

    setIsLoading(true);
    cancelPass({
      facilityId,
      id: passId,
      withPayment: fields.withPayment,
    })
      .then(({ success }) => {
        setIsLoading(false);
        if (success) {
          onComplete();
          dispatch(clearPassesCacheAction());
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [dispatch, facilityId, fields, onComplete, passId]);

  return (
    <CustomModalContentWrapper
      title="Отмена абонемента"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.title}>
              Вы уверены, что хотите отменить абонемент?
            </Typography>
          </Grid>
          {availableCancelPayment && (
            <Grid item xs={12}>
              <FormControlLabel
                className={styles.checkbox}
                control={(
                  <Checkbox
                    checked={fields.withPayment}
                    name="withPayment"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label={(
                  <Typography>
                    Вернуть клиенту деньги
                  </Typography>
                )}
              />
              {fields.withPayment ? (
                <Typography>
                  Будут отменены все оплаты, и клиенту возвращены деньги
                </Typography>
              ) : (
                <Typography>
                  Абонемент будет отменен без возврата оплат
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            <CustomBtn
              className={styles.saveBtn}
              onClick={onClose}
            >
              Нет
            </CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.saveBtn}
              btnType="primaryBlue"
              onClick={handlePassCancel}
              disabled={isLoading}
            >
              Да, отменить
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      </div>
    </CustomModalContentWrapper>
  );
}

export default CancelPassModalContent;
