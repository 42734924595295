import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import AllTicketsPage from 'components/pages/tickets/AllTicketsPage';
import NewOneTicketPage from 'components/pages/tickets/NewOneTicketPage';
import OneTicketInfoPage from 'components/pages/tickets/OneTicketInfoPage';
import OneTicketPage from 'components/pages/tickets/OneTicketPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import { PATH_PAGES } from 'config/constants';

function TicketsPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    timezone: tz,
  } = currentFacilityData || {};

  useEffect(() => {
    if (tz) {
      moment.tz.setDefault(tz);
      return;
    }

    moment.tz.setDefault();
  }, [tz]);

  const urlActions = PATH_PAGES.allTickets.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute path={PATH_PAGES.allTickets} component={AllTicketsPage} />
      <PrivateRoute exact path={PATH_PAGES.addTicket} component={NewOneTicketPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTicket} component={OneTicketPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTicketInfo} component={OneTicketInfoPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedTicketsPage = connect(
  mapStateToProps,
  {},
)(TicketsPage);

export default ConnectedTicketsPage;
