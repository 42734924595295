import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import { useQueryClient } from '@tanstack/react-query';
import {
  NumberParam, ObjectParam, StringParam, withDefault,
} from 'use-query-params';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { toNativeTimeFormat, useModal } from '@parkly/shared/helpers';

import { downloadAccountOperationDocumentAction } from 'actions/account';
import { useAccountBalance, useAccountOperations } from 'api/query/accounts';
import AccountBalance from 'components/molecules/AccountBalance';
import HeaderWithBackBtn from 'components/molecules/HeaderWithBackBtn';
import AccountWithdrawModalContent from 'components/organisms/AccountWithdrawModalContent';
import AccountOperationsTable from 'components/templates/AccountOperationsTable';
import { ACCOUNT_OPERATIONS_TYPES, ACCOUNT_OPERATIONS_TYPES_MAP, RIGHT_KEYS } from 'config/constants';
import { useCommonRights, useDebounce } from 'helpers/hooks';
import {
  createStringEnumParam, PageParam, withParams, withQueryParams,
} from 'helpers/queryParams';

import { useStyles } from './styles';

const TYPES = [
  {
    id: ACCOUNT_OPERATIONS_TYPES.deposit,
    title: ACCOUNT_OPERATIONS_TYPES_MAP[ACCOUNT_OPERATIONS_TYPES.deposit],
  },
  {
    id: ACCOUNT_OPERATIONS_TYPES.withdraw,
    title: ACCOUNT_OPERATIONS_TYPES_MAP[ACCOUNT_OPERATIONS_TYPES.withdraw],
  },
  {
    id: ACCOUNT_OPERATIONS_TYPES.charge,
    title: ACCOUNT_OPERATIONS_TYPES_MAP[ACCOUNT_OPERATIONS_TYPES.charge],
  },
  {
    id: ACCOUNT_OPERATIONS_TYPES.addition,
    title: ACCOUNT_OPERATIONS_TYPES_MAP[ACCOUNT_OPERATIONS_TYPES.addition],
  },
];

const TABS = [
  { id: '', label: 'Все' },
  { id: ACCOUNT_OPERATIONS_TYPES.addition, label: 'Реестры' },
];

function getInitParams() {
  return {
    tabValue: 0,
    from: '',
    to: '',
    type: 'unselect',
    page: 1,
  };
}

function prepareParam({
  params,
}) {
  const {
    from,
    to,
    type,
    page,
    tabValue,
  } = params || {};

  if (tabValue !== 0) {
    return {
      from,
      to,
      type: ACCOUNT_OPERATIONS_TYPES.addition,
      page,
      tabValue,
    };
  }

  return {
    from,
    to,
    type,
    page,
    tabValue,
  };
}

function AccountPage({
  downloadAccountOperationDocumentReq,

  // From HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const hasAccountUpdateRight = useCommonRights(RIGHT_KEYS.accountUpdate);

  const variables = useDebounce(params, 300);

  const { data: operations, isLoading } = useAccountOperations({
    variables,
    refetchInterval: 5000 * 60, // 5 mins
    refetchIntervalInBackground: true,
  });

  const {
    data: accountBalanceData,
    isLoading: isBalanceLoading,
  } = useAccountBalance({
    refetchInterval: 10000 * 60, // 10 mins
    refetchIntervalInBackground: true,
  });

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params, setParams]);
  const handleDateChange = useCallback(({ value, name }) => {
    handleParamChange(name, toNativeTimeFormat(value));
  }, [handleParamChange]);

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);
  }, [handleParamChange]);
  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  const handleDownloadDocument = useCallback((id) => {
    downloadAccountOperationDocumentReq({ id });
  }, [downloadAccountOperationDocumentReq]);

  const {
    balance,
    balanceForWithdraw,
  } = accountBalanceData || {};

  const [modalNode, onOpenModal, onHideModal] = useModal({
    title: 'Вывод средств',
    content: (
      <AccountWithdrawModalContent
        balanceForWithdraw={balanceForWithdraw}
        onClose={() => onHideModal()}
        onSuccess={() => {
          queryClient.invalidateQueries({ queryKey: ['account-operations'] });
          queryClient.invalidateQueries({ queryKey: ['account-balance'] });
        }}
      />
    ),
  });

  const {
    operationsData,
    operationsMeta,
  } = operations || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = operationsMeta || {};

  const {
    from,
    to,
    type,
    tabValue,
  } = params || {};

  const { type: selType } = variables || {};

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn
        title={t('navigation.account')}
        isBackBtn={false}
      />
      <AccountBalance
        currentBalance={balance}
        isLoading={isBalanceLoading}
        containerClassName={styles.balanceContainer}
      />
      {hasAccountUpdateRight && (
        <Grid container spacing={2}>
          <Grid item>
            <CustomBtn
              btnType="primaryRed"
              onClick={onOpenModal}
            >
              Вывести средства
            </CustomBtn>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={styles.title}>
            Операции по счету
          </Typography>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={6} sm={4} lg={2} xl={2}>
            <DateTimeInput
              className={[
                styles.datePicker,
                // (errors || {}).startDate ? styles.datePickerWithError : '',
              ].join(' ')}
              name="from"
              placeholder="Не выбрано"
              label="От"
              initStartDayTime
              rightIcon={(
                <>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleParamChange('from', '');
                    }}
                  >
                    <CloseIcon htmlColor="#7E8AA7" />
                  </IconButton>
                  <CalendarIcon />
                </>
              )}
              onChange={handleDateChange}
              value={from || undefined}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2} xl={2}>
            <DateTimeInput
              className={[
                styles.datePicker,
                // (errors || {}).startDate ? styles.datePickerWithError : '',
              ].join(' ')}
              name="to"
              placeholder="Не выбрано"
              label="До"
              initStartDayTime
              rightIcon={(
                <>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleParamChange('to', '');
                    }}
                  >
                    <CloseIcon htmlColor="#7E8AA7" />
                  </IconButton>
                  <CalendarIcon />
                </>
              )}
              onChange={handleDateChange}
              value={to || undefined}
            />
          </Grid>
          {tabValue === 0 && (
            <Grid item xs={12} sm={4} lg={2} xl={2}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={TYPES}
                label="Тип операции"
                defaultItem="Все"
                name="type"
                onChange={handleChanges}
                currentValue={type}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomTabBar
              tabs={TABS}
              tabValue={tabValue}
              handleParamChange={handleParamChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isLoading && (
            <CircularIndeterminate style={{ minHeight: 600 }} />
          )}
          {!isLoading && (
            <AccountOperationsTable
              hideStatusCol={selType === ACCOUNT_OPERATIONS_TYPES.addition}
              hideOperatorCol={selType === ACCOUNT_OPERATIONS_TYPES.addition}
              operationsData={operationsData}
              onDownloadDocument={handleDownloadDocument}
            />
          )}
          {!isLoading && lastPage > 1 && (
            <div className={styles.paginationContainer}>
              <Pagination
                className={styles.pagination}
                name="page"
                page={currentPage}
                onChange={onPageChange}
                count={lastPage}
                color="primary"
              />
            </div>
          )}
        </Grid>
      </Grid>
      {modalNode}
    </Container>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedAccountPage = connect(
  mapStateToProps,
  {
    downloadAccountOperationDocumentReq: downloadAccountOperationDocumentAction,
  },
)(AccountPage);

export const ConnectedAccountPageWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(ConnectedAccountPage);

const TypeEnumParam = createStringEnumParam([
  ACCOUNT_OPERATIONS_TYPES.deposit,
  ACCOUNT_OPERATIONS_TYPES.withdraw,
  ACCOUNT_OPERATIONS_TYPES.charge,
  ACCOUNT_OPERATIONS_TYPES.addition,
  'unselect',
]);

const ConnectedAccountPageWithQueryParams = withQueryParams({
  queryParams: {
    to: withDefault(StringParam, ''),
    from: withDefault(StringParam, ''),
    type: withDefault(TypeEnumParam, 'unselect'),
    tabValue: withDefault(NumberParam, 0),
    page: PageParam,
    sorting: withDefault(ObjectParam, {}),
  },
  prepareParam,
})(ConnectedAccountPage);

export default ConnectedAccountPageWithQueryParams;
