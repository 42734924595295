import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { useCheckFacilityRights } from 'helpers/hooks';
import { RIGHT_KEYS } from 'config/constants';
import { useActiveTariffPlans, useSetWelcomeTariffPlanMutation, useWelcomeTariffPlan } from 'api/query/tariffs';
import { useStyles } from './styles';

export default function WelcomeTariffPlanSelect({
  facilityId,
}) {
  const styles = useStyles();
  const hasTariffUpdateRight = useCheckFacilityRights(RIGHT_KEYS.tariffUpdate, facilityId);
  const [welcomeTariffPlanId, setWelcomeTariffPlanId] = useState(0);
  const [prevWelcomeTariffPlanId, setPrevWelcomeTariffPlanId] = useState(null);

  const {
    data: activeTariffPlansData,
    isLoading: isActiveTariffPlansLoading,
  } = useActiveTariffPlans({
    variables: { facilityId, withTechnical: false },
  });

  const {
    data: welcomeTariffPlanData,
    isLoading: isWelcomeTariffPlanLoading,
  } = useWelcomeTariffPlan({
    variables: { facilityId },
  });

  const setWelcomeTariffPlanMutation = useSetWelcomeTariffPlanMutation({
    onSuccess({ success }, { welcomeTariffPlanId: id }) {
      if (success) {
        setWelcomeTariffPlanId(id);
        toast.success('Welcome тарифный план сохранен!');
      }
    },
    onError() {
      if (prevWelcomeTariffPlanId) {
        setPrevWelcomeTariffPlanId(null);
        setWelcomeTariffPlanId(prevWelcomeTariffPlanId);
      }
    },
  });

  const isLoading = isWelcomeTariffPlanLoading || isActiveTariffPlansLoading;

  useEffect(() => {
    const {
      welcomeTariffPlanId: id,
    } = welcomeTariffPlanData || {};

    setWelcomeTariffPlanId(id);
  }, [welcomeTariffPlanData]);

  const tariffPlansSelectData = useMemo(() => {
    const data = (activeTariffPlansData || [])
      .map(({ id, name }) => ({
        id,
        title: name,
      }));

    data.push({
      id: 0,
      title: 'Выключено',
    });

    return data;
  }, [activeTariffPlansData]);

  const onChange = useCallback((e) => {
    const { target } = e;
    const { value, name } = target;

    if (name !== 'welcomeTariffPlanId') {
      return;
    }

    setWelcomeTariffPlanMutation.mutate({ facilityId, welcomeTariffPlanId: value });
    setPrevWelcomeTariffPlanId(welcomeTariffPlanId);
    setWelcomeTariffPlanId(value);
  }, [facilityId, setWelcomeTariffPlanMutation, welcomeTariffPlanId]);

  return (
    <Grid item container xs={12} spacing={3} alignItems="flex-start">
      <Grid item>
        <Typography className={styles.label}>
          Welcome тарифный план
        </Typography>
        <Typography className={styles.subLabel}>
          Тарифный план для свободного въезда
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        {isLoading ? (
          <div className={styles.loader}>
            <CircularProgress
              size={25}
            />
          </div>
        ) : (
          <ItemSelectors
            disabled={!hasTariffUpdateRight}
            classNameForm={styles.selectorsForm}
            classNameLabel={styles.selectorsLabel}
            className={styles.selector}
            items={tariffPlansSelectData}
            // error={errors.welcomeTariffPlanId}
            name="welcomeTariffPlanId"
            onChange={onChange}
            currentValue={welcomeTariffPlanId || 0}
          />
        )}
      </Grid>
    </Grid>
  );
}
