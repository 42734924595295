import { callAPI } from '@parkly/shared/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

async function validGetPayments({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const paymentsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const paymentsData = curData.map((item) => {
    const {
      id,
      sum,
      paidAt,
      payable,
      customerName,
      customerId,
      status,
      paymentType,
      canBeCanceled,
    } = item;

    return {
      id,
      sum,
      paidAt,
      payable,
      customerName,
      customerId,
      status,
      paymentType,
      canBeCanceled,
    };
  });

  return {
    paymentsData,
    paymentsMeta,
  };
}

export async function getPayments({
  facilityId,
  search,
  status,
  type,
  paidFrom,
  paidTo,
  customerId,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    search,
    status: status.join(','),
    type: type.join(','),
    paidFrom: paidFrom && moment(paidFrom).utc().toISOString(),
    paidTo: paidTo && moment(paidTo).utc().toISOString(),
    customerId,
    page,
    perPage,
    sortBy,
    sortDirection,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/payments?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPayments({
      res,
    }));
}

async function validGetPayment({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    sum,
    paidAt,
    payable,
    customerName,
    customerId,
    status,
    paymentType,
    canBeCanceled,
    statuses,
    receipts,
  } = resultData || {};

  return {
    id,
    sum,
    paidAt,
    payable,
    customerName,
    customerId,
    status,
    paymentType,
    canBeCanceled,
    statuses,
    receipts,
  };
}

export async function getPaymentInfo({
  facilityId,
  id,
}) {
  return callAPI(`facilities/${facilityId}/payments/${id}`)
    .then((res) => validGetPayment({
      res,
    }));
}

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function cancelPayment({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/payments/${id}/cancel`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}
