import React from 'react';

function DocumentsInactiveIcon({
  size = 32,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M18.586 4.586A2 2 0 0017.172 4H9.25c-.597 0-1.169.253-1.591.703A2.484 2.484 0 007
        6.4v19.2c0 .636.237 1.247.659 1.697.422.45.994.703 1.591.703h13.5c.597 0
        1.169-.253 1.591-.703.422-.45.659-1.06.659-1.697V11.828a2 2 0 00-.586-1.414l-5.828-5.828z"
        stroke="#FFFFFF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 4.5V10a1 1 0 001 1h5.5M20.7 21.761h-9.333M20.7 16.428h-9.333"
        stroke="#FFFFFF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DocumentsInactiveIcon;
