import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { GATE_DIRECTIONS } from 'config/constants';
import Typography from '@material-ui/core/Typography';

const getIcon = (direction) => {
  switch (direction) {
    case GATE_DIRECTIONS.enter:
      return <ArrowForwardIcon htmlColor="#43D37D" />;
    case GATE_DIRECTIONS.exit:
      return <ArrowBackIcon htmlColor="red" />;
    case GATE_DIRECTIONS.both:
      return <SyncAltIcon htmlColor="grey" />;
    default:
      return null;
  }
};

export default function Gate({
  gate,
}) {
  const {
    id,
    direction,
    name,
  } = gate || {};

  return (
    <div style={{ display: 'flex' }}>
      {getIcon(direction)}
      <Typography>
        {name.replaceAll(' ', ' ')}
      </Typography>
    </div>
  );
}
