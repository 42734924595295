import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  createEnumParam, DelimitedArrayParam, DelimitedNumericArrayParam, NumberParam, StringParam, withDefault,
} from 'use-query-params';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { useFacilities } from 'api/query/facilities';
import AnalyticAccessStatusFilter from 'components/organisms/AnalyticAccessStatusFilter';
import AnalyticGatesFilter from 'components/organisms/AnalyticGatesFilter';
import AnalyticTariffPlanFilter from 'components/organisms/AnalyticTariffPlanFilter';
import AnalyticChart from 'components/organisms/Charts/AnalyticChart';
import AnalyticTable from 'components/organisms/Charts/AnalyticTable';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import { ANALYTICS_MODES } from 'config/constants';
import {
  withParams,
  withQueryParams,
} from 'helpers/queryParams';

import { useStyles } from './styles';

function getTabs({ hasPaymentModule }) {
  return [
    { id: 0, label: 'Клиенты' },
    { id: 1, label: 'Сессии' },
    { id: 2, label: 'Проезды' },
    hasPaymentModule ? { id: 3, label: 'Доходы' } : null,
  ].filter((item) => !!item);
}

const TAB_MAP = {
  0: ['count_new_customer', 'count_paying_customer_by_type'],
  1: ['count_new_session', 'count_finish_session_by_status', 'avg_duration_finish_session_by_status'],
  2: [
    'count_success_enter_access_by_gate',
    'count_success_exit_access_by_gate',
    'count_access_by_type',
    'count_access_by_token_type',
    'count_error_access_by_type',
    'count_error_access_by_gate',
  ],
  3: [
    'count_paid_by_type',
    'count_cancel_by_type',
    'sum_paid_by_tariff_plan',
    'avg_sum_paid_by_type',
    'count_paying_customer_by_type',
  ],
};

const TAB_TABLES_MAP = {
  0: [
    'top_customers_by_sessions',
  ],
  1: [],
  2: [],
  3: [],
};

function prepareParam({
  params,
}) {
  const {
    mode,
    facilities,
    start,
    end,
    tab,
    tariff_plans: tariffPlans,
    access_statuses: accessStatuses,
    session_duration_from: sessionDurationFrom,
    session_duration_to: sessionDurationTo,
    gates,
  } = params || {};

  return {
    mode,
    facilities,
    start,
    end,
    tab,
    tariffPlans,
    accessStatuses,
    sessionDurationFrom,
    sessionDurationTo,
    gates,
  };
}

function AnalyticsPageV2({
  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const { data: facilitiesData } = useFacilities({});

  const hasPaymentModule = true;

  const {
    start,
    end,
    facilities,
    mode,
    tab,
    tariffPlans,
    gates,
    accessStatuses,
    sessionDurationFrom,
    sessionDurationTo,
  } = params || {};

  const tabs = useMemo(
    () => getTabs({ hasPaymentModule }),
    [hasPaymentModule],
  );

  const facilitiesItems = useMemo(
    () => (facilitiesData || []).map(({ id, title }) => ({ id, title })),
    [facilitiesData],
  );

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      [paramName]: newValue,
    }));
  }, [params, setParams]);

  const handleChanges = useCallback((event) => {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);

    if (propName === 'facilities') {
      handleParamChange('tariff_plans', []);
      handleParamChange('gates', []);
    }
  }, [handleParamChange]);

  const handleRangeChange = useCallback((event, newValue) => {
    handleParamChange('mode', newValue || 'week');
  }, [handleParamChange]);

  const handleTabChange = useCallback((event, newValue) => {
    handleParamChange('tab', newValue);

    if (![3, 1].includes(newValue)) {
      handleParamChange('tariff_plans', []);
    }

    if (newValue !== 1) {
      handleParamChange('session_duration_from', '');
      handleParamChange('session_duration_to', '');
    }

    if (newValue !== 2) {
      handleParamChange('gates', []);
      handleParamChange('access_statuses', []);
    }
  }, [handleParamChange]);

  const handleDateChange = useCallback(({ value, name }) => {
    handleParamChange(name, toNativeTimeFormat(value));
  }, [handleParamChange]);

  return (
    <Container className={styles.container}>
      <FiltersContainer>
        <HeaderWithBackBtn title={t('analytics.analytics')} isBackBtn={false} />
        <CustomTabBar tabs={tabs} tabValue={tab} handleParamChange={handleTabChange} />
        <Grid container spacing={1} className={styles.controlsContainer}>
          {(facilitiesItems || []).length > 1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MultiItemSelectors
                classNameForm={styles.statusSelectForm}
                className={styles.statusSelect}
                name="facilities"
                currentValue={facilities}
                items={facilitiesItems}
                unselectText="Все парковки"
                onChange={handleChanges}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateTimeInput
              className={[
                styles.datePicker,
              // (errors || {}).startDate ? styles.datePickerWithError : '',
              ].join(' ')}
              name="start"
              placeholder="Не выбрано"
              label="От"
              initStartDayTime
              rightIcon={(
                <>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleParamChange('start', '');
                    }}
                  >
                    <CloseIcon htmlColor="#7E8AA7" />
                  </IconButton>
                  <CalendarIcon />
                </>
              )}
              onChange={handleDateChange}
              value={start || undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateTimeInput
              className={[
                styles.datePicker,
              // (errors || {}).startDate ? styles.datePickerWithError : '',
              ].join(' ')}
              name="end"
              placeholder="Не выбрано"
              label="До"
              initStartDayTime
              rightIcon={(
                <>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleParamChange('end', '');
                    }}
                  >
                    <CloseIcon htmlColor="#7E8AA7" />
                  </IconButton>
                  <CalendarIcon />
                </>
              )}
              onChange={handleDateChange}
              value={end || undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ToggleButtonGroup className={styles.rangeContainer} value={mode} exclusive onChange={handleRangeChange}>
              <ToggleButton value="hour">
                <Typography>Час</Typography>
              </ToggleButton>
              <ToggleButton value="day">
                <Typography>День</Typography>
              </ToggleButton>
              <ToggleButton value="week">
                <Typography>Неделя</Typography>
              </ToggleButton>
              <ToggleButton value="month">
                <Typography>Месяц</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {[3, 1].includes(tab) && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticTariffPlanFilter
                facilityIds={facilities}
                name="tariff_plans"
                onChange={handleChanges}
                value={tariffPlans}
              />
            </Grid>
          )}
          {tab === 1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomTextInput
                className={styles.input}
                name="session_duration_from"
                label="Длительность От (минуты)"
                value={sessionDurationFrom}
                onChange={handleChanges}
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
          )}
          {tab === 1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomTextInput
                className={styles.input}
                name="session_duration_to"
                label="Длительность До (минуты)"
                value={sessionDurationTo}
                onChange={handleChanges}
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
          )}
          {tab === 2 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticGatesFilter
                facilityIds={facilities}
                name="gates"
                onChange={handleChanges}
                value={gates}
              />
            </Grid>
          )}
          {tab === 2 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticAccessStatusFilter
                facilityIds={facilities}
                name="access_statuses"
                onChange={handleChanges}
                value={accessStatuses}
              />
            </Grid>
          )}
        </Grid>
      </FiltersContainer>
      <Grid container spacing={2} className={styles.chartsContainer}>
        {TAB_MAP[tabs[tab].id].map((type) => (
          <Grid key={`${tab}_${type}`} item xs={12} md={6}>
            <AnalyticChart
              facilityIds={facilities}
              mode={mode}
              type={type}
              start={start}
              end={end}
              tariffPlanIds={tariffPlans}
              gateIds={gates}
              accessStatuses={accessStatuses}
              sessionDurationTo={sessionDurationTo}
              sessionDurationFrom={sessionDurationFrom}
            />
          </Grid>
        ))}
        {TAB_TABLES_MAP[tabs[tab].id].map((type) => (
          <Grid item xs={12}>
            <AnalyticTable
              facilityIds={facilities}
              mode={mode}
              type={type}
              start={start}
              end={end}
              tariffPlanIds={tariffPlans}
              gateIds={gates}
              accessStatuses={accessStatuses}
              sessionDurationTo={sessionDurationTo}
              sessionDurationFrom={sessionDurationFrom}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export const AnalyticsPageV2WithParams = withParams({
  initParams: {
    facilities: [],
    mode: ANALYTICS_MODES.day,
    start: '',
    end: '',
    tab: 0,
    tariff_plans: [],
    gates: [],
    access_statuses: [],
    session_duration_from: '',
    session_duration_to: '',
  },
  prepareParam,
})(AnalyticsPageV2);

const ModeEnumParam = createEnumParam([
  ANALYTICS_MODES.hour,
  ANALYTICS_MODES.day,
  ANALYTICS_MODES.week,
  ANALYTICS_MODES.month,
]);

const AnalyticsPageV2WithQueryParams = withQueryParams({
  queryParams: {
    start: withDefault(StringParam, ''),
    end: withDefault(StringParam, ''),
    facilities: withDefault(DelimitedNumericArrayParam, []),
    tariff_plans: withDefault(DelimitedNumericArrayParam, []),
    gates: withDefault(DelimitedNumericArrayParam, []),
    access_statuses: withDefault(DelimitedArrayParam, []),
    mode: withDefault(ModeEnumParam, ANALYTICS_MODES.day),
    tab: withDefault(NumberParam, 0),
    session_duration_from: withDefault(NumberParam, ''),
    session_duration_to: withDefault(NumberParam, ''),
  },
  prepareParam,
})(AnalyticsPageV2);

export default AnalyticsPageV2WithQueryParams;
