import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { createPortal } from 'react-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CameraStream from 'components/molecules/CameraStream';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { getCameraUUIDSAction } from 'actions/controllers';

import { SECTIONS } from 'config/constants';
import { useStyles } from './styles';

function getCurrentUUIDS({
  allCameraUUIDSState,
  facilityId,
}) {
  const list = (allCameraUUIDSState || {}).list || [];
  const preparedReqParam = { facilityId };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const uuids = list.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    uuids,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function CameraStreamList({
  facilityId,
  isExpanded,
  toggleExpanded = () => {},

  getCameraUUIDSReq,
  allCameraUUIDSState,
}) {
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ isExpanded });
  const [prevParams, setPrevParams] = useState('');

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      uuids,
      currentStrParam,
    } = getCurrentUUIDS({
      allCameraUUIDSState,
      facilityId,
    });

    if (currentStrParam !== prevParams) {
      if (!uuids) {
        getCameraUUIDSReq({ facilityId });

        setPrevParams(currentStrParam);
      }
    }
  }, [allCameraUUIDSState, facilityId, getCameraUUIDSReq, prevParams]);

  const {
    uuids: uuidsRes,
  } = getCurrentUUIDS({
    allCameraUUIDSState,
    facilityId,
  });

  const {
    data: uuids,
  } = uuidsRes || {};

  if (isEmpty(uuids)) {
    return null;
  }

  const content = (
    <div id="CameraStreamListId" className={styles.container}>
      {isExpanded && (
        <div className={styles.videoContainer}>
          {(uuids || []).map(({ uuid, channel, autoStopTimeout }) => (
            <CameraStream
              key={uuid}
              uuid={uuid}
              channel={channel}
              autoStopTimeout={autoStopTimeout}
            />
          ))}
        </div>
      )}
      <div className={styles.unexpandBadge} onClick={toggleExpanded}>
        <Typography>
          {isExpanded ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )}
          Видеостена
        </Typography>
      </div>
    </div>
  );

  if (matchDownSm) {
    return content;
  }

  const contentPortal = createPortal(content, document.getElementById(SECTIONS.right));

  return (
    <div>
      {contentPortal}
      {!isExpanded && (
        <div className={styles.expandBadge} onClick={toggleExpanded}>
          <Typography>
            <ArrowDownwardIcon fontSize="small" />
            Видеостена
          </Typography>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const { allCameraUUIDS } = controllers || {};

  return {
    allCameraUUIDSState: allCameraUUIDS,
  };
}

const ConnectedCameraStreamList = connect(
  mapStateToProps,
  {
    getCameraUUIDSReq: getCameraUUIDSAction,
  },
)(CameraStreamList);

export default ConnectedCameraStreamList;
