import {
  addNewApiOperator,
  addNewOperator,
  getActionsOneOperator,
  getCurrentOperator,
  getOneOperator,
  getOperatorRightList,
  getOperators,
  removeOperator,
  updateApiOperator,
  updateOperator,
} from 'api/operators';
import { PATH_PAGES } from 'config/constants';
import { getUserRights } from 'helpers/rights';

import {
  ADD_NEW_OPERATOR_REQUEST,
  CLEAR_CACHE_ONE_OPERATOR,
  CLEAR_CACHE_OPERATORS,
  GET_ACTIONS_ONE_OPERATOR_REQUEST,
  GET_CURRENT_OPERATOR, GET_LIST_360_MODULES,
  GET_LIST_360_RIGHTS,
  GET_LIST_360_ROLES,
  GET_ONE_OPERATOR_REQUEST,
  GET_OPERATORS,
  REMOVE_OPERATOR_REQUEST,
  UPDATE_OPERATOR_REQUEST,
} from './types';

export function clearCacheOneOperator() {
  function actionClearCacheOneOperator() {
    return { type: CLEAR_CACHE_ONE_OPERATOR };
  }

  return (dispatch) => {
    dispatch(actionClearCacheOneOperator());
  };
}

export function clearCacheOperators() {
  function actionClearCacheOperators() {
    return { type: CLEAR_CACHE_OPERATORS };
  }

  return (dispatch) => {
    dispatch(actionClearCacheOperators());
  };
}

export function getCurrentOperatorAction() {
  function getCurrentOperatorRequest(payload) {
    return { type: GET_CURRENT_OPERATOR, payload };
  }

  return (dispatch) => {
    dispatch(getCurrentOperatorRequest({
      loading: true,
    }));

    getCurrentOperator().then(
      (operator) => {
        const userRights = getUserRights(operator);

        dispatch(getCurrentOperatorRequest({
          loading: false,
          data: operator,
          userRights,
        }));
      },
      (error) => {
        dispatch(getCurrentOperatorRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function removeOperatorAction({
  operatorId,
  history,
}) {
  function removeOperatorRequest(payload) {
    return { type: REMOVE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!operatorId) {
      console.error('No operator id!');

      dispatch(removeOperatorRequest({
        loading: false,
        error: 'No operator id!',
      }));
      return;
    }

    dispatch(removeOperatorRequest({
      loading: true,
    }));

    removeOperator({
      operatorId,
    })
      .then(
        (result) => {
          dispatch(removeOperatorRequest({
            loading: false,
            result,
          }));
          dispatch(clearCacheOperators());
          history.replace(PATH_PAGES.operators);
        },
        (error) => {
          dispatch(removeOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function updateOperatorAction({
  name,
  jobTitle,
  phone,
  comment,
  status,
  role,
  rules,
  operatorId,
  allFacilitiesData,
}) {
  function updateOperatorRequest(payload) {
    return { type: UPDATE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!operatorId) {
      console.error('No operator id!');

      dispatch(updateOperatorRequest({
        loading: false,
        error: 'No operator id!',
      }));
      return;
    }

    dispatch(updateOperatorRequest({
      loading: true,
    }));

    updateOperator({
      name,
      jobTitle,
      phone,
      comment,
      status,
      role,
      rules,
      operatorId,
      allFacilitiesData,
    })
      .then(
        (result) => {
          // eslint-disable-next-line
          getOneOperatorAction({
            id: operatorId,
          })(dispatch);

          dispatch(updateOperatorRequest({
            loading: false,
            result,
          }));

          dispatch(clearCacheOperators());
        },
        (error) => {
          dispatch(updateOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function addNewOperatorAction({
  name,
  jobTitle,
  phone,
  comment,
  status,
  role,
  rules,
  history,
  allFacilitiesData,
}) {
  function addNewOperatorRequest(payload) {
    return { type: ADD_NEW_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!phone && !name) {
      console.error('No data!');

      dispatch(addNewOperatorRequest({
        loading: false,
        error: 'No data!',
      }));
      return;
    }

    dispatch(addNewOperatorRequest({
      loading: true,
    }));

    addNewOperator({
      name,
      jobTitle,
      phone,
      comment,
      status,
      role,
      rules,
      allFacilitiesData,
    })
      .then(
        (result) => {
          dispatch(addNewOperatorRequest({
            loading: false,
            result,
          }));
          dispatch(clearCacheOperators());
          const { operatorId } = result;

          if (operatorId || operatorId === 0) {
            const url = PATH_PAGES.oneOperator.replace(':id', operatorId);
            history.replace(url);
          }
        },
        (error) => {
          dispatch(addNewOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function updateApiOperatorAction({
  name,
  comment,
  status,
  role,
  rules,
  operatorId,
  allFacilitiesData,
}) {
  function updateOperatorRequest(payload) {
    return { type: UPDATE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!operatorId) {
      console.error('No operator id!');

      dispatch(updateOperatorRequest({
        loading: false,
        error: 'No operator id!',
      }));
      return;
    }

    dispatch(updateOperatorRequest({
      loading: true,
    }));

    updateApiOperator({
      name,
      comment,
      status,
      role,
      rules,
      operatorId,
      allFacilitiesData,
    })
      .then(
        (result) => {
          // eslint-disable-next-line
          getOneOperatorAction({
            id: operatorId,
          })(dispatch);

          dispatch(updateOperatorRequest({
            loading: false,
            result,
          }));

          dispatch(clearCacheOperators());
        },
        (error) => {
          dispatch(updateOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function addNewApiOperatorAction({
  name,
  comment,
  status,
  role,
  rules,
  history,
  allFacilitiesData,
}) {
  function addNewOperatorRequest(payload) {
    return { type: ADD_NEW_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!name) {
      console.error('No data!');

      dispatch(addNewOperatorRequest({
        loading: false,
        error: 'No data!',
      }));
      return;
    }

    dispatch(addNewOperatorRequest({
      loading: true,
    }));

    addNewApiOperator({
      name,
      comment,
      status,
      role,
      rules,
      allFacilitiesData,
    })
      .then(
        (result) => {
          dispatch(addNewOperatorRequest({
            loading: false,
            result,
          }));
          dispatch(clearCacheOperators());
          const { operatorId } = result;

          if (operatorId || operatorId === 0) {
            const url = PATH_PAGES.oneOperator.replace(':id', operatorId);
            history.replace(url);
          }
        },
        (error) => {
          dispatch(addNewOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getOneOperatorAction({
  id,
}) {
  function getOneOperatorRequest(payload) { return { type: GET_ONE_OPERATOR_REQUEST, payload }; }

  return (dispatch) => {
    if (!id && id !== 0) {
      console.error('No operator id in getOneOperatorAction!');

      dispatch(getOneOperatorRequest({
        loading: false,
        error: 'No operator id in getOneOperatorAction!',
      }));
      return;
    }

    dispatch(getOneOperatorRequest({
      loading: true,
    }));

    getOneOperator({
      id,
    })
      .then(
        (operator) => {
          dispatch(getOneOperatorRequest({
            loading: false,
            operator,
          }));
        },
        (error) => {
          dispatch(getOneOperatorRequest({
            loading: false,
            error: {
              id,
              error,
            },
          }));
        },
      );
  };
}

export function getOperatorsAction() {
  function getOperatorsRequest(payload) {
    return { type: GET_OPERATORS, payload };
  }

  return (dispatch) => {
    dispatch(getOperatorsRequest({
      loading: true,
    }));

    getOperators()
      .then(
        (data) => {
          dispatch(getOperatorsRequest({
            loading: false,
            data,
          }));
        },
        (error) => {
          dispatch(getOperatorsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getActionsOneOperatorAction({
  id,
  date,
  page,
}) {
  function getActionsOneOperatorRequest(payload) {
    return { type: GET_ACTIONS_ONE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    dispatch(getActionsOneOperatorRequest({
      loading: true,
    }));

    const apiParam = {
      id,
      date: date ? date.format('YYYY-MM-DD') : date,
      page,
    };
    getActionsOneOperator(apiParam)
      .then(
        (actions) => {
          dispatch(getActionsOneOperatorRequest({
            loading: false,
            actions,
            operatorId: id,
          }));
        },
        (error) => {
          dispatch(getActionsOneOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getListRightsAction() {
  function getList360RightsRequest(payload) {
    return { type: GET_LIST_360_RIGHTS, payload };
  }

  function getList360RolesRequest(payload) {
    return { type: GET_LIST_360_ROLES, payload };
  }

  function getList360ModulesRequest(payload) {
    return { type: GET_LIST_360_MODULES, payload };
  }

  return (dispatch) => {
    dispatch(getList360RightsRequest({
      loading: true,
    }));
    dispatch(getList360RolesRequest({
      loading: true,
    }));
    dispatch(getList360ModulesRequest({
      loading: true,
    }));

    getOperatorRightList()
      .then(
        ({ rights, roles, modules }) => {
          dispatch(getList360RightsRequest({
            loading: false,
            list: rights,
          }));
          dispatch(getList360RolesRequest({
            loading: false,
            list: roles,
          }));
          dispatch(getList360ModulesRequest({
            loading: false,
            list: modules,
          }));
        },
        (error) => {
          dispatch(getList360RightsRequest({
            loading: false,
            error,
          }));
          dispatch(getList360RolesRequest({
            loading: false,
            error,
          }));
          dispatch(getList360ModulesRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export default {
  clearCacheOperators,
  getCurrentOperatorAction,
};
