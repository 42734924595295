import {
  getCameraUUIDS,
  getConditionalControllers,
  getControllers,
  getGSMSettings,
  removeController,
  setController,
} from 'api/controllers';
import {
  CLEAR_CONTROLLERS_CACHE,
  GET_ALL_CONDITIONAL_CONTROLLERS,
  GET_ALL_CONTROLLERS,
  GET_CAMERA_UUIDS,
  GET_GSM_CONTROLLER_SETTINGS,
  REMOVE_CONTROLLER,
  SET_CONTROLLER,
} from './types';
import { getFacilityGatesWithControllersAction } from './facilities';

export function clearControllersCacheAction() {
  function actionClearCacheControllers() {
    return { type: CLEAR_CONTROLLERS_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheControllers());
  };
}

export function getControllersAction(param) {
  function getControllersRequest(payload) {
    return { type: GET_ALL_CONTROLLERS, payload };
  }

  return (dispatch) => {
    dispatch(getControllersRequest({
      loading: true,
    }));

    getControllers(param).then(
      (data) => {
        dispatch(getControllersRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getControllersRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getConditionalControllersAction(param) {
  function getCondControllersRequest(payload) {
    return { type: GET_ALL_CONDITIONAL_CONTROLLERS, payload };
  }

  return (dispatch) => {
    dispatch(getCondControllersRequest({
      loading: true,
    }));

    getConditionalControllers(param).then(
      (data) => {
        dispatch(getCondControllersRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getCondControllersRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function setControllerAction(params) {
  function setControllerRequest(payload) {
    return { type: SET_CONTROLLER, payload };
  }

  const { facilityId } = params || {};

  return (dispatch) => {
    dispatch(setControllerRequest({
      loading: true,
    }));

    setController(params).then(
      ({ id, success }) => {
        dispatch(setControllerRequest({
          loading: false,
        }));

        if (success) {
          dispatch(getControllersAction({ facilityId }));
          dispatch(getFacilityGatesWithControllersAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(setControllerRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function removeControllerAction(params) {
  function removeControllerRequest(payload) {
    return { type: REMOVE_CONTROLLER, payload };
  }

  const { facilityId } = params || {};

  return (dispatch) => {
    dispatch(removeControllerRequest({
      loading: true,
    }));

    removeController(params).then(
      ({ success }) => {
        dispatch(removeControllerRequest({
          loading: false,
        }));

        if (success) {
          dispatch(getControllersAction({ facilityId }));
          dispatch(getFacilityGatesWithControllersAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(removeControllerRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getGSMControllerSettingsAction(param) {
  function getGSMControllerSettingsRequest(payload) {
    return { type: GET_GSM_CONTROLLER_SETTINGS, payload };
  }

  return (dispatch) => {
    dispatch(getGSMControllerSettingsRequest({
      loading: true,
    }));

    getGSMSettings(param).then(
      (data) => {
        dispatch(getGSMControllerSettingsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getGSMControllerSettingsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getCameraUUIDSAction(param) {
  function getCameraUUIDSRequest(payload) {
    return { type: GET_CAMERA_UUIDS, payload };
  }

  return (dispatch) => {
    dispatch(getCameraUUIDSRequest({
      loading: true,
    }));

    getCameraUUIDS(param).then(
      (data) => {
        dispatch(getCameraUUIDSRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getCameraUUIDSRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
