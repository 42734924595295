import React from 'react';

import CloseIcon from '@parkly/shared/components/atoms/icons/CloseIcon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';

const CustomCloseIconBtn = withStyles(() => ({
  root: {
    height: 32,
    width: 32,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 16,
    right: 16,
  },
}))(IconButton);

const CustomModalContentWrapper = ({
  title,
  onClose,
  children,
  paperClassname,
  bodyClassname,
}) => {
  const styles = useStyles();

  return (
    <div
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className={[styles.paper, paperClassname].join(' ')}
    >
      <div className={styles.headerContainer}>
        <CustomCloseIconBtn
          onClick={onClose}
        >
          <CloseIcon />
        </CustomCloseIconBtn>
        <Typography className={styles.headerStr}>
          {title}
        </Typography>
      </div>
      <div className={[styles.modalBody, bodyClassname].join(' ')}>
        {children}
      </div>
    </div>
  );
};

export default CustomModalContentWrapper;
