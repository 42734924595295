import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CameraAltTwoToneIcon from '@material-ui/icons/CameraAltTwoTone';
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import { formatPhoneNumberInput } from '@parkly/shared/helpers';

import Gate from 'components/molecules/Gate';
import CustomerFastActions from 'components/organisms/CustomerFastActions';
import {
  GATEWAY_EVENTS_TYPES,
  MAP_GATEWAY_EVENTS_STATUS_COLOR,
  MAP_GATEWAY_EVENTS_STATUS_NAME,
  MAP_GATEWAY_EVENTS_TYPES_NAME,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';

import { useImageViewer } from '../../atoms/ImageViewer';

import { useStyles } from './styles';

/* help function */

export const getSessionDateContent = (
  sessionData,
  facilityId,
  history,
  hasCustomerShowRight,
  hasPassShowRight,
) => {
  const {
    type,
    linkToType,
    linkToId,
  } = sessionData || {};

  if (!sessionData) {
    return (
      <Typography>-</Typography>
    );
  }

  let link = '';
  if (linkToId !== null) {
    switch (linkToType) {
      case 'pass':
        if (hasPassShowRight) {
          link = PATH_PAGES.onePassInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        }
        break;
      case 'ticket':
        if (hasPassShowRight) {
          link = PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        }
        break;
      case 'customer':
        if (hasCustomerShowRight) {
          link = PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        }
        break;
    }
  }

  let paylableName = '-';
  switch (type) {
    case 'pass':
      paylableName = 'Абонемент';
      break;
    case 'ticket':
      paylableName = 'Пропуск';
      break;
    case 'customer':
      paylableName = '';
      break;
    case 'session':
      paylableName = 'Сессия';
      break;
    default:
  }

  if (link) {
    return (
      <CustomLink
        href={link}
        onClick={(e) => {
          e.preventDefault();
          history.push(link);
        }}
      >
        {paylableName}
      </CustomLink>
    );
  }

  return (
    <Typography>
      {paylableName}
    </Typography>
  );
};

const getTokenContent = (token, eventType) => {
  if ([GATEWAY_EVENTS_TYPES.callAppeared, GATEWAY_EVENTS_TYPES.callDtmf, GATEWAY_EVENTS_TYPES.parkly, GATEWAY_EVENTS_TYPES.callOffline, GATEWAY_EVENTS_TYPES.laurentPhoneCall].includes(eventType)) {
    return formatPhoneNumberInput(token);
  }

  if (GATEWAY_EVENTS_TYPES.wiegand === eventType) {
    return (
      <>
        {token}
        <ContactlessOutlinedIcon htmlColor="grey" />
      </>
    );
  }

  return token;
};

const propTypes = {};

function GatewayEventsCards({
  facilityId,
  gatewayEventsData,
  hideCustomer = false,
  hideRelation = false,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const isGatewayEventsData = gatewayEventsData && (gatewayEventsData || []).length > 0;

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, facilityId);
  const hasFastActionsShowRight = useCheckFacilityRights(RIGHT_KEYS.customerSessionGateControl, facilityId);
  const hasOperatorShowRight = useCommonRights(RIGHT_KEYS.admin);

  const [imageViewerNode, openImageViewer] = useImageViewer();

  return (
    <div className={styles.root}>
      {!isGatewayEventsData && (
        <div className={styles.noDataContainer}>
          <Typography
            className={styles.noDataStr}
          >
            Пока журнал проездов пуст
          </Typography>
        </div>
      )}
      {isGatewayEventsData && (
        <Grid container spacing={2}>
          {(gatewayEventsData || []).map((item) => {
            const {
              id,
              status,
              type,
              eventAt,
              gates,
              gatewayEventId,
              photo,
              customerName,
              sessionData,
              gatewayToken: gatewayTokens,
              operator,
              fastActions,
              description,
              isFakeSession,

            } = item;

            const key = id;

            const eventTimeStr = eventAt ? moment(eventAt).format('HH:mm:ss') : '-';
            const eventDateStr = eventAt ? moment(eventAt).format('DD.MM.yyyy') : '';

            return (
              <Grid key={key} item xs={12}>
                <Card className={styles.cardItem} elevation={2}>
                  <GridTitleBase
                    classNameContainer={styles.firstItemContainer}
                    title="Тип"
                    titleXs={5}
                  >
                    <div>
                      <Typography>{MAP_GATEWAY_EVENTS_TYPES_NAME[type]}</Typography>
                    </div>
                  </GridTitleBase>
                  <GridTitleBase
                    classNameContainer={styles.itemContainer}
                    title="Статус"
                    titleXs={5}
                  >
                    <div>

                      <Typography className={styles.statusStr} style={{ alignItems: 'baseline' }}>
                        <CircleIcon color={MAP_GATEWAY_EVENTS_STATUS_COLOR[status]} />
                        {description
                          ? `${MAP_GATEWAY_EVENTS_STATUS_NAME[status]}: ${description.charAt(0).toLowerCase() + description.slice(1)}`
                          : MAP_GATEWAY_EVENTS_STATUS_NAME[status]}
                        {isFakeSession && ' (cвободный выезд)'}
                      </Typography>
                    </div>
                  </GridTitleBase>
                  <GridTitleBase
                    classNameContainer={styles.itemContainer}
                    title="Проезд"
                    titleXs={5}
                  >
                    {(gates || []).map((gate) => (
                      <Gate key={gate.id} gate={gate} />
                    ))}
                    {(gates || []).length === 0 && (
                      <Typography>
                        -
                      </Typography>
                    )}
                  </GridTitleBase>
                  <GridTitleBase
                    classNameContainer={styles.itemContainer}
                    title="Токен"
                    titleXs={5}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      {gatewayEventId && photo && (
                        <IconButton size="small" onClick={() => openImageViewer(photo.url)}>
                          <CameraAltTwoToneIcon />
                        </IconButton>
                      )}
                      <div>
                        {(gatewayTokens || []).map((token, index) => (
                          <Typography key={`${index}_token`}>
                            {getTokenContent(token, type)}
                          </Typography>
                        ))}
                        {(gatewayTokens || []).length === 0 && (
                          <Typography>
                            -
                          </Typography>
                        )}
                      </div>
                    </div>
                  </GridTitleBase>
                  {gatewayEventId && photo && (
                    <GridTitleBase
                      classNameContainer={styles.itemContainer}
                      title="Фото"
                      titleXs={5}
                    >
                      <img className={styles.photo} src={photo.thumbUrl} onClick={() => openImageViewer(photo.url)} />
                    </GridTitleBase>
                  )}
                  {!hideCustomer && (
                    <GridTitleBase
                      classNameContainer={styles.itemContainer}
                      title="Клиент"
                      titleXs={5}
                    >
                      <Typography>
                        {customerName || '-'}
                      </Typography>
                    </GridTitleBase>
                  )}
                  {operator && (
                    <GridTitleBase
                      classNameContainer={styles.itemContainer}
                      title="Оператор"
                      titleXs={5}
                    >
                      {hasOperatorShowRight ? (
                        <CustomLink
                          className={styles.operatorStr}
                          href={PATH_PAGES.oneOperator.replace(':id', operator.id)}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(PATH_PAGES.oneOperator.replace(':id', operator.id));
                          }}
                        >
                          {operator.name.replaceAll(' ', ' ')}
                        </CustomLink>
                      ) : (
                        <Typography className={styles.operatorStr}>
                          {operator.name.replaceAll(' ', ' ')}
                        </Typography>
                      )}
                    </GridTitleBase>
                  )}
                  {sessionData && (
                    <GridTitleBase
                      classNameContainer={styles.itemContainer}
                      title="Связь"
                      titleXs={5}
                    >
                      {getSessionDateContent(sessionData, facilityId, history, hasCustomerShowRight, hasPassShowRight)}
                    </GridTitleBase>
                  )}
                  <GridTitleBase
                    classNameContainer={styles.lastItemContainer}
                    title="Время"
                    titleXs={5}
                  >
                    <Typography className={styles.timeStr}>
                      {eventTimeStr}
                      {' '}
                      <span className={styles.dateStr}>{eventDateStr}</span>
                    </Typography>
                  </GridTitleBase>
                  {hasFastActionsShowRight && (fastActions || []).length > 0 && (
                    <CustomerFastActions
                      className={styles.btnsContainer}
                      facilityId={facilityId}
                      fastActions={fastActions}
                      gatewayEventId={gatewayEventId}
                    />
                  )}
                </Card>
              </Grid>
            );
          })}

        </Grid>
      )}
      {imageViewerNode}
    </div>
  );
}

GatewayEventsCards.propTypes = propTypes;

export default GatewayEventsCards;
