import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import { plural } from '@parkly/shared/helpers';

import { getFacilityLicenseAction } from 'actions/licenses';
import { LICENSE_TARIFF_TYPES_MAP } from 'config/constants';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

const LICENCE_STATUSES = {
  new: 0,
  demo: 1,
  active: 2,
  overdue: 3,
};

const LICENCE_STATUSES_MAP = {
  [LICENCE_STATUSES.new]: 'Новая',
  [LICENCE_STATUSES.demo]: 'Демо',
  [LICENCE_STATUSES.active]: 'Активирована',
  [LICENCE_STATUSES.overdue]: 'Просрочена',
};

const LICENCE_SUPERVISOR_TARIFF_TYPES = {
  none: 0,
  base: 1,
  standard: 2,
  advanced: 3,
};

const LICENCE_SUPERVISOR_TARIFF_TYPES_MAP = {
  [LICENCE_SUPERVISOR_TARIFF_TYPES.none]: 'Нет',
  [LICENCE_SUPERVISOR_TARIFF_TYPES.base]: 'Базовый',
  [LICENCE_SUPERVISOR_TARIFF_TYPES.standard]: 'Стандартный',
  [LICENCE_SUPERVISOR_TARIFF_TYPES.advanced]: 'Продвинутый',
};


function getCurrentFacilityLicense({
  facilityLicenseState,
  match,
}) {
  const facilityId = ((match || {}).params || {}).facilityId;

  const { list } = facilityLicenseState || {};
  const currentFacilityLicense = (list || []).find(({ facilityId: licenseFacilityId } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + licenseFacilityId) === ('' + facilityId);

    return isNeeded;
  });

  return {
    facilityId,
    currentFacilityLicense,
  };
}

function LicenseSettingsPage({
  match,

  getFacilityLicenseReg,
  facilityLicenseState,

  registrationStatusData,
  currentOperatorState,
}) {
  const styles = useStyles();
  useCurrentFacilityMatch();

  const facilityId = ((match || {}).params || {}).facilityId;

  useEffect(() => {
    const {
      loading: isLoading,
    } = facilityLicenseState || {};

    if (isLoading) {
      return;
    }

    const {
      currentFacilityLicense,
    } = getCurrentFacilityLicense({
      facilityLicenseState,
      match,
    });

    if (currentFacilityLicense) {
      return;
    }

    getFacilityLicenseReg({ facilityId });
  }, [facilityId, facilityLicenseState, getFacilityLicenseReg, match]);

  const {
    loading: isLoading,
  } = facilityLicenseState || {};

  const {
    currentFacilityLicense,
  } = getCurrentFacilityLicense({
    facilityLicenseState,
    match,
  });

  const {
    error,
  } = currentFacilityLicense || {};

  if (isLoading) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn
          title="Лицензия"
          isBackBtn={false}
        />
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn
          title="Лицензия"
          isBackBtn={false}
        />
        <Typography>
          Что то пошло не так!
        </Typography>
      </div>
    );
  }

  const {
    number,
    tariffType,
    status,
    duration,
    placesCount,
    gatesCount,
    commission,
    recognizeCount,
    storeImageDuration,
    supervisorTariffType,
    phoneNumber,
    activationAt,
    endAt,
  } = currentFacilityLicense || {};

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title="Лицензия"
        isBackBtn={false}
      />
      <Grid className={styles.gridContainer} container item spacing={3} xs={12} md={8} lg={8} xl={5}>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Номер"
          titleSm={7}
        >
          <Typography>
            {number}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Тариф"
          titleSm={7}
        >
          <Typography>
            {LICENSE_TARIFF_TYPES_MAP[tariffType] || '-'}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Статус"
          titleSm={7}
        >
          <Typography>
            {LICENCE_STATUSES_MAP[status] || '-'}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Срок"
          titleSm={7}
        >
          <Typography>
            {`${duration} ${plural(duration, ['день', 'дня', 'дней'])}`}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Количество машиномест"
          titleSm={7}
        >
          <Typography>
            {`${placesCount} шт.`}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Количество проездов"
          titleSm={7}
        >
          <Typography>
            {`${gatesCount} шт.`}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Коммисия за эквайринг"
          titleSm={7}
        >
          <Typography>
            {`${commission} %`}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Количество каналов распознавания"
          titleSm={7}
        >
          <Typography>
            {`${recognizeCount} шт.`}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Количество дней хранения фотоматериалов"
          titleSm={7}
        >
          <Typography>
            {`${storeImageDuration} ${plural(storeImageDuration, ['день', 'дня', 'дней'])}`}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Тариф диспетчеризации"
          titleSm={7}
        >
          <Typography>
            {LICENCE_SUPERVISOR_TARIFF_TYPES_MAP[supervisorTariffType] || '-'}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Телефон для реактивной парковки"
          titleSm={7}
        >
          <Typography>
            {phoneNumber}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Дата активации"
          titleSm={7}
        >
          <Typography>
            {moment(activationAt).format('D MMM YYYY HH:mm')}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          classNameContainer={styles.itemContainer}
          title="Дата окончания"
          titleSm={7}
        >
          <Typography>
            {moment(endAt).format('D MMM YYYY HH:mm')}
          </Typography>
        </GridTitleBase>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  const { companies, operators, licenses } = state || {};
  const { registrationStatus } = companies || {};
  const { currentOperator: currentOperatorState } = operators || {};
  const { facilityLicense: facilityLicenseState } = licenses || {};

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    currentOperatorState,
    facilityLicenseState,
  };
}

const ConnectedPaySettingsPage = connect(
  mapStateToProps,
  {
    getFacilityLicenseReg: getFacilityLicenseAction,
  },
)(LicenseSettingsPage);

export default ConnectedPaySettingsPage;
