import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { useStyles } from './styles';

const Accordion = withStyles({
  root: {
    borderRadius: '6px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    // border: '1px solid rgba(0, 0, 0, .125)',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderRadius: '6px',
    backgroundColor: '#F6F7FB',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, .125)',
    borderTop: 'unset',
    borderRadius: '6px',
    maxHeight: 400,
    overflowY: 'auto',
  },
}))(MuiAccordionDetails);

export default function PlaceAccordionSelect({
  initPlaceId,
  initAreaId,

  onChange = () => {},
  selTitle = '',

  disabled = false,

  areas,
  isLoading,
}) {
  const styles = useStyles();

  const [selAreaIndex, setSelAreaIndex] = useState(0);
  const [selPlaceIndex, setSelPlaceIndex] = useState(null);

  useEffect(() => {
    if (isEmpty(areas) || !initAreaId || !initPlaceId) {
      return;
    }

    const areaIndex = areas.findIndex(({ id }) => id === initAreaId);

    if (areaIndex !== -1) {
      const placeIndex = areas[areaIndex].places.findIndex(({ id }) => id === initPlaceId);

      if (placeIndex === -1) {
        return;
      }

      setSelAreaIndex(areaIndex);
      setSelPlaceIndex(placeIndex);

      const placeName = areas[areaIndex].places[placeIndex].name;
      const title = (areas || []).length > 1 ? `${areas[areaIndex].name} - ${placeName}` : placeName;

      onChange(initPlaceId, title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas, initAreaId, initPlaceId]);

  const handleAreaChange = useCallback((index) => {
    setSelPlaceIndex(null);
    setSelAreaIndex(index);

    onChange(null, null);
  }, [onChange]);

  const handlePlaceChange = useCallback((index, id, name, status) => {
    if (status !== 0) {
      return;
    }

    setSelPlaceIndex(index);
    const title = (areas || []).length > 1 ? `${areas[selAreaIndex].name} - ${name}` : name;

    onChange(id, title);
  }, [areas, onChange, selAreaIndex]);

  return (
    <Accordion disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{`Машиноместо: ${selTitle}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <CircularIndeterminate />
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <Grid container spacing={1}>
            {(areas || []).length > 1 && (
              <>
                <Grid item xs={12}>
                  <Typography className={styles.title}>Зона:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {(areas || []).map(({ id, name }, index) => (
                      <Grid key={`area_${id}`} item>
                        <div
                          onClick={() => handleAreaChange(index)}
                          className={[
                            styles.item,
                            index === selAreaIndex ? styles.itemSelected : '',
                          ].join(' ')}
                        >
                          {name}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography className={styles.title}>Место:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {((areas && areas[selAreaIndex] && areas[selAreaIndex].places) || []).map(({ id, name, status }, index) => (
                  <Grid key={`place_${id}`} item>
                    <div
                      onClick={() => handlePlaceChange(index, id, name, status)}
                      className={[
                        styles.item,
                        status !== 0 ? styles.itemDisabled : '',
                        index === selPlaceIndex ? styles.itemSelected : '',
                      ].join(' ')}
                    >
                      {name}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
