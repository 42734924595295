import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactFlow, { Background, Controls, MarkerType, ReactFlowProvider, useReactFlow } from 'reactflow';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { useModal } from '@parkly/shared/helpers';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';

import { getLayoutedElements } from 'helpers/flowHooks';

import { deleteFacilityIntegrationFlowAction, getFacilityIntegrationFlowAction } from 'actions/facilities';
import DeviceNode from './nodes/DeviceNode';
import StateNode from './nodes/StateNode';
import CreateEdgeModalContent from './CreateEdgeModalContent';

import { useStyles } from './styles';
import 'reactflow/dist/style.css';
import { useCheckFacilityRights } from '../../../../helpers/hooks';
import { RIGHT_KEYS } from '../../../../config/constants';

function getCurrentIntegrationFlow({
  match,
  allFacilityIntegrationFlowsState,
}) {
  const facilityId = ((match || {}).params || {}).facilityId;

  const {
    loading: isLoading,
    list,
    error: flowError,
  } = allFacilityIntegrationFlowsState || {};

  const currentStrParam = JSON.stringify({
    id: facilityId,
  });

  const currentFlow = list.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    currentFlow,
    flowError,
  };
}

function formatEdges(edges) {
  return edges.map((edge) => ({
    ...edge,
    markerEnd: {
      width: 20,
      height: 20,
      color: '#1D3262',
      type: MarkerType.ArrowClosed,
    },
    focusable: false,
    deletable: false,
    style: {
      stroke: '#1D3262',
    },
  }));
}

function formatNodes(nodes) {
  return nodes.map((node) => ({
    ...node,
    focusable: false,
    deletable: false,
  }));
}

const nodeTypes = { deviceNode: DeviceNode, stateNode: StateNode };

const NODE_WIDTH = 172;
const NODE_HEIGHT = 36;

function FlowIntegrationSetting({
  match,

  allFacilityIntegrationFlowsState,
  getFacilityIntegrationFlowReg,

  deleteFacilityIntegrationFlowReg,
  deleteFacilityIntegrationFlowState,
  currentOperatorState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const reactFlowInstance = useReactFlow();

  const facilityId = ((match || {}).params || {}).facilityId;

  const [initNodes, setInitNodes] = useState([]);
  const [initEdges, setInitEdges] = useState([]);

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const hasAutomationUpdateRight = useCheckFacilityRights(RIGHT_KEYS.automationUpdate, facilityId);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      currentFlow,
      isLoading,
      flowError,
    } = getCurrentIntegrationFlow({
      match,
      allFacilityIntegrationFlowsState,
    });

    if (isLoading || flowError) {
      return;
    }

    if (currentFlow) {
      const {
        data: {
          nodes,
          edges,
        },
      } = currentFlow || {};

      setInitNodes(nodes);
      setInitEdges(edges);

      if (reactFlowInstance) {
        setTimeout(() => {
          reactFlowInstance.fitView();
        }, 500);
      }

      return;
    }

    getFacilityIntegrationFlowReg({ id: facilityId });
  }, [reactFlowInstance, allFacilityIntegrationFlowsState, match, facilityId, getFacilityIntegrationFlowReg]);

  const { nodes, edges } = getLayoutedElements(
    formatNodes(initNodes),
    formatEdges(initEdges),
    NODE_WIDTH,
    NODE_HEIGHT,
  );

  const [
    addEdgesModalNode,
    onOpenEdgesModal,
    onHideEdgesModal,
  ] = useModal({
    content: (
      <CreateEdgeModalContent
        facilityId={facilityId}
        onClose={() => {
          onHideEdgesModal();
          if (reactFlowInstance) {
            reactFlowInstance.fitView();
          }
        }}
      />
    ),
  });

  const handleAgreeRemoveDialog = () => {
    if (!facilityId) {
      return;
    }

    deleteFacilityIntegrationFlowReg({ facilityId });

    setIsRemoveDialogOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} lg={9} className={styles.container}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          fitView
        >
          <Controls showInteractive={false} />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </Grid>
      {hasAutomationUpdateRight && (
        <Grid item xs={12}>
          <CustomLink
            className={styles.addLinkBtn}
            onClick={onOpenEdgesModal}
          >
            <AddIcon />
            Добавить связи
          </CustomLink>
        </Grid>
      )}
      {hasAutomationUpdateRight && (
        <Grid item xs={12}>
          <CustomLink
            className={styles.clearLinkBtn}
            onClick={() => setIsRemoveDialogOpen(true)}
          >
            <CloseIcon />
            Удалить все связи
          </CustomLink>
        </Grid>
      )}
      {addEdgesModalNode}
      <CustomDialog
        open={isRemoveDialogOpen}
        handleAgree={handleAgreeRemoveDialog}
        handleClose={() => setIsRemoveDialogOpen(false)}
        dialogContent={t('integrations.removeEdges.content')}
        agreeTitle={t('integrations.removeEdges.agree')}
        disagreeTitle={t('integrations.removeEdges.disagree')}
      />
    </Grid>
  );
}

function mapStateToProps(state) {
  const { facilities, operators } = state || {};
  const { allFacilityIntegrationFlows, deleteFacilityIntegrationFlow } = facilities || {};
  const { currentOperator: currentOperatorState } = operators || {};

  return {
    allFacilityIntegrationFlowsState: allFacilityIntegrationFlows,
    deleteFacilityIntegrationFlowState: deleteFacilityIntegrationFlow,
    currentOperatorState,
  };
}

const FlowIntegrationSettingWithProvider = (props) => (
  <ReactFlowProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <FlowIntegrationSetting {...props} />
  </ReactFlowProvider>
);

const ConnectedFlowIntegrationSetting = connect(
  mapStateToProps,
  {
    getFacilityIntegrationFlowReg: getFacilityIntegrationFlowAction,
    deleteFacilityIntegrationFlowReg: deleteFacilityIntegrationFlowAction,
  },
)(FlowIntegrationSettingWithProvider);

export default ConnectedFlowIntegrationSetting;
