import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import TariffsTable from 'components/templates/TariffsTable';

import { getAllTariffsAction } from 'actions/tariffs';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useStyles } from './styles';

function getInitParams() {
  return {
    page: 1,
  };
}

function prepareParam({
  params,
  facilityId,
}) {
  const {
    page,
  } = params || {};

  const reqParam = {
    page,
    facilityId,
  };

  return reqParam;
}

function getCurrentTariffs({
  allTariffsState,
  params,
  facilityId,
}) {
  const tariffsList = (allTariffsState || {}).list || [];
  const preparedReqParam = prepareParam({
    params,
    facilityId,
  });
  const currentStrParam = JSON.stringify(preparedReqParam);
  const tariffs = tariffsList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    tariffs,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function requestAllTariffs({
  reqParam,
  getAllTariffsReq = () => {},
}) {
  throttle(
    getAllTariffsReq,
    300,
  )(reqParam);
}

function AllTariffsPage({
  match,

  getAllTariffsReq,
  allTariffsState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch();

  const facilityId = ((match || {}).params || {}).facilityId;

  const [params, setParams] = useState(getInitParams());
  const [prevParams, setPrevParams] = useState('');

  const hasTariffUpdateRight = useCheckFacilityRights(RIGHT_KEYS.tariffUpdate, facilityId);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      tariffs,
      reqParam,
      currentStrParam,
    } = getCurrentTariffs({
      allTariffsState,
      params,
      facilityId,
    });

    if (currentStrParam !== prevParams) {
      if (!tariffs) {
        requestAllTariffs({
          reqParam,
          getAllTariffsReq,
        });

        setPrevParams(currentStrParam);
      }
    }
  }, [params, allTariffsState, prevParams, getAllTariffsReq, facilityId]);

  const {
    tariffs,
  } = getCurrentTariffs({
    allTariffsState,
    params,
    facilityId,
  });

  const {
    tariffsData,
    tariffsMeta,
  } = (tariffs || {}).data || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = tariffsMeta || {};

  const isLoading = (allTariffsState || {}).loading;

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn
        title={t('tariffs.tariffs')}
        isBackBtn={false}
      />
      <div className={styles.pageBody}>
        <Grid container spacing={2}>
          {hasTariffUpdateRight && (
            <Grid container item spacing={2}>
              <Grid item>
                <CustomBtn
                  btnType="primaryGreen"
                  className={styles.addNewBtn}
                  onClick={() => history.push(PATH_PAGES.newTariffSettings.replace(':facilityId', facilityId))}
                >
                  {t('tariffs.addTariff')}
                </CustomBtn>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoading && (
              <CircularIndeterminate style={{ minHeight: 600 }} />
            )}
            {!isLoading && (
              <TariffsTable
                tariffsData={tariffsData}
                facilityId={facilityId}
              />
            )}
            {!isLoading && lastPage > 1 && (
              <div className={styles.paginationContainer}>
                <Pagination
                  className={styles.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  const { tariffs } = state || {};
  const { allTariffs } = tariffs || {};

  return {
    allTariffsState: allTariffs,
  };
}

const ConnectedAllTariffsPage = connect(
  mapStateToProps,
  {
    getAllTariffsReq: getAllTariffsAction,
  },
)(AllTariffsPage);

export default ConnectedAllTariffsPage;
