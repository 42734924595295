import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
  sortIcon: {
    marginLeft: 7,
  },
  clickable: {
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  tableRow: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
  },
  tableRowLast: {
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  noDataContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 230,
  },
  noDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      fontSize: theme.typography.fontSize12,
      margin: '10px 0',
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
  idStr: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
  },
  timeStr: {

  },
  dateStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  statusStr: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  typeStr: {
    color: 'grey',
  },
  operatorStr: {
    color: 'grey',
    '&:visited': {
      color: 'grey',
    },
  },
  photo: {
    height: 60,
    minWidth: 60,
    cursor: 'pointer',
    // filter: 'grayscale(100%)',
  },
}));

export default {
  useStyles,
};
