import {
  CANCEL_ONE_PASS,
  CLEAR_PASSES_CACHE,
  CREATE_ONE_PASS,
  GET_ACTIVE_PASS_SETTINGS,
  GET_ACTIVE_PASS_SETTINGS_PLACES,
  GET_ALL_PASSES,
  GET_ALL_PASSES_SETTINGS,
  GET_ONE_PASS,
  GET_ONE_PASS_INFO,
  GET_ONE_PASS_SETTINGS,
  PAY_ONE_PASS,
  SEND_PASS_LINK_TO_CUSTOMER,
  SET_ONE_PASS_SETTINGS,
  STOP_SESSIONS_ONE_PASS,
  UPDATE_ONE_PASS,
} from './types';
import { PATH_PAGES } from 'config/constants';
import {
  addPassPayment,
  cancelPass,
  createPass,
  getActivePassSettings,
  getPass,
  getPasses,
  getPassesSettings,
  getPassInfo,
  getPassSetting,
  getPassSettingPlaces,
  sendPassLinkToCustomer,
  setPassSettings,
  stopSessionPass,
  updatePass,
} from 'api/passes';
import { toast } from 'react-toastify';

export function clearPassesCacheAction() {
  function actionClearCachePasses() {
    return { type: CLEAR_PASSES_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCachePasses());
  };
}

export function getAllPassesSettingsAction(param) {
  function getAllPassesSettingsRequest(payload) {
    return { type: GET_ALL_PASSES_SETTINGS, payload };
  }

  return (dispatch) => {
    dispatch(getAllPassesSettingsRequest({
      loading: true,
    }));

    getPassesSettings(param).then(
      (data) => {
        dispatch(getAllPassesSettingsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAllPassesSettingsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getOnePassSettingsAction(param) {
  function getOnePassSettingsRequest(payload) {
    return { type: GET_ONE_PASS_SETTINGS, payload };
  }

  return (dispatch) => {
    dispatch(getOnePassSettingsRequest({
      loading: true,
    }));

    const { id } = param;

    getPassSetting(param).then(
      (passSettings) => {
        dispatch(getOnePassSettingsRequest({
          loading: false,
          passSettings,
        }));
      },
      (error) => {
        dispatch(getOnePassSettingsRequest({
          loading: false,
          error: {
            id,
            error,
          },
        }));
      },
    );
  };
}

export function getActivePassSettingsAction(param) {
  function getActivePassSettingsRequest(payload) {
    return { type: GET_ACTIVE_PASS_SETTINGS, payload };
  }

  return (dispatch) => {
    dispatch(getActivePassSettingsRequest({
      loading: true,
    }));

    getActivePassSettings(param).then(
      (data) => {
        dispatch(getActivePassSettingsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getActivePassSettingsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getActivePassSettingsPlacesAction(param) {
  function getActivePassSettingsPlacesRequest(payload) {
    return { type: GET_ACTIVE_PASS_SETTINGS_PLACES, payload };
  }

  return (dispatch) => {
    dispatch(getActivePassSettingsPlacesRequest({
      loading: true,
    }));

    getPassSettingPlaces(param).then(
      (data) => {
        dispatch(getActivePassSettingsPlacesRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getActivePassSettingsPlacesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function setPassesSettingsAction(params, history) {
  function setPassesSettingsRequest(payload) {
    return { type: SET_ONE_PASS_SETTINGS, payload };
  }

  return (dispatch) => {
    dispatch(setPassesSettingsRequest({
      loading: true,
    }));

    setPassSettings(params).then(
      ({ id, success }) => {
        dispatch(setPassesSettingsRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearPassesCacheAction());
          dispatch(getOnePassSettingsAction({ facilityId, id }));
          history.replace(PATH_PAGES.allPassesSettings.replace(':facilityId', facilityId));
        }
      },
      (error) => {
        dispatch(setPassesSettingsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getAllPassesAction(param) {
  function getAllPassesRequest(payload) {
    return { type: GET_ALL_PASSES, payload };
  }

  return (dispatch) => {
    dispatch(getAllPassesRequest({
      loading: true,
    }));

    getPasses(param).then(
      (data) => {
        dispatch(getAllPassesRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAllPassesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getOnePassAction(param) {
  function getOnePassRequest(payload) {
    return { type: GET_ONE_PASS, payload };
  }

  return (dispatch) => {
    dispatch(getOnePassRequest({
      loading: true,
    }));

    const { id } = param;

    getPass(param).then(
      (pass) => {
        dispatch(getOnePassRequest({
          loading: false,
          pass,
        }));
      },
      (error) => {
        dispatch(getOnePassRequest({
          loading: false,
          error: {
            id,
            error,
          },
        }));
      },
    );
  };
}

export function getOnePassInfoAction(param) {
  function getOnePassInfoRequest(payload) {
    return { type: GET_ONE_PASS_INFO, payload };
  }

  return (dispatch) => {
    dispatch(getOnePassInfoRequest({
      loading: true,
    }));

    const { id } = param;

    getPassInfo(param).then(
      (pass) => {
        dispatch(getOnePassInfoRequest({
          loading: false,
          pass,
        }));
      },
      (error) => {
        dispatch(getOnePassInfoRequest({
          loading: false,
          error: {
            id,
            error,
          },
        }));
      },
    );
  };
}

export function createPassesAction(params, history) {
  function setPassesRequest(payload) {
    return { type: CREATE_ONE_PASS, payload };
  }

  return (dispatch) => {
    dispatch(setPassesRequest({
      loading: true,
    }));

    createPass(params).then(
      ({ id, success }) => {
        dispatch(setPassesRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearPassesCacheAction());
          dispatch(getOnePassAction({ facilityId, id }));
          dispatch(getOnePassInfoAction({ facilityId, id }));
          history.replace(PATH_PAGES.onePassInfo.replace(':facilityId', facilityId).replace(':id', id));
        }
      },
      (error) => {
        dispatch(setPassesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function updatePassesAction(params, history) {
  function setPassesRequest(payload) {
    return { type: UPDATE_ONE_PASS, payload };
  }

  return (dispatch) => {
    dispatch(setPassesRequest({
      loading: true,
    }));

    updatePass(params).then(
      ({ id, success }) => {
        dispatch(setPassesRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearPassesCacheAction());
          dispatch(getOnePassAction({ facilityId, id }));
          dispatch(getOnePassInfoAction({ facilityId, id }));
          history.replace(PATH_PAGES.onePass.replace(':facilityId', facilityId).replace(':id', id));
        }
      },
      (error) => {
        dispatch(setPassesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function cancelPassAction(params) {
  function cancelPassRequest(payload) {
    return { type: CANCEL_ONE_PASS, payload };
  }

  return (dispatch) => {
    dispatch(cancelPassRequest({
      loading: true,
    }));

    cancelPass(params).then(
      ({ id, success }) => {
        dispatch(cancelPassRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearPassesCacheAction());
          dispatch(getOnePassAction({ facilityId, id }));
          dispatch(getOnePassInfoAction({ facilityId, id }));
        }
      },
      (error) => {
        dispatch(cancelPassRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function stopSessionsPassAction(params) {
  function stopSessionsPassRequest(payload) {
    return { type: STOP_SESSIONS_ONE_PASS, payload };
  }

  return (dispatch) => {
    dispatch(stopSessionsPassRequest({
      loading: true,
    }));

    stopSessionPass(params).then(
      ({ success }) => {
        dispatch(stopSessionsPassRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId, id } = params || {};

          dispatch(clearPassesCacheAction());
          dispatch(getOnePassAction({ facilityId, id }));
          dispatch(getOnePassInfoAction({ facilityId, id }));
        }
      },
      (error) => {
        dispatch(stopSessionsPassRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function payPassAction(params) {
  function payPassRequest(payload) {
    return { type: PAY_ONE_PASS, payload };
  }

  return (dispatch) => {
    dispatch(payPassRequest({
      loading: true,
    }));

    addPassPayment(params).then(
      ({ id, success }) => {
        dispatch(payPassRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearPassesCacheAction());
          dispatch(getOnePassAction({ facilityId, id }));
          dispatch(getOnePassInfoAction({ facilityId, id }));
        }
      },
      (error) => {
        dispatch(payPassRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function sendPassLinkToCustomerAction(params) {
  function sendPassLinkRequest(payload) {
    return { type: SEND_PASS_LINK_TO_CUSTOMER, payload };
  }

  return (dispatch) => {
    dispatch(sendPassLinkRequest({
      loading: true,
    }));

    sendPassLinkToCustomer(params).then(
      ({ success }) => {
        dispatch(sendPassLinkRequest({
          loading: false,
        }));

        if (success) {
          toast.success('Ссылка отправлена клиенту');
        }
      },
      (error) => {
        dispatch(sendPassLinkRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
