import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import ControllersIntegrationSetting from 'components/organisms/integrationSettings/ControllersIntegrationSetting';
import FlowIntegrationSetting from 'components/organisms/integrationSettings/FlowIntegrationSetting';
import GatesIntegrationSetting from 'components/organisms/integrationSettings/GatesIntegrationSetting';
import TestIntegrationDevicesSetting from 'components/organisms/integrationSettings/TestIntegrationControllersSetting';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getTabs() {
  return [
    { id: 0, label: 'Барьеры' },
    { id: 1, label: 'Сессия' },
    { id: 2, label: 'Контроллеры' },
    { id: 3, label: 'Интеграции' },
  ];
}

function IntegrationSettingsPage({
  match,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  useCurrentFacilityMatch();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title={t('integrations.integrationsSetting')}
        isBackBtn={false}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTabBar
            tabs={getTabs()}
            tabValue={tabValue}
            handleParamChange={handleTabChange}
          />
        </Grid>
      </Grid>
      {tabValue === 0 && (
        <GatesIntegrationSetting
          match={match}
        />
      )}
      {tabValue === 1 && (
        <FlowIntegrationSetting
          match={match}
        />
      )}
      {tabValue === 2 && (
        <ControllersIntegrationSetting
          match={match}
        />
      )}
      {tabValue === 3 && (
        <TestIntegrationDevicesSetting
          match={match}
        />
      )}
    </div>
  );
}

export default IntegrationSettingsPage;
