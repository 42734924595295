import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    touchAction: 'auto !important'
  },
  close: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  content: {
    margin: 'auto',
    padding: 0,
    width: '90%',
    height: '100%',
    maxHeight: '100%',
    textAlign: 'center',
  },
  slide: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    userSelect: 'none',
  }
}));

export default {
  useStyles,
};
