import React, { useMemo, useState } from 'react';
import {
  createEnumDelimitedArrayParam,
  createEnumParam,
  decodeArray,
  decodeDelimitedNumericArray,
  encodeDelimitedArray,
  NumberParam,
  objectToSearchString,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import moment from 'moment-timezone';

function decodeNumericArrayEnum(
  input,
  enumValues,
) {
  const arr = decodeArray(input);
  if (arr == null) return arr;
  if (!arr.length) return undefined;
  return arr.every((str) => str != null && enumValues.includes(str))
    ? (arr)
    : undefined;
}

function decodeDelimitedNumericArrayEnum(
  input,
  enumValues,
  entrySeparator = '_',
) {
  if (input != null && Array.isArray(input) && !input.length) return undefined;
  const arr = decodeDelimitedNumericArray(input, entrySeparator);
  return decodeNumericArrayEnum(arr, enumValues);
}
export const createEnumDelimitedNumericArrayParam = (
  enumValues,
  entrySeparator = '_',
) => ({
  encode: (text) => encodeDelimitedArray(
    text == null || Array.isArray(text) ? text : [text],
    entrySeparator,
  ),
  decode: (input) => decodeDelimitedNumericArrayEnum(input, enumValues, entrySeparator),
});

export const createStringEnumParam = (enumValues) => createEnumParam(enumValues.map((v) => String(v)));

export const createStringEnumDelimitedArrayParam = (enumValues, delimiter = '_') => createEnumDelimitedArrayParam(enumValues.map((v) => String(v)), delimiter);

export const PageParam = withDefault(NumberParam, 1, false);

export const MomentDateParam = {
  encode: (date) => {
    if ((date === null || date === undefined || date === '') || !date.isValid) {
      return null;
    }

    return date.format('YYYY-MM-DD');
  },
  decode: (date) => {
    if ((date === null || date === undefined)) {
      return null;
    }

    const d = moment(date);

    return d.isValid() ? d : null;
  },
};

export const objToQuery = (obj) => `?${objectToSearchString(obj)}`;

export const useReqQParams = ({
  queryParams = {},
  prepareParam = () => {},
}) => {
  const [query, setQuery] = useQueryParams(queryParams, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  const reqParams = useMemo(() => prepareParam({
    params: query,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [JSON.stringify(query)]);

  return [reqParams, setQuery];
};

export const withParams = ({
  initParams = {},
  prepareParam = () => {},
}) => (Comp) => (props) => {
  const { initParams: propsInitParams } = props || {};

  const [params, setParams] = useState({
    ...initParams,
    ...propsInitParams,
  });

  const reqParams = useMemo(() => prepareParam({
    params,
  }), [params]);

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Comp params={reqParams} setParams={setParams} {...props} />
  );
};

export const withQueryParams = ({
  queryParams = {},
  prepareParam = () => {},
}) => (Comp) => (props) => {
  const [params, setParams] = useReqQParams({
    queryParams,
    prepareParam,
  });

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Comp params={params} setParams={setParams} {...props} />
  );
};
