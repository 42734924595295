import React, { useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import OpenVpnIcon from '@parkly/shared/components/atoms/icons/OpenVpnIcon';
import useConfirm from '@parkly/shared/helpers/confirm';
import { useModal } from '@parkly/shared/helpers';
import { useCreateOpenVpnClientMutation, useDeleteOpenVpnClientMutation, useOpenVpnClients } from 'api/query/openvpn';
import { useCheckFacilityRights } from 'helpers/hooks';
import { RIGHT_KEYS } from 'config/constants';
import OpenVpnClientModalContent from './OpenVpnClientModalContent';
import { useStyles } from './styles';

export default function OpenVpnList({
  facilityId,
}) {
  const styles = useStyles();

  const confirm = useConfirm();
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    data: clients,
    refetch,
  } = useOpenVpnClients({ variables: { facilityId } });

  const createMutation = useCreateOpenVpnClientMutation({
    onSuccess() {
      refetch();
    },
    onSettled() {
      setIsCreateLoading(false);
    },
  });

  const deleteMutation = useDeleteOpenVpnClientMutation({
    onSuccess() {
      refetch();
    },
    onSettled() {
      setDeleteLoading(null);
    },
  });

  const handleCreateClick = (e) => {
    e.preventDefault();
    confirm({
      title: 'Вы уверены, что хотите создать новый OpenVPN config?',
      agreeTitle: 'Создать',
      disagreeTitle: 'Нет',
    }).then(() => {
      setIsCreateLoading(true);
      createMutation.mutate({ facilityId });
    }).catch(() => {});
  };

  const handleDeleteClick = (id) => {
    confirm({
      title: 'Вы уверены, что хотите удалить?',
      agreeTitle: 'Удалить',
      disagreeTitle: 'Нет',
    }).then(() => {
      setDeleteLoading(id);
      deleteMutation.mutate({ facilityId, id });
    }).catch(() => {});
  };

  const handleItemClick = ({ id, username, ip }) => {
    openShowModal({ id, username, ip });
  };

  const [showModalNode, openShowModal, hideShowModal] = useModal({
    content: <OpenVpnClientModalContent
      facilityId={facilityId}
      onClose={() => {
        hideShowModal();
      }}
    />,
  });

  const hasAutomationUpdateRight = useCheckFacilityRights(RIGHT_KEYS.automationUpdate, facilityId);
  const OpenVpnComponent = hasAutomationUpdateRight ? CustomLink : Typography;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography style={{ fontSize: 16, fontWeight: 700 }}>
          OpenVPN
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={2} direction="column">
        {(clients || []).map(({ id, username, ip, canBeDeleted }) => (
          <Grid key={id} item className={styles.item}>
            <OpenVpnComponent
              onClick={() => handleItemClick({ id, username, ip, })}
              className={styles.link}
            >
              <OpenVpnIcon />
              <Typography>
                {ip}
              </Typography>
            </OpenVpnComponent>
            {hasAutomationUpdateRight && canBeDeleted && (
              <IconButton
                size="small"
                disabled={deleteLoading === id}
                // className={styles.iconButton}
                onClick={() => handleDeleteClick(id)}
              >
                {deleteLoading !== id ? (
                  <DeleteIcon fontSize="small" />
                ) : (
                  <CircularProgress
                    size={14}
                    color="inherit"
                  />
                )}

              </IconButton>
            )}
          </Grid>
        ))}
      </Grid>
      {hasAutomationUpdateRight && (
        <Grid item xs={12}>
          <CustomLink
            onClick={handleCreateClick}
            disabled={isCreateLoading}
          >
            Создать
            {isCreateLoading && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={14}
                color="inherit"
              />
            )}
          </CustomLink>
        </Grid>
      )}
      {showModalNode}
    </Grid>
  );
}
