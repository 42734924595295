import isEmpty from 'lodash/isEmpty';
import { store } from 'store';

import { getRegistrationStatusAction } from 'actions/company';
import { setCurrentFacilityAction, setCurrentFacilityLoadedAction } from 'actions/currentFacility';
import { clearLicensesCacheAction } from 'actions/licenses';
import {
  createFacility,
  deleteFacilityGate,
  deleteFacilityIntegrationFlow,
  downloadQrImageFacilityGate,
  downloadQrImageFacilitySite,
  downloadQrPdfFacilityGate,
  getFacilities,
  getFacility,
  getFacilityAreas,
  getFacilityFreePlaces,
  getFacilityGates,
  getFacilityGatesWithControllers,
  getFacilityIntegrationFlow,
  getFacilityIntegrationInfo,
  getFacilityMPPSetting,
  getFacilityPlaces,
  getFacilitySchedule,
  getFacilitySiteSetting,
  openFacilityGate,
  setFacilityGate,
  setFacilityIntegrationFlow,
  setFacilityMPPSetting,
  setFacilityPlaces,
  setFacilitySchedule,
  setFacilitySiteSetting,
  testCallbackFacilityIntegrationDevice,
  testFacilityIntegrationDevice,
  updateFacility,
} from 'api/facilities';
import { PATH_PAGES, PLACE_PURPOSES } from 'config/constants';

import {
  CLEAR_FACILITIES_CACHE,
  CREATE_FACILITY,
  DELETE_FACILITY_GATE,
  DELETE_FACILITY_INTEGRATION_FLOW,
  DOWNLOAD_QR_IMAGE_FACILITY_GATE,
  DOWNLOAD_QR_PDF_FACILITY_GATE,
  DOWNLOAD_QR_PDF_FACILITY_SITE,
  GET_FACILITIES,
  GET_FACILITY,
  GET_FACILITY_AREAS,
  GET_FACILITY_GATES,
  GET_FACILITY_GATES_WITH_CONTROLLERS,
  GET_FACILITY_INTEGRATION_FLOW,
  GET_FACILITY_INTEGRATION_INFO,
  GET_FACILITY_MPP_SETTING,
  GET_FACILITY_PASS_PLACES,
  GET_FACILITY_PLACES,
  GET_FACILITY_SCHEDULE,
  GET_FACILITY_SITE_SETTING,
  GET_FREE_FACILITY_PLACES,
  OPEN_FACILITY_GATE,
  SET_FACILITY_GATE,
  SET_FACILITY_INTEGRATION_FLOW,
  SET_FACILITY_MPP_SETTING,
  SET_FACILITY_PLACES,
  SET_FACILITY_SCHEDULE,
  SET_FACILITY_SITE_SETTING,
  TEST_CALLBACK_FACILITY_INTEGRATION_DEVICE,
  TEST_FACILITY_INTEGRATION_DEVICE,
} from './types';

export function clearFacilitiesCacheAction() {
  function actionClearCacheFacilities() {
    return { type: CLEAR_FACILITIES_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheFacilities());
  };
}

export function getFacilitiesAction() {
  function getFacilitiesRequest(payload) {
    return { type: GET_FACILITIES, payload };
  }

  return (dispatch) => {
    dispatch(getFacilitiesRequest({
      loading: true,
    }));

    getFacilities().then(
      (data) => {
        dispatch(getFacilitiesRequest({
          loading: false,
          data,
        }));

        if (
          !isEmpty(data)
          && !store.getState().currentFacility.currentFacility.data
        ) {
          dispatch(setCurrentFacilityAction(data[0]));
        } else {
          dispatch(setCurrentFacilityLoadedAction());
        }
      },
      (error) => {
        dispatch(getFacilitiesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityAction(param) {
  function getFacilityRequest(payload) {
    return { type: GET_FACILITY, payload };
  }

  const { id } = param || {};

  return (dispatch) => {
    dispatch(getFacilityRequest({
      loading: true,
    }));

    getFacility(param).then(
      (facility) => {
        dispatch(getFacilityRequest({
          loading: false,
          facility,
        }));
      },
      (error) => {
        dispatch(getFacilityRequest({
          loading: false,
          error: {
            id,
            error,
          },
        }));
      },
    );
  };
}

export function createFacilityAction({ facilityData, history }, goNextPage) {
  function createFacilityRequest(payload) {
    return { type: CREATE_FACILITY, payload };
  }

  return (dispatch) => {
    dispatch(createFacilityRequest({
      loading: true,
    }));

    createFacility(facilityData).then(
      ({
        id, title, timezone, lat, lon, success,
      }) => {
        dispatch(createFacilityRequest({
          loading: false,
        }));

        if (success) {
          dispatch(setCurrentFacilityAction({
            id, title, timezone, lat, lon,
          }));
          dispatch(getFacilityAction({ id }));
          dispatch(getFacilitiesAction());
          dispatch(getRegistrationStatusAction());
          dispatch(clearLicensesCacheAction());

          if (goNextPage) {
            history.push(PATH_PAGES.scheduleSettings.replace(':facilityId', id));
          }
        }
      },
      (error) => {
        dispatch(createFacilityRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function updateFacilityAction(params) {
  function updateFacilityRequest(payload) {
    return { type: CREATE_FACILITY, payload };
  }

  return (dispatch) => {
    dispatch(updateFacilityRequest({
      loading: true,
    }));

    updateFacility(params).then(
      ({ success }) => {
        dispatch(updateFacilityRequest({
          loading: false,
        }));

        const { id } = params || {};

        if (success) {
          dispatch(getFacilityAction({ id }));
        }
      },
      (error) => {
        dispatch(updateFacilityRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityScheduleAction(param) {
  function getFacilityScheduleRequest(payload) {
    return { type: GET_FACILITY_SCHEDULE, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityScheduleRequest({
      loading: true,
    }));

    getFacilitySchedule(param).then(
      (schedule) => {
        dispatch(getFacilityScheduleRequest({
          loading: false,
          schedule: {
            ...schedule,
            id: param.id,
          },
        }));
      },
      (error) => {
        dispatch(getFacilityScheduleRequest({
          loading: false,
          error: {
            id: param.id,
            error,
          },
        }));
      },
    );
  };
}

export function setFacilityScheduleAction(params, history, goNextPage) {
  function setFacilityScheduleRequest(payload) {
    return { type: SET_FACILITY_SCHEDULE, payload };
  }

  return (dispatch) => {
    dispatch(setFacilityScheduleRequest({
      loading: true,
    }));

    setFacilitySchedule(params).then(
      ({ success }) => {
        dispatch(setFacilityScheduleRequest({
          loading: false,
        }));

        if (success) {
          const { id } = params || {};

          dispatch(getFacilityScheduleAction({ id }));
          dispatch(getRegistrationStatusAction());

          if (goNextPage) {
            history.push(PATH_PAGES.placesSettings.replace(':facilityId', id));
          }
        }
      },
      (error) => {
        dispatch(setFacilityScheduleRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityPlacesAction(param) {
  function getFacilityPlacesRequest(payload) {
    return { type: GET_FACILITY_PLACES, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityPlacesRequest({
      loading: true,
    }));

    getFacilityPlaces(param).then(
      (places) => {
        dispatch(getFacilityPlacesRequest({
          loading: false,
          places: {
            places,
            id: param.id,
          },
        }));
      },
      (error) => {
        dispatch(getFacilityPlacesRequest({
          loading: false,
          error: {
            id: param.id,
            error,
          },
        }));
      },
    );
  };
}

export function getFacilityPassPlacesAction(param) {
  function getFacilityPassPlacesRequest(payload) {
    return { type: GET_FACILITY_PASS_PLACES, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityPassPlacesRequest({
      loading: true,
    }));

    getFacilityPlaces(param).then(
      (places) => {
        dispatch(getFacilityPassPlacesRequest({
          loading: false,
          places: {
            places,
            id: param.id,
          },
        }));
      },
      (error) => {
        dispatch(getFacilityPassPlacesRequest({
          loading: false,
          error: {
            id: param.id,
            error,
          },
        }));
      },
    );
  };
}

export function setFacilityPlacesAction(params, history, goNextPage) {
  function setFacilityPlacesRequest(payload) {
    return { type: SET_FACILITY_PLACES, payload };
  }

  return (dispatch) => {
    dispatch(setFacilityPlacesRequest({
      loading: true,
    }));

    setFacilityPlaces(params).then(
      ({ success }) => {
        dispatch(setFacilityPlacesRequest({
          loading: false,
        }));

        if (success) {
          const { id } = params || {};

          dispatch(getFacilityPlacesAction({ id }));
          dispatch(getFacilityPassPlacesAction({ id, purpose: PLACE_PURPOSES.pass }));
          dispatch(getRegistrationStatusAction());

          if (goNextPage) {
            history.push(PATH_PAGES.integrationSettings.replace(':facilityId', id));
          }
        }
      },
      (error) => {
        dispatch(setFacilityPlacesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityAreasAction(param) {
  function getFacilityAreasRequest(payload) {
    return { type: GET_FACILITY_AREAS, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityAreasRequest({
      loading: true,
    }));

    getFacilityAreas(param).then(
      (data) => {
        dispatch(getFacilityAreasRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getFacilityAreasRequest({
          loading: false,
          error: {
            error,
          },
        }));
      },
    );
  };
}

export function getFacilityFreePlacesAction(param) {
  function getFacilityFreePlacesRequest(payload) {
    return { type: GET_FREE_FACILITY_PLACES, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityFreePlacesRequest({
      loading: true,
    }));

    getFacilityFreePlaces(param).then(
      (data) => {
        dispatch(getFacilityFreePlacesRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getFacilityFreePlacesRequest({
          loading: false,
          error: {
            error,
          },
        }));
      },
    );
  };
}

export function getFacilityGatesAction(param) {
  function getFacilityGatesRequest(payload) {
    return { type: GET_FACILITY_GATES, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityGatesRequest({
      loading: true,
    }));

    getFacilityGates(param).then(
      (data) => {
        dispatch(getFacilityGatesRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getFacilityGatesRequest({
          loading: false,
          error: {
            error,
          },
        }));
      },
    );
  };
}

export function getFacilityGatesWithControllersAction(param) {
  function getFacilityGatesWithControllersRequest(payload) {
    return { type: GET_FACILITY_GATES_WITH_CONTROLLERS, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityGatesWithControllersRequest({
      loading: true,
    }));

    getFacilityGatesWithControllers(param).then(
      (data) => {
        dispatch(getFacilityGatesWithControllersRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getFacilityGatesWithControllersRequest({
          loading: false,
          error: {
            error,
          },
        }));
      },
    );
  };
}

export function setFacilityGateAction(params) {
  function setFacilityGateRequest(payload) {
    return { type: SET_FACILITY_GATE, payload };
  }

  return (dispatch) => {
    dispatch(setFacilityGateRequest({
      loading: true,
    }));

    setFacilityGate(params).then(
      ({ success, id }) => {
        dispatch(setFacilityGateRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params;

          dispatch(getFacilityGatesAction({ id: facilityId }));
          dispatch(getFacilityGatesWithControllersAction({ id: facilityId }));
          dispatch(getFacilityIntegrationFlowAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(setFacilityGateRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function deleteFacilityGateAction(params) {
  function deleteFacilityGateRequest(payload) {
    return { type: DELETE_FACILITY_GATE, payload };
  }

  return (dispatch) => {
    dispatch(deleteFacilityGateRequest({
      loading: true,
    }));

    deleteFacilityGate(params).then(
      ({ success }) => {
        dispatch(deleteFacilityGateRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params;

          dispatch(getFacilityGatesAction({ id: facilityId }));
          dispatch(getFacilityGatesWithControllersAction({ id: facilityId }));
          dispatch(getFacilityIntegrationFlowAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(deleteFacilityGateRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function openFacilityGateAction(param) {
  function openFacilityGateRequest(payload) {
    return { type: OPEN_FACILITY_GATE, payload };
  }

  return (dispatch) => {
    dispatch(openFacilityGateRequest({
      loading: true,
    }));

    openFacilityGate(param).then(
      () => {
        dispatch(openFacilityGateRequest({
          loading: false,
        }));
      },
      (error) => {
        dispatch(openFacilityGateRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityIntegrationFlowAction(param) {
  function getFacilityIntegrationFlowRequest(payload) {
    return { type: GET_FACILITY_INTEGRATION_FLOW, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityIntegrationFlowRequest({
      loading: true,
    }));

    getFacilityIntegrationFlow(param).then(
      (data) => {
        dispatch(getFacilityIntegrationFlowRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getFacilityIntegrationFlowRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function setFacilityIntegrationFlowAction(params) {
  function setFacilityIntegrationFlowRequest(payload) {
    return { type: SET_FACILITY_INTEGRATION_FLOW, payload };
  }

  return (dispatch) => {
    dispatch(setFacilityIntegrationFlowRequest({
      loading: true,
    }));

    setFacilityIntegrationFlow(params).then(
      ({ success }) => {
        dispatch(setFacilityIntegrationFlowRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params;

          dispatch(getFacilityIntegrationFlowAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(setFacilityIntegrationFlowRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function deleteFacilityIntegrationFlowAction(params) {
  function deleteFacilityIntegrationFlowRequest(payload) {
    return { type: DELETE_FACILITY_INTEGRATION_FLOW, payload };
  }

  return (dispatch) => {
    dispatch(deleteFacilityIntegrationFlowRequest({
      loading: true,
    }));

    deleteFacilityIntegrationFlow(params).then(
      ({ success }) => {
        dispatch(deleteFacilityIntegrationFlowRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params;

          dispatch(getFacilityIntegrationFlowAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(deleteFacilityIntegrationFlowRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityIntegrationInfoAction(param) {
  function getFacilityIntegrationInfoRequest(payload) {
    return { type: GET_FACILITY_INTEGRATION_INFO, payload };
  }

  const { id } = param || {};

  return (dispatch) => {
    dispatch(getFacilityIntegrationInfoRequest({
      loading: true,
    }));

    getFacilityIntegrationInfo(param).then(
      (data) => {
        dispatch(getFacilityIntegrationInfoRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getFacilityIntegrationInfoRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function testFacilityIntegrationDeviceAction(params) {
  function testFacilityIntegrationDeviceRequest(payload) {
    return { type: TEST_FACILITY_INTEGRATION_DEVICE, payload };
  }

  return (dispatch) => {
    dispatch(testFacilityIntegrationDeviceRequest({
      loading: true,
    }));

    testFacilityIntegrationDevice(params).then(
      ({ success }) => {
        dispatch(testFacilityIntegrationDeviceRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params;

          dispatch(getFacilityGatesAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(testFacilityIntegrationDeviceRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function testCallbackFacilityIntegrationDeviceAction(params) {
  function testCallbackFacilityIntegrationDeviceRequest(payload) {
    return { type: TEST_CALLBACK_FACILITY_INTEGRATION_DEVICE, payload };
  }

  return (dispatch) => {
    dispatch(testCallbackFacilityIntegrationDeviceRequest({
      loading: true,
    }));

    testCallbackFacilityIntegrationDevice(params).then(
      ({ success }) => {
        dispatch(testCallbackFacilityIntegrationDeviceRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params;

          dispatch(getFacilityGatesAction({ id: facilityId }));
        }
      },
      (error) => {
        dispatch(testCallbackFacilityIntegrationDeviceRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function downloadQrImageFacilityGateAction(params) {
  function downloadQrImageFacilityGateRequest(payload) {
    return { type: DOWNLOAD_QR_IMAGE_FACILITY_GATE, payload };
  }

  return (dispatch) => {
    dispatch(downloadQrImageFacilityGateRequest({
      loading: true,
    }));

    downloadQrImageFacilityGate(params).then(
      () => {
        dispatch(downloadQrImageFacilityGateRequest({
          loading: false,
        }));
      },
      (error) => {
        dispatch(downloadQrImageFacilityGateRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function downloadQrPdfFacilityGateAction(params) {
  function downloadQrPdfFacilityGateRequest(payload) {
    return { type: DOWNLOAD_QR_PDF_FACILITY_GATE, payload };
  }

  return (dispatch) => {
    dispatch(downloadQrPdfFacilityGateRequest({
      loading: true,
    }));

    downloadQrPdfFacilityGate(params).then(
      () => {
        dispatch(downloadQrPdfFacilityGateRequest({
          loading: false,
        }));
      },
      (error) => {
        dispatch(downloadQrPdfFacilityGateRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilitySiteSettingAction(param) {
  function getFacilitySiteSettingRequest(payload) {
    return { type: GET_FACILITY_SITE_SETTING, payload };
  }

  const { id } = param || {};

  return (dispatch) => {
    dispatch(getFacilitySiteSettingRequest({
      loading: true,
    }));

    getFacilitySiteSetting(param).then(
      (settings) => {
        dispatch(getFacilitySiteSettingRequest({
          loading: false,
          settings: {
            ...settings,
            id,
          },
        }));
      },
      (error) => {
        dispatch(getFacilitySiteSettingRequest({
          loading: false,
          error: {
            error,
            id,
          },
        }));
      },
    );
  };
}

export function setFacilitySiteSettingAction(params) {
  function setFacilitySiteSettingRequest(payload) {
    return { type: SET_FACILITY_SITE_SETTING, payload };
  }

  return (dispatch) => {
    dispatch(setFacilitySiteSettingRequest({
      loading: true,
    }));

    setFacilitySiteSetting(params).then(
      ({ success }) => {
        dispatch(setFacilitySiteSettingRequest({
          loading: false,
        }));

        if (success) {
          const { id } = params || {};

          dispatch(getFacilitySiteSettingAction({ id }));
        }
      },
      (error) => {
        dispatch(setFacilitySiteSettingRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function downloadQrImageFacilitySiteAction(params) {
  function downloadQrImageFacilitySiteRequest(payload) {
    return { type: DOWNLOAD_QR_PDF_FACILITY_SITE, payload };
  }

  return (dispatch) => {
    dispatch(downloadQrImageFacilitySiteRequest({
      loading: true,
    }));

    downloadQrImageFacilitySite(params).then(
      () => {
        dispatch(downloadQrImageFacilitySiteRequest({
          loading: false,
        }));
      },
      (error) => {
        dispatch(downloadQrImageFacilitySiteRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityMPPSettingAction(param) {
  function getFacilityMPPSettingRequest(payload) {
    return { type: GET_FACILITY_MPP_SETTING, payload };
  }

  const { id } = param || {};

  return (dispatch) => {
    dispatch(getFacilityMPPSettingRequest({
      loading: true,
    }));

    getFacilityMPPSetting(param).then(
      (settings) => {
        dispatch(getFacilityMPPSettingRequest({
          loading: false,
          settings: {
            ...settings,
            id,
          },
        }));
      },
      (error) => {
        dispatch(getFacilityMPPSettingRequest({
          loading: false,
          error: {
            error,
            id,
          },
        }));
      },
    );
  };
}

export function setFacilityMPPSettingAction(params) {
  function setFacilityMPPSettingRequest(payload) {
    return { type: SET_FACILITY_MPP_SETTING, payload };
  }

  return (dispatch) => {
    dispatch(setFacilityMPPSettingRequest({
      loading: true,
    }));

    setFacilityMPPSetting(params).then(
      ({ success }) => {
        dispatch(setFacilityMPPSettingRequest({
          loading: false,
        }));

        if (success) {
          const { id } = params || {};

          dispatch(getFacilityMPPSettingAction({ id }));
        }
      },
      (error) => {
        dispatch(setFacilityMPPSettingRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
