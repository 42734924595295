import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    height: 80,
    border: '2px solid #DDE0E8',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    height: 80,
    width: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DDE0E8',

    '& svg': {
      fontSize: '3.5rem',
    },
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  balanceStr: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '28px',
  },
  subContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px 40px',
    width: '100%',
    justifyContent: 'flex-start',
    marginTop: 24,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    color: '#7E8AA7',
  },
  priceStr: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
  },
  addBalanceBtn: {
    flexBasis: 200,
    height: 48,
  },
}));

export default {
  useStyles,
};
