import { createQuery } from 'react-query-kit';

import { getFacilities } from 'api/facilities';

export const useFacilities = createQuery({
  primaryKey: 'facilities',
  queryFn: ({ queryKey: [_, params] }) => getFacilities(params),
  retry: false,
  staleTime: 1000 * 60 * 60,
});
