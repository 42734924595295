import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { CONTROLLER_TYPES } from 'config/constants';
import { testNewController } from 'api/controllers';

import { useStyles } from './styles';

function TestNewControllerModalContent({
  facilityId,

  type,
  options,

  testNewControllerApi = testNewController,

  onClose = () => {},
}) {
  const styles = useStyles();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleTestController = useCallback(() => {
    setIsLoading(true);
    testNewControllerApi({
      facilityId,
      type,
      settings: options,
    }).then(({ success }) => {
      if (!success) {
        setIsError(true);
      }
      setIsLoading(false);
    }).catch((error) => {
      setIsError(true);
      setIsLoading(false);
    });
  }, [facilityId, options, testNewControllerApi, type]);

  useEffect(() => {
    if (step !== 0) {
      return;
    }

    handleTestController();
  }, [handleTestController, step]);

  if (isLoading) {
    return (
      <CustomModalContentWrapper
        title="Тестирование"
        onClose={onClose}
      >
        <CircularIndeterminate />
      </CustomModalContentWrapper>
    );
  }

  if (isError) {
    return (
      <CustomModalContentWrapper
        title="Тестирование"
        onClose={onClose}
      >
        <Typography>
          Что-то пошло не так! Попробуйте позже
        </Typography>
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Тестирование"
      onClose={onClose}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Устройство открылось?
            </Typography>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={3}>
              <CustomBtn
                btnType="primaryGreen"
                className={styles.btn}
                onClick={onClose}
              >
                Да
              </CustomBtn>
            </Grid>
            <Grid item xs={3}>
              <CustomBtn
                btnType="primaryRed"
                className={styles.btn}
                onClick={() => setStep(1)}
              >
                Нет
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Проверьте настройки контроллера
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {type === CONTROLLER_TYPES.gsm && (
              <Typography>
                {`Номер ${options.atsNumber} должен быть добавлен в устройство`}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <CustomBtn
              className={styles.btn}
              btnType="primaryBlue"
              onClick={() => setStep(0)}
            >
              Тест
            </CustomBtn>
          </Grid>

        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

export default TestNewControllerModalContent;
