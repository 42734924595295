import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { connect } from 'react-redux';
import { getFacilityFreePlacesAction } from 'actions/facilities';

import { setFacilityFreePlaces } from 'api/facilities';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { useStyles } from './styles';

function getCurrentFreePlaces({
  allFacilityFreePlacesState,
  facilityId,
}) {
  const list = (allFacilityFreePlacesState || {}).list || [];
  const preparedReqParam = { facilityId };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const freePlacesRes = list.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    freePlacesRes,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function FreePlacesSelects({
  facilityId,

  allFacilityFreePlacesState,
  getFacilityFreePlacesReq,
}) {
  const styles = useStyles();
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [prevParams, setPrevParams] = useState('');

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      freePlacesRes,
      currentStrParam,
    } = getCurrentFreePlaces({
      allFacilityFreePlacesState,
      facilityId,
    });

    if (currentStrParam !== prevParams) {
      if (!freePlacesRes) {
        getFacilityFreePlacesReq({ facilityId });

        setPrevParams(currentStrParam);
      }
    }
  }, [allFacilityFreePlacesState, facilityId, getFacilityFreePlacesReq, prevParams]);

  const {
    freePlacesRes,
  } = getCurrentFreePlaces({
    allFacilityFreePlacesState,
    facilityId,
  });

  const {
    data: freePlaces,
  } = freePlacesRes || {};

  const freePlacesItems = useMemo(() => {
    const result = [];

    (freePlaces || []).forEach(({ id, max }) => {
      const zoneResult = [];

      for (let i = 0; i <= max; i++) {
        zoneResult.push({
          id: i,
          title: i,
        });
      }
      result[id] = zoneResult;
    });

    return result;
  }, [freePlaces]);

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;

    setIsSetLoading(true);
    setFacilityFreePlaces({
      facilityId,
      zoneId: name,
      value,
    }).then(({ success }) => {
      setIsSetLoading(false);
      if (success) {
        getFacilityFreePlacesReq({ facilityId });
      }
    }).catch(() => {
      setIsSetLoading(false);
    });
  }, [getFacilityFreePlacesReq, facilityId]);

  if (isSetLoading) {
    return (
      <>
        <CircularIndeterminate />
      </>
    );
  }

  return (
    <>
      {(freePlaces || []).map(({ id, title, current }) => (
        <ItemSelectors
          key={id}
          selectorsType="secondary"
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={freePlacesItems[id]}
          label={title}
          name={'' + id}
          onChange={handleOnChange}
          currentValue={current}
        />
      ))}
    </>
  );
}

function mapStateToProps(state) {
  const { facilities } = state || {};
  const { allFacilityFreePlaces } = facilities || {};

  return {
    allFacilityFreePlacesState: allFacilityFreePlaces,
  };
}

const ConnectedFreePlacesSelects = connect(
  mapStateToProps,
  {
    getFacilityFreePlacesReq: getFacilityFreePlacesAction,
  },
)(FreePlacesSelects);

export default ConnectedFreePlacesSelects;
