import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { Card, CircularProgress, Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  getApiErrorMsg,
  useFormFields,
} from '@parkly/shared/helpers';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { PATH_PAGES } from 'config/constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { toast } from 'react-toastify';
import { useDeleteCustomerMutation, useOneCustomer, useSetCustomerMutation } from 'api/query/customers';
import { useQueryClient } from '@tanstack/react-query';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import useConfirm from '@parkly/shared/helpers/confirm';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { useActiveTariffPlans } from 'api/query/tariffs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from './styles';

const TG_BOT_LINK = process.env.REACT_APP_TELEGRAM_TICKETS_BOT;

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function OneCustomerPage({
  match,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const { params, path } = match || {};
  const {
    id: customerId,
    facilityId,
  } = params || {};

  useCurrentFacilityMatch(PATH_PAGES.customers);
  const confirm = useConfirm();

  const isNewMode = !customerId && path === PATH_PAGES.addCustomer;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      phone: '',
      email: '',
      comment: '',
      defaultTariffPlanId: '',
      hasTelegramBotAccess: false,
    },
    formatValueFunc: formatValue,
  });

  const queryClient = useQueryClient();

  const {
    data: currentCustomer,
    isLoading: isCurrentCustomerLoading,
  } = useOneCustomer({
    variables: { facilityId, id: customerId },
    enabled: !isNewMode,
  });

  const setCustomerMutation = useSetCustomerMutation({
    onSuccess({ id }) {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      if (isNewMode) {
        history.replace(PATH_PAGES.oneCustomer
          .replace(':facilityId', facilityId)
          .replace(':id', id));
        toast.success('Клиент добавлен');
      } else {
        toast.success('Сохранено');
      }
    },
    onError() {

    },
  });
  const { isLoading: isSetCustomerLoading } = setCustomerMutation || {};

  const deleteCustomerMutation = useDeleteCustomerMutation({
    onSuccess() {
      history.replace(
        PATH_PAGES.allCustomers.replace(':facilityId', facilityId),
      );
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
    onError() {

    },
  });
  const { isLoading: isDeleteCustomerLoading } = deleteCustomerMutation || {};

  useEffect(() => {
    if (!currentCustomer) {
      return;
    }

    updateState({
      ...currentCustomer,
      defaultTariffPlanId: currentCustomer.defaultTariffPlanId || 0,
      phone: currentCustomer.phone ? formatPhoneNumber(currentCustomer.phone || '') : '',
    });
  }, [currentCustomer, updateState]);

  const {
    data: activeTariffPlansData,
    isLoading: isActiveTariffPlansLoading,
  } = useActiveTariffPlans({
    variables: { facilityId, withTechnical: true },
  });

  const tariffPlansSelectData = useMemo(() => {
    const result = [];

    result.push({ id: 0, title: 'Не задан' });

    (activeTariffPlansData || []).forEach(({ id, name }) => {
      result.push({
        id,
        title: name,
      });
    });

    return result;
  }, [activeTariffPlansData]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (isNewMode) {
      setCustomerMutation.mutate({
        facilityId,
        name: fields.name,
        phone: cleanPhone(fields.phone),
        email: fields.email,
        comment: fields.comment,
        defaultTariffPlanId: fields.defaultTariffPlanId === 0 ? null : fields.defaultTariffPlanId,
        hasTelegramBotAccess: fields.hasTelegramBotAccess,
      });
    } else {
      setCustomerMutation.mutate({
        id: customerId,
        facilityId,
        name: fields.name,
        phone: cleanPhone(fields.phone),
        email: fields.email,
        comment: fields.comment,
        defaultTariffPlanId: fields.defaultTariffPlanId === 0 ? null : fields.defaultTariffPlanId,
        hasTelegramBotAccess: fields.hasTelegramBotAccess,
      });
    }
  };

  const onDelete = () => {
    if (isNewMode) {
      return;
    }

    confirm({
      title: 'Вы уверены, что хотите удалить клиента?',
      content: 'Данные клиента будут удалены, включая информацию о нем из журналов.',
      agreeTitle: 'Удалить',
      disagreeTitle: 'Нет',
    }).then(() => {
      deleteCustomerMutation.mutate({ id: customerId, facilityId });
    }).catch(() => {});
  };

  if (
    !isNewMode && !currentCustomer
    && isCurrentCustomerLoading
    && isActiveTariffPlansLoading
  ) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  const { error: currentCustomerError } = currentCustomer || {};

  if (currentCustomerError) {
    const currentCustomerErrorMsg = getApiErrorMsg(currentCustomerError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Клиент"
        />
        <Typography>{currentCustomerErrorMsg}</Typography>
      </Container>
    );
  }

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn
        title={isNewMode ? t('customer.newCustomerTitle') : t('customers.customer')}
      />
      <form className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid container spacing={3} item xs={8}>
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputItem}
                required
                error={errors.name}
                name="name"
                label={t('customer.name')}
                value={fields.name || ''}
                autoComplete="off"
                inputProps={{
                  maxLength: 250,
                }}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.phone}
                name="phone"
                label={t('customer.phone')}
                value={fields.phone || ''}
                onChange={onChange}
                inputProps={{
                  maxLength: MAX_PHONE_NUMBER_LENGTH,
                }}
                InputProps={{
                  endAdornment: fields.phone
                    ? (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => updateState({ phone: '' })}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.email}
                name="email"
                label={t('customer.email')}
                value={fields.email || ''}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} item xs={4}>
            <Grid item xs={12}>
              <CustomTextInput
                multiline
                minRows={5}
                className={styles.inputItem}
                error={errors.comment}
                name="comment"
                label={t('customer.comment')}
                value={fields.comment || ''}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={tariffPlansSelectData}
                label="Тарифный план по умолчанию"
                name="defaultTariffPlanId"
                onChange={onChange}
                currentValue={fields.defaultTariffPlanId}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={fields.hasTelegramBotAccess}
                  name="hasTelegramBotAccess"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label={(
                <Typography>
                  Доступен заказ пропусков через
                  {' '}
                  <CustomLink
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(TG_BOT_LINK, '_blank');
                    }}
                  >
                    Telegram бота
                  </CustomLink>
                </Typography>
              )}
            />
          </Grid>
          <Grid container item xs={12} sm={8} md={6}>
            <Card className={styles.warningCard}>
              <Typography className={styles.warningCardTitle}>
                <InfoIcon htmlColor="orange" />
                Внимание
              </Typography>
              <Typography>Управление доступами теперь осуществляется через "Пропуска"</Typography>
              <CustomLink
                href={`${PATH_PAGES.help}#tickets`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`${PATH_PAGES.help}#tickets`);
                }}
              >
                Подробнее
              </CustomLink>
            </Card>
          </Grid>
          <Grid container className={styles.btnsContainer} justifyContent="space-between" spacing={2} item xs={12}>
            <Grid item xs={3}>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={isSetCustomerLoading}
              >
                {isNewMode ? 'Добавить' : 'Сохранить'}
                {isSetCustomerLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
            {!isNewMode && (
              <Grid item xs={3}>
                <CustomBtn
                  onClick={onDelete}
                  className={styles.btnSubmit}
                  disabled={isDeleteCustomerLoading}
                >
                  Удалить
                  {isDeleteCustomerLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedOneCustomerPage = connect(
  mapStateToProps,
  {
  },
)(OneCustomerPage);

export default ConnectedOneCustomerPage;
