import React from 'react';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import IconButton from '@material-ui/core/IconButton';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { useStyles } from './styles';

function MultiItemSelectors({
  classNameForm,
  className,
  items = [],
  currentValue,
  name,
  unselectText,
  onChange,
  limitSelected = 3,
  fastSelectOne = false,
}) {
  const styles = useStyles();

  return (
    <ItemSelectors
      multiple
      displayEmpty
      name={name}
      selectorsType="secondary"
      className={[styles.select, className].join(' ')}
      classNameForm={classNameForm}
      currentValue={currentValue}
      onChange={onChange}
      input={(
        <Input
          placeholder={unselectText}
        />
      )}
      renderValue={(selected) => {
        if (selected.length < 1) {
          return (
            <Typography className={styles.unSelectStr}>
              {unselectText}
            </Typography>
          );
        }

        const selectedLabels = selected.map((statusKey) => {
          const current = items.find(({ id }) => id === statusKey);
          const { label = '', title = '' } = current || {};

          return label || title;
        });

        if (selectedLabels.length > limitSelected) {
          return (
            <Typography className={styles.selectedStr}>
              {`${selectedLabels.slice(0, limitSelected)} +${selectedLabels.length - limitSelected}`}
            </Typography>
          );
        }

        return (
          <Typography className={styles.selectedStr}>
            {selectedLabels.join(', ')}
          </Typography>
        );
      }}
    >
      {items.map((current = {}) => {
        const {
          label, title, id, color,
        } = current;

        return (
          <MenuItem
            key={id}
            value={id}
            className={styles.item}
          >
            <Checkbox color="primary" checked={(currentValue || []).includes(id)} size="small" />
            <Typography className={styles.itemStr}>
              {color && (
                <CircleIcon className={styles.circleIcon} color={color} />
              )}
              {label || title}
            </Typography>
            {fastSelectOne && (
              <IconButton
                style={{ marginLeft: 'auto' }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  onChange({
                    target: {
                      name,
                      value: [id],
                    },
                  });
                }}
              >
                <FastForwardIcon />
              </IconButton>
            )}
          </MenuItem>
        );
      })}
    </ItemSelectors>
  );
}

export default MultiItemSelectors;
