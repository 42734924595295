import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { getHumanizeDuration } from '@parkly/shared/helpers';

import { useAnalytic } from 'api/query/analytics';
import { PATH_PAGES } from 'config/constants';
import { useDebounce } from 'helpers/hooks';

import { useStyles } from './styles';

function getPathForLink(link) {
  const {
    type,
    to,
    facility_id: facilityId,
  } = link || {};

  switch (type) {
    case 'customer':
      return PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', to);
    default:
      return '';
  }
}

function CellData({ cell }) {
  const history = useHistory();
  const styles = useStyles();
  const {
    type,
    value,
    duration,
    link,
  } = cell || {};

  if (type === 'link') {
    const path = getPathForLink(link);

    return (
      <CustomLink
        href={path}
        onClick={(e) => {
          e.preventDefault();
          history.push(path);
        }}
      >
        {value}
      </CustomLink>
    );
  }

  if (type === 'duration') {
    const { units = ['d', 'h'] } = duration || {};

    return (
      <Typography>
        {getHumanizeDuration(value * 60 * 1000, units)}
      </Typography>
    );
  }

  return (
    <Typography>
      {value}
    </Typography>
  );
}

export default function AnalyticTable({
  mode,
  type,
  facilityIds,
  start,
  end,
  tariffPlanIds,
  gateIds,
  accessStatuses,
  sessionDurationFrom,
  sessionDurationTo,
}) {
  const styles = useStyles();
  const [title, setTitle] = useState('');
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  const params = useMemo(() => ({
    mode,
    type,
    facilityIds,
    tariffPlanIds,
    gateIds,
    accessStatuses,
    sessionDurationFrom,
    sessionDurationTo,
    start: start && end ? start : undefined,
    end: start && end ? end : undefined,
  }), [mode, type, facilityIds, tariffPlanIds, gateIds, accessStatuses, sessionDurationFrom, sessionDurationTo, start, end]);

  const variables = useDebounce(params, 0);

  const { data: chartData, isFetching, isLoading } = useAnalytic({
    variables,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (chartData) {
      const { title: chartTitle, data } = chartData;
      setTitle(chartTitle);

      const { data: resRows, headers: resHeaders } = data || {};

      setHeaders(resHeaders);
      setRows(resRows);
    }
  }, [chartData]);

  if (isFetching) {
    return (
      <Paper className={styles.paper}>
        <Typography className={styles.title}>
          {title}
        </Typography>
        <Grid container style={{ height: 455 }} justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={styles.paper}>
      <Typography className={styles.title}>
        {title}
      </Typography>
      {isEmpty(rows) && (
        <div className={styles.tableNoDataContainer}>
          <Typography className={styles.tableNoDataStr}>Пока пусто</Typography>
        </div>
      )}
      {!isEmpty(rows) && (
        <TableContainer className={styles.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {(headers || []).map(({ key, title: headerTitle }) => (
                  <TableCell align="left" key={`header-${key}`}>
                    <Typography className={styles.tableHeaderStr}>{headerTitle}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rows || []).map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  {(headers || []).map(({ key }) => (
                    <TableCell align="left">
                      <CellData cell={row[key]} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
