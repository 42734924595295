import React, { useCallback } from 'react';
import clone from 'lodash/clone';
import { Grid, Table, TableBody, TableContainer } from '@material-ui/core';
import { TARIFF_INTERVALS } from 'config/constants';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from './styles';

const TARIFF_INTERVAL_MAP = {
  [TARIFF_INTERVALS.min]: 'мин',
  [TARIFF_INTERVALS.hour]: 'час',
  [TARIFF_INTERVALS.day]: 'сут',
  [TARIFF_INTERVALS.month]: 'мес',
};

function TariffIntervalPrices({
  errors,
  interval,
  prices,
  onChange,
  disabled = false,
}) {
  const styles = useStyles();

  const handleClearAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const createChangeInputHandler = (index) => {
    return (event) => {
      const { target } = event || {};
      const {
        name,
        value,
      } = target || {};

      const newPrices = clone(prices);
      const newValue = parseInt(value, 10);

      newPrices[index] = {
        ...newPrices[index],
        [name]: newValue,
      };

      if (index !== prices.length - 1 && name === 'to') {
        newPrices[index + 1] = {
          ...newPrices[index + 1],
          from: newValue + 1,
        };
      }

      onChange(newPrices);
    };
  };

  const createDeleteRowHandler = (index) => {
    return () => {
      const newPrices = clone(prices);
      newPrices.splice(index, 1);
      onChange(newPrices);
    };
  };

  const onAddNewRow = useCallback(() => {
    if (prices.length === 0) {
      onChange([{ from: 0, to: 1, rate: '' }]);
      return;
    }

    const lastTo = prices[prices.length - 1].to || 0;
    const newPrices = clone(prices);

    newPrices.push({
      from: lastTo + 1,
      to: TARIFF_INTERVALS.month / interval,
      rate: '',
    });

    onChange(newPrices);
  }, [interval, onChange, prices]);

  return (
    <Grid container item xs={12} alignItems="center">
      <TableContainer className={styles.container} component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {!disabled && (
                <TableCell align="left" style={{ width: 50 }}>
                  <IconButton
                    onClick={handleClearAll}
                    size="small"
                    disableRipple
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              )}
              <TableCell align="left">
                <Typography className={styles.headerStr}>
                  {`От, ${TARIFF_INTERVAL_MAP[interval]}`}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={styles.headerStr}>
                  {`До, ${TARIFF_INTERVAL_MAP[interval]}`}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={styles.headerStr}>
                  {`Руб/${TARIFF_INTERVAL_MAP[interval]}`}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(prices || []).map(({ rate, from, to }, index) => (
              <TableRow>
                {!disabled && (
                  <TableCell style={{ width: 50 }} align="left">
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={createDeleteRowHandler(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell align="left">
                  <CustomTextInput
                    type="secondary"
                    name="from"
                    value={from}
                    error={errors[`prices.${index}.from`]}
                    autoComplete="off"
                    disabled={index !== 0 || disabled}
                    inputProps={{
                      type: 'number',
                      min: 0,
                    }}
                    onChange={createChangeInputHandler(index)}
                  />
                </TableCell>
                <TableCell align="left">
                  <CustomTextInput
                    type="secondary"
                    disabled={disabled}
                    name="to"
                    value={to}
                    error={errors[`prices.${index}.to`]}
                    autoComplete="off"
                    inputProps={{
                      type: 'number',
                      min: 0,
                    }}
                    onChange={createChangeInputHandler(index)}
                  />
                </TableCell>
                <TableCell align="left">
                  <CustomTextInput
                    type="secondary"
                    name="rate"
                    value={rate}
                    error={errors[`prices.${index}.rate`]}
                    disabled={disabled}
                    autoComplete="off"
                    inputProps={{
                      type: 'number',
                      min: 0,
                    }}
                    onChange={createChangeInputHandler(index)}
                  />
                </TableCell>
              </TableRow>
            ))}
            {!disabled && (
              <TableRow>
                <TableCell align="left" style={{ width: 50 }}>
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={onAddNewRow}
                  >
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default TariffIntervalPrices;
