import { createMutation, createQuery } from 'react-query-kit';
import { cancelPayment, getPaymentInfo, getPayments } from 'api/payments';

export const usePayments = createQuery({
  primaryKey: 'payments',
  queryFn: ({ queryKey: [_, params] }) => {
    return getPayments(params);
  },
  retry: false,
});

export const usePaymentInfo = createQuery({
  primaryKey: 'payments',
  queryFn: ({ queryKey: [_, { facilityId, id }] }) => {
    return getPaymentInfo({ facilityId, id });
  },
  retry: false,
});

export const useCancelPaymentMutation = createMutation({
  mutationFn: async ({ id, facilityId }) => cancelPayment({ id, facilityId }),
});
