import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  paginationContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: 'flex',
    width: '100%',
  },
  pagination: {
    margin: 'auto',
  },
  searchField: {

  },
  btn: {
    width: '100%',
  },
  select: {
    width: '100%',
  },

  clickable: {
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  sortIcon: {
    marginLeft: 7,
  },
  noDataContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 480,
  },
  noDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  nameStr: {
    fontSize: 16,
  },
  phoneStr: {
    fontSize: 14,
    fontWeight: 700,
  },
  timeStr: {

  },
  dateStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  tokenStr: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));

export default {
  useStyles,
};
