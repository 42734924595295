import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import NavigationLink from '@parkly/shared/components/atoms/NavigationLink';

import { Typography } from '@material-ui/core';
import { SECTIONS } from 'config/constants';
import { useStyles } from './styles';

const propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  })),
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  activeItemClassName: PropTypes.string,
};

function HeaderWithNavigationLinks({
  links,
  className,
  itemClassName,
  activeItemClassName,
}) {
  const styles = useStyles();
  const content = (
    <div
      className={[
        styles.container,
        className || '',
      ].join(' ')}
    >
      {(links || []).filter((item) => !!item).map(({ to, label }) => (
        <NavigationLink
          key={to}
          className={[
            styles.item,
            itemClassName || '',
          ].join(' ')}
          activeClassName={[
            styles.activeItem,
            activeItemClassName || '',
          ].join(' ')}
          to={to}
        >
          { ({ isActive }) => (
            <>
              <Typography>
                {label}
              </Typography>
              {isActive && (
                <div className={styles.arrow} />
              )}
            </>
          )}
        </NavigationLink>
      ))}
    </div>
  );

  return createPortal(content, document.getElementById(SECTIONS.topBar));
}

HeaderWithNavigationLinks.propTypes = propTypes;

export default HeaderWithNavigationLinks;
