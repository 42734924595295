import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { PLACE_PURPOSES } from 'config/constants';
import { useStyles } from './styles';

export default function AddNewPlaces({
// eslint-disable-next-line no-unused-vars
  onAdd = (count, purpose) => {},
  purposeItems,
}) {
  const styles = useStyles();
  const [newPlacesCount, setNewPlacesCount] = useState(1);
  const [newPlacesPurpose, setNewPlacesPurpose] = useState(PLACE_PURPOSES.reactive);

  const handleAdd = () => {
    if (newPlacesCount <= 0) {
      setNewPlacesCount(1);
      return;
    }

    onAdd(newPlacesCount, newPlacesPurpose);

    setNewPlacesCount(1);
  };

  return (
    <>
      <Typography style={{ marginLeft: 22 }}>Добавить новые места</Typography>
      <Grid item xs={12} container spacing={2} style={{ marginLeft: 8 }}>
        <Grid item xs={2}>
          <CustomTextInput
            type="secondary"
            className={styles.inputItem}
            value={newPlacesCount}
            onChange={(e) => setNewPlacesCount(e.target.value)}
            inputProps={{
              min: 0,
              type: 'number',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ItemSelectors
            selectorsType="secondary"
            currentValue={newPlacesPurpose}
            items={purposeItems}
            onChange={(e) => setNewPlacesPurpose(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomBtn
            onClick={handleAdd}
          >
            Добавить
          </CustomBtn>
        </Grid>
      </Grid>
    </>
  );
}
