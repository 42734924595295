import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  stacked: {

  },
  value: {
    fill: '#004BF6',
  },
  noValue: {
    fill: 'white',
  },
  reservedRect: {
    '&:hover': {
      fill: '#417BFF',
    },
  },
  emptyRect: {
    '&:hover': {
      fill: '#E9EFFF',
    },
  },
  tooltipNode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    padding: 12,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: 6,
  },
  alertIcons: {
    cursor: 'pointer',
  },
  tooltipNodeAlert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    padding: '4px 8px',
    backgroundColor: theme.palette.greyC.darkGrey,
    borderRadius: 6,
  },
  tooltipAlert: {},
  tooltipAlertStr: {
    color: '#FFFFFF',
    fontSize: theme.typography.fontSize12,
  },
  tooltipCircle: {
    marginRight: 6,
    borderRadius: '50%',
    backgroundColor: '#004BF6',
    flexShrink: 0,
    flexGrow: 0,
    width: 10,
    height: 10,
  },
  tooltipCircleEmpty: {
    backgroundColor: '#E4EDFF',
  },
  tooltipStr: {
    flexShrink: 0,
    flexGrow: 0,
    fontSize: theme.typography.fontSize14,
  },

  yAxis: {
    '& line': {
      stroke: theme.palette.greyC.darkGrey,
      opacity: 0.5,
      transform: 'translateX(-6px)',
    },
    '& path': {
      stroke: theme.palette.greyC.gray2,
    },
    '& text': {
      fontSize: theme.typography.fontSize14,
      fontFamily: theme.typography.fontFamily,
    },
    '& g:first-child': {
      display: 'none',
    },
  },
  xAxis: {
    '& line': {
      stroke: theme.palette.greyC.gray2,
    },
    '& path': {
      stroke: theme.palette.greyC.gray2,
    },
    '& text': {
      fontSize: theme.typography.fontSize12,
      fontFamily: theme.typography.fontFamily,
      transform: 'translateX(10px) translateY(10px) rotate(45deg);',
    },
  },
  alertLine: {
    stroke: theme.palette.redC.errorRed,
    'stroke-dasharray': '5,5',
  },
}));

export default {
  useStyles,
};
