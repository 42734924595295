import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useFormFields } from '@parkly/shared/helpers';
import { getGSMControllerSettingsAction, setControllerAction } from 'actions/controllers';

import { CONTROLLER_TYPES } from 'config/constants';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import { toast } from 'react-toastify';
import { useOpenVpnClients } from 'api/query/openvpn';
import { testNewController } from 'api/controllers';
import { useStyles } from './styles';

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeLaurentControllerModalContent({
  facilityId,
  id,
  onClose = () => {},

  testNewControllerApi = testNewController,

  setControllerState,
  setControllerReg,

  allControllersState,
}) {
  const styles = useStyles();

  const [step, setStep] = useState(0);

  const { data: vpnClients } = useOpenVpnClients({ variables: { facilityId } });

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      password: '',
      port: '',
      host: '',
      vpn: '',
      isVpn: true,
      sn: '',
      mac: '',
    },
  });

  const handleNextStep = useCallback(() => {
    // TODO validate

    testNewControllerApi({
      facilityId,
      type: CONTROLLER_TYPES.laurent,
      settings: {
        host: !fields.isVpn ? fields.host : undefined,
        port: fields.port,
        password: fields.password,
        vpn: fields.isVpn ? fields.vpn : undefined,
      },
    }).then(({ data, success, message }) => {
      if (!success) {
        if (message) {
          toast.error(message);
        }
        return;
      }

      const { sn, mac } = data || {};

      updateState({
        sn,
        mac,
      });

      setStep(1);
    }).catch(() => {

    });
  }, [testNewControllerApi, facilityId, fields, updateState]);

  const handleSetController = useCallback(() => {
    if (step !== 1) {
      return;
    }

    setControllerReg({
      facilityId,
      id,
      type: CONTROLLER_TYPES.laurent,
      settings: {
        host: !fields.isVpn ? fields.host : undefined,
        port: fields.port,
        password: fields.password,
        vpn: fields.isVpn ? fields.vpn : undefined,
        sn: fields.sn,
        mac: fields.mac,
      },
    });

    onClose();
  }, [facilityId, fields, id, onClose, setControllerReg, step]);

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        host: controller.settings.host || '',
        port: controller.settings.port,
        password: '',
        vpn: controller.settings.vpn || '',
        isVpn: !!fields.vpn,
      });
    }
  }, [id, facilityId, updateState, allControllersState]);

  const vpnItems = useMemo(() => (vpnClients || []).map(({ id, ip }) => ({ id, title: ip })), [vpnClients]);

  return (
    <CustomModalContentWrapper
      title="Подключение Laurent"
      onClose={onClose}
    >
      {step === 0 && (
        <Grid container spacing={2} alignItems="center">
          {fields.isVpn ? (
            <Grid item xs={9}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                error={errors.vpn}
                name="vpn"
                label="Хост"
                items={vpnItems}
                value={fields.vpn}
                onChange={onChange}
              />
            </Grid>
          ) : (
            <Grid item xs={9}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.host}
                name="host"
                label="Хост"
                value={fields.host}
                onChange={onChange}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <FormControlLabel
              // className={styles.checkbox}
              // disabled={!hasCustomerAccessTokenControlRight || isPassRequirementValue}
              control={(
                <Checkbox
                  checked={fields.isVpn}
                  name="isVpn"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label="VPN"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.port}
              name="port"
              label="Порт"
              value={fields.port}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputPasswordItem}
                error={errors.password}
                type="passwordMightVisible"
                name="password"
                label="Пароль"
                value={fields.password}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleNextStep}
                >
                  Далее
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GridTitleBase
              classNameContainer={styles.itemGridTitle}
              title="SN"
              titleSm={6}
            >
              <Typography>
                {fields.sn}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemGridTitle}
              title="MAC"
              titleSm={6}
            >
              <Typography>
                {fields.mac}
              </Typography>
            </GridTitleBase>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleSetController}
                >
                  Сохранить
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController,
    allControllers,
  } = controllers || {};

  return {
    setControllerState: setController,
    allControllersState: allControllers,
  };
}

const ConnectedMakeLaurentControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
    getGSMControllerSettingsReg: getGSMControllerSettingsAction,
  },
)(MakeLaurentControllerModalContent);

export default ConnectedMakeLaurentControllerModalContent;
