import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  formContainer: {
    width: '100%',
  },
  form: {
    marginTop: 16,
  },
  inputItem: {
    margin: 0
  },
  linkItem: {

  },
  divider: {
    marginTop: 16,
    backgroundColor: theme.palette.greyC.gray,
  },
  checkbox: {
    marginLeft: -10,
  },
  subTitle: {
    marginLeft: 8,
    marginTop: 16,
    marginBottom: 8,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  btnSubmit: {
    marginTop: 8,
    width: '100%',
    height: 48,
    display: 'block',
  },
  tableContainer: {
    marginTop: 16,
  },
  head: {
    border: `1px solid white`,
    color: 'white',
    fontWeight: 700,
    cursor: 'pointer',
    backgroundColor: theme.palette.greyC.gray2,
    '&:hover': {
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
  body: {
    border: `1px solid white`,
    fontWeight: 700,
    cursor: 'pointer',
    backgroundColor: theme.palette.greyC.buttonGrey,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green1,
    },
  },
  selected: {
    cursor: 'pointer',
    backgroundColor: theme.palette.greenC.green5,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green1,
    },
  },
}));

export default {
  useStyles,
};
