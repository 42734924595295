import React from 'react';

function PlusIcon({
  size = 24,
  stroke = '#506188',
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 12H20" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M12 20L12 4" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>

  );
}

export default PlusIcon;
