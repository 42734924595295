import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllCustomersPage from 'components/pages/customers/AllCustomersPage';
import OneCustomerPage from 'components/pages/customers/OneCustomerPage';
import CustomerInfoPage from 'components/pages/customers/CustomerInfoPage';

import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';

function getRedirect({
  currentFacilityId,
  hasCustomerShowRight,
  hasPassShowRight,
  hasBackListShowRight,
  hasTicketShowRight,
}) {
  if (hasCustomerShowRight) {
    return PATH_PAGES.allCustomers.replace(':facilityId', currentFacilityId);
  }

  if (hasTicketShowRight) {
    return PATH_PAGES.tickets.replace(':facilityId', currentFacilityId);
  }

  if (hasPassShowRight) {
    return PATH_PAGES.allPasses.replace(':facilityId', currentFacilityId);
  }

  if (hasBackListShowRight) {
    return PATH_PAGES.allBlackList;
  }

  return PATH_PAGES.notEnoughRights;
}

function CustomersPage({
  match,
  currentFacilityState,
}) {

  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    timezone: tz,
  } = currentFacilityData || {};

  useEffect(() => {
    if (tz) {
      moment.tz.setDefault(tz);
      return;
    }

    moment.tz.setDefault();
  }, [tz]);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasTicketShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, currentFacilityId);
  const hasBackListShowRight = useCommonRights(RIGHT_KEYS.blackListShow);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute
        path={PATH_PAGES.allCustomers}
        component={AllCustomersPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.oneCustomerInfo}
        component={CustomerInfoPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.addCustomer}
        component={OneCustomerPage}
      />
      <PrivateRoute
        exact
        path={PATH_PAGES.oneCustomer}
        component={OneCustomerPage}
      />
      <Redirect
        to={getRedirect({
          currentFacilityId,
          hasCustomerShowRight,
          hasPassShowRight,
          hasBackListShowRight,
          hasTicketShowRight,
        })}
      />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedCustomersPage = connect(
  mapStateToProps,
  {
  },
)(CustomersPage);

export default ConnectedCustomersPage;
