import { callAPI } from '@parkly/shared/api';

async function validGetBlackList({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const blackListData = curData.map((item) => {
    const {
      id,
      createdAt,
      reason,
      operatorName,
      active,
      blockType,
      blockValue,
    } = item;

    return {
      id,
      createdAt,
      reason,
      operatorName,
      active,
      blockType,
      blockValue,
    };
  });

  const result = {
    blackListData,
  };

  return result;
}

export async function getAllBlackList() {
  return callAPI('blacklist')
    .then((res) => validGetBlackList({
      res,
    }));
}

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function updateOrCreateBlackListItem({
  id,
  reason,
  active,
  blockType,
  blockValue,
}) {
  return callAPI(
    'blacklist',
    'POST',
    {
      id,
      reason,
      active,
      blockType,
      blockValue,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function validOneBlackList({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    createdAt,
    reason,
    operatorName,
    active,
    blockType,
    blockValue,
  } = resultData;

  return {
    id,
    createdAt,
    reason,
    operatorName,
    active,
    blockType,
    blockValue,
  };
}

export async function getOneBlackList({ id }) {
  return callAPI(`blacklist/${id}`)
    .then((res) => validOneBlackList({
      res,
    }));
}

export async function updateOrCreateBlackList({
  id,
  reason,
  active,
  blockType,
  blockValue,
}) {
  return callAPI(
    'blacklist',
    'POST',
    {
      id: id === undefined ? null : id,
      reason,
      active,
      blockType,
      blockValue,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function removeBlackList({ id }) {
  return callAPI(
    `blacklist/${id}`,
    'DELETE',
  )
    .then((res) => validCreate({
      res,
    }));
}
