import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { TARIFF_INTERVAL_MAP } from 'config/constants';
import { useStyles } from './styles';

function TariffStandardPrices({
  interval,
  prices,
  onChange,
  disabled = false,
}) {
  const styles = useStyles();

  const handleInputChange = useCallback((event) => {
    const { target } = event || {};
    const {
      value,
    } = target || {};

    onChange({
      rate: value,
    });
  }, [onChange]);

  const {
    rate,
  } = prices || {};

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item xs={8}>
        <Typography className={styles.label}>
          {`руб/${TARIFF_INTERVAL_MAP[interval]}`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <CustomTextInput
          className={styles.inputItem}
          required
          type="secondary"
          value={rate}
          autoComplete="off"
          inputProps={{
            type: 'number',
            min: 0,
          }}
          disabled={disabled}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  );
}

export default TariffStandardPrices;
