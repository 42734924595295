import {
  CLEAR_CURRENT_FACILITY_CACHE,
  SET_CURRENT_FACILITY,
  SET_CURRENT_FACILITY_LOADED,
  SET_FORCE_CURRENT_FACILITY,
} from 'actions/types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { LOCALSTORAGE_KEYS } from '@parkly/shared/config/constants';

const INITIAL_STATE = {
  currentFacility: {
    loading: true,
    data: null,
  },
  forceCurrentFacility: {
    loading: true,
    data: null,
  },
};

function getSetFacilityState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

function currentFacility(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_FACILITY:
      return {
        ...state,
        currentFacility: getSetFacilityState({
          prevState: state.currentFacility,
          payload: action.payload,
        }),
      };

    case SET_FORCE_CURRENT_FACILITY:
      return {
        ...state,
        forceCurrentFacility: getSetFacilityState({
          prevState: state.forceCurrentFacility,
          payload: action.payload,
        }),
      };

    case SET_CURRENT_FACILITY_LOADED:
      return {
        ...state,
        currentFacility: {
          ...state.currentFacility,
          loading: false,
        },
      };

    case CLEAR_CURRENT_FACILITY_CACHE:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default persistReducer({
  key: LOCALSTORAGE_KEYS.currentFacility,
  storage,
  whitelist: ['currentFacility'],
}, currentFacility);
