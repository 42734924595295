import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { formatPhoneNumberInput } from '@parkly/shared/helpers';

import { ACCESS_TOKEN_TYPES, ACCESS_TOKEN_TYPES_MAP, PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import {
  createBlackListAction,
  getOneBlackListAction,
  removeBlackListAction,
  updateActiveBlackListAction,
} from 'actions/blackList';
import { useStyles } from './styles';
import { useCommonRights } from '../../../../helpers/hooks';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';

/* help functions */
function getOneBlackList({
  oneBlackListState,
  match,
}) {
  const blackListId = ((match || {}).params || {}).id;

  const { list } = oneBlackListState || {};
  const currentBlackList = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + blackListId);

    return isNeeded;
  });

  return {
    blackListId,
    currentBlackList,
  };
}

function getSettings({
  oneBlackListState,
  match,
}) {
  const { params, path } = match || {};
  const {
    id,
  } = params || {};

  const isNewBlackListMode = !id && path === PATH_PAGES.newBlackList;

  if (isNewBlackListMode) {
    return {
      blockType: ACCESS_TOKEN_TYPES.phone,
      blockValue: '+7',
      reason: '',
      active: true,
    };
  }

  const {
    currentBlackList,
  } = getOneBlackList({
    oneBlackListState,
    match,
  });

  const {
    blockType,
    blockValue,
    reason,
    active,
  } = currentBlackList || {};

  return {
    blockType,
    blockValue,
    reason,
    active,
  };
}

function getErrors({
  addNewBlackListState,
  updateBlackListState,
}) {
  return {
    blockType: false,
    blockValue: false,
    reason: false,
    active: false,
  };
}

const TOKEN_TYPES = [
  {
    id: ACCESS_TOKEN_TYPES.phone,
    title: ACCESS_TOKEN_TYPES_MAP[ACCESS_TOKEN_TYPES.phone],
  },
  {
    id: ACCESS_TOKEN_TYPES.plateNumber,
    title: ACCESS_TOKEN_TYPES_MAP[ACCESS_TOKEN_TYPES.plateNumber],
  },
  // {
  //   id: ACCESS_TOKEN_TYPES.rfid,
  //   title: ACCESS_TOKEN_TYPES_MAP[ACCESS_TOKEN_TYPES.rfid],
  // },
];

const propTypes = {
  addNewBlackListState: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  updateBlackListState: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  oneBlackListState: PropTypes.shape({
    loading: PropTypes.bool,
    list: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }).isRequired,
  }).isRequired,
  getOneBlackListReq: PropTypes.func,
  addNewBlackListReq: PropTypes.func,
  updBlackListActiveReq: PropTypes.func,
  removeBlackListReq: PropTypes.func,
};

function OneBlackListPage({
  match,
  addNewBlackListReq = () => {},
  addNewBlackListState,
  getOneBlackListReq = () => {},
  oneBlackListState,
  updateBlackListState,
  updBlackListActiveReq = () => {},
  removeBlackListReq = () => {},

  currentOperatorState,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [settings, setSettings] = useState(getSettings({
    oneBlackListState,
    match,
  }));
  const [errors, setErrors] = useState(getErrors({
    addNewBlackListState,
    updateBlackListState,
  }));
  const [isRemoveDialog, setIsRemoveDialog] = useState(false);

  const hasBlacklistUpdateRight = useCommonRights(RIGHT_KEYS.blackListUpdate);

  const { params, path } = match || {};
  const {
    id,
  } = params || {};

  const isNewBlackListMode = !id && path === PATH_PAGES.newBlackList;

  useEffect(
    () => {
      const a = getSettings({
        oneBlackListState,
        match,
      });
      setSettings(a);
    },
    // eslint-disable-next-line
    [oneBlackListState, ((match || {}).params || {}).id],
  );

  useEffect(
    () => {
      const {
        blackListId,
        currentBlackList: thisBlackList,
      } = getOneBlackList({
        oneBlackListState,
        match,
      });

      const { loading: isOneBlackListLoading } = oneBlackListState || {};
      if (!isOneBlackListLoading && !thisBlackList && blackListId && !isNewBlackListMode) {
        getOneBlackListReq({
          id: blackListId,
        });
      }
    },
    [getOneBlackListReq, isNewBlackListMode, match, oneBlackListState],
  );
  useEffect(
    () => {
      const addErrors = (addNewBlackListState || {}).error;
      const updateErrors = (updateBlackListState || {}).error;
      const error = updateErrors || addErrors;

      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors((prevErrors) => ({
          ...prevErrors,
          ...backErrors,
        }));
      }
    },
    [addNewBlackListState, updateBlackListState],
  );

  const isAddNewLoading = (addNewBlackListState || {}).loading;

  const isLoading = isAddNewLoading
    || (oneBlackListState || {}).loading
    || (addNewBlackListState || {}).loading
    || (updateBlackListState || {}).loading;

  const {
    blockType,
    blockValue,
    reason,
    active,
  } = settings;

  const isSaveBtnDisabled = !blockValue || isAddNewLoading;

  function updateSettings(propName, value) {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [propName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [propName]: false,
    }));
  }
  const handleChanges = (event) => {
    const propName = event.target.name;

    const { value, checked } = event.target;
    let newValue = event.target.type !== 'checkbox' ? value : checked;

    if (propName === 'blockType') {
      updateSettings(propName, newValue);
      if (newValue === ACCESS_TOKEN_TYPES.phone) {
        updateSettings('blockValue', '+7');
      } else {
        updateSettings('blockValue', '');
      }
      return;
    }

    if (propName === 'blockValue') {
      if (blockType === ACCESS_TOKEN_TYPES.phone) {
        updateSettings(propName, formatPhoneNumberInput(newValue));
      } else {
        updateSettings(propName, newValue);
      }
      return;
    }

    updateSettings(propName, newValue);
  };
  const openRemoveDialog = () => {
    setIsRemoveDialog(true);
  };
  const handleCloseRemoveDialog = () => {
    setIsRemoveDialog(false);
  };
  const handleAgreeRemoveDialog = () => {
    removeBlackListReq({
      id,
    }, history);
    setIsRemoveDialog(false);
  };

  const onAddOrSaveBtnClick = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (isNewBlackListMode) {
      addNewBlackListReq({
        blockType: settings.blockType,
        blockValue: settings.blockValue,
        reason: settings.reason,
        active: settings.active,
      }, history);

      return;
    }

    updBlackListActiveReq({
      id,
      active: settings.active,
    });
  };

  return (
    <Container
      className={classes.container}
      maxWidth={false}
    >
      <HeaderWithBackBtn
        title={isNewBlackListMode ? t('blackList.newBlackList') : t('blackList.oneBlackList')}
      />

      {!isLoading && (
        <>
          <form
            className={classes.form}
            onSubmit={onAddOrSaveBtnClick}
            autoComplete="off"
          >
            <Grid className={classes.pageBody} container spacing={2}>
              <Grid container spacing={2} item className={classes.bodyMain} xs={12} sm={8}>
                <Grid item xs={12} sm={6}>
                  <ItemSelectors
                    classNameForm={classes.selectorsForm}
                    classNameLabel={classes.selectorsLabel}
                    className={classes.selector}
                    disabled={!isNewBlackListMode}
                    items={TOKEN_TYPES}
                    label={t('blackList.blockType')}
                    name="blockType"
                    onChange={handleChanges}
                    currentValue={blockType || 0}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextInput
                    className={classes.inputItem}
                    error={errors.blockValue}
                    required
                    name="blockValue"
                    label={t('blackList.blockValue')}
                    value={blockValue}
                    autoComplete="off"
                    inputProps={{
                      maxLength: blockType === ACCESS_TOKEN_TYPES.phone ? MAX_PHONE_NUMBER_LENGTH : 250,
                    }}
                    disabled={!isNewBlackListMode}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    className={classes.inputItem}
                    error={errors.reason}
                    name="reason"
                    type="text"
                    label={t('blackList.reason')}
                    value={reason}
                    autoComplete="off"
                    disabled={!isNewBlackListMode}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item sm={9} xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="active"
                        className={classes.switch}
                        checked={active || false}
                        onChange={handleChanges}
                        disabled={!hasBlacklistUpdateRight}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={t('blackList.active')}
                  />
                </Grid>
              </Grid>
            </Grid>
            {hasBlacklistUpdateRight && (
              <Grid container className={classes.makeAdminContainer}>
                <Grid item xs={12} sm={8}>
                  <Grid container className={classes.makeAdminContainer}>
                    <Grid
                      item
                      sm={isNewBlackListMode ? 12 : 3}
                      style={isNewBlackListMode ? {} : { maxWidth: 157 }}
                    >
                      <CustomBtn
                        type="submit"
                        className={classes.btnAddOrSave}
                        disabled={isSaveBtnDisabled}
                      >
                        {t('blackList.save')}
                      </CustomBtn>
                    </Grid>
                    {!isNewBlackListMode && (
                      <Grid item sm={9}>
                        <CustomBtn
                          className={classes.btnRemove}
                          onClick={openRemoveDialog}
                        >
                          {t('blackList.remove')}
                        </CustomBtn>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} />
              </Grid>
            )}
          </form>
          <CustomDialog
            open={isRemoveDialog}
            handleAgree={handleAgreeRemoveDialog}
            handleClose={handleCloseRemoveDialog}
            dialogContent={t('blackList.removeQuestion')}
            agreeTitle={t('others.remove')}
            disagreeTitle={t('others.cancel')}
          />
        </>
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
    </Container>
  );
}

OneBlackListPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { blackLists } = state || {};
  const {
    oneBlackList: oneBlackListState,
    addNewBlackList: addNewBlackListState,
    updBlackList: updateBlackListState,
    removeBlackList: removeBlackListState,
  } = blackLists || {};

  return {
    oneBlackListState,
    addNewBlackListState,
    updateBlackListState,
    removeBlackListState,
  };
}

const ConnectedOneBlackListPage = connect(
  mapStateToProps,
  {
    getOneBlackListReq: getOneBlackListAction,
    addNewBlackListReq: createBlackListAction,
    updBlackListActiveReq: updateActiveBlackListAction,
    removeBlackListReq: removeBlackListAction,
  },
)(OneBlackListPage);

export default ConnectedOneBlackListPage;
