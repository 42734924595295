import React, { useState } from 'react';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import { useStyles } from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useNewApiTokenMutation } from '../../../api/query/operators';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

export default function OperatorNewApiTokenModalContent({
  operatorId,
  onClose,
}) {
  const styles = useStyles();
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');

  const mutation = useNewApiTokenMutation({
    onSuccess({ success, token: newToken }) {
      if (success) {
        setStep(1);
        setToken(newToken);
      }
    },
  });
  return (
    <CustomModalContentWrapper
      title="Получение API токена"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.title}>
              Внимание! Раннее полученный токен перестанет действовать
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.btn}
              onClick={onClose}
            >
              Отменить
            </CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.btn}
              btnType="primaryGreen"
              onClick={() => mutation.mutate({ operatorId })}
            >
              Получить
            </CustomBtn>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Новый токен:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={token}
              variant="outlined"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if (copy(token)) {
                          toast.success('Скопировано');
                        }
                      }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <CustomBtn
              className={styles.btn}
              btnType="primaryGreen"
              onClick={onClose}
            >
              Готово
            </CustomBtn>
          </Grid>
        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}
