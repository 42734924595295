import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';

import { PASSES_SETTINGS_STATUS, PATH_PAGES, UNITS_PLURAL_MAP } from 'config/constants';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { useHistory } from 'react-router-dom';
import { plural } from '@parkly/shared/helpers';
import { useStyles } from './styles';

/* help function */

const getStatusContentByStatus = ({ status, t }) => {
  switch (status) {
    case PASSES_SETTINGS_STATUS.active:
      return (
        <>
          <CircleIcon color="#43D37D" />
          {t('tariffs.statuses.active')}
        </>
      );
    case PASSES_SETTINGS_STATUS.draft:
      return (
        <>
          <CircleIcon color="blue" />
          {t('tariffs.statuses.draft')}
        </>
      );
    case PASSES_SETTINGS_STATUS.archive:
      return (
        <>
          <CircleIcon color="red" />
          {t('tariffs.statuses.archive')}
        </>
      );
    default:
      return (
        <>
          -
        </>
      );
  }
};

function getHeaders({
  t,
}) {
  const headers = [
    {
      id: 'name',
      label: 'Название',
    },
    {
      id: 'period',
      label: 'Период',
    },
    {
      id: 'tariffPlan',
      label: 'Тарифный план',
    },
    {
      id: 'status',
      label: 'Статус',
    },
  ];

  return headers.filter((item) => !!item);
}

function getRows(passesData = []) {
  const result = passesData.map((item) => item);

  return result;
}

const propTypes = {};

function PassesSettingsTable({
  passesData,
  facilityId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const rows = getRows(passesData);
  const isPassesData = passesData && (passesData || []).length > 0;

  const headers = getHeaders({
    t,
  });

  const createOnPassClickHandler = useCallback((id) => {
    return (e) => {
      e.preventDefault();
      history.push(
        PATH_PAGES.onePassSettings
          .replace(':facilityId', facilityId)
          .replace(':passId', id),
      );
    };
  }, [facilityId, history]);

  const createOnTariffPlanClickHandler = useCallback((id) => {
    return (e) => {
      e.preventDefault();
      history.push(
        PATH_PAGES.oneTariffPlanSettings
          .replace(':facilityId', facilityId)
          .replace(':tariffPlanId', id),
      );
    };
  }, [facilityId, history]);


  return (
    <Paper className={classes.root}>
      {!isPassesData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            Пока список абонементов пуст
          </Typography>
        </div>
      )}
      {isPassesData && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id }) => (
                  <TableCell key={id} align="left">
                    <div className={classes.headerContainer}>
                      <Typography className={classes.headerStr}>
                        {label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  name,
                  intervalMode,
                  intervalValue,
                  tariffPlanId,
                  tariffPlanName,
                  status,
                } = row || {};

                const key = id;

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <CustomLink
                        onClick={createOnPassClickHandler(id)}
                        href={PATH_PAGES.onePassSettings.replace(':facilityId', facilityId).replace(':passId', id)}
                        className={classes.nameStr}
                      >
                        {name}
                      </CustomLink>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {`${intervalValue} ${plural(intervalValue, UNITS_PLURAL_MAP[intervalMode])}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <CustomLink
                        href={PATH_PAGES.oneTariffPlanSettings.replace(':facilityId', facilityId).replace(':tariffPlanId', tariffPlanId)}
                        onClick={createOnTariffPlanClickHandler(tariffPlanId)}
                      >
                        {tariffPlanName}
                      </CustomLink>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.statusStr}>
                        {getStatusContentByStatus({ status, t })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

PassesSettingsTable.propTypes = propTypes;

export default PassesSettingsTable;
