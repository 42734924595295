import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  modalBody: {
    position: 'relative',
    maxHeight: 'calc(80vh - 72px)',
    overflowY: 'auto',
  },
  container: {

  },
  itemContainer: {
    minHeight: 'unset !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  status: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  paymentStatusesStr: {
    fontWeight: 700,
  },
  payableDetailsStr: {
    marginTop: 7,
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  btnsContainer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: 24,
    width: 'calc(100% - 36px)',
    backgroundColor: 'white',
    borderRadius: 12,
  },
  btn: {
    width: '100%',
    height: 48,
  },
}));

export default {
  useStyles,
};
