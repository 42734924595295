function P360LogoIcon({
  width = 82,
  height = 24,
  colorP = 'white',
  color360 = '#00D88A',
  className,
}) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 569 165" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M436.851 82.8239C436.851 35.5599 464.403 0.839844 502.259 0.839844C540.339 0.839844 568.115 34.2158 568.115 82.8239C568.115 130.76 540.339 164.36 502.259 164.36C464.403 164.36 436.851 129.416 436.851 82.8239ZM454.771 82.5998C454.771 119.784 474.483 147.336 502.483 147.336C530.035 147.336 550.195 120.456 550.195 82.5998C550.195 44.5198 530.483 17.8638 502.483 17.8638C474.483 17.8638 454.771 44.9678 454.771 82.5998Z"
        fill={color360}
      />
      <path
        d="M299.913 107.912C299.913 95.3682 304.169 83.4962 312.457 71.6242L360.617 4.2002H381.897L348.073 52.1362C351.433 51.4642 355.017 51.2402 358.825 51.2402C392.649 51.2402 417.513 74.7602 417.513 107.912C417.513 141.288 392.649 164.36 358.825 164.36C324.553 164.36 299.913 140.392 299.913 107.912ZM317.833 107.912C317.833 130.536 335.305 147.336 358.825 147.336C382.793 147.336 399.593 131.432 399.593 107.912C399.593 84.6162 382.569 68.2642 358.825 68.2642C335.081 68.2642 317.833 85.0642 317.833 107.912Z"
        fill={color360}
      />
      <path
        d="M245.934 22.1202H173.582V4.2002H270.798V19.2082L225.55 68.0402H227.342C254.222 68.0402 277.294 82.6002 277.294 114.632C277.294 143.08 254.446 164.36 223.758 164.36C191.054 164.36 165.07 137.48 165.07 102.76H182.99C182.99 128.296 200.462 147.336 223.31 147.336C243.918 147.336 259.374 133.224 259.374 114.856C259.374 95.3682 245.262 82.1522 223.758 82.1522H212.334L202.702 71.1762L245.934 22.1202Z"
        fill={color360}
      />
      <path
        d="M0.335938 161V4.2002H80.5279C114.128 4.2002 142.352 26.3762 142.352 59.7522C142.352 91.3362 112.56 115.304 80.5279 115.304H31.6959V161H0.335938ZM110.992 59.7522C110.992 44.2962 97.9999 33.0962 80.5279 33.0962H31.6959V85.9602H80.5279C97.7759 85.9602 110.992 74.5362 110.992 59.7522Z"
        fill={colorP}
      />
    </svg>
  );
}

export default P360LogoIcon;
