import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useLastErrorGatewayEvents } from 'api/query/gatewayEvents';
import GatewayEventsContent from './GatewayEventsContent';

function LastErrorsGatewayEvents({
  facilityId,
  refetchInterval = 1000 * 60, // 1 mins
  limit = 3,
}) {
  const { data: gatewayEvents, isLoading } = useLastErrorGatewayEvents({
    variables: { facilityId, limit },
    refetchInterval,
    refetchIntervalInBackground: true,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GatewayEventsContent
          facilityId={facilityId}
          isLoading={isLoading}
          gatewayEventsData={gatewayEvents}
          showPagination={false}
        />
      </Grid>
    </Grid>
  );
}

export default LastErrorsGatewayEvents;
