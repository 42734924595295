import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import { useTicketTypes } from 'api/query/tickets';
import TicketsSettingsTable from 'components/templates/TicketsSettingsTable';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useDebounce } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getInitParams() {
  return {
    page: 1,
  };
}

function prepareParam({
  params,
  facilityId,
}) {
  const {
    page,
  } = params || {};

  const reqParam = {
    page,
    facilityId,
  };

  return reqParam;
}

function AllTicketsSettingsPage({
  match,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch();

  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const [params, setParams] = useState(getInitParams());
  const variables = useDebounce(prepareParam(params), 300);

  const { data: tickets, isLoading } = useTicketTypes({
    variables: { ...variables, facilityId: currentFacilityId },
    refetchInterval: 1000 * 60 * 30, // 30 min
    refetchIntervalInBackground: true,
  });

  const hasTicketsSettingsUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeUpdate, currentFacilityId);

  const {
    ticketTypesData,
    ticketTypesMeta,
  } = (tickets || {});

  const {
    currentPage = 1,
    lastPage = 1,
  } = ticketTypesMeta || {};

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn
        title="Типы пропусков"
        isBackBtn={false}
      />
      <div className={styles.pageBody}>
        <Grid container spacing={2}>
          {hasTicketsSettingsUpdateRight && (
            <Grid container item spacing={2}>
              <Grid item>
                <CustomBtn
                  btnType="primaryGreen"
                  className={styles.addNewBtn}
                  onClick={() => history.push(PATH_PAGES.newTicketSettings.replace(':facilityId', currentFacilityId))}
                >
                  Добавить тип пропуска
                </CustomBtn>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoading && (
              <CircularIndeterminate style={{ minHeight: 600 }} />
            )}
            {!isLoading && (
              <TicketsSettingsTable
                ticketTypesData={ticketTypesData}
                facilityId={currentFacilityId}
              />
            )}
            {!isLoading && lastPage > 1 && (
              <div className={styles.paginationContainer}>
                <Pagination
                  className={styles.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default AllTicketsSettingsPage;
