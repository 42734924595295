import React, { useCallback, useState } from 'react';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomerFastActionMutation } from 'api/query/customers';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function CustomerFastActions({
  fastActions,
  facilityId,
  gatewayEventId,
  className,
  asMenu = false,
}) {
  const [loadingBtn, setLoadingBtn] = useState(null);

  const queryClient = useQueryClient();

  const customerFastActionMutation = useCustomerFastActionMutation({
    onMutate({ type: actionType }) {
      setLoadingBtn(actionType);
    },
    onSettled() {
      setLoadingBtn(null);
      queryClient.invalidateQueries({ queryKey: ['gateway-events'] });
      queryClient.invalidateQueries({ queryKey: ['sessions'] });
    },
    onSuccess({ success }) {
      if (success) {
        toast.success('Выполнено');
      }
    },
  });

  const handleOnCLick = useCallback((actionType) => {
    setLoadingBtn(actionType);

    customerFastActionMutation.mutate({ facilityId, gatewayEventId, type: actionType });
  }, [customerFastActionMutation, facilityId, gatewayEventId]);

  if (asMenu) {
    return (
      <CustomerFastActionsMenu
        fastActions={fastActions}
        onClick={handleOnCLick}
      />
    );
  }

  return (
    <Grid container spacing={1} className={className}>
      {(fastActions || []).map(({ id, title, type: actionType }) => (
        <Grid key={id} item xs={12}>
          <CustomBtn
            style={{ width: '100%' }}
            onClick={() => handleOnCLick(actionType)}
            btnType="secondary"
            disabled={actionType === loadingBtn}
          >
            {title}
            {actionType === loadingBtn && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={20}
                color="inherit"
              />
            )}
          </CustomBtn>
        </Grid>
      ))}
    </Grid>
  );
}

function CustomerFastActionsMenu({ fastActions, onClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (type) => {
    onClick(type);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(fastActions || []).map(({ id, title, type: actionType }) => (
          <MenuItem key={id} onClick={() => handleClick(actionType)}>{title}</MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CustomerFastActions;
