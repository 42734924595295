import React from 'react';

function HomeInactiveIcon({
  size = 32,
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6.5" y="6.5" width="8" height="8" rx="2" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
      <rect x="6.5" y="17.5" width="8" height="8" rx="2" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
      <rect x="17.5" y="6.5" width="8" height="19" rx="2" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
}

export default HomeInactiveIcon;
