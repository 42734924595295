import {
  CLEAR_CURRENT_FACILITY_CACHE,
  SET_CURRENT_FACILITY,
  SET_CURRENT_FACILITY_LOADED,
  SET_FORCE_CURRENT_FACILITY,
} from './types';

export function clearCurrentFacilityCacheAction() {
  function actionClearCacheFacility() {
    return { type: CLEAR_CURRENT_FACILITY_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheFacility());
  };
}

export function setCurrentFacilityAction(facility, force) {
  function setCurrentFacilityRequest(payload) {
    return { type: SET_CURRENT_FACILITY, payload };
  }

  function setForceCurrentFacilityRequest(payload) {
    return { type: SET_FORCE_CURRENT_FACILITY, payload };
  }

  return (dispatch) => {
    dispatch(setCurrentFacilityRequest({
      loading: false,
      data: facility,
    }));

    if (force) {
      dispatch(setForceCurrentFacilityRequest({
        loading: false,
        data: facility,
      }));
    }
  };
}

export function setCurrentFacilityLoadedAction() {
  function setCurrentFacilityRequest() {
    return { type: SET_CURRENT_FACILITY_LOADED };
  }

  return (dispatch) => {
    dispatch(setCurrentFacilityRequest());
  };
}
