import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useFormFields } from '@parkly/shared/helpers';
import Typography from '@material-ui/core/Typography';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import TariffPrices from 'components/organisms/tariffPrices/TariffPrices';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { getOneTariffAction, setTariffAction } from 'actions/tariffs';
import { PATH_PAGES, RIGHT_KEYS, TARIFF_INTERVALS, TARIFF_STATUS, TARIFF_TYPE } from 'config/constants';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useStyles } from './styles';
import { useCheckFacilityRights } from '../../../../../helpers/hooks';

const TARIFF_INTERVAL_LIST = [
  { id: TARIFF_INTERVALS.min, title: 'Минута' },
  { id: TARIFF_INTERVALS.hour, title: 'Час' },
  { id: TARIFF_INTERVALS.day, title: 'Сутки' },
  { id: TARIFF_INTERVALS.month, title: 'Месяц' },
];

const TARIFF_TYPE_LIST = [
  { id: TARIFF_TYPE.standard, title: 'Стандартный' },
  { id: TARIFF_TYPE.interval, title: 'Интервальный' },
  { id: TARIFF_TYPE.cumulative, title: 'Накопительный' },
];

function getOneTariff({
  oneTariffState,
  match,
}) {
  const { params } = match || {};
  const { tariffId } = params || {};

  const { list } = oneTariffState || {};
  const currentTariff = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + tariffId);

    return isNeeded;
  });

  return {
    tariffId,
    currentTariff,
  };
}

function OneTariffPage({
  match,

  oneTariffState,
  getOneTariffReq,

  setTariffReq,
  setTariffState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.tariffSettings);

  const { params, path } = match || {};
  const {
    tariffId,
    facilityId,
  } = params || {};

  const hasTariffUpdateRight = useCheckFacilityRights(RIGHT_KEYS.tariffUpdate, facilityId);

  const isNewMode = !tariffId && path === PATH_PAGES.newTariffSettings;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      interval: TARIFF_INTERVALS.hour,
      precision: TARIFF_INTERVALS.min,
      roundIgnore: 0,
      type: TARIFF_TYPE.standard,
      status: TARIFF_STATUS.draft,
      prices: [],
    },
  });

  useEffect(() => {
    const {
      tariffId: requestedId,
      currentTariff,
    } = getOneTariff({
      oneTariffState,
      match,
    });

    const { loading: isTariffLoading } = oneTariffState || {};
    if (!isTariffLoading && !currentTariff && requestedId && !isNewMode) {
      getOneTariffReq({
        id: tariffId,
        facilityId,
      });
    }

    if (currentTariff && !isNewMode) {
      updateState({
        ...currentTariff,
      });
    }
  }, [facilityId, getOneTariffReq, isNewMode, match, oneTariffState, tariffId, updateState]);

  useEffect(() => {
    const error = (setTariffState || {}).error;
    if (error) {
      const { response } = error || {};
      const { data } = response || {};
      const backErrors = (data || {}).errors || {};

      setErrors({
        ...backErrors,
      });
    }
  }, [setErrors, setTariffState]);

  const handlePricesChange = useCallback((prices) => {
    updateState({
      prices,
    });
    const newErrors = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key] of Object.entries(errors)) {
      if (key.indexOf('prices') !== -1) {
        newErrors[key] = false;
      }
    }
    setErrors({
      ...newErrors,
    });
  }, [errors, setErrors, updateState]);

  const handleChangeType = useCallback((event) => {
    const { target } = event || {};
    const { value } = target || {};

    updateState({
      type: value,
      prices: [],
    });
  }, [updateState]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (isNewMode) {
      setTariffReq(
        {
          facilityId,
          name: fields.name,
          interval: fields.interval,
          precision: fields.precision,
          roundIgnore: parseInt(fields.roundIgnore, 10),
          type: fields.type,
          status: fields.status,
          prices: fields.prices,
        },
        history,
      );
      return;
    }

    setTariffReq(
      {
        facilityId,
        id: tariffId,
        name: fields.name,
        interval: fields.interval,
        precision: fields.precision,
        roundIgnore: fields.roundIgnore,
        type: fields.type,
        status: fields.status,
        prices: fields.prices,
      },
      history,
    );
  }, [facilityId, fields, history, isNewMode, setTariffReq, tariffId]);

  const {
    loading: isSaveLoading,
  } = setTariffState || {};

  const {
    loading: isTariffLoading,
  } = oneTariffState || {};

  const {
    currentTariff,
  } = getOneTariff({
    oneTariffState,
    match,
  });

  const { status } = currentTariff || {};

  const isDisabledUpdate = !(isNewMode || status === TARIFF_STATUS.draft);

  const availableStatuses = useMemo(() => {
    if (isNewMode) {
      return [
        { id: TARIFF_STATUS.draft, title: 'Черновик' },
        { id: TARIFF_STATUS.active, title: 'Активный' },
        { id: TARIFF_STATUS.archive, title: 'Архивный' },
      ];
    }

    switch (status) {
      case TARIFF_STATUS.active:
        return [
          { id: TARIFF_STATUS.active, title: 'Активный' },
          { id: TARIFF_STATUS.archive, title: 'Архивный' },
        ];
      case TARIFF_STATUS.archive:
        return [
          { id: TARIFF_STATUS.archive, title: 'Архивный' },
        ];
      case TARIFF_STATUS.draft:
      default:
        return [
          { id: TARIFF_STATUS.draft, title: 'Черновик' },
          { id: TARIFF_STATUS.active, title: 'Активный' },
          { id: TARIFF_STATUS.archive, title: 'Архивный' },
        ];
    }
  }, [isNewMode, status]);

  if (isTariffLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <Container className={styles.container} maxWidth="sm">
      <HeaderWithBackBtn
        title={isNewMode ? t('tariffs.newTariff') : t('tariffs.tariff')}
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputNameItem}
              required
              type="secondary"
              error={errors.name}
              name="name"
              label={t('tariffs.name')}
              value={fields.name}
              autoComplete="off"
              disabled={!hasTariffUpdateRight}
              inputProps={{
                maxLength: 250,
              }}
              onChange={onChange}
            />
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={9}>
              <Typography className={styles.label}>
                {t('tariffs.interval')}
              </Typography>
              <Typography className={styles.subLabel}>
                {t('tariffs.intervalInfo')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ItemSelectors
                selectorsType="secondary"
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={TARIFF_INTERVAL_LIST}
                disabled={isDisabledUpdate || !hasTariffUpdateRight}
                name="interval"
                onChange={onChange}
                currentValue={fields.interval}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={9}>
              <Typography className={styles.label}>
                {t('tariffs.precision')}
              </Typography>
              <Typography className={styles.subLabel}>
                {t('tariffs.precisionInfo')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ItemSelectors
                selectorsType="secondary"
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={TARIFF_INTERVAL_LIST}
                disabled={isDisabledUpdate || !hasTariffUpdateRight}
                name="precision"
                onChange={onChange}
                currentValue={fields.precision}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={10}>
              <Typography className={styles.label}>
                {t('tariffs.defInterval')}
              </Typography>
              <Typography className={styles.subLabel}>
                {t('tariffs.defIntervalInfo')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <CustomTextInput
                className={styles.inputItem}
                required
                error={errors.roundIgnore}
                name="roundIgnore"
                type="secondary"
                value={fields.roundIgnore}
                disabled={isDisabledUpdate || !hasTariffUpdateRight}
                autoComplete="off"
                inputProps={{
                  type: 'number',
                  min: 0,
                }}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={8}>
              <Typography className={styles.label}>
                {t('tariffs.typeSelect')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <ItemSelectors
                selectorsType="secondary"
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={TARIFF_TYPE_LIST}
                disabled={isDisabledUpdate || !hasTariffUpdateRight}
                name="type"
                onChange={handleChangeType}
                currentValue={fields.type}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2} className={styles.pricesContainer}>
            <Grid item xs={12}>
              <TariffPrices
                errors={errors}
                disabled={isDisabledUpdate || !hasTariffUpdateRight}
                type={fields.type}
                interval={fields.interval}
                prices={fields.prices}
                onChange={handlePricesChange}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <ItemSelectors
                selectorsType="secondary"
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={availableStatuses}
                name="status"
                disabled={!hasTariffUpdateRight}
                onChange={onChange}
                currentValue={fields.status}
              />
            </Grid>
            {hasTariffUpdateRight && (
              <Grid item xs={3}>
                <CustomBtn
                  className={styles.saveBtn}
                  btnType="primaryBlue"
                  type="submit"
                  disabled={isSaveLoading}
                >
                  {t('tariffs.save')}
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

function mapStateToProps(state) {
  const { tariffs } = state || {};
  const { oneTariff: oneTariffState, setTariff: setTariffState } = tariffs || {};

  return {
    oneTariffState,
    setTariffState,
  };
}

const ConnectedOneTariffPage = connect(
  mapStateToProps,
  {
    getOneTariffReq: getOneTariffAction,
    setTariffReq: setTariffAction,
  },
)(OneTariffPage);

export default ConnectedOneTariffPage;
