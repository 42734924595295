import React from 'react';

function BarrierIcon({
  size = 24,
  stroke = '#1D3262',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 10.25V20H8V10.25C8 9.00736 6.99264 8 5.75 8C4.50736 8 3.5 9.00736 3.5 10.25Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.9999 13.9999L11.0221 12.4877M11.0272 8.01553L14.1631 6.45204M11.0272 8.01553L11.0221 12.4877M11.0272 8.01553L7.88686 9.58125M14.1631 6.45204L14.1544 10.926M14.1631 6.45204L17.293 4.89191M11.0221 12.4877L14.1544 10.926M14.1544 10.926L17.2892 9.36302M17.2892 9.36302L20.8664 7.57949L19.0816 3.99975L17.293 4.89191M17.2892 9.36302L17.293 4.89191"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BarrierIcon;
