import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {},
  summary: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gateName: {
    fontSize: 18,
    fontWeight: 700,
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& span': {
      fontWeight: 500,
      fontSize: 14,
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsText: {
    '& span': {
      fontWeight: 700,
    },
  },
  lineText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
  },
  selectorsForm: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  btn: {
    width: '100%',
    height: 48,
  },
  alert: {
    fontSize: 14,
    '& span': {
      fontWeight: 700,
    }
  },
  conditionalContainer: {
    borderLeft:  '2px solid #F6F7FB',
    marginLeft: 12,
    paddingTop: 12,
    paddingLeft: 12,
  }
}));


export default {
  useStyles,
};
