import React from 'react';

function BalanceActiveIcon({
  fill = 'white',
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 17V19C19.5 20.1 18.6 20.5 17.5 20.5H4.5C3.39 20.5 2.5 20.1 2.5 19V5C2.5 3.9 3.39 3.5 4.5 3.5H17.5C18.6 3.5 19.5 3.9 19.5 5V7H11C9.89 7 10 6.9 10 8V16C10 17.1 9.89 17 11 17H19.5ZM11 16H20.5V8H11V16ZM15 13.5C14.17 13.5 13.5 12.83 13.5 12C13.5 11.17 14.17 10.5 15 10.5C15.83 10.5 16.5 11.17 16.5 12C16.5 12.83 15.83 13.5 15 13.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default BalanceActiveIcon;
