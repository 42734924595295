import { callAPI } from '@parkly/shared/api';

export function validGetContractor(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    name,
    shortName,
    address,
    taxNumber,
    kpp,
    ogrn,
    dateOgrn,
    opf,
    onFace: {
      position,
      fullName,
    },
  } = resultData;

  const {
    postcode = '',
    region = '',
    street = '',
    house = '',
  } = address || {};

  return {
    name,
    shortName,
    address: {
      postcode,
      region,
      street,
      house,
    },
    taxNumber,
    kpp,
    ogrn,
    dateOgrn,
    opf,
    onFace: {
      position,
      fullName,
    },
  };
}

export async function getContractorULByInn({ inn }) {
  return callAPI(`contractor/ul/${inn}`)
    .then((res) => validGetContractor({
      res,
    }));
}

export async function getContractorFLByInn({ inn }) {
  return callAPI(`contractor/fl/${inn}`)
    .then((res) => validGetContractor({
      res,
    }));
}
