export function updateRehydratedPersisit(storeState, key) {
  const state = storeState || {};

  switch (key) {
  case 'auth':
    return {
      ...state,
      loading: false,
    };

  default:
    return state;
  }
}

export default {
  updateRehydratedPersisit,
};
