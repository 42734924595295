import { getCustomersSelect } from 'api/customers';
import { GET_CUSTOMERS_SELECT } from './types';

export function getCustomersSelectAction(param) {
  function getCustomersSelectRequest(payload) {
    return { type: GET_CUSTOMERS_SELECT, payload };
  }

  return (dispatch) => {
    dispatch(getCustomersSelectRequest({
      loading: true,
    }));

    getCustomersSelect(param).then(
      (data) => {
        dispatch(getCustomersSelectRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getCustomersSelectRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export default {

};
