import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import Pagination from '@material-ui/lab/Pagination';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import { useModal } from '@parkly/shared/helpers';
import SessionsTable from 'components/templates/SessionsTable';
import ChangeSessionTariffPlanModalContent from 'components/organisms/ChangeSessionTariffPlanModalContent';
import SessionsCards from 'components/templates/SessionsCards';
import { RIGHT_KEYS, SESSION_STATUSES } from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useChangeBulkSessionStatusMutation, useChangeSessionStatusMutation } from 'api/query/sessions';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Hidden from '@material-ui/core/Hidden';
import { useStyles } from './styles';

function SessionsContent({
  facilityId,
  isLoading = false,
  sessionsData = [],
  sessionsMeta,
  showPagination = false,
  onPageChange = () => {},
  hideCustomer = false,
  hideCheckbox = false,
  hidePhotos = false,
}) {
  const styles = useStyles();

  const [changeTariffPlanParams, setChangeTariffPlanParams] = useState({});
  const [stopSessionParams, setStopSessionParams] = useState(null);
  const [cancelSessionParams, setCancelSessionParams] = useState(null);

  const queryClient = useQueryClient();

  const changeSessionStatusMutation = useChangeSessionStatusMutation({
    onSuccess({ success }) {
      if (success) {
        toast.success('Выполнено');
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        setStopSessionParams(null);
        setCancelSessionParams(null);
      }
    },
  });

  const changeBulkSessionStatusMutation = useChangeBulkSessionStatusMutation({
    onSuccess({ success }) {
      if (success) {
        toast.success('Выполнено');
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
      }
    },
  });

  const hasCustomerUpdateRight = useCheckFacilityRights(RIGHT_KEYS.customerUpdate, facilityId);

  const openStopSessionDialog = useCallback((stopParams) => {
    setStopSessionParams(stopParams);
  }, []);

  const handleOnStopSession = useCallback(() => {
    changeSessionStatusMutation.mutate({ ...stopSessionParams, status: SESSION_STATUSES.stopped });
  }, [changeSessionStatusMutation, stopSessionParams]);

  const openCancelSessionDialog = useCallback((cancelParams) => {
    setCancelSessionParams(cancelParams);
  }, []);

  const handleOnCancelSession = useCallback(() => {
    changeSessionStatusMutation.mutate({ ...cancelSessionParams, status: SESSION_STATUSES.canceled });
  }, [cancelSessionParams, changeSessionStatusMutation]);

  const handleBulkStopSessions = useCallback((ids) => {
    changeBulkSessionStatusMutation.mutate({ ids, facilityId, status: SESSION_STATUSES.stopped });
  }, [changeBulkSessionStatusMutation, facilityId]);

  const handleBulkCancelSessions = useCallback((ids) => {
    changeBulkSessionStatusMutation.mutate({ ids, facilityId, status: SESSION_STATUSES.canceled });
  }, [changeBulkSessionStatusMutation, facilityId]);

  const [
    changeTariffPlanNode,
    onOpenChangeTariffPlanModal,
    onHideChangeTariffPlanModal,
  ] = useModal({
    content: (
      <ChangeSessionTariffPlanModalContent
        {...{
          ...changeTariffPlanParams,
          onClose: () => {
            onHideChangeTariffPlanModal();
          },
        }}
      />
    ),
  });

  const handleOnChangeTariffPlanSession = (changeTariffPlanModalParams) => {
    setChangeTariffPlanParams({
      ...changeTariffPlanModalParams,
    });
    onOpenChangeTariffPlanModal();
  };

  const {
    currentPage = 1,
    lastPage = 1,
  } = sessionsMeta || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
        {!isLoading && (
          <>
            <Hidden implementation="js" smUp>
              <SessionsCards
                facilityId={facilityId}
                hideCustomer={hideCustomer}
                hideBtn={!hasCustomerUpdateRight}
                hideCheckbox={hideCheckbox}
                sessionsData={sessionsData}
                onStop={openStopSessionDialog}
                onCancel={openCancelSessionDialog}
                onChangeTariffPlan={handleOnChangeTariffPlanSession}
              />
            </Hidden>
            <Hidden implementation="js" xsDown>
              <SessionsTable
                facilityId={facilityId}
                hideCustomer={hideCustomer}
                hideBtn={!hasCustomerUpdateRight}
                hideCheckbox={hideCheckbox}
                hidePhotos={hidePhotos}
                sessionsData={sessionsData}
                onStop={openStopSessionDialog}
                onCancel={openCancelSessionDialog}
                onChangeTariffPlan={handleOnChangeTariffPlanSession}
                onBulkStop={handleBulkStopSessions}
                onBulkCancel={handleBulkCancelSessions}
              />
            </Hidden>
          </>

        )}
        {!isLoading && lastPage > 1 && showPagination && (
          <div className={styles.paginationContainer}>
            <Pagination
              className={styles.pagination}
              name="page"
              page={currentPage}
              onChange={onPageChange}
              count={lastPage}
              color="primary"
            />
          </div>
        )}
      </Grid>
      {changeTariffPlanNode}
      <CustomDialog
        open={!!stopSessionParams}
        handleAgree={handleOnStopSession}
        handleClose={() => setStopSessionParams(null)}
        dialogTitle="Вы уверены, что хотите остановить сессию?"
        dialogContent="Имеет смысл использовать, если пользователь покинул парковку."
        agreeTitle="Остановить"
        disagreeTitle="Отмена"
      />
      <CustomDialog
        open={!!cancelSessionParams}
        handleAgree={handleOnCancelSession}
        handleClose={() => setCancelSessionParams(null)}
        dialogTitle="Вы уверены, что хотите отменить сессию?"
        dialogContent="Данная сессия будет помечена как отмененная и не будет учитываться в статистике и расчетах. Имеет смысл использовать, если сессия была создана по ошибке."
        agreeTitle="Да, отменить"
        disagreeTitle="Нет"
      />
    </Grid>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedSessionsContent = connect(
  mapStateToProps,
  {},
)(SessionsContent);

export default ConnectedSessionsContent;
