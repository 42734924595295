import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useFormFields } from '@parkly/shared/helpers';

import { getFacilityAreasAction, getFacilityGatesAction, setFacilityIntegrationFlowAction } from 'actions/facilities';
import { GATE_DIRECTIONS, GATE_DIRECTIONS_LABEL, GATE_TYPES } from 'config/constants';

import { useStyles } from './styles';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

const TYPES = [
  { id: 'gate', title: 'Барьер' },
  { id: 'state', title: 'Зона' },
];

const STATE_OPTIONS = [
  { id: 'init', title: 'Вне парковки' },
  { id: 'in_area', title: 'На территории' },
  { id: 'in_parking', title: 'На парковке' },
];

const GATE = 'gate';
const STATE = 'state';

function getCurrentGates({
  facilityId,
  allFacilityGatesState,
}) {
  const {
    loading: isLoading,
    list: gatesList,
    error: allGatesError,
  } = allFacilityGatesState || {};

  const currentStrParam = JSON.stringify({
    id: facilityId,
  });

  const currentGates = gatesList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    currentGates,
    allGatesError,
  };
}

function getCurrentAreas({
  facilityId,
  oneFacilityAreasState,
}) {
  const {
    loading: isLoading,
    list: areasList,
    error: allAreasError,
  } = oneFacilityAreasState || {};

  const currentStrParam = JSON.stringify({
    id: facilityId,
  });

  const currentAreas = areasList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    currentAreas,
    allAreasError,
  };
}

function CreateEdgeModalContent({
  id,
  facilityId,
  onClose = () => {},

  setFacilityIntegrationFlowReg,
  setFacilityIntegrationFlowState,

  allFacilityGatesState,
  getFacilityGatesReg,

  oneFacilityAreasState,
  getFacilityAreasReg,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      fromType: STATE,
      fromValue: '',
      toType: GATE,
      toValue: '',
      title: '',
      subtitle: '',
    },
  });

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      currentGates,
      allGatesError,
      isLoading,
    } = getCurrentGates({
      facilityId,
      allFacilityGatesState,
    });

    if (isLoading || currentGates || allGatesError) {
      return;
    }

    getFacilityGatesReg({ id: facilityId });
  }, [allFacilityGatesState, facilityId, getFacilityGatesReg]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      currentAreas,
      allAreasError,
      isLoading,
    } = getCurrentAreas({
      facilityId,
      oneFacilityAreasState,
    });

    if (isLoading || currentAreas || allAreasError) {
      return;
    }

    getFacilityAreasReg({ id: facilityId });
  }, [oneFacilityAreasState, facilityId, getFacilityAreasReg]);

  const {
    currentGates,
    isLoading: isGatesLoading,
  } = getCurrentGates({
    facilityId,
    allFacilityGatesState,
  });

  const gateItems = useMemo(() => {
    const { data: gatesData } = currentGates || {};

    const result = [];

    (gatesData || []).forEach(({ id: gateId, name, direction, type }) => {
      if (direction === GATE_DIRECTIONS.both) {
        result.push({
          id: `${gateId}_enter`,
          title: `${name} (${GATE_DIRECTIONS_LABEL[type === GATE_TYPES.door ? 'door_enter' : 'enter']})`,
        });
        result.push({
          id: `${gateId}_exit`,
          title: `${name} (${GATE_DIRECTIONS_LABEL[type === GATE_TYPES.door ? 'door_exit' : 'exit']})`,
        });
      } else {
        result.push({
          id: `${gateId}_${direction}`,
          title: `${name} (${GATE_DIRECTIONS_LABEL[type === GATE_TYPES.door ? `door_${direction}` : direction]})`,
        });
      }
    });

    return result;
  }, [currentGates]);

  const {
    currentAreas,
    isLoading: isAreasLoading,
  } = getCurrentAreas({
    facilityId,
    oneFacilityAreasState,
  });

  const areaItems = useMemo(() => {
    const { data: areasData } = currentAreas || {};

    const result = (areasData || []).map(({ slug, name }) => ({
      id: slug,
      title: name,
    }));

    result.push({
      id: 'init',
      title: 'Вне парковки',
    });

    return result;
  }, [currentAreas]);

  const fromOptions = useMemo(() => {
    if (fields.fromType === GATE) {
      return gateItems;
    }

    if (fields.fromType === STATE) {
      return areaItems;
    }

    return [];
  }, [fields.fromType, gateItems, areaItems]);

  const toOptions = useMemo(() => {
    if (fields.toType === GATE) {
      return gateItems;
    }

    if (fields.toType === STATE) {
      return areaItems;
    }

    return [];
  }, [fields.toType, gateItems, areaItems]);

  const handleChangeTypes = (event) => {
    const { target } = event || {};
    const {
      name,
      value,
    } = target || {};

    if (name === 'fromType') {
      updateState({
        fromType: value,
        toType: value === GATE ? STATE : GATE,
        fromValue: '',
        toValue: '',
        title: '',
        subtitle: '',
      });
      return;
    }

    if (name === 'toType') {
      updateState({
        toType: value,
        fromType: value === GATE ? STATE : GATE,
        fromValue: '',
        toValue: '',
        title: '',
        subtitle: '',
      });
      return;
    }
  };

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const isToValueValid = fields.toValue.length > 0 || fields.toValue > 0;
    const isFromValueValid = fields.fromValue.length > 0 || fields.fromValue > 0;
    const isTitleValid = fields.title.length > 0 || fields.fromType === GATE;

    if (!isToValueValid || !isFromValueValid || !isTitleValid) {
      setErrors({
        toValue: !isToValueValid,
        fromValue: !isFromValueValid,
        title: !isTitleValid,
      });
      return;
    }

    setFacilityIntegrationFlowReg({
      facilityId,
      fromType: fields.fromType,
      fromValue: fields.fromValue,
      toType: fields.toType,
      toValue: fields.toValue,
      title: fields.title,
      subtitle: fields.subtitle,
    });

    onClose();
  }, [facilityId, fields, onClose, setErrors, setFacilityIntegrationFlowReg]);

  if (isAreasLoading || isGatesLoading) {
    return (
      <CustomModalContentWrapper
        title="Добавление связи"
        onClose={onClose}
        paperClassname={styles.paper}
        bodyClassname={styles.modalBody}
      >
        <CircularIndeterminate />
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Добавление связи"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <ItemSelectors
              className={styles.selectorsForm}
              name="fromType"
              error={errors.fromType}
              items={TYPES}
              currentValue={fields.fromType}
              onChange={handleChangeTypes}
              required
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              name="fromValue"
              items={fromOptions}
              label={fields.fromType === GATE ? t('integrations.addEdge.device') : t('integrations.addEdge.state')}
              error={errors.fromValue}
              currentValue={fields.fromValue}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {fields.fromType === GATE ? 'дает доступ к:' : 'содержит:'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <ItemSelectors
              className={styles.selectorsForm}
              name="toType"
              error={errors.toType}
              items={TYPES}
              currentValue={fields.toType}
              onChange={handleChangeTypes}
              required
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              name="toValue"
              label={fields.toType === GATE ? t('integrations.addEdge.device') : t('integrations.addEdge.state')}
              error={errors.toValue}
              items={toOptions}
              currentValue={fields.toValue}
              onChange={onChange}
              required
            />
          </Grid>

          {fields.fromType === STATE && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  className={styles.inputItem}
                  label={t('integrations.addEdge.title')}
                  name="title"
                  error={errors.title}
                  value={fields.title}
                  onChange={onChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  className={styles.inputItem}
                  label={t('integrations.addEdge.subtitle')}
                  name="subtitle"
                  error={errors.subtitle}
                  value={fields.subtitle}
                  onChange={onChange}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <CustomBtn
              btnType="primaryBlue"
              type="submit"
              className={styles.submitBtn}
            >
              Добавить
            </CustomBtn>
          </Grid>
        </Grid>
      </form>
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { facilities } = state || {};
  const {
    setFacilityIntegrationFlow,
    allFacilityGates,
    oneFacilityAreas,
  } = facilities || {};

  return {
    allFacilityGatesState: allFacilityGates,
    oneFacilityAreasState: oneFacilityAreas,
    setFacilityIntegrationFlowState: setFacilityIntegrationFlow,
  };
}

const ConnectedCreateEdgeModalContent = connect(
  mapStateToProps,
  {
    setFacilityIntegrationFlowReg: setFacilityIntegrationFlowAction,
    getFacilityGatesReg: getFacilityGatesAction,
    getFacilityAreasReg: getFacilityAreasAction,
  },
)(CreateEdgeModalContent);

export default ConnectedCreateEdgeModalContent;
