import React, { useCallback, useEffect, useState } from 'react';
import { Map, Placemark, SearchControl, withYMaps, YMaps } from 'react-yandex-maps';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useFormFields } from '@parkly/shared/helpers';

import { useStyles } from './styles';

function YandexMapSelectCoordsModalContent({
  initCoords = null,
  initAddress = '',
  onClose = () => {},
  onSubmit = () => {},
  defaultCenter,
  ymaps,
}) {
  const styles = useStyles();
  const [coords, setCoords] = useState(initCoords);
  const [country, setCountry] = useState('RU');
  const [searchControl, setSearchControl] = useState(null);

  const [fields, errors, onChange, setErrors, setValues] = useFormFields({
    initValues: {
      address: initAddress || '',
    },
  });

  useEffect(() => {
    if (ymaps && searchControl) {
      searchControl.events.add('resultselect', (event) => {
        const index = event.get('index');

        searchControl.getResult(index).then((res) => {
          setCoords(res.geometry.getCoordinates());
          setValues({ address: res.getAddressLine() });
          setCountry(res.getCountryCode());
        }).catch((e) => console.log(e));
      });
    }

    return () => {
      if (searchControl) {
        searchControl.events.remove('resultselect');
      }
    };
  }, [ymaps, searchControl, setValues]);

  const setSearchControlRef = useCallback((instance) => {
    setSearchControl(instance);
  }, []);

  const handleMapClick = useCallback((event) => {
    const coordinates = event.get('coords');
    setCoords(coordinates);
    ymaps.geocode(coordinates).then((res) => {
      setValues({ address: res.geoObjects.get(0).getAddressLine() });
      setCountry(res.geoObjects.get(0).getCountryCode());
    }).catch((e) => console.log(e));
  }, [setValues, ymaps]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      coords,
      address: fields.address.replace('Россия, ', ''),
      country,
    });
  }, [onSubmit, coords, fields.address, country]);

  return (
    <CustomModalContentWrapper
      title="Адрес"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Map
          className={styles.map}
          defaultState={{
            center: defaultCenter || [55.751290, 37.61877],
            zoom: defaultCenter ? 15 : 10,
            controls: ['zoomControl'],
          }}
          options={{
            suppressMapOpenBlock: true,
            yandexMapDisablePoiInteractivity: true,
          }}
          modules={['control.ZoomControl', 'control.SearchControl']}
          onClick={handleMapClick}
        >
          <SearchControl
            instanceRef={setSearchControlRef}
            options={{
              float: 'right',
              noPlacemark: true,
            }}
          />
          {coords && (
            <Placemark geometry={coords} />
          )}
        </Map>
        <CustomTextInput
          label="Адрес"
          error={errors.address}
          value={fields.address}
          name="address"
          onChange={onChange}
        />
        <CustomBtn
          className={styles.submitBtn}
          btnType="primaryBlue"
          onClick={handleSubmit}
        >
          Готово
        </CustomBtn>
      </div>
    </CustomModalContentWrapper>
  );
}

const WithYMapsYandexMapSelectCoordsModalContent = withYMaps(
  YandexMapSelectCoordsModalContent,
  true,
  ['geocode'],
);

function WrapToYMaps(props) {
  return (
    <YMaps
      query={{
        apikey: process.env.REACT_APP_YANDEX_MAP_KEY,
      }}
    >
      <WithYMapsYandexMapSelectCoordsModalContent
        {...props}
      />
    </YMaps>
  );
}

export default WrapToYMaps;
