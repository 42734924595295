import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {

  },
  modalBody: {

  },
  container: {

  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center'
  },
  btn: {
    width: '100%',
    height: 48,
  },
}));

export default {
  useStyles,
};
