import { createQuery } from 'react-query-kit';
import { getActivePassSettings, getPasses, getPassSettingPlaces } from 'api/passes';

export const useActivePassTypes = createQuery({
  primaryKey: 'pass-types',
  queryFn: ({ queryKey: [_, params] }) => {
    return getActivePassSettings(params);
  },
  retry: false,
});

export const useActivePassTypePlaces = createQuery({
  primaryKey: 'active-pass-type-places',
  queryFn: ({ queryKey: [_, params] }) => {
    return getPassSettingPlaces(params);
  },
  retry: false,
});

export const usePasses = createQuery({
  primaryKey: 'passes',
  queryFn: ({ queryKey: [_, params] }) => {
    return getPasses(params);
  },
  retry: false,
});
