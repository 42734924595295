import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  controllerLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  controllerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));

export default {
  useStyles,
};
