import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

import { callAPI } from '@parkly/shared/api';

async function validStats({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  return curData;
}

export async function getStats({
  facilityId,
  period,
  type,
}) {
  return callAPI(
    `facilities/${facilityId}/stats`,
    'POST',
    {
      period,
      type,
    },
  )
    .then((res) => validStats({
      res,
    }));
}

export async function getAnalytic({
  mode,
  type,
  start,
  end,
  facilityIds,
  tariffPlanIds,
  gateIds,
  accessStatuses,
  sessionDurationTo,
  sessionDurationFrom,
}) {
  const query = new URLSearchParams(pickBy({
    mode,
    type,
    start: start && moment(start).toISOString(true),
    end: end && moment(end).toISOString(true),
    facilityIds: isEmpty(facilityIds) ? undefined : facilityIds.join(','),
    tariffPlans: isEmpty(tariffPlanIds) ? undefined : tariffPlanIds.join(','),
    gates: isEmpty(gateIds) ? undefined : gateIds.join(','),
    accessStatuses: isEmpty(accessStatuses) ? undefined : accessStatuses.join(','),
    sessionDurationTo: sessionDurationTo ? parseInt(sessionDurationTo, 10) : undefined,
    sessionDurationFrom: sessionDurationFrom ? parseInt(sessionDurationFrom, 10) : undefined,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `analytics?${queryParams}`;

  return callAPI(
    endpoint,
    'GET',
  )
    .then((res) => validStats({
      res,
    }));
}
