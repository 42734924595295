import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import TestNewControllerModalContent from 'components/organisms/TestNewControllerModalContent';
import {
  checkIsPhoneValid,
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  useFormFields,
  useModal,
} from '@parkly/shared/helpers';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import { getGSMControllerSettingsAction, setControllerAction } from 'actions/controllers';

import { CONTROLLER_TYPES } from 'config/constants';
import { useStyles } from './styles';

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function getCurrentGSMSettings({
  facilityId,
  allGSMControllerSettingsState,
}) {
  const {
    loading: isLoading,
    list,
    error: gsmSettingsError,
  } = allGSMControllerSettingsState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const current = list.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: gsmSettingsData,
  } = current || {};

  return {
    isGSMSettingsLoading: isLoading,
    gsmSettingsData,
    gsmSettingsError,
  };
}

function MakeGSMControllerModalContent({
  facilityId,
  id,
  onClose = () => {},

  setControllerState,
  setControllerReg,

  allControllersState,

  allGSMControllerSettingsState,
  getGSMControllerSettingsReg,
}) {
  const styles = useStyles();

  const [step, setStep] = useState(0);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      phone: '+7',
      name: '',
    },
    formatValueFunc: formatValue,
  });

  const [
    testNewControllerModal,
    onOpenTestNewControllerModal,
    onHideTestNewControllerModal,
  ] = useModal({
    content: (
      <TestNewControllerModalContent
        facilityId={facilityId}
        type={CONTROLLER_TYPES.gsm}
        options={{
          phoneNumber: cleanPhone(fields.phone),
          // TODO
          atsNumber: formatPhoneNumber('+74991234566'),
        }}
        onClose={() => {
          onHideTestNewControllerModal();
        }}
      />
    ),
  });

  const handleNextStep = useCallback(() => {
    const trimmedPhone = cleanPhone(fields.phone);
    const isPhoneValid = checkIsPhoneValid(trimmedPhone);
    const isNameValid = fields.name.length > 0;

    if (!isPhoneValid || !isNameValid) {
      setErrors({
        phone: !isPhoneValid,
        name: !isNameValid,
      });
      return;
    }

    setStep(1);
  }, [fields, setErrors]);

  const handleSetController = useCallback(() => {
    if (step !== 1) {
      return;
    }

    setControllerReg({
      facilityId,
      id,
      name: fields.name,
      type: CONTROLLER_TYPES.gsm,
      settings: {
        phoneNumber: cleanPhone(fields.phone),
      },
    });

    onClose();
  }, [facilityId, fields, id, onClose, setControllerReg, step]);

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        phone: formatPhoneNumber(controller.settings.phoneNumber || '+7'),
        name: controller.name || '',
      });
    }
  }, [id, facilityId, updateState, allControllersState]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      isGSMSettingsLoading,
      gsmSettingsData,
      gsmSettingsError,
    } = getCurrentGSMSettings({
      facilityId,
      allGSMControllerSettingsState,
    });

    if (isGSMSettingsLoading || gsmSettingsData || gsmSettingsError) {
      return;
    }

    getGSMControllerSettingsReg({ facilityId });
  }, [allGSMControllerSettingsState, facilityId, getGSMControllerSettingsReg]);

  const {
    gsmSettingsData,
  } = getCurrentGSMSettings({
    facilityId,
    allGSMControllerSettingsState,
  });

  return (
    <CustomModalContentWrapper
      title="Подключение GSM-реле"
      onClose={onClose}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Укажите номер телефона установленного в устройство
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.name}
              name="name"
              label="Название"
              value={fields.name}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.phone}
              name="phone"
              inputProps={{
                maxLength: MAX_PHONE_NUMBER_LENGTH,
              }}
              label="Номер телефона"
              value={fields.phone}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleNextStep}
                >
                  Далее
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Добавьте в реле номер телефона системы для управления доступом
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container item justifyContent="center">
              <Typography variant="h4">
                {gsmSettingsData ? formatPhoneNumber(gsmSettingsData.lineNumber) : 'Что-то пошло нет так!'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="secondary"
                  className={styles.btn}
                  onClick={onOpenTestNewControllerModal}
                >
                  Протестировать
                </CustomBtn>
              </Grid>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleSetController}
                >
                  Готово
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {testNewControllerModal}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController,
    allControllers,
    allGSMControllerSettings,
  } = controllers || {};

  return {
    setControllerState: setController,
    allControllersState: allControllers,
    allGSMControllerSettingsState: allGSMControllerSettings,
  };
}

const ConnectedMakeGSMControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
    getGSMControllerSettingsReg: getGSMControllerSettingsAction,
  },
)(MakeGSMControllerModalContent);

export default ConnectedMakeGSMControllerModalContent;
