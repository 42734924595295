import { updateStoreReqRes } from '@parkly/shared/helpers';
import {
  CLEAR_CONTROLLERS_CACHE,
  GET_ALL_CONDITIONAL_CONTROLLERS,
  GET_ALL_CONTROLLERS,
  GET_CAMERA_UUIDS,
  GET_GSM_CONTROLLER_SETTINGS,
  REMOVE_CONTROLLER,
  SET_CONTROLLER,
} from 'actions/types';

const INITIAL_STATE = {
  allControllers: {
    loading: false,
    list: [],
  },
  allConditionalControllers: {
    loading: false,
    list: [],
  },
  setController: {
    loading: false,
  },
  removeController: {
    loading: false,
  },
  allGSMControllerSettings: {
    loading: false,
    list: [],
  },
  allCameraUUIDS: {
    loading: false,
    list: [],
  },
};

function getSetState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function controllers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_CONTROLLERS:
      return updateStoreReqRes(
        state,
        'allControllers',
        action,
      );

    case GET_ALL_CONDITIONAL_CONTROLLERS:
      return updateStoreReqRes(
        state,
        'allConditionalControllers',
        action,
      );

    case SET_CONTROLLER:
      return {
        ...state,
        setController: getSetState({
          prevState: state.setController,
          payload: action.payload,
        }),
      };

    case REMOVE_CONTROLLER:
      return {
        ...state,
        removeController: getSetState({
          prevState: state.removeController,
          payload: action.payload,
        }),
      };

    case GET_GSM_CONTROLLER_SETTINGS:
      return updateStoreReqRes(
        state,
        'allGSMControllerSettings',
        action,
      );

    case GET_CAMERA_UUIDS: {
      return updateStoreReqRes(
        state,
        'allCameraUUIDS',
        action,
      );
    }

    case CLEAR_CONTROLLERS_CACHE:
      return {
        ...state,
        allControllers: INITIAL_STATE.allControllers,
        setController: INITIAL_STATE.setController,
        removeController: INITIAL_STATE.removeController,
        allGSMControllerSettings: INITIAL_STATE.allGSMControllerSettings,
        allCameraUUIDS: INITIAL_STATE.allCameraUUIDS,
      };

    default:
      return state;
  }
}
