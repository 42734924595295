import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { getActiveTariffPlansAction } from 'actions/tariffs';
import { useStyles } from './styles';
import { CircularProgress, Grid } from '@material-ui/core';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { useChangeSessionTariffPlanMutation } from 'api/query/sessions';
import { useQueryClient } from '@tanstack/react-query';


function getCurrentActiveTariffPlans({
  activeTariffPlansState,
  facilityId,
}) {
  const activeTariffPlansList = (activeTariffPlansState || {}).list || [];
  const preparedReqParam = { facilityId };
  const currentStrParam = JSON.stringify(preparedReqParam);
  const activeTariffPlans = activeTariffPlansList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    activeTariffPlans,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function ChangeSessionTariffPlanModalContent({
  id: sessionId,
  facilityId,
  tariffPlanId,
  onClose = () => {},

  activeTariffPlansState,
  getActiveTariffPlansReg,
}) {
  const styles = useStyles();

  const [selTariffPlan, setSelTariffPlan] = useState(tariffPlanId);

  const queryClient = useQueryClient();
  const mutation = useChangeSessionTariffPlanMutation({
    onSuccess({ success }) {
      if (success) {
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });

        onClose();
      }
    },
  });

  const { isLoading } = mutation || {};

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      activeTariffPlans,
    } = getCurrentActiveTariffPlans({
      activeTariffPlansState,
      facilityId,
    });

    const {
      loading,
    } = activeTariffPlansState || {};

    if (!activeTariffPlans && !loading) {
      getActiveTariffPlansReg({
        facilityId,
      });
    }
  }, [facilityId, activeTariffPlansState, getActiveTariffPlansReg]);

  const onChange = (event) => {
    const { target } = event || {};
    const {
      value,
    } = target || {};

    setSelTariffPlan(value);
  };

  const handleSave = () => {
    mutation.mutate({
      id: sessionId,
      facilityId,
      tariffPlanId: selTariffPlan,
    });
  };

  const {
    activeTariffPlans,
  } = getCurrentActiveTariffPlans({
    activeTariffPlansState,
    facilityId,
  });

  const { data: activeTariffPlansData } = activeTariffPlans || {};

  const tariffPlansSelectData = useMemo(() => ((activeTariffPlansData || [])
    .map(({ id, name }) => ({
      id,
      title: name,
    }))
  ), [activeTariffPlansData]);

  if (
    (activeTariffPlansState || {}).loading
  ) {
    return (
      <CustomModalContentWrapper
        title="Смена тарифного плана"
        onClose={onClose}
        paperClassname={styles.paper}
        bodyClassname={styles.modalBody}
      >
        <CircularIndeterminate />
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Смена тарифного плана"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Grid container item spacing={2}>
          <Grid item xs={7}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              className={styles.selector}
              items={tariffPlansSelectData}
              label="Новый тарифный план"
              name="selTariffPlan"
              onChange={onChange}
              currentValue={selTariffPlan}
            />
          </Grid>
          <Grid item xs={5}>
            <CustomBtn
              className={styles.saveBtn}
              btnType="primaryGreen"
              onClick={handleSave}
              disabled={isLoading}
            >
              Сохранить
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      </div>
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { tariffs } = state || {};
  const { activeTariffPlans } = tariffs || {};

  return {
    activeTariffPlansState: activeTariffPlans,
  };
}

const ConnectedChangeSessionTariffPlanModalContent = connect(
  mapStateToProps,
  {
    getActiveTariffPlansReg: getActiveTariffPlansAction,
  },
  null,
  { forwardRef: true },
)(ChangeSessionTariffPlanModalContent);

export default ConnectedChangeSessionTariffPlanModalContent;
