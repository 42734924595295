import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  item: {
    cursor: 'pointer',
    minWidth: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
    marginBottom: 2,
    borderRadius: 8,
    padding: 8,
    border: '1px solid rgba(0, 0, 0, .125)',
  },
  itemSelected: {
    border: '2px solid #004BF6',
  },
  itemDisabled: {
    backgroundColor: '#F6F7FB',
    cursor: 'unset',
  }
}));

export default {
  useStyles,
};
