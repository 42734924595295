import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {

  },
  modalBody: {

  },
  container: {

  },
  inputItem: {
    width: '100%',
    height: 48,
    margin: 0,
    '& .MuiFilledInput-root': {
      height: 48,
    }
  },
  tariffPriceStr: {
    fontWeight: 700,
  },
  saveBtn: {
    width: '100%',
    height: 48,
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
}));

export default {
  useStyles,
};
