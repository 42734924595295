import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import PassesSettingsTable from 'components/templates/PassesSettingsTable';

import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { getAllPassesSettingsAction } from 'actions/passes';

import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useStyles } from './styles';

function getInitParams() {
  return {
    page: 1,
  };
}

function prepareParam({
  params,
  facilityId,
}) {
  const {
    page,
  } = params || {};

  const reqParam = {
    page,
    facilityId,
  };

  return reqParam;
}

function getCurrentPasses({
  allPassesSettingsState,
  params,
  facilityId,
}) {
  const passesList = (allPassesSettingsState || {}).list || [];
  const preparedReqParam = prepareParam({
    params,
    facilityId,
  });
  const currentStrParam = JSON.stringify(preparedReqParam);
  const passes = passesList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    passes,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function requestAllPassesSettings({
  reqParam,
  getAllPassesSettingsReq = () => {},
}) {
  throttle(
    getAllPassesSettingsReq,
    300,
  )(reqParam);
}

function AllPassesSettingsPage({
  match,

  getAllPassesSettingsReq,
  allPassesSettingsState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch();

  const [params, setParams] = useState(getInitParams());
  const [prevParams, setPrevParams] = useState('');

  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const hasPassesSettingsUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passTypeUpdate, currentFacilityId);

  useEffect(() => {
    if (!currentFacilityId) {
      return;
    }

    const {
      tariffs,
      reqParam,
      currentStrParam,
    } = getCurrentPasses({
      allPassesSettingsState,
      params,
      facilityId: currentFacilityId,
    });

    if (currentStrParam !== prevParams) {
      if (!tariffs) {
        requestAllPassesSettings({
          reqParam,
          getAllPassesSettingsReq,
        });

        setPrevParams(currentStrParam);
      }
    }
  }, [params, currentFacilityId, allPassesSettingsState, prevParams, getAllPassesSettingsReq]);

  const {
    passes,
  } = getCurrentPasses({
    allPassesSettingsState,
    params,
    facilityId: currentFacilityId,
  });

  const {
    passesData,
    passesMeta,
  } = (passes || {}).data || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = passesMeta || {};

  const isLoading = (allPassesSettingsState || {}).loading;

  const handleParamChange = useCallback((paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  }, [params]);

  const onPageChange = useCallback((_, pageNumber) => {
    handleParamChange('page', pageNumber);
  }, [handleParamChange]);

  return (
    <Container className={styles.container}>
      <HeaderWithBackBtn
        title="Типы абонементов"
        isBackBtn={false}
      />
      <div className={styles.pageBody}>
        <Grid container spacing={2}>
          {hasPassesSettingsUpdateRight && (
            <Grid container item spacing={2}>
              <Grid item>
                <CustomBtn
                  btnType="primaryGreen"
                  className={styles.addNewBtn}
                  onClick={() => history.push(PATH_PAGES.newPassSettings.replace(':facilityId', currentFacilityId))}
                >
                  Добавить тип абонемента
                </CustomBtn>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoading && (
              <CircularIndeterminate style={{ minHeight: 600 }} />
            )}
            {!isLoading && (
              <PassesSettingsTable
                passesData={passesData}
                facilityId={currentFacilityId}
              />
            )}
            {!isLoading && lastPage > 1 && (
              <div className={styles.paginationContainer}>
                <Pagination
                  className={styles.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  const { passes } = state || {};
  const { allPassesSettings } = passes || {};

  return {
    allPassesSettingsState: allPassesSettings,
  };
}

const ConnectedAllTariffsPage = connect(
  mapStateToProps,
  {
    getAllPassesSettingsReq: getAllPassesSettingsAction,
  },
)(AllPassesSettingsPage);

export default ConnectedAllTariffsPage;
