import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    backgroundColor: 'black',
    padding: (props) => (props.isExpanded ? 32 : 0),
    height: (props) => (props.isExpanded ? 'max(100vh - 48px, 100%)' : 0),

    [theme.breakpoints.down('sm')]: {
      position: 'unset !important',
      height: 'unset !important',
      maxWidth: 'unset !important',
      maxHeight: 'unset !important',
      marginTop: 16,
      width: 300,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      padding: '0px !important',
      backgroundColor: 'unset',
    },
  },
  videoContainer: {
    width: 'calc(300px - 64px)',

    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      overflowY: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  unexpandBadge: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    zIndex: 1000,
    position: 'absolute',
    top: 0,
    left: (props) => (props.isExpanded ? '50%' : '-72px'),
    transform: (props) => (props.isExpanded ? 'translate(-50%, 0)' : 'translate(-72px, 0)'),
    backgroundColor: 'black',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 16,
    cursor: 'pointer',
    userSelect: 'none',
    '& p': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  expandBadge: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    zIndex: 1000,
    position: 'absolute',
    top: '-8px',
    right: '66px',
    backgroundColor: 'black',
    color: 'white',
    padding: '4px 8px',
    borderRadius: 16,
    cursor: 'pointer',
    userSelect: 'none',
    '& p': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default {
  useStyles,
};
