import { CLEAR_CONTRACTOR_CACHE, GET_CONTRACTOR_FL, GET_CONTRACTOR_UL } from '@parkly/shared/actions/types';

const INITIAL_STATE = {
  contractor: {
    loading: false,
    data: null,
  },
};

export default function contractor(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CONTRACTOR_FL:
      return {
        ...state,
        contractor: {
          ...state.contractor,
          ...action.payload,
        },
      };

    case GET_CONTRACTOR_UL:
      return {
        ...state,
        contractor: {
          ...state.contractor,
          ...action.payload,
        },
      };

    case CLEAR_CONTRACTOR_CACHE:
      return {
        ...state,
        contractor: INITIAL_STATE.contractor,
      };
    default:
      return state;
  }
}
