import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
  },
  selectForm: {
    height: 48,
    border: 0,
  },
  select: {
    height: 48,
    borderRadius: 6,
    backgroundColor: '#F6F7FB',
  },
}));

export default {
  useStyles,
};
