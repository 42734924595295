import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllOperators from 'components/pages/operators/AllOperators';
import OneOperator from 'components/pages/operators/OneOperator';
import OperatorActionsPage from 'components/pages/operators/OperatorActionsPage';
import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import { PATH_PAGES } from 'config/constants';

const propTypes = {};

function OperatorsPage() {
  useEffect(() => {
    moment.tz.setDefault();
  }, []);

  const urlActions = PATH_PAGES.operatorsAll;

  return (
    <ParentPageContainer isLoading={false}>
      <PrivateRoute exact path={PATH_PAGES.operatorsAll} component={AllOperators} />
      <PrivateRoute exact path={PATH_PAGES.operatorsActions} component={OperatorActionsPage} />
      <PrivateRoute exact path={PATH_PAGES.addNewOperator} component={OneOperator} />
      <PrivateRoute exact path={PATH_PAGES.oneOperator} component={OneOperator} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

OperatorsPage.propTypes = propTypes;

export default OperatorsPage;
