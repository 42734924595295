import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  videoContainer: {
    flexShrink: 0,
    position: 'relative',
    width: '100%',
    aspectRatio: 1.20,
    backgroundColor: props => props.active ? 'black' : 'grey',
  },
  video: {
    width: '100%',
    height: '100%',
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  stopBtn: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  fullscreenBtn: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}));

export default {
  useStyles,
};
