import React, { useMemo } from 'react';

import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';

import { useGatesBulk } from 'api/query/gates';

import { useStyles } from './styles';

export default function AnalyticGatesFilter({
  facilityIds,
  name,
  value,
  onChange,
}) {
  const styles = useStyles();

  const { data: gatesData } = useGatesBulk({
    variables: { facilityIds },
  });

  const gatesItems = useMemo(() => (gatesData || [])
    .sort(({ facilityId: a }, { facilityId: b }) => a - b)
    .map(({ id, name: gateName, facilityName }) => ({
      id,
      title: `${gateName} (${facilityName})`,
    })), [gatesData]);

  return (
    <MultiItemSelectors
      classNameForm={styles.selectForm}
      className={styles.select}
      name={name}
      currentValue={value}
      items={gatesItems}
      unselectText="Все проезды"
      onChange={onChange}
      fastSelectOne
      limitSelected={2}
    />
  );
}
