import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import Grid from '@material-ui/core/Grid';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { CircularProgress, InputAdornment, Tooltip } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useCreateWithdrawPaymentMutation } from 'api/query/accounts';
import { useStyles } from './styles';

function AccountWithdrawModalContent({
  balanceForWithdraw,
  onClose = () => {},
  onSuccess = () => {},
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const currency = t('others.currency');

  const [amount, setAmount] = useState(balanceForWithdraw || 0);
  const [isAmountError, setIsAmountError] = useState(false);
  const [step, setStep] = useState(1);

  const createWithdrawPaymentMutation = useCreateWithdrawPaymentMutation({
    onSuccess() {
      setStep(2);
      onSuccess();
    },
    onError() {
      setIsAmountError(true);
    },
  });

  const { isLoading: isCreateLoading } = createWithdrawPaymentMutation || {};

  const handleCreateWithdrawPayment = useCallback(() => {
    if (amount > (balanceForWithdraw || 0) || ((+amount || 0)) === 0) {
      setIsAmountError(true);
      return;
    }

    createWithdrawPaymentMutation.mutate({ amount });
  }, [amount, balanceForWithdraw, createWithdrawPaymentMutation]);

  const balanceForWithdrawStr = `${(+balanceForWithdraw || 0).toLocaleString()} ${currency}`;

  if (step === 2) {
    return (
      <CustomModalContentWrapper
        title="Вывод средств"
        onClose={onClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Запрос на вывод средств создан
            </Typography>
          </Grid>
        </Grid>
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Вывод средств"
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title="Часть средств заморожены пока ожидается завершение клиентских сессий и завершение банковских операций">
            <Typography className={styles.balanceStr}>
              Доступно для вывода:
              {' '}
              <span>{balanceForWithdrawStr}</span>
              <InfoOutlinedIcon fontSize="small" />
            </Typography>
          </Tooltip>
          {/* {balanceForWithdraw > 0 && ( */}
          {/*  <CustomLink */}
          {/*    onClick={() => { */}
          {/*      priceInputRef.current.focus(); */}
          {/*      setAmount(balanceForWithdraw); */}
          {/*    }} */}
          {/*  > */}
          {/*    Заполнить */}
          {/*  </CustomLink> */}
          {/* )} */}
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomTextInput
            // inputRef={priceInputRef}
            className={styles.addBalanceInput}
            type="secondary"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            error={isAmountError}
            inputProps={{
              min: 0,
              max: balanceForWithdraw || 0,
            }}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            InputProps={{
              type: 'number',
              endAdornment: <InputAdornment position="end">₽</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <CustomBtn
            className={styles.makeInvoiceBtn}
            btnType="primaryBlue"
            onClick={handleCreateWithdrawPayment}
            disabled={isCreateLoading}
          >
            Вывести средства
            {isCreateLoading && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={20}
                color="inherit"
              />
            )}
          </CustomBtn>
        </Grid>
      </Grid>
    </CustomModalContentWrapper>
  );
}

export default AccountWithdrawModalContent;
