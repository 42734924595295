import React from 'react';

function GateIcon({
  size = 24,
  stroke = '#1D3262',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.625 14.1333V10.9333H6.375V14.1333M17.625 14.1333H6.375M17.625 14.1333V17.3333M6.375 14.1333V17.3333M6.375 17.3333V20H3V8.89928C3 8.51294 3.22254 8.16117 3.57163 7.99567L11.5716 4.20308C11.8427 4.07455 12.1573 4.07455 12.4284 4.20308L20.4284 7.99567C20.7775 8.16117 21 8.51294 21 8.89928V20H17.625V17.3333M6.375 17.3333H17.625"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GateIcon;
