import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    paddingRight: 10,
  },
  selectedStr: {
    textTransform: 'initial',
    paddingLeft: 12,
    marginRight: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  item: {
    display: 'flex',
    paddingTop: '2px',
    paddingBottom: '2px',
    alignItems: 'center',
    '&.Mui-selected': {
      backgroundColor: 'white',
    },
  },
  itemStr: {
    paddingLeft: 12,
    marginLeft: 5,

    display: 'flex',
    alignItems: 'center',
  },
  circleIcon: {
    marginRight: 8,
  },
  unSelectStr: {
    color: theme.palette.greyC.gray2,
    paddingLeft: 12,
  },
}));

export default {
  useStyles,
};
