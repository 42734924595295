import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  selectorsForm: {
    width: 120,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    width: 'max-content',
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
}));

export default {
  useStyles,
};
