import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 290,
  },
  modalBody: {
    height: 'calc(290px - 72px)'
  },
  container: {
    height: '100%',
  },
  btn: {
    width: '100%',
    height: 48,
  },
  selectForm: {
    height: 48,
    border: 0,

  },
  select: {
    height: 48,
    borderRadius: 6,
    backgroundColor: '#F6F7FB',
  },
}));

export default {
  useStyles,
};
