import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    color: '#004BF6 !important',
    fontSize: '40px !important',
    fontWeight: '700 !important',

    '& span': {
      fontWeight: 500,
      color: '#02c173',
    },
  },
}));

export default {
  useStyles,
};
