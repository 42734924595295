import { makeStyles } from '@material-ui/core/styles';
import { GATE_DIRECTIONS, GATE_TYPES } from 'config/constants';

export const useDeviceNodeStyles = makeStyles((theme) => ({
  container: {
    width: 172,
    height: 36,
    border: '1px solid #eee',
    padding: 5,
    borderRadius: 5,
    background: (data) => {
      const {
        type,
        direction,
      } = data || {};

      switch (direction) {
        case GATE_DIRECTIONS.enter:
          if (type === GATE_TYPES.door) {
            return theme.palette.blueC.blue;
          }
          return theme.palette.greenC.green5;

        case GATE_DIRECTIONS.exit:
          if (type === GATE_TYPES.door) {
            return theme.palette.blueC.blue;
          }
          return theme.palette.redC.red3;

        default:
          return 'white'
      }
    },

    display: 'flex',
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  handle: {
    background: theme.palette.greyC.darkGrey,
  },
  icon: {
    color: 'white',
  },
  text: {
    color: 'white'
  },
}));

export const useStateNodeStyles = makeStyles((theme) => ({
  container: {
    width: 172,
    height: 36,
    border: '1px solid #eee',
    padding: 5,
    borderRadius: 5,
    background: theme.palette.greyC.darkGrey,

    display: 'flex',
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  handle: {
    background: theme.palette.greyC.darkGrey,
  },
  icon: {
    color: 'white',
  },
  text: {
    color: 'white'
  },
}));

export default {
  useDeviceNodeStyles,
  useStateNodeStyles,
};
