import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import AccessRedirect from '@parkly/shared/components/pages/AccessRedirect';
import SignOutPage from '@parkly/shared/components/pages/SignOutPage';
import RedirectToProd from '@parkly/shared/components/templates/RedirectToProd';
import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';
import theme from '@parkly/shared/config/styles/theme';
import { ConfirmProvider } from '@parkly/shared/helpers/confirm';
import { checkIsAccessToStaging, checkIsStaging } from '@parkly/shared/helpers/others';

import AppContent from 'components/pages/AppContent';
import PhoneSignInPage from 'components/pages/PhoneSignInPage';
import UserAgreement from 'components/pages/UserAgreement';
import PrivateRoute from 'components/templates/containers/PrivateRoute';

import { createNewQueryClient } from './api/query/base';
import { PATH_PAGES } from './config/constants';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const isStaging = checkIsStaging();

  if (isStaging) {
    if (window.location.pathname === DEFAULT_PATH_PAGES.accessRedirect) {
      return <AccessRedirect home={DEFAULT_PATH_PAGES.home} />;
    }

    const isAccessToStaging = checkIsAccessToStaging();

    if (!isAccessToStaging) {
      return <RedirectToProd />;
    }
  }

  const queryClient = createNewQueryClient();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConfirmProvider>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                <Switch>
                  <Route path={DEFAULT_PATH_PAGES.login} component={PhoneSignInPage} />
                  <Route path={DEFAULT_PATH_PAGES.logout} component={SignOutPage} />
                  <Route path={PATH_PAGES.userAgreement} component={UserAgreement} />
                  <PrivateRoute path="/" component={AppContent} />
                </Switch>
              </QueryParamProvider>
            </BrowserRouter>
          </ConfirmProvider>
        </MuiPickersUtilsProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
