import { getContractorFLByInn, getContractorULByInn } from '@parkly/shared/api/contractor';
import { CLEAR_CONTRACTOR_CACHE, GET_CONTRACTOR_FL, GET_CONTRACTOR_UL } from '@parkly/shared/actions/types';

export function clearContractorCacheAction() {
  function actionClearContractorCache() {
    return { type: CLEAR_CONTRACTOR_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearContractorCache());
  };
}

export function getContractorFLAction(inn) {
  function getContractorFLRequest(payload) {
    return { type: GET_CONTRACTOR_FL, payload };
  }

  return (dispatch) => {
    dispatch(getContractorFLRequest({
      loading: true,
    }));

    getContractorFLByInn({ inn }).then(
      (contractor) => {
        dispatch(getContractorFLRequest({
          loading: false,
          data: contractor,
        }));
      },
      (error) => {
        dispatch(getContractorFLRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getContractorULAction(inn) {
  function getContractorULRequest(payload) {
    return { type: GET_CONTRACTOR_UL, payload };
  }

  return (dispatch) => {
    dispatch(getContractorULRequest({
      loading: true,
    }));

    getContractorULByInn({ inn }).then(
      (contractor) => {
        dispatch(getContractorULRequest({
          loading: false,
          data: contractor,
        }));
      },
      (error) => {
        dispatch(getContractorULRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
