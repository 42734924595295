import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useFormFields, useModal } from '@parkly/shared/helpers';

import YandexMapSelectCoordsModalContent from 'components/organisms/YandexMapSelectCoordsModalContent';

import { deleteFacilityGateAction, setFacilityGateAction } from 'actions/facilities';
import { GATE_DIRECTIONS, GATE_DIRECTIONS_LABEL, GATE_TYPE_LABELS, GATE_TYPES } from 'config/constants';

import { useStyles } from './styles';

const TYPE_OPTIONS = [
  { id: GATE_TYPES.barrier, title: 'Шлагбаум' },
  { id: GATE_TYPES.gate, title: 'Ворота' },
  { id: GATE_TYPES.door, title: 'Дверь' },
];

const DIRECTION_OPTIONS = {
  barrier: [
    { id: GATE_DIRECTIONS.enter, title: GATE_DIRECTIONS_LABEL.enter },
    { id: GATE_DIRECTIONS.exit, title: GATE_DIRECTIONS_LABEL.exit },
    // { id: GATE_DIRECTIONS.both, title: GATE_DIRECTIONS_LABEL.both },
  ],
  gate: [
    { id: GATE_DIRECTIONS.enter, title: GATE_DIRECTIONS_LABEL.enter },
    { id: GATE_DIRECTIONS.exit, title: GATE_DIRECTIONS_LABEL.exit },
    // { id: GATE_DIRECTIONS.both, title: GATE_DIRECTIONS_LABEL.both },
  ],
  door: [
    { id: GATE_DIRECTIONS.enter, title: GATE_DIRECTIONS_LABEL.door_enter },
    { id: GATE_DIRECTIONS.exit, title: GATE_DIRECTIONS_LABEL.door_exit },
    { id: GATE_DIRECTIONS.both, title: GATE_DIRECTIONS_LABEL.door_both },
  ],
  unselected: [],
};

function getCurrentGate({
  id,
  facilityId,
  allFacilityGatesState,
}) {
  const {
    list: gatesList,
  } = allFacilityGatesState || {};

  const currentStrParam = JSON.stringify({
    id: facilityId,
  });

  const currentGates = gatesList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: gatesData,
  } = currentGates || {};

  return gatesData.find(({ id: itemId }) => id === itemId);
}

function SetGateModalContent({
  id,
  facilityId,
  onClose = () => {},

  allFacilityGatesState,
  setFacilityGateReg,
  setFacilityGateState,

  deleteFacilityGateReg,
  deleteFacilityGateState,

  currentFacilityState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      type: '',
      direction: '',
      name: '',
      lat: null,
      lon: null,
      lonlat: '',
      address: '',
    },
  });

  const {
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    lat: facilityLat,
    lon: facilityLon,
  } = currentFacilityData || {};

  useEffect(() => {
    if (!id) {
      return;
    }

    const gate = getCurrentGate({
      id,
      facilityId,
      allFacilityGatesState,
    });

    if (gate) {
      updateState({
        direction: gate.direction,
        type: gate.type,
        name: gate.name,
        lat: gate.lat,
        lon: gate.lon,
        lonlat: gate.lat && gate.lon ? `${gate.lat}, ${gate.lon}` : '',
        address: gate.address,
      });
    }
  }, [id, facilityId, allFacilityGatesState, updateState]);

  const onChangeDirection = useCallback((event) => {
    if (id) {
      onChange(event);
      return;
    }

    const { target } = event || {};
    const {
      value,
    } = target || {};

    updateState({
      name: `${GATE_TYPE_LABELS[fields.type]} - ${GATE_DIRECTIONS_LABEL[fields.type === GATE_TYPES.door ? `door_${value}` : value]}`,
    });
    onChange(event);
  }, [fields, id, onChange, updateState]);

  const [
    yaMapModalNode,
    onOpenYaMapModal,
    onHideYaMapModal,
  ] = useModal({
    content: (
      <YandexMapSelectCoordsModalContent
        initCoords={[fields.lat, fields.lon]}
        initAddress={fields.address}
        defaultCenter={[facilityLat, facilityLon]}
        onClose={() => {
          onHideYaMapModal();
        }}
        onSubmit={({ coords, address }) => {
          if (coords && address) {
            const lat = coords[0];
            const lon = coords[1];

            updateState({
              address,
              lat,
              lon,
              lonlat: `${lat}, ${lon}`,
            });
            setErrors({
              address: false,
            });
          }

          onHideYaMapModal();
        }}
      />
    ),
  });

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const isTitleValid = fields.name.length > 0;
    const isAddressValid = fields.address.length > 0;
    const isLatValid = fields.lat !== 0;
    const isLonValid = fields.lon !== 0;
    const isDirectionValid = fields.direction.length > 0;
    const isTypeValid = fields.type.length > 0;

    if (
      !isTitleValid || !isAddressValid || !isLatValid
      || !isLonValid || !isDirectionValid || !isTypeValid
    ) {
      setErrors({
        name: !isTitleValid,
        lonlat: !isAddressValid || !isLatValid || !isLonValid,
        direction: !isDirectionValid,
        type: !isTypeValid,
      });
      return;
    }

    setFacilityGateReg({
      facilityId,
      id,
      direction: fields.direction,
      type: fields.type,
      name: fields.name,
      lat: fields.lat,
      lon: fields.lon,
      address: fields.address,
    });

    onClose();
  }, [facilityId, fields, id, onClose, setErrors, setFacilityGateReg]);

  const onRemove = () => {
    if (!id) {
      return;
    }

    deleteFacilityGateReg({ id, facilityId });
    onClose();
  };

  return (
    <CustomModalContentWrapper
      title={t('integrations.addGate.addGate')}
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              label={t('integrations.addGate.type')}
              name="type"
              error={errors.type}
              items={TYPE_OPTIONS}
              currentValue={fields.type}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              name="direction"
              label={t('integrations.addGate.direction')}
              items={DIRECTION_OPTIONS[fields.type]}
              error={errors.direction}
              currentValue={fields.direction}
              onChange={onChangeDirection}
              disabled={!fields.type}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.name}
              name="name"
              label={t('integrations.addGate.title')}
              value={fields.name}
              autoComplete="off"
              required
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomTextInput
              className={styles.inputItem}
              label={t('integrations.addGate.lonlat')}
              error={errors.lonlat}
              value={fields.lonlat}
              onClick={onOpenYaMapModal}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomBtn
              btnType="primaryBlue"
              type="submit"
              className={styles.submitBtn}
            >
              {t('integrations.addGate.submit')}
            </CustomBtn>
          </Grid>
          {id && (
            <Grid item xs={12} sm={6}>
              <CustomBtn
                className={styles.submitBtn}
                onClick={onRemove}
              >
                {t('integrations.addGate.remove')}
              </CustomBtn>
            </Grid>
          )}

        </Grid>
      </form>
      {yaMapModalNode}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { facilities, currentFacility } = state || {};
  const {
    allFacilityGates,
    setFacilityGate,
    deleteFacilityGate,
  } = facilities || {};

  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
    allFacilityGatesState: allFacilityGates,
    setFacilityGateState: setFacilityGate,
    deleteFacilityGateState: deleteFacilityGate,
  };
}

const ConnectedSetGateModalContent = connect(
  mapStateToProps,
  {
    setFacilityGateReg: setFacilityGateAction,
    deleteFacilityGateReg: deleteFacilityGateAction,
  },
)(SetGateModalContent);

export default ConnectedSetGateModalContent;
