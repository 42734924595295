import { callAPI } from '@parkly/shared/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

export function validGetCustomer(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getCustomer({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/customers/${id}`)
    .then((res) => validGetCustomer({
      res,
    }));
}

async function validGetCustomers({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const customersMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const customersData = curData.map((item) => {
    const {
      id,
      facilityId,
      userId,
      name,
      phone,
      email,
      comment,
      lastActivityAt,
      isLastActivityFail,
      lastActivityFailMessage,
      defaultTariffPlanId,
      payments,
      tokens,
    } = item;

    return {
      id,
      facilityId,
      userId,
      name,
      phone,
      email,
      comment,
      tokens,
      payments,
      lastActivityAt,
      defaultTariffPlanId,
      isLastActivityFail,
      lastActivityFailMessage,
    };
  });

  const result = {
    customersData,
    customersMeta,
  };

  return result;
}

export async function getCustomers({
  facilityId,
  search,
  page,
  defaultTariffPlan,
  guest,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    search,
    defaultTariffPlanId: defaultTariffPlan === 'unselect' ? undefined : defaultTariffPlan,
    guest: guest === 'unselect' ? undefined : guest,
    page,
    perPage,
    sortBy,
    sortDirection,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/customers?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetCustomers({
      res,
    }));
}

async function validGetCustomersSelect({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const customersData = curData.map((item) => {
    const {
      id,
      name,
      phone,
    } = item;

    return {
      id,
      name,
      phone,
    };
  });

  return customersData;
}

export async function getCustomersSelect({
  facilityId,
  search,
}) {
  const query = new URLSearchParams(pickBy({
    search,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/customers/select?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetCustomersSelect({
      res,
    }));
}

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function setCustomer({
  facilityId,
  id,
  name,
  phone,
  email,
  comment,
  tokens,
  defaultTariffPlanId,
  hasTelegramBotAccess,
}) {
  return callAPI(
    `facilities/${facilityId}/customers`,
    'POST',
    {
      customerId: id,
      name,
      phone,
      email,
      comment,
      tokens,
      defaultTariffPlanId,
      hasTelegramBotAccess,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function deleteCustomer({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/customers/${id}`,
    'DELETE',
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function deleteCustomerAccessToken({
  id,
  customerId,
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/customers/${customerId}/tokens/${id}`,
    'DELETE',
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function startCustomerGateSession({
  id,
  customerId,
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/customers/${customerId}/start-session`,
    'POST',
    {
      gateId: id,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function finishCustomerGateSession({
  id,
  customerId,
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/customers/${customerId}/finish-session`,
    'POST',
    {
      gateId: id,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function fastActionCustomer({
  facilityId,
  gatewayEventId,
  type,
}){
  return callAPI(
    `facilities/${facilityId}/customers/fast-action`,
    'POST',
    {
      type: type,
      gatewayEventId: gatewayEventId,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}
