import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
    height: '50vh',
  },
  btn: {
    width: '100%',
    height: 48,
  },
}));

export default {
  useStyles,
};
