import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  form: {
    marginTop: 24,
  },
  inputItem: {
    width: '100%',
    height: 48,
    margin: 0,
    '& .MuiFilledInput-root': {
      height: 48,
    }
  },
  inputAutocompleteItem: {
    margin: 0,
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
  subLabel: {
    color: theme.palette.greyC.darkGrey,
  },
  checkbox: {
    height: 48,
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  holderTypeToggleBtns: {
    height: 40,
    '& .MuiToggleButton-root': {
      textTransform: 'unset',
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '& .Mui-disabled.Mui-selected': {
      backgroundColor: theme.palette.greyC.buttonGrey,
      color: theme.palette.text.primary,
    },
  },
  saveBtn: {
    width: '100%',
    height: 48,
  }
}));

export default {
  useStyles,
};
