import { LOGIN_SUCCESS } from '@parkly/shared/actions/types';
import { saveUser } from '@parkly/shared/helpers';

export function login(user, history) {
  function success(user) { return { type: LOGIN_SUCCESS, user }; }

  return (dispatch) => {
    saveUser(user);
    dispatch(success(user));
    history.push('/');
  }
}
