import React from 'react';

function DocumentsActiveIcon({
  size = 32,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 3.251l-.078-.001H9.25a2.93 2.93 0 00-2.138.94A3.233 3.233 0 006.25 6.4v19.2c0
        .82.305 1.616.862 2.21a2.93 2.93 0 002.138.94h13.5a2.93 2.93 0
        002.138-.94c.557-.594.862-1.39.862-2.21V11.828l-.001-.078H19A1.75 1.75 0 0117.25
        10V3.251zm8.002 6.999H19a.25.25 0 01-.25-.25V3.748c.13.091.253.194.366.307l5.829
        5.829c.113.114.216.236.307.366zm-13.885 5.428a.75.75 0 000 1.5H20.7a.75.75 0
        000-1.5h-9.333zm0 5.333a.75.75 0 000 1.5H20.7a.75.75 0 000-1.5h-9.333z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default DocumentsActiveIcon;
