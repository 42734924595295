import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 26,
  },
  menuList: {
    // backgroundColor: theme.palette.primary,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    height: '100%',

    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '250px',
    },
  },
  text: {
    flex: '0 1 auto',
  },
  navigationLink: {
    color: 'inherit',
    '&[disabled]': {
      pointerEvents: 'none',
    },
  },
  activeCustomListItemMenu: {
    '& .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary': {
      fontWeight: 'bold',
    },
  },
  customListItemMenuNested: {
    paddingLeft: 30,
  },
  expandIcon: {
    marginLeft: 'auto',
    marginRight: 32,
  },
}));

export default {
  useStyles,
};
