import React from 'react';

function BalanceInactiveIcon({
  fill = 'white',
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 7.5V4.99999C19.5 3.89999 19.1 3.5 18 3.5H4.00001C2.89001 3.5 2.5 3.89999 2.5 4.99999V18.5C2.5 19.6 2.89001 20.5 4.00001 20.5H18C19.1 20.5 19.5 20.1 19.5 19V16.5C20 16.5 21 15.74 21 15V8.99999C21 8.25999 20 7.5 19.5 7.5ZM20 8.5V15.5H11.5V8.5H20ZM3.5 19.5V4.5H18.5V7.5H12C11 7.5 10.5 8 10.5 8.99999V15C10.5 16.1 10.9 16.5 12 16.5H18.5V19.5H3.5Z"
        fill={fill}
      />
      <path
        d="M14.75 13C15.4404 13 16 12.4404 16 11.75C16 11.0596 15.4404 10.5 14.75 10.5C14.0596 10.5 13.5 11.0596 13.5 11.75C13.5 12.4404 14.0596 13 14.75 13Z"
        fill={fill}
      />
    </svg>

  );
}

export default BalanceInactiveIcon;
