import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  btn: {
    width: '100%',
    height: 48,
    '& svg': {
      marginRight: 8,
    },
  },
}));

export default {
  useStyles,
};
