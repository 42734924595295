import { updateStoreReqRes } from '@parkly/shared/helpers';
import {
  CLEAR_BLACK_LIST_CACHE,
  CREATE_BLACK_LIST,
  DELETE_ONE_BLACK_LIST,
  GET_ALL_BLACK_LIST,
  GET_ONE_BLACK_LIST,
  SET_STATUS_ONE_BLACK_LIST,
} from 'actions/types';

const INITIAL_STATE = {
  allBlackList: {
    loading: false,
    list: [],
  },
  oneBlackList: {
    loading: false,
    list: [],
  },
  addNewBlackList: {
    loading: false,
  },
  updBlackList: {
    loading: false,
  },
  removeBlackList: {
    loading: false,
  },
};

function updateOneBlackListList({
  list,
  newBlackList,
  error,
}) {
  const { id } = newBlackList || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newBlackList || error,
  ];

  return newList;
}

function getNewState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function blackLists(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_BLACK_LIST:
      return updateStoreReqRes(
        state,
        'allBlackList',
        action,
      );

    case GET_ONE_BLACK_LIST:
      return {
        ...state,
        oneBlackList: {
          ...state.oneBlackList,
          loading: action.payload.loading,
          list: updateOneBlackListList({
            list: state.oneBlackList.list,
            newBlackList: action.payload.blackList,
            error: action.payload.error,
          }),
        },
      };

    case CREATE_BLACK_LIST:
      return {
        ...state,
        addNewBlackList: getNewState({
          prevState: state.addNewBlackList,
          payload: action.payload,
        }),
      };

    case SET_STATUS_ONE_BLACK_LIST:
      return {
        ...state,
        updBlackList: getNewState({
          prevState: state.updBlackList,
          payload: action.payload,
        }),
      };

    case DELETE_ONE_BLACK_LIST:
      return {
        ...state,
        removeBlackList: getNewState({
          prevState: state.removeBlackList,
          payload: action.payload,
        }),
      };

    case CLEAR_BLACK_LIST_CACHE:
      return {
        allBlackList: INITIAL_STATE.allBlackList,
        oneBlackList: INITIAL_STATE.oneBlackList,
        addNewBlackList: INITIAL_STATE.addNewBlackList,
        updBlackList: INITIAL_STATE.updBlackList,
        removeBlackList: INITIAL_STATE.removeBlackList,
      };

    default:
      return state;
  }
}
