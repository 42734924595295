import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  headerStr: {
    fontWeight: 700,
  },
  inputItem: {
    width: '100%',
    height: 48,
    margin: 0,
    '& .MuiFilledInput-root': {
      height: 48,
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
}));

export default {
  useStyles,
};
