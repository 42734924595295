import React from 'react';

function CameConnectIcon({
  size = 24,
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_303_39)">
        <path
          d="M24 0H0V24H24V0Z"
          fill="#009DDF"
        />
        <path
          d="M14.7548 10.0382C13.2104 10.0382 11.8748 10.8939 11.1652 12.1461L11.8748 12.4174C12.4591 11.4365 13.5235 10.7687 14.7548 10.7687C16.6122 10.7687 18.0939 12.2713 18.0939 14.1078C18.0939 15.9652 16.5913 17.4469 14.7548 17.4469C14.3165 17.4469 13.8783 17.3635 13.4817 17.1965C14.1704 16.633 14.6922 15.8817 15.0052 15.0469L13.6278 14.5252C13.127 15.9026 11.7913 16.8835 10.247 16.8835C8.26435 16.8835 6.63653 15.2765 6.63653 13.273C6.63653 11.2904 8.24348 9.66259 10.247 9.66259C10.5809 9.66259 10.8939 9.70433 11.1861 9.78781C11.6452 9.39129 12.1878 9.07824 12.7722 8.86955C12.0209 8.43129 11.1652 8.20172 10.247 8.20172C7.45044 8.20172 5.17566 10.4765 5.17566 13.273C5.17566 16.0695 7.45044 18.3443 10.247 18.3443C11.1652 18.3443 12.0417 18.0939 12.7931 17.6556C13.3774 17.9687 14.0452 18.1565 14.7548 18.1565C17.0087 18.1565 18.8452 16.32 18.8452 14.0661C18.8452 11.8748 17.0087 10.0382 14.7548 10.0382Z"
          fill="white"
        />
        <path
          d="M14.7548 5.59302C14.4417 5.59302 14.1287 5.61389 13.8157 5.63476L13.8991 6.36519C14.1704 6.34432 14.4626 6.32345 14.7548 6.32345C17.0504 6.32345 19.0957 7.30432 20.5357 8.89041L21.0783 8.38954C19.5131 6.67824 17.2591 5.59302 14.7548 5.59302Z"
          fill="white"
        />
        <path
          d="M14.7548 7.82605C14.5252 7.82605 14.2957 7.84692 14.0661 7.86779L14.1496 8.59822C14.3583 8.57735 14.5461 8.55648 14.7548 8.55648C16.4035 8.55648 17.8644 9.26605 18.887 10.393L19.4296 9.89214C18.2818 8.61909 16.6122 7.82605 14.7548 7.82605Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_39">
          <rect
            width={size}
            height={size}
            rx="4"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CameConnectIcon;
