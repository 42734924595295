import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import {useFormFields} from '@parkly/shared/helpers';
import {setControllerAction} from 'actions/controllers';
import {CONTROLLER_TYPES} from 'config/constants';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import {toast} from 'react-toastify';
import {testNewController} from 'api/controllers';
import {useStyles} from './styles';

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    return strParam === currentStrParam;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeLedMatrixControllerModalContent({
  facilityId,
  id,
  onClose = () => {},

  testNewControllerApi = testNewController,

  setControllerState,
  setControllerReg,

  allControllersState,
}) {
  const styles = useStyles();

  const [step, setStep] = useState(0);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      sn: '',
    },
  });

  const handleNextStep = useCallback(() => {

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    let controllerId = null;
    if (controller) {
      controllerId = controller.id
    }

    testNewControllerApi({
      facilityId,
      type: CONTROLLER_TYPES.ledMatrix,
      settings: {
        sn: fields.sn,
        id: controllerId,
      },
    }).then(({ data, success, message }) => {
      if (!success) {
        if (message) {
          toast.error(message);
        }
        return;
      }

      const { sn } = data || {};

      updateState({
        sn,
      });

      setStep(1);
    }).catch(() => {

    });
  }, [testNewControllerApi, facilityId, fields, updateState]);

  const handleSetController = useCallback(() => {
    if (step !== 1) {
      return;
    }

    setControllerReg({
      facilityId,
      id,
      type: CONTROLLER_TYPES.ledMatrix,
      settings: {
        sn: fields.sn,
      },
    });

    onClose();
  }, [facilityId, fields, id, onClose, setControllerReg, step]);

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        sn: controller.settings.sn,
      });
    }
  }, [id, facilityId, updateState, allControllersState]);

  return (
    <CustomModalContentWrapper
      title="Подключение LED Matrix"
      onClose={onClose}
    >
      {step === 0 && (
        <Grid container spacing={2} alignItems="center">


          <Grid item xs={9}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.host}
              name="sn"
              label="Серийный номер"
              value={fields.sn}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleNextStep}
                >
                  Далее
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GridTitleBase
              classNameContainer={styles.itemGridTitle}
              title="SN"
              titleSm={6}
            >
              <Typography>
                {fields.sn}
              </Typography>
            </GridTitleBase>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleSetController}
                >
                  Сохранить
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController,
    allControllers,
  } = controllers || {};

  return {
    setControllerState: setController,
    allControllersState: allControllers,
  };
}

const ConnectedMakeLedMatrixControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
  },
)(MakeLedMatrixControllerModalContent);

export default ConnectedMakeLedMatrixControllerModalContent;
