import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: 30,
  },
  bodyMain: {

  },
  form: {
    marginTop: 24,
  },
  usePhoneAsToken: {
    marginTop: -24,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  inputItem: {
    margin: 0,
  },
  twoInput: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  twoInputItem: {
    width: '48%',
  },
  twoInputItemFullWidth: {
    width: '100%',
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  divider: {
    marginTop: 32,
    backgroundColor: theme.palette.greyC.gray,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  btnSubmit: {
    width: '100%',
    height: 48,
  },
  btnAddOrSave: {
    zIndex: 1,
    paddingLeft: 25,
    paddingRight: 25,
    color: 'white',
    width: 'auto',
    backgroundColor: theme.palette.greenC.green4,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green5,
    },
  },
  btnRemove: {
    marginLeft: -18,
    paddingLeft: 25,
    paddingRight: 25,
    color: theme.palette.greyC.darkGrey,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: 'none',
    },
  },
  btnsContainer: {
    marginTop: 24
  },
  warningCard: {
    padding: 16
  },
  warningCardTitle: {
    fontSize: 16,
    fontWeight: 700,
    display: 'flex',
    gap: 5
  }
}));

export default {
  useStyles,
};
