import React from 'react';

function SettingsActiveIcon({
  fill = 'white',
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 10C10.4 10 9.5 10.9 9.5 12C9.5 13.1 10.4 14 11.5 14C12.6 14 13.5 13.1 13.5 12C13.5 10.9 12.6 10 11.5 10ZM19 3H4C2.89 3 2.5 3.4 2.5 4.5V19.5C2.5 20.6 2.89 21 4 21H19C20.11 21 20.5 20.6 20.5 19.5V4.5C20.5 3.4 20.11 3 19 3ZM16.75 12C16.75 12.23 16.73 12.46 16.7 12.68L18.18 13.84C18.31 13.95 18.35 14.14 18.26 14.29L16.86 16.71C16.77 16.86 16.59 16.92 16.43 16.86L14.69 16.16C14.33 16.44 13.93 16.67 13.51 16.85L13.25 18.7C13.22 18.87 13.07 19 12.9 19H10.1C9.93 19 9.78 18.87 9.75 18.71L9.49 16.86C9.06 16.68 8.67 16.45 8.31 16.17L6.57 16.87C6.41 16.93 6.23 16.87 6.14 16.72L4.74 14.3C4.65 14.15 4.69 13.96 4.82 13.85L6.3 12.69C6.27 12.46 6.25 12.23 6.25 12C6.25 11.77 6.27 11.54 6.3 11.32L4.82 10.16C4.69 10.05 4.65 9.86 4.74 9.71L6.14 7.29C6.23 7.14 6.41 7.08 6.57 7.14L8.31 7.84C8.67 7.56 9.07 7.33 9.49 7.15L9.75 5.3C9.78 5.13 9.93 5 10.1 5H12.9C13.07 5 13.22 5.13 13.25 5.29L13.51 7.14C13.94 7.32 14.33 7.55 14.69 7.83L16.43 7.13C16.59 7.07 16.77 7.13 16.86 7.28L18.26 9.7C18.35 9.85 18.31 10.04 18.18 10.15L16.7 11.31C16.73 11.54 16.75 11.77 16.75 12Z"
        fill={fill}
      />
    </svg>

  );
}

export default SettingsActiveIcon;
