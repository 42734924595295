import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: 0,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px !important',
      marginTop: 0,
    },
  },
  header: {
    position: 'static',
    backgroundColor: theme.palette.greyC.gray,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    height: 56,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerPlacesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  headerPlacesText: {
    fontSize: 16,
  },
  selectorsForm: {
    width: 100,
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  pageBody: {

  },
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  camerasContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 300,
    backgroundColor: 'black',
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    height: '100%'
  },
}));

export default {
  useStyles,
};
