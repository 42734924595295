import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '80vw',
    height: '70vh',
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
      height: '60vh',
    },
  },
  modalBody: {
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  map: {
    height: 'calc(100% - 16px - 24px - 8px - 48px - 24px)',
    width: '100%',
  },
  addressStr: {
    marginTop: 16,
    fontSize: 16,
  },
  submitBtn: {
    width: 200,
    marginTop: 8,
    height: 48,
  },
}));

export default {
  useStyles,
};
