import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, useMediaQuery } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';

import CameraStreamList from 'components/organisms/CameraStreamList';
import FreePlacesSelects from 'components/organisms/FreePlacesSelects';
import GateControls from 'components/organisms/GateControls';
import LastErrorsGatewayEvents from 'components/organisms/GatewayEvents/LastErrorsGatewayEvents';
import MobileHomeContent from 'components/organisms/MobileHomeContent';
import { PassesWithParams } from 'components/organisms/Passes';
import { SessionsWithParams } from 'components/organisms/Sessions';
import {
  FAIL_GATEWAY_EVENTS_STATUSES, PASSES_TYPES, PATH_PAGES, RIGHT_KEYS, SECTIONS,
} from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';
import { objToQuery } from 'helpers/queryParams';

import { useStyles } from './styles';

function HomePage({
  currentFacilityState,
}) {
  const [isVideoExpanded, setIsVideoExpanded] = useState(true);
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    id: currentFacilityId,
    title,
  } = currentFacilityData || {};

  const hasOpenGateRight = useCheckFacilityRights(RIGHT_KEYS.automationOpenGate, currentFacilityId);
  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, currentFacilityId);
  const hasGatewayLogShowRight = useCheckFacilityRights(RIGHT_KEYS.gatewayLogShow, currentFacilityId);
  const hasPassesShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasPlacesShowRight = useCheckFacilityRights(RIGHT_KEYS.placesShow, currentFacilityId);

  if (matchDownXs) {
    return (
      <Container className={styles.container}>
        <MobileHomeContent facilityId={currentFacilityId} />
      </Container>
    );
  }

  const header = (
    <div className={styles.header}>
      <Typography className={styles.headerTitle}>
        {title}
      </Typography>
      {hasPlacesShowRight && (
        <div className={styles.headerPlacesContainer}>
          <Typography className={styles.headerPlacesText}>
            Свободные места
          </Typography>
          <FreePlacesSelects facilityId={currentFacilityId} />
        </div>
      )}
    </div>
  );

  const prt = createPortal(header, document.getElementById(SECTIONS.topBar));

  return (
    <Container className={styles.container}>
      <>{prt}</>
      <Grid container spacing={2}>
        {hasOpenGateRight && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={styles.title}>
                  Управление барьерами
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <GateControls />
              </Grid>
            </Grid>
          </Grid>
        )}
        {hasPassesShowRight && (
          <Grid item xs={12}>
            <PassesWithParams
              initParams={{
                forceType: PASSES_TYPES.old_overdue,
              }}
              showTitleIfNotEmpty
              showContentIfEmpty={false}
              title="Данные абонементы просрочены. Свяжитесь с владельцем для продления, либо остановите активную парковочную сессию"
              facilityId={currentFacilityId}
            />
          </Grid>
        )}
        {/* PD-1177 */}
        {/* {false && hasGatewayLogShowRight && ( */}
        {/*  <> */}
        {/*    <Grid item xs={12}> */}
        {/*      <Typography className={styles.title}> */}
        {/*        Неуспешные проезды */}
        {/*      </Typography> */}
        {/*    </Grid> */}
        {/*    <Grid item xs={12}> */}
        {/*      <LastErrorsGatewayEvents */}
        {/*        limit={3} */}
        {/*        facilityId={currentFacilityId} */}
        {/*        refetchInterval={30 * 1000} */}
        {/*      /> */}
        {/*      <CustomLink */}
        {/*        href={PATH_PAGES.allGatewayEvents.replace(':facilityId', currentFacilityId)} */}
        {/*        onClick={(e) => { */}
        {/*          e.preventDefault(); */}
        {/*          history.push( */}
        {/*            PATH_PAGES.allGatewayEvents.replace(':facilityId', currentFacilityId) */}
        {/*            + objToQuery({ status: [FAIL_GATEWAY_EVENTS_STATUSES.join(',')] }), */}
        {/*          ); */}
        {/*        }} */}
        {/*      > */}
        {/*        Показать все */}
        {/*      </CustomLink> */}
        {/*    </Grid> */}
        {/*  </> */}
        {/* )} */}
        {hasSessionShowRight && (
          <>
            <Grid item xs={12}>
              <Typography className={styles.title}>
                Сессии
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SessionsWithParams
                hideCheckbox
                initParams={{ sorting: { activityAt: 'sortReverse' } }}
                facilityId={currentFacilityId}
                defaultSortByActivityAt
              />
              <CustomLink
                href={PATH_PAGES.allSessions.replace(':facilityId', currentFacilityId)}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(PATH_PAGES.allSessions.replace(':facilityId', currentFacilityId));
                }}
              >
                Показать все
              </CustomLink>
            </Grid>
          </>
        )}
      </Grid>
      <CameraStreamList
        facilityId={currentFacilityId}
        isExpanded={isVideoExpanded || matchDownSm}
        toggleExpanded={() => setIsVideoExpanded(!isVideoExpanded)}
      />
    </Container>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedHomePage = connect(
  mapStateToProps,
  {},
)(HomePage);

export default ConnectedHomePage;
