import { callAPI, downloadFileContentDispositionApi } from '@parkly/shared/api';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import moment from 'moment-timezone';

async function validGetAllAccountOperationsRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const operationsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const operationsData = curData.map((item) => {
    const {
      id,
      createdAt,
      name,
      status,
      amount,
      operatorName,
      documents,
    } = item;

    return {
      id,
      createdAt,
      name,
      status,
      amount,
      operatorName,
      documents,
    };
  });

  const result = {
    operationsData,
    operationsMeta,
  };

  return result;
}

export async function getAccountOperations({
  from,
  to,
  type,
  page,
  perPage = 15,
}) {
  const query = new URLSearchParams(pickBy({
    from: from ? moment(from).utc().toISOString() : from,
    to: to ? moment(to).utc().toISOString() : to,
    type: type === 'unselect' ? undefined : type,
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `account/operations?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetAllAccountOperationsRes({
      res,
    }));
}

async function validBalanceRes({ res }) {
  const { data: dataRes } = res || {};
  const { data } = dataRes || {};

  const {
    balance,
    balanceForWithdraw,
  } = data;

  return {
    balance,
    balanceForWithdraw,
  };
}

export async function getAccountBalance() {
  return callAPI(
    'account/balance',
    'GET',
  )
    .then((res) => validBalanceRes({
      res,
    }));
}

async function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function createWithdrawPayment({
  amount,
}) {
  return callAPI(
    'account/withdraw',
    'POST',
    {
      amount,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function downloadAccountOperationDocument({
  id,
}) {
  return downloadFileContentDispositionApi(`account/document/${id}`);
}
