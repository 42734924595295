import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { TARIFF_INTERVALS } from 'config/constants';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import fill from 'lodash/fill';

import { useStyles } from './styles';

function TariffPlanMonthSchedules({
  activeTariffs = [],
  schedules,
  onChange,
  disabled = false,
}) {
  const styles = useStyles();

  const tariffSelectData = useMemo(() => ((activeTariffs || [])
    .filter(({ interval }) => (
      [
        TARIFF_INTERVALS.month,
      ].includes(interval)
    ))
    .map(({ id, name }) => ({
      id,
      title: name,
    }))
  ), [activeTariffs]);

  const handleChange = useCallback((event) => {
    const { target } = event || {};
    const { value, name } = target || {};

    const newSchedules = fill(schedules[0], value, name, name + 1);

    onChange([newSchedules]);
  }, [onChange, schedules]);

  return (
    <Grid container spacing={2} xs={8}>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Январь
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={0}
          currentValue={schedules[0][0]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Февраль
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={1}
          currentValue={schedules[0][1]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Март
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={2}
          currentValue={schedules[0][2]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Апрель
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={3}
          currentValue={schedules[0][3]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Май
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={4}
          currentValue={schedules[0][4]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Июнь
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={5}
          currentValue={schedules[0][5]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Июль
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={6}
          currentValue={schedules[0][6]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Август
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={7}
          currentValue={schedules[0][7]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Сентябрь
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={8}
          currentValue={schedules[0][8]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Октябрь
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={9}
          currentValue={schedules[0][9]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Ноябрь
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={10}
          currentValue={schedules[0][10]}
        />
      </Grid>
      <Grid component={Paper} square className={styles.paper} item xs={4}>
        <Typography>
          Декабрь
        </Typography>
        <ItemSelectors
          classNameForm={styles.selectorsForm}
          classNameLabel={styles.selectorsLabel}
          className={styles.selector}
          items={tariffSelectData}
          disabled={disabled}
          onChange={handleChange}
          name={11}
          currentValue={schedules[0][11]}
        />
      </Grid>
    </Grid>
  );
}

export default TariffPlanMonthSchedules;
