import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import BackIcon from '@parkly/shared/components/atoms/icons/BackIcon';

import { useStyles } from './styles';

const CustomIconButton = withStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 26, 84, 0.08)',
    height: 32,
    width: 32,
    padding: 0,
    marginRight: 20,
  },
}))(IconButton);

const propTypes = {
  title: PropTypes.string,
  isBackBtn: PropTypes.bool,
  hideFacilitySelect: PropTypes.bool,
  disabledFacilitySelect: PropTypes.bool,
};

function HeaderWithBackBtn({
  title = '',
  isBackBtn = true,
  children = null,
  backUrl
}) {
  const classes = useStyles();
  const history = useHistory();

  function onBackBtnClick() {
    history.goBack();
  }

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        direction={'row'}
        justifyContent="space-between"
      >
        <Grid container item spacing={0} alignItems="center" style={{ width: 'unset' }}>
          {isBackBtn && (
            <Grid item>
              <CustomIconButton onClick={onBackBtnClick} aria-label="back">
                <BackIcon />
              </CustomIconButton>
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.title} component="h1">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </div>
  );
}
HeaderWithBackBtn.propTypes = propTypes;

export default HeaderWithBackBtn;
