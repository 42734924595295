import React from 'react';
import { Grid } from '@material-ui/core';
import { createEnumParam, useQueryParam, withDefault } from 'use-query-params';

import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import MPPShowcaseSettings from 'components/organisms/ShowcaseSettings/MPPShowcaseSettings';
import SiteShowcaseSettings from 'components/organisms/ShowcaseSettings/SiteShowcaseSettings';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

const TabParam = createEnumParam([
  'minisite',
  'mpp',
]);

function ShowcaseSettingsPage({
  match,
}) {
  const styles = useStyles();
  useCurrentFacilityMatch();

  const tabs = [
    { id: 0, slug: 'minisite', label: 'Минисайт' },
    { id: 1, slug: 'mpp', label: 'Парковки России' },
  ];

  const [tab, setTab] = useQueryParam('tab', withDefault(TabParam, 'minisite'));
  const activeTab = tab || tabs[0].slug;

  const handleTabChange = (event, newTabId) => {
    const selectedTab = tabs[newTabId];
    if (selectedTab) setTab(selectedTab.slug);
  };

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title="Витрины"
        isBackBtn={false}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTabBar
            tabs={tabs}
            tabValue={tabs.findIndex((t) => t.slug === activeTab)}
            handleParamChange={handleTabChange}
          />
        </Grid>
      </Grid>
      {activeTab === 'minisite' && <SiteShowcaseSettings match={match} />}
      {activeTab === 'mpp' && <MPPShowcaseSettings match={match} />}
    </div>
  );
}

export default ShowcaseSettingsPage;
