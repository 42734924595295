import React from 'react';

function ZKTecoIcon({
                         size = 24,
                       }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="#7DC242"
          d="M1.11 17.63l.36-1.78 6.5-7.59H2.61l.39-1.89h8.11l-.33 1.76-6.55 7.65c.69-.02 1.09-.02 1.2-.02.49 0 1.17-.02 2.03-.02h4.85l-.41 1.89H1.11zm9.83 0l2.36-11.26h2.3l-1 4.79 5.2-4.79h3.1l-5.04 4.42 3.7 6.84h-2.62l-2.77-5.36-2.2 1.92-.72 3.44h-2.31z"
        />
      </g>
    </svg>
  );
}

export default ZKTecoIcon;
