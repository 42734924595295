import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment-timezone';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import { useHistory } from 'react-router-dom';
import {
  PASSES_STATUSES,
  PASSES_STATUSES_COLOR_MAP,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';

import { useQueryClient } from '@tanstack/react-query';
import { useActivateTicketMutation, useCancelTicketMutation } from 'api/query/tickets';
import useConfirm from '@parkly/shared/helpers/confirm';
import { getCreatedByContent } from 'components/pages/tickets/OneTicketInfoPage/OneTicketInfoPage';
import ReqValues from 'components/molecules/ReqValues';
import { useStyles } from './styles';

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function getHeaders({
  t,
  hideCustomer,
  hideReqValues,
}) {
  const headers = [
    {
      id: 'id',
      label: 'Номер',
    },
    {
      isSorted: true,
      id: 'startTime',
      label: 'Дата начала',
    },
    {
      id: 'createdBy',
      label: 'Заказчик',
    },
    {
      id: 'pass_type',
      label: 'Тип',
    },
    !hideCustomer
      ? {
        id: 'customer',
        label: 'Гость',
      } : null,
    {
      id: 'placeName',
      label: 'Место',
    },
    !hideReqValues
      ? {
        id: 'tokens',
        label: 'Доступы',
      } : null,
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  return headers.filter((item) => !!item);
}

const getStatusNode = ({ status }, styles, key) => {
  const str = PASSES_STATUSES_MAP[status] || '-';

  const color = PASSES_STATUSES_COLOR_MAP[status] || 'grey';

  return (
    <Typography key={`${key}-${status}-${color}`} className={styles.statusStr}>
      <CircleIcon color={color} />
      {str}
    </Typography>
  );
};

function TicketsContent({
  facilityId,
  isLoading = false,
  ticketsData = [],
  tikcetsMeta,

  showPagination = false,
  onPageChange = () => {},
  sortingSettingsChange = () => {},
  sorting = {},
  hideCustomer = false,
  hideReqValues = false,

  onNeedUpdate = () => {},
  stopSessionsPassReg,

  showIfNotEmpty = false,
}) {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const styles = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);
  const hasTicketTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeShow, facilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasOperatorShowRight = useCommonRights(RIGHT_KEYS.admin);

  const activateTicketMutation = useActivateTicketMutation({
    onSuccess({ success }) {
      if (success) {
        onNeedUpdate();
      }
    },
  });

  const { isLoading: isActivateLoading } = activateTicketMutation || {};

  const createActivateHandler = (id) => () => {
    confirm({
      async: true,
      title: 'Вы уверены, что хотите активировать пропуск?',
      agreeTitle: 'Активировать',
      disagreeTitle: 'Отменить',
    }).then((complete) => {
      activateTicketMutation.mutateAsync({ facilityId, id }).finally(() => complete());
    }).catch(() => {});
  };

  const cancelTicketMutation = useCancelTicketMutation({
    onSuccess({ success }) {
      if (success) {
        onNeedUpdate();
      }
    },
  });

  const { isLoading: isCancelLoading } = activateTicketMutation || {};

  const createCancelHandler = (id) => () => {
    confirm({
      async: true,
      title: 'Вы уверены, что хотите отменить пропуск?',
      agreeTitle: 'Да, отменить',
      disagreeTitle: 'Нет',
    }).then((complete) => {
      cancelTicketMutation.mutateAsync({ facilityId, id }).finally(() => complete());
    }).catch(() => {});
  };

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  const {
    currentPage = 1,
    lastPage = 1,
  } = tikcetsMeta || {};

  const headers = getHeaders({ t, hideCustomer, hideReqValues });

  if (!showIfNotEmpty && isEmpty(ticketsData)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      {isEmpty(ticketsData) && !isLoading && (
        <Grid item className={styles.noDataContainer} component={Paper}>
          <Typography
            className={styles.noDataStr}
          >
            Пока нет пропусков
          </Typography>
        </Grid>
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {!isLoading && !isEmpty(ticketsData) && (
        <TableContainer className={styles.container} component={Paper}>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = sorting[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? styles.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={styles.headerContainer}>
                        <Typography className={styles.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={styles.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={styles.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(ticketsData || []).map((row, index, array) => {
                const {
                  id,
                  number,
                  passTypeName,
                  passTypeId,
                  customerName,
                  customerId,
                  finishTime,
                  startTime,
                  placeName,
                  status,
                  requiredData,
                  canBeCanceled,
                  createdBy,
                } = row || {};
                const key = id;

                const isLast = index === (array.length - 1);

                const finishTimeStr = finishTime ? moment(finishTime).format('HH:mm:ss') : '-';
                const finishDateStr = finishTime ? moment(finishTime).format('DD.MM.yyyy') : '-';
                const startTimeStr = startTime ? moment(startTime).format('HH:mm:ss') : '-';
                const startDateStr = startTime ? moment(startTime).format('DD.MM.yyyy') : '-';

                const tableRowClass = [
                  styles.tableRow,
                  isLast ? styles.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <CustomLink
                        className={styles.nameStr}
                        href={PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', id)}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', id));
                        }}
                      >
                        {number.substring(9)}
                      </CustomLink>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={styles.dateStr}>
                        {startDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {getCreatedByContent(createdBy, facilityId, history, hasCustomerShowRight, hasOperatorShowRight)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {hasTicketTypeShowRight ? (
                        <>
                          <CustomLink
                            href={PATH_PAGES.oneTicketSettings.replace(':facilityId', facilityId).replace(':ticketId', passTypeId)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(PATH_PAGES.oneTicketSettings.replace(':facilityId', facilityId).replace(':ticketId', passTypeId));
                            }}
                          >
                            {passTypeName}
                          </CustomLink>
                        </>
                      ) : (
                        <>
                          <Typography>{passTypeName}</Typography>
                        </>
                      )}
                    </TableCell>
                    {!hideCustomer && (
                      <TableCell align="left">
                        {(hasCustomerShowRight && customerId) ? (
                          <CustomLink
                            // className={styles.nameStr}
                            href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId));
                            }}
                          >
                            {customerName}
                          </CustomLink>
                        ) : (
                          <Typography>{customerName}</Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <Typography className={styles.boldStr}>
                        {placeName || '-'}
                      </Typography>
                    </TableCell>
                    {!hideReqValues && (
                      <TableCell align="left">
                        <ReqValues requiredData={requiredData} />
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {getStatusNode({ status }, styles, key)}
                    </TableCell>
                    <TableCell align="right">
                      <Grid container spacing={1} justifyContent="flex-end">
                        {hasTicketUpdateRight && canBeCanceled && (
                          <Grid item>
                            <Tooltip title="Отменить">
                              <IconButton
                                size="small"
                                onClick={createCancelHandler(id)}
                              >
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {hasTicketUpdateRight && status === PASSES_STATUSES.draft && (
                          <Grid item>
                            <Tooltip title="Активировать">
                              <IconButton
                                size="small"
                                onClick={createActivateHandler(id)}
                              >
                                <PlayCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && showPagination && lastPage > 1 && (
        <div className={styles.paginationContainer}>
          <Pagination
            className={styles.pagination}
            name="page"
            page={currentPage}
            onChange={onPageChange}
            count={lastPage}
            color="primary"
          />
        </div>
      )}
    </Grid>
  );
}

export default TicketsContent;
