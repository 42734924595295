import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';

export default function HighlightedTypography({
  className,
  text,
  highlightColor = 'orange',
  ...props
}) {
  const spans = useMemo(() => {
    let flag = false;
    const result = [];
    text.split('').forEach((char, index) => {
      if (char === '*') {
        flag = true;
        return;
      }

      result.push((
        <span
          /* eslint-disable-next-line react/no-array-index-key */
          key={`diff-${index}`}
          style={flag ? { backgroundColor: highlightColor } : {}}
        >
          {char}
        </span>
      ));
      flag = false;
    });

    if (flag) {
      result.push((
        <span
          /* eslint-disable-next-line react/no-array-index-key */
          key={`diff-last}`}
          style={{ backgroundColor: highlightColor }}
        >
          {" "}
        </span>
      ));
    }

    return result;
  }, [text, highlightColor]);

  return (
    <Typography
      component="div"
      className={className}
      {...props}
    >
      {spans}
    </Typography>
  );
}
