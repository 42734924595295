import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';

import { getFacilityPlacesAction, setFacilityPlacesAction } from 'actions/facilities';
import PlacesSettingsTable from 'components/templates/PlacesSettingsTable';
import { PLACE_PURPOSES, PLACE_STATUSES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getInit() {
  return [
    {
      name: 'На парковке',
      placesCount: 1,
      disabilityPersonPlacesCount: 0,
      places: [
        {
          status: PLACE_STATUSES.free,
          purpose: PLACE_PURPOSES.reactive,
          name: '1',
          isOwned: false,
          photoId: null,
          tempPhotoId: null,
        },
      ],
    },
  ];
}

function getTabs(state) {
  const tabs = state.map((item, index) => ({
    id: +(item.id || index),
    label: item.name || 'unset',
  }));

  return tabs;
}

function getOneFacilityPlaces({
  oneFacilityPlacesState,
  match,
}) {
  const { facilityId } = (match || {}).params || {};

  const { list } = oneFacilityPlacesState || {};
  const currentFacilityPlacesData = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + facilityId);

    return isNeeded;
  });

  const {
    places: currentFacilityPlaces,
    error,
  } = currentFacilityPlacesData || {};

  return {
    facilityId,
    currentFacilityPlaces,
    currentFacilityPlacesError: error,
  };
}

function PlacesSettingsPage({
  match,

  getFacilityPlacesReg,
  oneFacilityPlacesState,

  setFacilityPlacesReg,
  setFacilityPlacesState,

  registrationStatusData,
  currentOperatorState,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  useCurrentFacilityMatch();

  const [state, setState] = useState(getInit());
  const [tabValue, setTabValue] = useState(0);

  const id = ((match || {}).params || {}).facilityId;

  const { isRegistrationCompleted } = registrationStatusData || {};

  const hasPlacesUpdateRight = useCheckFacilityRights(RIGHT_KEYS.placesUpdate, id);

  useEffect(() => {
    const {
      loading: isLoading,
    } = oneFacilityPlacesState || {};

    if (isLoading) {
      return;
    }

    const {
      currentFacilityPlaces,
      currentFacilityPlacesError,
    } = getOneFacilityPlaces({
      oneFacilityPlacesState,
      match,
    });

    if (currentFacilityPlaces || currentFacilityPlacesError) {
      const {
        places,
      } = currentFacilityPlaces || {};

      if (!isEmpty(places)) {
        setState(places);
      } else {
        setState(getInit());
      }
      return;
    }

    getFacilityPlacesReg({ id });
  }, [oneFacilityPlacesState, getFacilityPlacesReg, match, id]);

  const handleTabChange = (_, index) => {
    setTabValue(index);
  };

  const handleAreaChange = (event) => {
    const { target } = event || {};
    const {
      value,
    } = target || {};

    state[tabValue].name = value;

    setState(clone(state));
  };

  const handleAddArea = () => {
    const newArea = {
      name: 'Зона',
      disabilityPersonPlacesCount: 0,
      places: [],
    };

    state.push(newArea);

    setState(clone(state));
  };

  const handleDisabilityPersonPlacesCountChange = (event) => {
    const { target } = event || {};
    const {
      value,
    } = target || {};

    state[tabValue].disabilityPersonPlacesCount = value < 0 ? 0 : (`${parseInt(value, 10)}`);

    setState(clone(state));
  };

  const onSaveClick = () => {
    const result = state.map((area) => ({
      ...area,
      places: area.places.map((place, index) => ({
        ...place,
        name: place.name || (`${index + 1}`),
      })),
    }));

    setFacilityPlacesReg({ id, places: result }, history, !isRegistrationCompleted);
  };

  const onZoneDelete = () => {
    if (state.length === 1) {
      return;
    }

    state.splice(tabValue, 1);
    setTabValue(0);
    setState(clone(state));
  };

  const {
    loading: isLoading,
  } = oneFacilityPlacesState || {};

  const {
    loading: isSaveLoading,
  } = setFacilityPlacesState || {};

  if (isLoading) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn
          title={t('places.placesSetting')}
          isBackBtn={false}
        />
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title={t('places.placesSetting')}
        isBackBtn={false}
      />
      <Grid item xs={12} lg={9} className={styles.subContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <CustomTextInput
                  className={styles.inputItem}
                  label={t('places.area')}
                  value={state[tabValue].name}
                  disabled={!hasPlacesUpdateRight}
                  onChange={handleAreaChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTextInput
                  className={styles.inputItem}
                  label={t('places.placesCount')}
                  value={`${parseInt(state[tabValue].places.length, 10)}`}
                  autoComplete="off"
                  disabled
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTextInput
                  className={styles.inputItem}
                  label={t('places.placesInvalidCount')}
                  value={state[tabValue].disabilityPersonPlacesCount}
                  onChange={handleDisabilityPersonPlacesCountChange}
                  autoComplete="off"
                  disabled={!hasPlacesUpdateRight}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
              {/* {state.length !== 1 && hasPlacesUpdateRight && ( */}
              {/*  <Grid item xs={1}> */}
              {/*    <IconButton */}
              {/*      className={styles.iconButton} */}
              {/*      onClick={onZoneDelete} */}
              {/*    > */}
              {/*      <DeleteIcon /> */}
              {/*    </IconButton> */}
              {/*  </Grid> */}
              {/* )} */}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <PlacesSettingsTable
              facilityId={id}
              places={state[tabValue].places}
              maxPlacesCnt={state[tabValue].maxLicensePlacesCount}
              onChange={(newPlaces) => {
                setState((old) => old.map((tab, tabIndex) => {
                  if (tabIndex === tabValue) {
                    return {
                      ...tab,
                      places: newPlaces,
                    };
                  }

                  return tab;
                }));
              }}
            />
          </Grid>
          {hasPlacesUpdateRight && (
            <Grid item xs={6} md={4}>
              <CustomBtn
                className={styles.btnSubmit}
                btnType="primaryBlue"
                onClick={onSaveClick}
                disabled={isSaveLoading}
              >
                Сохранить
              </CustomBtn>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  const { facilities, companies, operators } = state || {};
  const { oneFacilityPlaces, setFacilityPlaces } = facilities || {};
  const { registrationStatus } = companies || {};
  const { currentOperator: currentOperatorState } = operators || {};

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    oneFacilityPlacesState: oneFacilityPlaces,
    setFacilityPlacesState: setFacilityPlaces,
    currentOperatorState,
  };
}

const ConnectedPlacesSettingsPage = connect(
  mapStateToProps,
  {
    getFacilityPlacesReg: getFacilityPlacesAction,
    setFacilityPlacesReg: setFacilityPlacesAction,
  },
)(PlacesSettingsPage);

export default ConnectedPlacesSettingsPage;
