import {
  getActiveTariffPlans,
  getActiveTariffs,
  getTariff,
  getTariffPlan,
  getTariffPlans,
  getTariffs,
  setTariff,
  setTariffPlan,
} from 'api/tariffs';
import {
  CLEAR_TARIFFS_CACHE,
  GET_ACTIVE_TARIFF_PLANS,
  GET_ACTIVE_TARIFFS,
  GET_ALL_TARIFF_PLANS,
  GET_ALL_TARIFFS,
  GET_ONE_TARIFF,
  GET_ONE_TARIFF_PLAN,
  SET_ONE_TARIFF,
  SET_ONE_TARIFF_PLAN,
} from './types';
import { PATH_PAGES } from '../config/constants';

export function clearTariffsCacheAction() {
  function actionClearCacheTariffs() {
    return { type: CLEAR_TARIFFS_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheTariffs());
  };
}

export function getAllTariffsAction(param) {
  function getAllTariffsRequest(payload) {
    return { type: GET_ALL_TARIFFS, payload };
  }

  return (dispatch) => {
    dispatch(getAllTariffsRequest({
      loading: true,
    }));

    getTariffs(param).then(
      (data) => {
        dispatch(getAllTariffsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAllTariffsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getActiveTariffsAction(param) {
  function getActiveTariffsRequest(payload) {
    return { type: GET_ACTIVE_TARIFFS, payload };
  }

  return (dispatch) => {
    dispatch(getActiveTariffsRequest({
      loading: true,
    }));

    getActiveTariffs(param).then(
      (data) => {
        dispatch(getActiveTariffsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getActiveTariffsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getOneTariffAction(param) {
  function getOneTariffRequest(payload) {
    return { type: GET_ONE_TARIFF, payload };
  }

  return (dispatch) => {
    dispatch(getOneTariffRequest({
      loading: true,
    }));

    const { id } = param;

    getTariff(param).then(
      (tariff) => {
        dispatch(getOneTariffRequest({
          loading: false,
          tariff,
        }));
      },
      (error) => {
        dispatch(getOneTariffRequest({
          loading: false,
          error: {
            id,
            error,
          },
        }));
      },
    );
  };
}

export function setTariffAction(params, history) {
  function setTariffRequest(payload) {
    return { type: SET_ONE_TARIFF, payload };
  }

  return (dispatch) => {
    dispatch(setTariffRequest({
      loading: true,
    }));

    setTariff(params).then(
      ({ id, success }) => {
        dispatch(setTariffRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearTariffsCacheAction());
          dispatch(getOneTariffAction({ facilityId, id }));
          history.replace(PATH_PAGES.allTariffSettings.replace(':facilityId', facilityId));
        }
      },
      (error) => {
        dispatch(setTariffRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getAllTariffPlansAction(param) {
  function getAllTariffPlansRequest(payload) {
    return { type: GET_ALL_TARIFF_PLANS, payload };
  }

  return (dispatch) => {
    dispatch(getAllTariffPlansRequest({
      loading: true,
    }));

    getTariffPlans(param).then(
      (data) => {
        dispatch(getAllTariffPlansRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAllTariffPlansRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getOneTariffPlanAction(param) {
  function getOneTariffPlanRequest(payload) {
    return { type: GET_ONE_TARIFF_PLAN, payload };
  }

  return (dispatch) => {
    dispatch(getOneTariffPlanRequest({
      loading: true,
    }));

    const { id } = param;

    getTariffPlan(param).then(
      (tariffPlan) => {
        dispatch(getOneTariffPlanRequest({
          loading: false,
          tariffPlan,
        }));
      },
      (error) => {
        dispatch(getOneTariffPlanRequest({
          loading: false,
          error: {
            id,
            error,
          },
        }));
      },
    );
  };
}

export function setTariffPlanAction(params, history) {
  function setTariffPlanRequest(payload) {
    return { type: SET_ONE_TARIFF_PLAN, payload };
  }

  return (dispatch) => {
    dispatch(setTariffPlanRequest({
      loading: true,
    }));

    setTariffPlan(params).then(
      ({ id, success }) => {
        dispatch(setTariffPlanRequest({
          loading: false,
        }));

        if (success) {
          const { facilityId } = params || {};

          dispatch(clearTariffsCacheAction());
          dispatch(getOneTariffPlanAction({ facilityId, id }));
          history.replace(PATH_PAGES.allTariffPlansSettings.replace(':facilityId', facilityId));
        }
      },
      (error) => {
        dispatch(setTariffPlanRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getActiveTariffPlansAction(param) {
  function getActiveTariffPlansRequest(payload) {
    return { type: GET_ACTIVE_TARIFF_PLANS, payload };
  }

  return (dispatch) => {
    dispatch(getActiveTariffPlansRequest({
      loading: true,
    }));

    getActiveTariffPlans(param).then(
      (data) => {
        dispatch(getActiveTariffPlansRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getActiveTariffPlansRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
