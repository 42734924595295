import { getCompany, getRegistrationStatus, setCompanyInfo } from 'api/company';
import {
  CLEAR_COMPANY_CACHE,
  CLEAR_REGISTRATION_STATUS_CACHE,
  GET_COMPANY,
  GET_REGISTRATION_STATUS,
  SET_COMPANY,
} from './types';
import { PATH_PAGES } from 'config/constants';

export function clearCompanyCacheAction() {
  function actionClearCacheCompany() {
    return { type: CLEAR_COMPANY_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheCompany());
  };
}

export function clearGetRegistrationStatusCacheAction() {
  function actionClearCacheRegistrationStatus() {
    return { type: CLEAR_REGISTRATION_STATUS_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheRegistrationStatus());
  };
}

export function getCompanyAction() {
  function getCompanyRequest(payload) {
    return { type: GET_COMPANY, payload };
  }

  return (dispatch) => {
    dispatch(getCompanyRequest({
      loading: true,
    }));

    getCompany().then(
      (company) => {
        dispatch(getCompanyRequest({
          loading: false,
          data: company,
        }));
      },
      (error) => {
        dispatch(getCompanyRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function setCompanyInfoAction(companyInfo, history, goNextPage) {
  function setCompanyRequest(payload) {
    return { type: SET_COMPANY, payload };
  }

  return (dispatch) => {
    dispatch(setCompanyRequest({
      loading: true,
    }));

    setCompanyInfo(companyInfo).then(
      () => {
        dispatch(setCompanyRequest({
          loading: false,
        }));

        dispatch(getCompanyAction());
        dispatch(getRegistrationStatusAction());

        if (goNextPage) {
          history.push(PATH_PAGES.facilitySettings.replace(':facilityId', 'new'))
        }
      },
      (error) => {
        dispatch(setCompanyRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getRegistrationStatusAction() {
  function getRegistrationStatusRequest(payload) {
    return { type: GET_REGISTRATION_STATUS, payload };
  }

  return (dispatch) => {
    dispatch(getRegistrationStatusRequest({
      loading: true,
    }));

    getRegistrationStatus().then(
      (status) => {
        dispatch(getRegistrationStatusRequest({
          loading: false,
          data: status,
        }));
      },
      (error) => {
        dispatch(getRegistrationStatusRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export default {
  getCompanyAction,
  setCompanyInfoAction,
  getRegistrationStatusAction,
};
