import React from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';

import { ACCESS_TOKEN_TYPES, ACCESS_TOKEN_TYPES_MAP, PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';
import { useCurrentFacilityId } from 'helpers/routerHooks';
import { formatPhoneNumber } from '@parkly/shared/helpers';
import { useStyles } from './styles';

/* help function */

function formatBlockValue(blockType, blockValue) {
  switch (blockType) {
    case ACCESS_TOKEN_TYPES.phone:
      return formatPhoneNumber(blockValue);
    default:
      return blockValue;
  }
}

function getHeaders({
  t,
}) {
  const headers = [
    {
      id: 'blockType',
      label: 'Тип',
    },
    {
      id: 'blockValue',
      label: 'Значение',
    },
    {
      id: 'createdAt',
      label: 'Дата создания',
    },
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'reason',
      label: 'Причина',
    },
    {
      id: 'operatorName',
      label: 'Оператор',
    },
  ];

  return headers.filter((item) => !!item);
}

function getRows(blackListData = []) {
  const result = blackListData.map((item) => item);

  return result;
}

const propTypes = {};

function BlackListTable({
  blackListData,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const currentFacilityId = useCurrentFacilityId();

  const hasBlacklistUpdateRight = useCommonRights(RIGHT_KEYS.blackListUpdate);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);

  const rows = getRows(blackListData);
  const isBlackListData = blackListData && (blackListData || []).length > 0;

  const headers = getHeaders({
    t,
  });

  return (
    <Paper className={classes.root}>
      {!isBlackListData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            Пока черный список пуст
          </Typography>
        </div>
      )}
      {isBlackListData && (
        <TableContainer className={classes.container}>
          <Table
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id }) => (
                  <TableCell
                    key={id}
                    align="left"
                  >
                    <div className={classes.headerContainer}>
                      <Typography className={classes.headerStr}>
                        {label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  createdAt,
                  reason,
                  operatorName,
                  active,
                  blockType,
                  blockValue,
                } = row || {};
                const key = id;

                const createdTimeStr = moment(createdAt).format('HH:mm:ss');
                const createdDateStr = moment(createdAt).format('DD.MM.yyyy');

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  hasBlacklistUpdateRight ? classes.clickable : '',
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    hover={hasBlacklistUpdateRight}
                    onClick={() => hasBlacklistUpdateRight ? history.push(PATH_PAGES.oneBlackList.replace(':id', id)) : null}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      {hasBlacklistUpdateRight ? (
                        <CustomLink
                          href={PATH_PAGES.oneBlackList.replace(':id', id)}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(PATH_PAGES.oneBlackList.replace(':id', id));
                          }}
                        >
                          {ACCESS_TOKEN_TYPES_MAP[blockType]}
                        </CustomLink>
                      ) : (
                        <Typography>
                          {ACCESS_TOKEN_TYPES_MAP[blockType]}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {hasCustomerShowRight ? (
                        <CustomLink
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(`${PATH_PAGES.allCustomers.replace(':facilityId', currentFacilityId)}?search=${blockValue}`);
                          }}
                        >
                          {formatBlockValue(blockType, blockValue)}
                        </CustomLink>
                      ) : (
                        <Typography>
                          {formatBlockValue(blockType, blockValue)}
                        </Typography>
                      )}

                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.timeStr}>
                        {createdTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {createdDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.statusStr}>
                        {active ? (
                          <>
                            <CircleIcon color="#43D37D" />
                            Активна
                          </>
                        ) : (
                          <>
                            <CircleIcon color="red" />
                            Выключено
                          </>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {reason}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {operatorName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

BlackListTable.propTypes = propTypes;

export default BlackListTable;
