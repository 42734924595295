import { createMutation, createQuery } from 'react-query-kit';
import { getFacilityCustomSettings, setFacilityCustomSettings } from 'api/facilities';

export const useFacilityCustomSettings = createQuery({
  primaryKey: 'facility-custom-settings',
  queryFn: ({ queryKey: [_, { facilityId }] }) => {
    return getFacilityCustomSettings({ facilityId });
  },
  retry: false,
});

export const useSetFacilityCustomSettingsMutation = createMutation({
  mutationFn: async (params) => setFacilityCustomSettings(params),
});
