import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',

    height: 48,
    width: '100%',

    display: 'flex',
    flexFlow: 'column-reverse',

    cursor: 'pointer',
  },

  '.label, .input': {
    //transition: all 0.2s;
    touchAction: 'manipulation',
  },

  input: {
    width: '100%',
    /* height: 100%;*/

    border: 'none',
    /*  border-radius: 0;*/

    cursor: 'pointer',
    '-webkit-appearance': 'none',

    background: theme.palette.greyC.lightGrey,

    '&:focus': {
      border: 'none',
      outline: 0,
    },

    /* -- */

    '&::-webkit-calendar-picker-indicator': {
      marginLeft: '-20px !important',
      marginRight: '0 !important',
    },

    minHeight: 48,
    borderRadius: 6,
    padding: '28px 12px 11px 12px',

    /* fix problem with to large input*/
    '&[type="datetime-local"]': {
      paddingRight: 0,
      paddingLeft: 0,

      '@media (min-width: 350px)': {
        padding: '28px 0 11px 12px',
      },
      '@media (min-width: 380px)': {
        padding: '28px 12px 11px 12px',
      },
    },

    /* focused or selected */

    '&.selected, &:focus': {
      zIndex: 2,
    },

    /* error */

    '&.error': {
      zIndex: 1,
      boxShadow: '0px 0px 0px 2.4px red', // $red

      '&:focus': {
        zIndex: 2,
        boxShadow: '0px 0px 0px 2.4px red', // $red
      },
    },

    '&.loading': {
      color: 'transparent',
    },
  },

  label: {
    zIndex: 3,
    maxWidth: '66.66%',
    transformOrigin: 'left bottom',
    transform: 'translate(12px, 45px) scale(1.5)',

    // '@at-root .input:focus + .label': {
    //   transform: 'translate(12px, 24px) scale(1)',
    // },

    color: 'grey', //$greyDark;
    fontSize: 12,
    cursor: 'pointer',
  },

  labelWithPlaceholder: {
    transform: 'translate(12px, 24px) scale(1)',
  },

  desktop: {
    caretColor: 'transparent',
  },
}));

export default {
  useStyles,
};
