import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { CircularProgress } from '@material-ui/core';
import { finishCustomerGateSession, startCustomerGateSession } from 'api/customers';

function CustomerGatesControls({
  gateControlButtons,
  customerId,
  facilityId,

  onSuccess = () => {},
}) {
  const [loadingBtn, setLoadingBtn] = useState(null);

  const handleStart = useCallback((id) => {
    setLoadingBtn(id);

    startCustomerGateSession({
      id,
      facilityId,
      customerId,
    }).then(({ success }) => {
      setLoadingBtn(null);
      if (success) {
        onSuccess();
      }
    }).catch(() => {
      setLoadingBtn(null);
    });
  }, [customerId, facilityId, onSuccess]);

  const handleFinish = useCallback((id) => {
    finishCustomerGateSession({
      id,
      facilityId,
      customerId,
    }).then(({ success }) => {
      if (success) {
        onSuccess();
      }
      setLoadingBtn(null);
    }).catch(() => {
      setLoadingBtn(null);
    });
  }, [customerId, facilityId, onSuccess]);

  const createOnClickHandler = (id, type) => () => {
    switch (type) {
      case 'start':
        handleStart(id);
        break;
      case 'finish':
        handleFinish(id);
        break;
      default:
        console.log('not implemented');
    }
  };

  return (
    <Grid container spacing={2}>
      {(gateControlButtons || []).map(({ id, title, type }) => (
        <Grid key={id} item>
          <CustomBtn
            onClick={createOnClickHandler(id, type)}
            btnType={type === 'start' ? 'primaryGreen' : 'primaryRed'}
            disabled={id === loadingBtn}
          >
            {title}
            {id === loadingBtn && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={20}
                color="inherit"
              />
            )}
          </CustomBtn>
        </Grid>
      ))}
    </Grid>
  );
}

export default CustomerGatesControls;
