import React from 'react';

function GuestPassesIcon({
  fill = '#fff',
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M14.25 11.5C14.25 12.4665 13.4665 13.25 12.5 13.25V14.75C14.2949 14.75 15.75 13.2949 15.75 11.5H14.25ZM12.5 9.75C13.4665 9.75 14.25 10.5335 14.25 11.5H15.75C15.75 9.70507 14.2949 8.25 12.5 8.25V9.75ZM10.75 11.5C10.75 10.5335 11.5335 9.75 12.5 9.75V8.25C10.7051 8.25 9.25 9.70507 9.25 11.5H10.75ZM12.5 13.25C11.5335 13.25 10.75 12.4665 10.75 11.5H9.25C9.25 13.2949 10.7051 14.75 12.5 14.75V13.25ZM7.25 20.25C7.25 18.638 8.65874 17.25 10.5 17.25V15.75C7.92298 15.75 5.75 17.7199 5.75 20.25H7.25ZM7.25 21V20.25H5.75V21H7.25ZM8.1 21.75C7.58423 21.75 7.25 21.3694 7.25 21H5.75C5.75 22.2875 6.84846 23.25 8.1 23.25V21.75ZM16.9 21.75H8.1V23.25H16.9V21.75ZM17.75 21C17.75 21.3694 17.4158 21.75 16.9 21.75V23.25C18.1515 23.25 19.25 22.2875 19.25 21H17.75ZM17.75 20.25V21H19.25V20.25H17.75ZM14.5 17.25C16.3413 17.25 17.75 18.638 17.75 20.25H19.25C19.25 17.7199 17.077 15.75 14.5 15.75V17.25ZM10.5 17.25H14.5V15.75H10.5V17.25Z"
        fill={fill}
      />
      <path
        d="M21 11H25M21 15H25"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="3"
        y="6"
        width="26"
        height="20"
        rx="2"
        stroke={fill}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default GuestPassesIcon;
