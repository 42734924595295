import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllTicketsSettingsPage from 'components/pages/settings/tickets/AllTicketsSettingsPage';
import OneTicketSettingsPage from 'components/pages/settings/tickets/OneTicketSettingsPage';

import { PATH_PAGES } from 'config/constants';

function TicketsSettingsPage({
  match,
  currentFacilityState,
}) {
  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const {
    loading: isLoading,
  } = currentFacilityState || {};

  const urlActions = PATH_PAGES.allTicketsSettings.replace(':facilityId', currentFacilityId);

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.allTicketsSettings} component={AllTicketsSettingsPage} />
      <PrivateRoute exact path={PATH_PAGES.newTicketSettings} component={OneTicketSettingsPage} />
      <PrivateRoute exact path={PATH_PAGES.oneTicketSettings} component={OneTicketSettingsPage} />
      <Redirect to={urlActions} />
    </ParentPageContainer>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentFacilityState,
  };
}

const ConnectedTicketsSettingsPage = connect(
  mapStateToProps,
  {},
)(TicketsSettingsPage);

export default ConnectedTicketsSettingsPage;
