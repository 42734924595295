import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  iframe: {
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    height: 'calc(100vh - 100px)',
    width: '100%',
    border: 'none',
  },
}));

export default {
  useStyles,
};
