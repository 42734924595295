import { getAllBlackList, getOneBlackList, removeBlackList, updateOrCreateBlackList } from 'api/blackList';
import { PATH_PAGES } from 'config/constants';
import {
  CLEAR_BLACK_LIST_CACHE,
  CREATE_BLACK_LIST,
  DELETE_ONE_BLACK_LIST,
  GET_ALL_BLACK_LIST,
  GET_ONE_BLACK_LIST,
  SET_STATUS_ONE_BLACK_LIST,
} from './types';

export function clearBlackListCacheAction() {
  function actionClearCacheBlackList() {
    return { type: CLEAR_BLACK_LIST_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheBlackList());
  };
}

export function getAllBlackListAction(param) {
  function getAllBlackListRequest(payload) {
    return { type: GET_ALL_BLACK_LIST, payload };
  }

  return (dispatch) => {
    dispatch(getAllBlackListRequest({
      loading: true,
    }));

    getAllBlackList(param).then(
      (data) => {
        dispatch(getAllBlackListRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAllBlackListRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getOneBlackListAction({ id }) {
  function getOneBlackListRequest(payload) {
    return { type: GET_ONE_BLACK_LIST, payload };
  }

  return (dispatch) => {
    dispatch(getOneBlackListRequest({
      loading: true,
    }));

    getOneBlackList({ id }).then(
      (blackList) => {
        dispatch(getOneBlackListRequest({
          loading: false,
          blackList,
        }));
      },
      (error) => {
        dispatch(getOneBlackListRequest({
          loading: false,
          error: {
            error,
            id,
          },
        }));
      },
    );
  };
}

export function createBlackListAction(param, history) {
  function createBlackListRequest(payload) {
    return { type: CREATE_BLACK_LIST, payload };
  }

  return (dispatch) => {
    dispatch(createBlackListRequest({
      loading: true,
    }));

    updateOrCreateBlackList(param).then(
      (result) => {
        dispatch(createBlackListRequest({
          loading: false,
        }));
        dispatch(clearBlackListCacheAction());
        const { id } = result || {};

        if (id || id === 0) {
          const url = PATH_PAGES.oneBlackList.replace(':id', id);
          history.replace(url);
        }
      },
      (error) => {
        dispatch(createBlackListRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function updateActiveBlackListAction(param) {
  function updateBlackListRequest(payload) {
    return { type: SET_STATUS_ONE_BLACK_LIST, payload };
  }

  return (dispatch) => {
    dispatch(updateBlackListRequest({
      loading: true,
    }));

    updateOrCreateBlackList(param).then(
      (result) => {
        dispatch(updateBlackListRequest({
          loading: false,
        }));
        const { id } = result || {};

        dispatch(getOneBlackListAction({ id }));
      },
      (error) => {
        dispatch(updateBlackListRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function removeBlackListAction(param, history) {
  function removeBlackListRequest(payload) {
    return { type: DELETE_ONE_BLACK_LIST, payload };
  }

  return (dispatch) => {
    dispatch(removeBlackListRequest({
      loading: true,
    }));

    removeBlackList(param).then(
      ({ success }) => {
        dispatch(removeBlackListRequest({
          loading: false,
        }));
        if (success) {
          history.replace(PATH_PAGES.allBlackList);
        }
      },
      (error) => {
        dispatch(removeBlackListRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

