import {
  ADD_NEW_OPERATOR_REQUEST,
  GET_ACTIONS_ONE_OPERATOR_REQUEST,
  GET_CURRENT_OPERATOR, GET_LIST_360_MODULES,
  GET_LIST_360_RIGHTS,
  GET_LIST_360_ROLES,
  GET_ONE_OPERATOR_REQUEST,
  GET_OPERATORS,
  REMOVE_OPERATOR_REQUEST,
  UPDATE_OPERATOR_REQUEST,
} from 'actions/types';

const INITIAL_STATE = {
  operators: {
    loading: false,
    data: null,
  },
  oneOperator: {
    loading: false,
    list: [],
  },
  actionsOneOperator: {
    loading: false,
    list: [],
  },
  addNewOperator: {
    loading: false,
  },
  updateOperator: {
    loading: false,
  },
  removeOperator: {
    loading: false,
  },
  rightList: {
    loading: false,
    list: [],
  },
  roleList: {
    loading: false,
    list: [],
  },
  modulesList: {
    loading: false,
    list: [],
  },
  currentOperator: {
    loading: false,
    data: null,
    userRights: {},
  },
};

function updateOneOperatorList({
  list,
  newOperator,
  error,
}) {
  const { id } = newOperator || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newOperator || error,
  ];

  return newList;
}

function updateActionsOneOperatorList({
  list,
  newActions,
  operatorId,
}) {
  if (!newActions) {
    return list;
  }

  const {
    data,
    meta,
  } = newActions;

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const currentId = (item || {}).operatorId;
        const isFilter = currentId !== operatorId;

        return isFilter;
      }),
    {
      actions: data,
      meta,
      operatorId,
    },
  ];

  return newList;
}

function getNewOperatorState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

function getUpdateOperatorState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function operators(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CURRENT_OPERATOR:
      return {
        ...state,
        currentOperator: {
          ...state.currentOperator,
          ...action.payload,
        },
      };
    case GET_OPERATORS:
      return {
        ...state,
        operators: {
          ...state.operators,
          ...action.payload,
        },
      };

    case GET_ONE_OPERATOR_REQUEST:
      return {
        ...state,
        oneOperator: {
          ...state.oneOperator,
          loading: action.payload.loading,
          list: updateOneOperatorList({
            list: state.oneOperator.list,
            newOperator: action.payload.operator,
            error: action.payload.error,
          }),
        },
      };

    case GET_ACTIONS_ONE_OPERATOR_REQUEST:
      return {
        ...state,
        actionsOneOperator: {
          ...state.oneOperator,
          loading: action.payload.loading,
          list: updateActionsOneOperatorList({
            list: state.actionsOneOperator.list,
            newActions: action.payload.actions,
            operatorId: action.payload.operatorId,
          }),
        },
      };

    case ADD_NEW_OPERATOR_REQUEST:
      return {
        ...state,
        addNewOperator: getNewOperatorState({
          prevState: state.addNewOperator,
          payload: action.payload,
        }),
      };

    case UPDATE_OPERATOR_REQUEST:
      return {
        ...state,
        updateOperator: getUpdateOperatorState({
          prevState: state.updateOperator,
          payload: action.payload,
        }),
      };

    case REMOVE_OPERATOR_REQUEST:
      return {
        ...state,
        removeOperator: {
          ...state.removeOperator,
          ...action.payload,
        },
      };

    case GET_LIST_360_RIGHTS:
      return {
        ...state,
        rightList: {
          ...state.rightList,
          ...action.payload,
        },
      };

    case GET_LIST_360_ROLES:
      return {
        ...state,
        roleList: {
          ...state.roleList,
          ...action.payload,
        },
      };

    case GET_LIST_360_MODULES:
      return {
        ...state,
        modulesList: {
          ...state.modulesList,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
