import React, { useEffect, useRef } from 'react';
import {
  axisBottom,
  axisLeft,
  extent,
  max,
  scaleLinear,
  scaleTime,
  select,
  stack,
  stackOffsetNone,
  stackOrderNone,
  timeDay,
} from 'd3';
import moment from 'moment-timezone';
import { hideTooltip, showTooltip } from '@parkly/shared/helpers';

import { useStyles } from './styles';

function AnalyticBarChart({
  barChartData,
  width = 1060,
  height = 400,
  measurementUnit = '',
}) {
  const d3Container = useRef(null);
  const styles = useStyles();

  useEffect(() => {
    if (!barChartData || !d3Container.current) {
      return;
    }

    const margin = {
      top: 25,
      right: 25,
      bottom: 50,
      left: 88,
      leftAxis: 60,
    };
    const chartWidth = width - margin.left - margin.right;
    const spaceBetween = 1;
    const sizes = {
      bandwidth: Math.round(100 * (chartWidth / barChartData.length - spaceBetween)) / 100,
      spaceBetween,
    };

    const yMax = max(barChartData, ({ value }) => parseInt(value, 10)) || 5;

    const barChartDataWithNoValue = barChartData.map(({ value, ...rest }) => ({
      value,
      noValue: yMax - value,
      ...rest,
    }));

    const keysList = ['value', 'noValue'];

    const stackForData = stack()
      .keys(keysList)
      .order(stackOrderNone)
      .offset(stackOffsetNone);
    const stackedSeries = stackForData(barChartDataWithNoValue);

    const svg = select(d3Container.current);

    const yScale = scaleLinear()
      .domain([0, yMax])
      .range([height - margin.bottom, margin.top]);

    /* Bar chart start */

    svg.selectAll('.stacked')
      .data(stackedSeries)
      .join('g')
      .attr('class', (d, i) => `stacked ${styles.stacked} ${styles[keysList[i]]}`)
      .selectAll('rect')
      .data((d) => d)
      .join(
        (enter) => enter
          .append('rect')
          .attr('y', height - margin.bottom)
          .attr('height', 0),
        (update) => update,
        (exit) => exit
          .remove(),
      )
      .attr('class', (d) => (d[1] === d.data.value
        ? styles.reservedRect
        : styles.emptyRect))
      .attr('x', (d, i) => (margin.left + i * (sizes.spaceBetween + sizes.bandwidth)))
      .attr('width', sizes.bandwidth)
      .on('mousemove', (currentEvent, d) => {
        const isFilled = d[1] === d.data.value;
        const timeStr = moment(d.data.date).format('DD MMM');

        const value = Math.round(100 * d.data.value) / 100;
        const noValue = Math.round(100 * (yMax - d.data.noValue)) / 100;

        const toolTipStr = isFilled
          ? `${timeStr}, ${value} ${measurementUnit}`
          : `${timeStr}, ${noValue} ${measurementUnit}`;
        const toolTipHtml = `
          <div class=${styles.tooltipNode}>
            <div class="${styles.tooltipCircle}"></div>
            <Typography
              class=${styles.tooltipStr}
            >
              ${toolTipStr}
            </Typography>
          </div>
        `;

        showTooltip(currentEvent, toolTipHtml);
      })
      .on('mouseout', hideTooltip)
      .transition()
      .duration(500)
      // .delay(250)
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]));

    /* Bar chart end */

    if (barChartData.length > 1) {
      /* Axis start */

      const yAxis = axisLeft()
        .ticks(6)
        // .tickSize(6)
        .tickPadding(10)
        .tickSizeInner(-(width - margin.leftAxis))
        .tickFormat((val) => `${val} ${measurementUnit}`)
        .scale(yScale);

      const yAxisG = svg.select('.yAxis').empty()
        ? svg.append('g')
          .attr('class', `yAxis ${styles.yAxis}`)
          .attr('transform', `translate(${margin.left}, 0)`)
        : svg.select('.yAxis');

      yAxisG
        .transition()
        .duration(500)
        .call(yAxis);

      const dateDomain = extent(barChartData, ({ date }) => moment(date));
      const xScale = scaleTime()
        .domain(dateDomain)
        .range([
          margin.left,
          margin.left
            + (barChartData.length - 1) * (sizes.spaceBetween + sizes.bandwidth),
        ]);

      const xAxis = axisBottom()
        .ticks(timeDay.every(1))
        // .ticks(24)
        .tickPadding(10)
        .tickFormat((date) => moment(date).format('DD MMM'))
        .scale(xScale);

      const xAxisG = svg.select('.xAxis').empty()
        ? svg.append('g')
          .attr('class', `xAxis ${styles.xAxis}`)
          .attr('transform', `translate(${sizes.bandwidth / 2}, ${height - margin.bottom})`)
        : svg.select('.xAxis')
          .attr('transform', `translate(${sizes.bandwidth / 2}, ${height - margin.bottom})`);

      xAxisG
        .transition()
        .duration(500)
        .call(xAxis);
      /* Axis end */
    }
  }, [barChartData, height, measurementUnit, styles, width]);

  return (
    <svg
      ref={d3Container}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    />
  );
}

export default AnalyticBarChart;
