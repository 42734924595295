import React, { useCallback, useMemo } from 'react';
import stc from 'string-to-color';
import clone from 'lodash/clone';
import fill from 'lodash/fill';
import { Chip, Grid, Table, TableBody, TableContainer } from '@material-ui/core';
import { TARIFF_INTERVALS } from 'config/constants';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { useFormFields } from '@parkly/shared/helpers';

import { useStyles } from './styles';

const WEEK_DAY_SELECT = [
  { id: 0, title: 'Все дни недели' },
  { id: 1, title: 'Понедельник' },
  { id: 2, title: 'Вторник' },
  { id: 3, title: 'Среда' },
  { id: 4, title: 'Четверг' },
  { id: 5, title: 'Пятница' },
  { id: 6, title: 'Суббота' },
  { id: 7, title: 'Воскресенье' },
];

const FROM_TIME_SELECT = [
  { id: 0, title: '00:00' },
  { id: 1, title: '01:00' },
  { id: 2, title: '02:00' },
  { id: 3, title: '03:00' },
  { id: 4, title: '04:00' },
  { id: 5, title: '05:00' },
  { id: 6, title: '06:00' },
  { id: 7, title: '07:00' },
  { id: 8, title: '08:00' },
  { id: 9, title: '09:00' },
  { id: 10, title: '10:00' },
  { id: 11, title: '11:00' },
  { id: 12, title: '12:00' },
  { id: 13, title: '13:00' },
  { id: 14, title: '14:00' },
  { id: 15, title: '15:00' },
  { id: 16, title: '16:00' },
  { id: 17, title: '17:00' },
  { id: 18, title: '18:00' },
  { id: 19, title: '19:00' },
  { id: 20, title: '20:00' },
  { id: 21, title: '21:00' },
  { id: 22, title: '22:00' },
  { id: 23, title: '23:00' },
];

const TO_TIME_SELECT = [
  { id: 1, title: '01:00' },
  { id: 2, title: '02:00' },
  { id: 3, title: '03:00' },
  { id: 4, title: '04:00' },
  { id: 5, title: '05:00' },
  { id: 6, title: '06:00' },
  { id: 7, title: '07:00' },
  { id: 8, title: '08:00' },
  { id: 9, title: '09:00' },
  { id: 10, title: '10:00' },
  { id: 11, title: '11:00' },
  { id: 12, title: '12:00' },
  { id: 13, title: '13:00' },
  { id: 14, title: '14:00' },
  { id: 15, title: '15:00' },
  { id: 16, title: '16:00' },
  { id: 17, title: '17:00' },
  { id: 18, title: '18:00' },
  { id: 19, title: '19:00' },
  { id: 20, title: '20:00' },
  { id: 21, title: '21:00' },
  { id: 22, title: '22:00' },
  { id: 23, title: '23:00' },
  { id: 24, title: '24:00' },
];

function TariffPlanDetailSchedules({
  activeTariffs = [],
  schedules,
  onChange,
  disabled = false,
}) {
  const styles = useStyles();

  const [fields, errors, onChangeForm, setErrors] = useFormFields({
    initValues: {
      weekDay: 0,
      from: 0,
      to: 24,
      tariff: '',
    },
  });

  const tariffSelectData = useMemo(() => ((activeTariffs || [])
    .filter(({ interval }) => (
      [
        TARIFF_INTERVALS.min,
        TARIFF_INTERVALS.day,
        TARIFF_INTERVALS.hour,
      ].includes(interval)
    ))
    .map(({ id, name }) => ({
      id,
      title: name,
    }))
  ), [activeTariffs]);

  const handleApply = useCallback(() => {
    const weekDayValid = !!fields.weekDay || fields.weekDay === 0;
    const fromValid = !!fields.from || fields.from === 0;
    const toValid = !!fields.to || fields.to === 0;
    const fromToValid = fields.to > fields.from;
    const tariffValid = !!fields.tariff;

    if (!weekDayValid || !fromValid || !toValid || !tariffValid || !fromToValid) {
      setErrors({
        weekDay: !weekDayValid,
        from: !fromValid || !fromToValid,
        to: !toValid || !fromToValid,
        tariff: !tariffValid,
      });
      return;
    }

    if (fields.weekDay === 0) {
      const newSchedules = fill(
        schedules,
        [fields.tariff, fields.tariff, fields.tariff, fields.tariff, fields.tariff, fields.tariff, fields.tariff],
        fields.from,
        fields.to,
      );

      onChange(newSchedules);
    } else {
      const newSchedules = schedules.map((item, index) => {
        const newItem = clone(item);
        if (index >= fields.from && index < fields.to) {
          newItem[fields.weekDay - 1] = fields.tariff;
        }

        return newItem;
      });
      onChange(newSchedules);
    }
  }, [fields, onChange, schedules, setErrors]);

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item container xs={8}>
        <TableContainer className={styles.tableContainer}>
          <Table
            size="small"
            stickyHeader
            padding="checkbox"
            aria-label="sticky table dense"
          >
            <TableHead>
              <TableRow>
                <TableCell className={styles.head} align="center" />
                <TableCell className={styles.head} align="center">
                  ПН
                </TableCell>
                <TableCell className={styles.head} align="center">
                  ВТ
                </TableCell>
                <TableCell className={styles.head} align="center">
                  СР
                </TableCell>
                <TableCell className={styles.head} align="center">
                  ЧТ
                </TableCell>
                <TableCell className={styles.head} align="center">
                  ПТ
                </TableCell>
                <TableCell className={styles.head} align="center">
                  СБ
                </TableCell>
                <TableCell className={styles.head} align="center">
                  ВС
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedules.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className={styles.head} align="center">
                    {('' + index).padStart(2, '0') + ':00'}
                  </TableCell>
                  <TableCell style={{ backgroundColor: (item[0] ? stc(item[0]) : '#DDE0E8') }} className={styles.body} />
                  <TableCell style={{ backgroundColor: (item[1] ? stc(item[1]) : '#DDE0E8') }} className={styles.body} />
                  <TableCell style={{ backgroundColor: (item[2] ? stc(item[2]) : '#DDE0E8') }} className={styles.body} />
                  <TableCell style={{ backgroundColor: (item[3] ? stc(item[3]) : '#DDE0E8') }} className={styles.body} />
                  <TableCell style={{ backgroundColor: (item[4] ? stc(item[4]) : '#DDE0E8') }} className={styles.body} />
                  <TableCell style={{ backgroundColor: (item[5] ? stc(item[5]) : '#DDE0E8') }} className={styles.body} />
                  <TableCell style={{ backgroundColor: (item[6] ? stc(item[6]) : '#DDE0E8') }} className={styles.body} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container spacing={2} item xs={4} direction="row">
        <Grid item xs={12}>
          <Typography className={styles.label}>
            Тарифы:
          </Typography>
          <div
            className={[
              styles.tariffChipContainer,
              disabled ? styles.tariffChipContainerFullHeight : '',
            ].join(' ')}
          >
            {(activeTariffs || []).map(({ id, name }) => (
              <Chip
                key={id}
                className={styles.tariffChip}
                label={name}
                style={{ backgroundColor: stc(id) }}
              />
            ))}
          </div>
          {!disabled && (
            <>
              <Typography className={styles.label}>
                Заполните через диапазоны:
              </Typography>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <ItemSelectors
                    classNameForm={styles.selectorsForm}
                    classNameLabel={styles.selectorsLabel}
                    className={styles.selector}
                    label="День недели"
                    items={WEEK_DAY_SELECT}
                    disabled={disabled}
                    name="weekDay"
                    error={errors.weekDay}
                    onChange={onChangeForm}
                    currentValue={fields.weekDay}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ItemSelectors
                    classNameForm={styles.selectorsForm}
                    classNameLabel={styles.selectorsLabel}
                    className={styles.selector}
                    label="От"
                    items={FROM_TIME_SELECT}
                    disabled={disabled}
                    name="from"
                    error={errors.from}
                    onChange={onChangeForm}
                    currentValue={fields.from}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ItemSelectors
                    classNameForm={styles.selectorsForm}
                    classNameLabel={styles.selectorsLabel}
                    className={styles.selector}
                    label="До"
                    items={TO_TIME_SELECT}
                    disabled={disabled}
                    name="to"
                    error={errors.to}
                    onChange={onChangeForm}
                    currentValue={fields.to}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ItemSelectors
                    classNameForm={styles.selectorsForm}
                    classNameLabel={styles.selectorsLabel}
                    className={styles.selector}
                    items={tariffSelectData}
                    label="Тариф"
                    disabled={disabled}
                    name="tariff"
                    error={errors.tariff}
                    onChange={onChangeForm}
                    currentValue={fields.tariff}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomBtn
                    className={styles.applyIntervalBtn}
                    btnType="primaryBlue"
                    disabled={disabled}
                    onClick={handleApply}
                  >
                    Применить
                  </CustomBtn>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TariffPlanDetailSchedules;
