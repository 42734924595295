import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  tableContainer: {

  },
  selectorsForm: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  head: {
    border: '1px solid white',
    color: 'white',
    fontWeight: 700,
    // cursor: 'pointer',
    backgroundColor: theme.palette.greyC.gray2,
    // '&:hover': {
    //   backgroundColor: theme.palette.greyC.darkGrey,
    // },
  },
  body: {
    border: '1px solid white',
    fontWeight: 700,
    // cursor: 'pointer',
    backgroundColor: theme.palette.greyC.buttonGrey,
    // '&:hover': {
    //   backgroundColor: theme.palette.greenC.green1,
    // },
  },
  selected: {
  },
  label: {
    marginBottom: 8,
  },
  tariffChipContainer: {
    maxHeight: 185,
    overflow: 'auto',
    paddingRight: 16,
    marginBottom: 8,
  },
  tariffChipContainerFullHeight: {
    maxHeight: 'unset',
  },
  tariffChip: {
    marginTop: 8,
    display: 'flex',
    '& .MuiChip-label': {
      color: 'white',
      fontSize: 14,
      fontWeight: 700,
    },
  },
  applyIntervalBtn: {
    width: '100%',
    height: 48,
  },
}));

export default {
  useStyles,
};
