import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';

import { PATH_PAGES, TARIFF_INTERVAL_MAP, TARIFF_STATUS, TARIFF_TYPE } from 'config/constants';

import { useStyles } from './styles';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { useHistory } from 'react-router-dom';

/* help function */

const getStatusContentByStatus = ({ status, t }) => {
  switch (status) {
    case TARIFF_STATUS.active:
      return (
        <>
          <CircleIcon color="#43D37D" />
          {t('tariffs.statuses.active')}
        </>
      );
    case TARIFF_STATUS.draft:
      return (
        <>
          <CircleIcon color="blue" />
          {t('tariffs.statuses.draft')}
        </>
      );
    case TARIFF_STATUS.archive:
      return (
        <>
          <CircleIcon color="red" />
          {t('tariffs.statuses.archive')}
        </>
      );
    default:
      return (
        <>
          -
        </>
      );
  }
};

const getTypeStr = ({ type, t }) => {
  switch (type) {
    case TARIFF_TYPE.standard:
      return t('tariffs.types.standard');
    case TARIFF_TYPE.interval:
      return t('tariffs.types.interval');
    case TARIFF_TYPE.cumulative:
      return t('tariffs.types.cumulative');
    default:
      return '-';
  }
};

function getHeaders({
  t,
}) {
  const headers = [
    {
      id: 'name',
      label: t('tariffs.tariff'),
    },
    {
      id: 'intervalPrecision',
      label: t('tariffs.intervalPrecision'),
    },
    {
      id: 'type',
      label: t('tariffs.type'),
    },
    {
      id: 'status',
      label: t('tariffs.status'),
    },
  ];

  return headers.filter((item) => !!item);
}

function getRows(tariffsData = []) {
  const result = tariffsData.map((item) => item);

  return result;
}

const propTypes = {};

function TariffsTable({
  tariffsData,
  facilityId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const rows = getRows(tariffsData);
  const isTariffsData = tariffsData && (tariffsData || []).length > 0;

  const headers = getHeaders({
    t,
  });

  const createOnTariffClickHandler = useCallback((id) => {
    return (e) => {
      e.preventDefault();
      history.push(
        PATH_PAGES.oneTariffSettings
          .replace(':facilityId', facilityId)
          .replace(':tariffId', id),
      );
    };
  }, [facilityId, history]);

  return (
    <Paper className={classes.root}>
      {!isTariffsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            {t('tariffs.noDataYet')}
          </Typography>
        </div>
      )}
      {isTariffsData && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id }) => (
                  <TableCell key={id} align="left">
                    <div className={classes.headerContainer}>
                      <Typography className={classes.headerStr}>
                        {label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  name,
                  interval,
                  precision,
                  type,
                  status,
                } = row || {};
                const key = id;

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <CustomLink
                        href={PATH_PAGES.oneTariffSettings.replace(':facilityId', facilityId).replace(':tariffId', id)}
                        onClick={createOnTariffClickHandler(id)}
                        className={classes.nameStr}
                      >
                        {name}
                      </CustomLink>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.intervalPrecisionStr}>
                        {`${TARIFF_INTERVAL_MAP[interval]}/${TARIFF_INTERVAL_MAP[precision]}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.typeStr}>
                        {getTypeStr({ type, t })}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.statusStr}>
                        {getStatusContentByStatus({ status, t })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

TariffsTable.propTypes = propTypes;

export default TariffsTable;
