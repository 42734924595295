import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import PlusIcon from '@parkly/shared/components/atoms/icons/PlusIcon';
import { useModal } from '@parkly/shared/helpers';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import BarrierIcon from '@parkly/shared/components/atoms/icons/BarrierIcon';
import GateIcon from '@parkly/shared/components/atoms/icons/GateIcon';
import DoorIcon from '@parkly/shared/components/atoms/icons/DoorIcon';

import { getFacilityGatesAction } from 'actions/facilities';
import { GATE_TYPES, RIGHT_KEYS } from 'config/constants';

import SetGateModalContent from './SetGateModalContent';

import { useStyles } from './styles';
import { useCheckFacilityRights } from '../../../../helpers/hooks';

function getCurrentGates({
  match,
  allFacilityGatesState,
}) {
  const facilityId = ((match || {}).params || {}).facilityId;

  const {
    loading: isLoading,
    list: gatesList,
    error: allGatesError,
  } = allFacilityGatesState || {};

  const currentStrParam = JSON.stringify({
    id: facilityId,
  });

  const currentGates = gatesList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    currentGates,
    allGatesError,
  };
}

function GatesIntegrationSetting({
  match,

  allFacilityGatesState,
  getFacilityGatesReg,

  currentOperatorState,
}) {
  const styles = useStyles();
  const facilityId = ((match || {}).params || {}).facilityId;

  const [selGate, setSelGate] = useState(null);

  const hasAutomationUpdateRight = useCheckFacilityRights(RIGHT_KEYS.automationUpdate, facilityId);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      currentGates,
      allGatesError,
      isLoading,
    } = getCurrentGates({
      match,
      allFacilityGatesState,
    });

    if (isLoading || currentGates || allGatesError) {
      return;
    }

    getFacilityGatesReg({ id: facilityId });
  }, [allFacilityGatesState, match, facilityId, getFacilityGatesReg]);

  const {
    currentGates,
    isLoading,
  } = getCurrentGates({
    match,
    allFacilityGatesState,
  });

  const {
    data: gatesData,
  } = currentGates || {};

  const [
    addGateModalNode,
    onOpenGateModal,
    onHideGateModal,
  ] = useModal({
    content: (
      <SetGateModalContent
        id={selGate}
        facilityId={facilityId}
        onClose={() => {
          onHideGateModal();
        }}
      />
    ),
  });

  const createUpdateGateHandler = (id) => {
    if (!hasAutomationUpdateRight) {
      return () => {};
    }

    return () => {
      setSelGate(id);
      onOpenGateModal();
    };
  };

  const handleOpenAddGate = () => {
    setSelGate(null);
    onOpenGateModal();
  };

  if (isLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  const GateComponent = hasAutomationUpdateRight ? CustomLink : Typography;

  return (
    <Grid container spacing={2} direction="column">
      {(gatesData || []).map(({ id, name, type, direction }) => (
        <Grid item key={id}>
          <GateComponent
            className={styles.devicesLink}
            onClick={createUpdateGateHandler(id)}
          >
            {type === GATE_TYPES.barrier && (
              <BarrierIcon />
            )}
            {type === GATE_TYPES.gate && (
              <GateIcon />
            )}
            {type === GATE_TYPES.door && (
              <DoorIcon />
            )}
            {name}
          </GateComponent>
        </Grid>
      ))}
      {hasAutomationUpdateRight && (
        <Grid item>
          <CustomLink
            className={styles.addDevicesLink}
            onClick={handleOpenAddGate}
          >
            <PlusIcon size={16} stroke="#00C77F" />
            Добавить барьер
          </CustomLink>
        </Grid>
      )}
      {addGateModalNode}
    </Grid>
  );
}

function mapStateToProps(state) {
  const { facilities, operators } = state || {};
  const { allFacilityGates } = facilities || {};
  const { currentOperator: currentOperatorState } = operators || {};

  return {
    allFacilityGatesState: allFacilityGates,
    currentOperatorState,
  };
}

const ConnectedGatesIntegrationSetting = connect(
  mapStateToProps,
  {
    getFacilityGatesReg: getFacilityGatesAction,
  },
)(GatesIntegrationSetting);

export default ConnectedGatesIntegrationSetting;
