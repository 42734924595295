import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import Pagination from '@material-ui/lab/Pagination';
import GatewayEventsTable from 'components/templates/GatewayEventsTable';
import GatewayEventsCards from 'components/templates/GatewayEventsCards';
import Hidden from '@material-ui/core/Hidden';
import { useStyles } from './styles';

function GatewayEventsContent({
  facilityId,
  isLoading = false,
  gatewayEventsData = [],
  gatewayEventsMeta,
  showPagination = false,
  onPageChange = () => {},
  sorting,
  sortingSettingsChange,
  hideCustomer = false,
  hideControllers = true,
}) {
  const styles = useStyles();

  const {
    currentPage = 1,
    lastPage = 1,
  } = gatewayEventsMeta || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
        {!isLoading && (
          <>
            <Hidden implementation="js" smUp>
              <GatewayEventsCards
                facilityId={facilityId}
                hideCustomer={hideCustomer}
                gatewayEventsData={gatewayEventsData}
              />
            </Hidden>
            <Hidden implementation="js" xsDown>
              <GatewayEventsTable
                facilityId={facilityId}
                hideCustomer={hideCustomer}
                gatewayEventsData={gatewayEventsData}
                sorting={sorting}
                sortingSettingsChange={sortingSettingsChange}
                hideControllers={hideControllers}
              />
            </Hidden>
          </>
        )}
        {!isLoading && lastPage > 1 && showPagination && (
          <div className={styles.paginationContainer}>
            <Pagination
              className={styles.pagination}
              name="page"
              page={currentPage}
              onChange={onPageChange}
              count={lastPage}
              color="primary"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedGatewayEventsContent = connect(
  mapStateToProps,
  {},
)(GatewayEventsContent);

export default ConnectedGatewayEventsContent;
