import {
  CLEAR_COMPANY_CACHE,
  CLEAR_REGISTRATION_STATUS_CACHE,
  GET_COMPANY,
  GET_REGISTRATION_STATUS,
  SET_COMPANY,
} from 'actions/types';

const INITIAL_STATE = {
  company: {
    loading: false,
    data: null,
  },
  setCompany: {
    loading: false,
  },
  registrationStatus: {
    loading: false,
    data: null,
  }
};

export default function companies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        company: {
          ...state.company,
          ...action.payload,
        },
      };

    case SET_COMPANY:
      return {
        ...state,
        setCompany: {
          ...state.setCompany,
          ...action.payload,
        },
      };

    case GET_REGISTRATION_STATUS:
      return {
        ...state,
        registrationStatus: {
          ...state.registrationStatus,
          ...action.payload,
        },
      };


    case CLEAR_REGISTRATION_STATUS_CACHE:
      return {
        ...state,
        registrationStatus: INITIAL_STATE.registrationStatus,
      };

    case CLEAR_COMPANY_CACHE:
      return {
        ...state,
        setCompany: INITIAL_STATE.setCompany,
        company: INITIAL_STATE.company,
      };

    default:
      return state;
  }
}
