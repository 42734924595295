import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';

import {
  PATH_PAGES, PAYMENT_ORDERS_STATUSES, PAYMENT_ORDERS_STATUSES_MAP, RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';

import { useStyles } from './styles';

/* help function */

export const getStatusContentByStatus = (status) => {
  switch (status) {
    case PAYMENT_ORDERS_STATUSES.new:
    case PAYMENT_ORDERS_STATUSES.invoiced:
      return (
        <>
          <CircleIcon color="grey" />
          {PAYMENT_ORDERS_STATUSES_MAP[status]}
        </>
      );
    case PAYMENT_ORDERS_STATUSES.paid:
    case PAYMENT_ORDERS_STATUSES.done:
      return (
        <>
          <CircleIcon color="#43D37D" />
          {PAYMENT_ORDERS_STATUSES_MAP[status]}
        </>
      );
    case PAYMENT_ORDERS_STATUSES.canceled:
    case PAYMENT_ORDERS_STATUSES.failed:
      return (
        <>
          <CircleIcon color="red" />
          {PAYMENT_ORDERS_STATUSES_MAP[status]}
        </>
      );
    default:
      return (
        <>
          -
        </>
      );
  }
};

export const getPaymentTypeContent = (paymentType) => {
  switch (paymentType) {
    case 'manual':
      return (
        <Typography>
          Ручной платеж
        </Typography>
      );
    case 'vendotek':
      return (
        <Typography>
          Терминал
        </Typography>
      );
    default:
      return (
        <Typography>
          На сайте
        </Typography>
      );
  }
};

export const getPayableContent = (payable, facilityId, history) => {
  const {
    type,
    linkToType,
    linkToId,
  } = payable || {};

  if (!payable) {
    return (
      <Typography>-</Typography>
    );
  }

  let link = '';
  if (linkToId !== null) {
    switch (linkToType) {
      case 'pass':
        link = PATH_PAGES.onePassInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        break;
      case 'ticket':
        link = PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        break;
      case 'customer':
        link = PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', linkToId);
        break;
    }
  }

  let paylableName = '-';
  switch (type) {
    case 'pass':
      paylableName = 'Абонемент';
      break;
    case 'pass_renewal':
      paylableName = 'Продление абонемента';
      break;
    case 'ticket':
      paylableName = 'Пропуск';
      break;
    case 'session':
      paylableName = 'Сессия';
      break;
    default:
  }

  if (link) {
    return (
      <CustomLink
        href={link}
        onClick={(e) => {
          e.preventDefault();
          history.push(link);
        }}
      >
        {paylableName}
      </CustomLink>
    );
  }

  return (
    <Typography>
      {paylableName}
    </Typography>
  );
};

export const getPayableDetailsContent = (payable, className) => {
  const {
    details,
  } = payable || {};

  if (!details) {
    return null;
  }

  const {
    suffix,
    start,
    end,
  } = details || {};

  const startStr = start ? moment(start).format('D MMM HH:mm') : '';
  const endStr = end ? moment(end).format('- D MMM HH:mm') : '';

  if (suffix) {
    return (
      <Typography className={className}>
        {`${suffix}, ${startStr} ${endStr}`}
      </Typography>
    );
  }

  return (
    <Typography className={className}>
      {`${startStr} ${endStr}`}
    </Typography>
  );
};

function getHeaders({
  t,
  hideCustomer,
}) {
  const headers = [
    {
      id: 'id',
      label: 'ID',
      isSorted: true,
    },
    {
      id: 'paidAt',
      label: 'Дата',
      isSorted: true,
    },
    {
      id: 'sum',
      label: 'Сумма',
    },
    !hideCustomer
      ? {
        id: 'customer',
        label: 'Клиент',
      } : null,
    {
      id: 'payable',
      label: 'За что платили',
    },
    {
      id: 'paymentType',
      label: 'Способ оплаты',
    },
    {
      id: 'status',
      label: 'Статус оплаты',
    },
    {
      id: 'actions',
      label: '',
    },
  ];

  return headers.filter((item) => !!item);
}

function getRows(paymentsData = []) {
  return paymentsData.map((item) => item);
}

const propTypes = {};

function PaymentsTable({
  facilityId,
  paymentsData,
  hideCustomer = false,
  hideBtn = false,
  sorting = {},
  sortingSettingsChange = () => {},
  onCancel = () => {},
  onPaymentClick = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const rows = getRows(paymentsData);
  const isPaymentsData = paymentsData && (paymentsData || []).length > 0;

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasPaymentsCancelRight = useCheckFacilityRights(RIGHT_KEYS.paymentsCancel, facilityId);

  const headers = getHeaders({
    t,
    hideCustomer,
  });

  const createOnCancelHandler = (id) => () => {
    onCancel(id);
  };

  const createOnClickHandler = (id) => () => {
    onPaymentClick(id);
  };

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  return (
    <Paper className={classes.root}>
      {!isPaymentsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            Пока журнал оплат пуст
          </Typography>
        </div>
      )}
      {isPaymentsData && (
        <TableContainer className={classes.container} component={Paper}>
          <Table
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = sorting[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? classes.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={classes.headerContainer}>
                        <Typography className={classes.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={classes.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={classes.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  sum,
                  paidAt,
                  payable,
                  customerName,
                  customerId,
                  paymentType,
                  status,
                  canBeCanceled,
                } = row || {};

                const key = id;

                const paidTimeStr = paidAt ? moment(paidAt).format('HH:mm:ss') : '-';
                const paidDateStr = paidAt ? moment(paidAt).format('DD.MM.yyyy') : '-';

                const currency = t('others.currency');
                const sumStr = sum
                  ? `${(+sum || 0).toLocaleString()} ${currency}`
                  : '-';

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <CustomLink onClick={createOnClickHandler(id)} className={classes.idStr}>
                        {id}
                      </CustomLink>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.timeStr}>
                        {paidTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {paidDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.timeStr}>
                        {sumStr}
                      </Typography>
                    </TableCell>
                    {!hideCustomer && (
                      <TableCell align="left">
                        {customerId && hasCustomerShowRight ? (
                          <CustomLink
                            href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId)}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId));
                            }}
                          >
                            {customerName}
                          </CustomLink>
                        ) : (
                          <Typography>
                            {customerName || 'Гость'}
                          </Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {getPayableContent(payable, facilityId, history)}
                      {payable && (
                        getPayableDetailsContent(payable, classes.payableDetailsStr)
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {getPaymentTypeContent(paymentType)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.statusStr}>
                        {getStatusContentByStatus(status)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {(canBeCanceled && !hideBtn && hasPaymentsCancelRight) && (
                        <Grid container spacing={1} justifyContent="flex-end">
                          <Grid item>
                            <Tooltip title="Отменить">
                              <IconButton
                                size="small"
                                onClick={createOnCancelHandler(id)}
                              >
                                <BlockIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

PaymentsTable.propTypes = propTypes;

export default PaymentsTable;
