import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

import { callAPI } from '@parkly/shared/api';

async function validGetTariffs({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const tariffsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const tariffsData = curData.map((item) => {
    const {
      id,
      name,
      interval,
      precision,
      roundIgnore,
      type,
      status,
      prices,
    } = item;

    return {
      id,
      name,
      interval,
      precision,
      roundIgnore,
      type,
      status,
      prices,
    };
  });

  const result = {
    tariffsData,
    tariffsMeta,
  };

  return result;
}

export async function getTariffs({
  facilityId,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/tariffs?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetTariffs({
      res,
    }));
}

async function validGetActiveTariffs({
  res,
}) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const tariffsData = data.map((item) => {
    const {
      id,
      name,
      interval,
      precision,
      roundIgnore,
      type,
      status,
    } = item;

    return {
      id,
      name,
      interval,
      precision,
      roundIgnore,
      type,
      status,
    };
  });

  return tariffsData;
}

export async function getActiveTariffs({
  facilityId,
}) {
  return callAPI(`facilities/${facilityId}/tariffs/active`)
    .then((res) => validGetActiveTariffs({
      res,
    }));
}

export function validGetTariff({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getTariff({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/tariffs/${id}`)
    .then((res) => validGetTariff({
      res,
    }));
}

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function setTariff({
  facilityId,
  id,
  name,
  interval,
  precision,
  roundIgnore,
  type,
  status,
  prices,
}) {
  return callAPI(
    `facilities/${facilityId}/tariffs`,
    'POST',
    {
      tariffId: id || null,
      name,
      interval,
      precision,
      roundIgnore,
      type,
      status,
      prices,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validGetTariffPlans({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const tariffPlansMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const tariffPlansData = curData.map((item) => {
    const {
      id,
      name,
      description,
      trial,
      exitTime,
      type,
      changeType,
      status,
    } = item;

    return {
      id,
      name,
      description,
      trial,
      exitTime,
      type,
      changeType,
      status,
    };
  });

  const result = {
    tariffPlansData,
    tariffPlansMeta,
  };

  return result;
}

export async function getTariffPlans({
  facilityId,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/tariff-plans?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetTariffPlans({
      res,
    }));
}

async function validGetTariffPlan({ res }) {
  const { data: resData } = res || {};

  const { data } = resData || {};

  const {
    id,
    name,
    description,
    trial,
    exitTime,
    changeType,
    type,
    status,
    schedules,
  } = data || {};

  const schedulesArr = (schedules || []).at(0)?.params?.schedule || [];

  return {
    id,
    name,
    description,
    trial,
    exitTime,
    changeType,
    type,
    status,
    schedules: schedulesArr,
  };
}

export async function getTariffPlan({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/tariff-plans/${id}`)
    .then((res) => validGetTariffPlan({
      res,
    }));
}

export async function setTariffPlan({
  facilityId,
  id,
  name,
  description,
  trial,
  exitTime,
  changeType,
  type,
  status,
  schedule,
}) {
  return callAPI(
    `facilities/${facilityId}/tariff-plans`,
    'POST',
    {
      tariffPlanId: id || null,
      name,
      description,
      trial,
      exitTime,
      changeType,
      type,
      status,
      schedule,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validGetActiveTariffPlans({ res }) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const tariffPlansData = data.map((item) => {
    const {
      id,
      name,
      trial,
      exitTime,
      type,
      status,
      changeType,
      facilityId,
      facilityName,
    } = item;

    return {
      id,
      name,
      trial,
      exitTime,
      type,
      status,
      changeType,
      facilityId,
      facilityName,
    };
  });

  return tariffPlansData;
}

export async function getActiveTariffPlans({
  facilityId,
  withTechnical = false,
}) {
  const query = new URLSearchParams(pickBy({
    withTechnical: withTechnical ? 1 : 0,
  }, identity));

  const queryParams = query.toString();

  return callAPI(
    `facilities/${facilityId}/tariff-plans/active?${queryParams}`,
    'GET',
  )
    .then((res) => validGetActiveTariffPlans({
      res,
    }));
}

export async function getTariffPlansBulk({
  facilityIds,
  withTechnical = false,
}) {
  const query = new URLSearchParams(pickBy({
    withTechnical: withTechnical ? 1 : 0,
    facilityIds: isEmpty(facilityIds) ? undefined : facilityIds.join(','),
  }, identity));

  const queryParams = query.toString();

  return callAPI(
    `tariff-plans/bulk?${queryParams}`,
    'GET',
  )
    .then((res) => validGetActiveTariffPlans({
      res,
    }));
}

async function validGetFacilityPaySetting({ res }) {
  const { data: dataRes } = res || {};

  return dataRes;
}

export async function getFacilityPaySetting({ facilityId }) {
  return callAPI(
    `facilities/${facilityId}/tariff-settings`,
    'GET',
  )
    .then((res) => validGetFacilityPaySetting({ res }));
}

export async function setFacilityPaySetting({ facilityId, welcomeTariffPlanId }) {
  return callAPI(
    `facilities/${facilityId}/tariff-settings`,
    'POST',
    {
      welcomeTariffPlanId: welcomeTariffPlanId === 0 ? null : welcomeTariffPlanId,
    },
  )
    .then((res) => validCreate({ res }));
}
