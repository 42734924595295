import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Copyright() {
  const year = new Date().getFullYear();
  const copyrightStr = `${year} ООО «Паркли»`;

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {copyrightStr}
    </Typography>
  );
}
