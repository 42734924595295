import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useFormFields } from '@parkly/shared/helpers';

import {
  getFacilityMPPSettingAction,
  setFacilityMPPSettingAction,
} from 'actions/facilities';
import { MODULES_KEYS, MPP_REQUEST_STATUSES_NAME, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useHasModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getOneFacilityMPPSettings({ oneFacilityMPPSettingState, match }) {
  const { facilityId } = (match || {}).params || {};

  const { list } = oneFacilityMPPSettingState || {};
  const currentFacilityMPPSetting = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = '' + id === '' + facilityId;

    return isNeeded;
  });

  return {
    facilityId,
    currentFacilityMPPSetting,
  };
}

function MPPShowcaseSettings({
  match,

  getFacilityMPPSettingReg,
  oneFacilityMPPSettingState,

  setFacilityMPPSettingReg,
  setFacilityMPPSettingState,
}) {
  const styles = useStyles();
  useCurrentFacilityMatch();

  const { facilityId } = (match || {}).params || {};
  const [formVisible, setFormVisible] = useState(false);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      status: '',
      title: '',
      purpose: '',
      schedule: '',
      address: '',
      coordinates: '',
      contractorName: '',
      prices: '',
      levels: '',
      placesCount: '',
      handicappedPlacesCount: '',
      phone: '',
      note: '',
      showFreePlaces: false,
      showPrices: false,
      facilityTypeTickets: false,
      facilityTypeSubscription: false,
    },
  });

  const handleCheckboxChange = (event) => {
    setFormVisible(event.target.checked);
  };

  const hasShowcaseUpdateRight = useCheckFacilityRights(RIGHT_KEYS.siteUpdate, facilityId);

  const hasPaymentModule = useHasModule(facilityId, MODULES_KEYS.payments);

  useEffect(() => {
    const { loading: isLoading } = oneFacilityMPPSettingState || {};

    if (isLoading) {
      return;
    }

    const { currentFacilityMPPSetting } = getOneFacilityMPPSettings({
      oneFacilityMPPSettingState,
      match,
    });

    if (currentFacilityMPPSetting) {
      setFormVisible(currentFacilityMPPSetting.status > 1);
      updateState({
        ...currentFacilityMPPSetting,
      });
      return;
    }

    getFacilityMPPSettingReg({ id: facilityId });
  }, [facilityId, getFacilityMPPSettingReg, match, oneFacilityMPPSettingState, updateState]);

  const handleSaveClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (!hasShowcaseUpdateRight) {
        return;
      }

      setFacilityMPPSettingReg({
        id: facilityId,
        title: fields.title,
        purpose: fields.purpose,
        schedule: fields.schedule,
        address: fields.address,
        coordinates: fields.coordinates,
        contractorName: fields.contractorName,
        prices: fields.prices,
        levels: fields.levels,
        placesCount: fields.placesCount.toString(),
        handicappedPlacesCount: fields.handicappedPlacesCount.toString(),
        phone: fields.phone,
        note: fields.note,
        showFreePlaces: fields.showFreePlaces ?? false,
        showPrices: fields.showPrices ?? false,
        facilityTypeTickets: fields.facilityTypeTickets ?? false,
        facilityTypeSubscription: fields.facilityTypeSubscription ?? false,
      });
    },
    [facilityId, fields, hasShowcaseUpdateRight, setFacilityMPPSettingReg, hasPaymentModule],
  );

  const { loading: isMPPSettingLoading } = oneFacilityMPPSettingState || {};

  const { currentFacilityMPPSetting } = getOneFacilityMPPSettings({
    oneFacilityMPPSettingState,
    match,
  });

  const { status } = currentFacilityMPPSetting || 0;
  const formDisabled = status > 2;
  const { error: currentFacilityMPPError } = currentFacilityMPPSetting || {};

  const { loading: isSaveLoading } = setFacilityMPPSettingState || {};

  if (isMPPSettingLoading || currentFacilityMPPError) {
    return (
      <div className={styles.container}>
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} autoComplete="off" onSubmit={handleSaveClick} noValidate>
        <Grid item xs={12} lg={9} className={styles.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Статус заявки:
                    {MPP_REQUEST_STATUSES_NAME[fields.status] ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={formVisible}
                        onChange={handleCheckboxChange}
                        name="loadDataCheckbox"
                        color="primary"
                        disabled={!hasShowcaseUpdateRight || status > 1}
                      />
                    )}
                    label={'Размещать информацию о парковке в системе "Парковки России"'}
                  />
                </Grid>
                {formVisible && (
                  <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.title}
                        name="title"
                        label="Название парковки"
                        value={fields.title}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.purpose}
                        name="purpose"
                        label="Назначение парковки"
                        value={fields.purpose}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.schedule}
                        name="schedule"
                        label="Режим работы"
                        value={fields.schedule}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.address}
                        name="address"
                        label="Адрес"
                        value={fields.address}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.coordinates}
                        name="coordinates"
                        label="Координаты"
                        value={fields.coordinates}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        multiline
                        minRows={3}
                        className={styles.inputItem}
                        error={errors.contractorName}
                        name="contractorName"
                        label="Юридическое лицо"
                        value={fields.contractorName}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        multiline
                        minRows={3}
                        className={styles.inputItem}
                        error={errors.prices}
                        name="prices"
                        label="Стоимость"
                        value={fields.prices}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.levels}
                        name="levels"
                        label="Общее количество уровней парковки"
                        value={fields.levels}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.placesCount}
                        name="placesCount"
                        label="Количество парковочных мест"
                        value={fields.placesCount}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.handicappedPlacesCount}
                        name="handicappedPlacesCount"
                        label="Количество парковочных мест для инвалидов"
                        value={fields.handicappedPlacesCount}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        className={styles.inputItem}
                        error={errors.phone}
                        name="phone"
                        label="Контактный телефон"
                        value={fields.phone}
                        autoComplete="off"
                        required
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextInput
                        multiline
                        minRows={3}
                        className={styles.inputItem}
                        error={errors.note}
                        name="note"
                        label="Примечание"
                        value={fields.note}
                        autoComplete="off"
                        disabled={!hasShowcaseUpdateRight || formDisabled}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={styles.checkbox}
                        control={(
                          <Checkbox
                            disabled={!hasShowcaseUpdateRight || formDisabled}
                            checked={fields.showFreePlaces}
                            name="showFreePlaces"
                            onChange={onChange}
                            color="primary"
                          />
                        )}
                        label="Информация о свободных местах"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={styles.checkbox}
                        control={(
                          <Checkbox
                            disabled={!hasShowcaseUpdateRight || formDisabled}
                            checked={fields.showPrices}
                            name="showPrices"
                            onChange={onChange}
                            color="primary"
                          />
                        )}
                        label="Информация о ценах"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography style={{ fontSize: 16, fontWeight: 700 }}>Типы услуг:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={styles.checkbox}
                        control={(
                          <Checkbox
                            disabled={!hasShowcaseUpdateRight || formDisabled}
                            checked={fields.facilityTypeTickets}
                            name="facilityTypeTickets"
                            onChange={onChange}
                            color="primary"
                          />
                        )}
                        label="Стоянка"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={styles.checkbox}
                        control={(
                          <Checkbox
                            disabled={!hasShowcaseUpdateRight || formDisabled}
                            checked={fields.facilityTypeSubscription}
                            name="facilityTypeSubscription"
                            onChange={onChange}
                            color="primary"
                          />
                        )}
                        label="Абонементы"
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={6} md={4}>
                  <CustomBtn
                    type="submit"
                    className={styles.btnSubmit}
                    btnType="primaryBlue"
                    disabled={isSaveLoading || !hasShowcaseUpdateRight}
                  >
                    Сохранить
                  </CustomBtn>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  const { facilities } = state || {};
  const { oneFacilityMPPSetting, setFacilityMPPSetting } = facilities || {};

  return {
    oneFacilityMPPSettingState: oneFacilityMPPSetting,
    setFacilityMPPSettingState: setFacilityMPPSetting,
  };
}

const ConnectedMPPShowcaseSettings = connect(mapStateToProps, {
  getFacilityMPPSettingReg: getFacilityMPPSettingAction,
  setFacilityMPPSettingReg: setFacilityMPPSettingAction,
})(MPPShowcaseSettings);

export default ConnectedMPPShowcaseSettings;
