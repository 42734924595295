import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',

    height: 48,
    width: '100%',

    display: 'flex',
    flexFlow: 'column-reverse',

    cursor: 'pointer',
  },

  '.label, .input': {
    //transition: all 0.2s;
    touchAction: 'manipulation',
  },

  input: {
    width: '100%',
    /* height: 100%;*/

    border: 'none',
    /*  border-radius: 0;*/

    cursor: 'pointer',
    // '-webkit-appearance': 'none',

    background: '#FFFFFF',

    '&:focus': {
      border: 'none',
      outline: 0,
    },

    /* -- */

    '&::-webkit-calendar-picker-indicator': {
      marginLeft: '-20px !important',
      marginRight: '0 !important',
    },

    minHeight: 48,
    borderRadius: 6,
    padding: '28px 12px 11px 12px',

    /* fix problem with to large input */
    '&[type="datetime-local"]': {
      paddingRight: 0,
      paddingLeft: 0,

      '@media (min-width: 350px)': {
        padding: '28px 0 11px 12px',
      },
      '@media (min-width: 380px)': {
        padding: '28px 12px 11px 12px',
      },
    },

    color: theme.palette.text.primary,

    '&::placeholder': {
      color: theme.palette.greyC.darkGrey,
      fontSize: 14,
    },

    '&:focus::placeholder': {
      color: theme.palette.greyC.darkGrey,
      fontSize: 14,
    },

    /* focused or selected */

    '&.selected, &:focus': {
      zIndex: 2,
    },

    /* error */

    '&.error': {
      zIndex: 1,
      boxShadow: '0px 0px 0px 2.4px red', // $red

      '&:focus': {
        zIndex: 2,
        boxShadow: '0px 0px 0px 2.4px red', // $red
      },
    },
  },

  label: {
    zIndex: 3,
    maxWidth: '66.66%',
    transformOrigin: 'left bottom',
    transform: 'translate(12px, 45px) scale(1.5)',
    whiteSpace: 'nowrap',
    // '@at-root .input:focus + .label': {
    //   transform: 'translate(12px, 24px) scale(1)',
    // },
    color: 'grey', //$greyDark;
    fontSize: 12,
    cursor: 'pointer',
  },

  labelWithPlaceholder: {
    transform: 'translate(12px, 24px) scale(1)',
  },

  /* --- */

  /* -- mobile -- */

  inputMobile: {
    color: 'transparent!important',

    '&::-webkit-datetime-edit-month-field, &::-webkit-datetime-edit-year-field': {
      color: 'transparent!important',
    },
    '&::-webkit-calendar-picker-indicator': {
      visibility: 'hidden',
    },
  },

  mobileTimeLabel: {
    position: 'absolute',
    top: 28,
    left: 12,
    fontSize: 14,
    fontFamily: 'Lato, Roboto, sans-serif',
    color: 'blue', //$blueDark,
    zIndex: 2,
  },
  mobileTimeLabelLoading: {
    background: '#e0e0e070',
    color: 'transparent',
  },

  rightIcon: {
    position: 'absolute',
    top: 0,
    right: 10,
    zIndex: 10,

    display: 'flex',
    height: 48,

    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default {
  useStyles,
};
