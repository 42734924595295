import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { useFormFields } from '@parkly/shared/helpers';

import { setControllerAction } from 'actions/controllers';
import { setController, testNewController } from 'api/controllers';
import { CONTROLLER_TYPES } from 'config/constants';

import { useStyles } from './styles';


function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeCameConnectControllerModalContent({
  id,
  facilityId,
  onClose = () => {},

  allControllersState,

  testNewControllerApi = testNewController,
  setControllerApi = setController,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [cameDevices, setCameDevices] = useState([]);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      username: '',
      password: '',
      deviceId: '',
    },
  });

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        username: controller.settings.username || '',
        password: controller.settings.password || '',
        deviceId: controller.settings.deviceId || '',
      });
    }
  }, [id, facilityId, updateState, allControllersState]);

  const handleNextStep = useCallback(async () => {
    const isUserNameValid = fields.username.length > 0;
    const isPasswordChecked = fields.password.length > 0;

    if (!isUserNameValid || !isPasswordChecked) {
      setErrors({
        username: !isUserNameValid,
        password: !isPasswordChecked,
      });
      return;
    }

    setIsLoading(true);

    testNewControllerApi({
      facilityId,
      type: CONTROLLER_TYPES.cameConnect,
      settings: {
        username: fields.username,
        password: fields.password,
      },
    }).then(({ data, success }) => {
      if (!success) {
        setErrors({
          username: true,
          password: true,
        });
        setIsLoading(false);
        return;
      }

      setCameDevices(data);
      setIsLoading(false);
      setStep(1);
    }).catch((error) => {
      setIsLoading(false);
    });
  }, [facilityId, fields, setErrors, testNewControllerApi]);

  const devices = useMemo(() => {
    const result = [];

    (cameDevices || []).forEach(({ siteName, devices: devs }) => {
      (devs || []).forEach(({ id: devId, name, categoryId }) => {
        result.push({
          id: devId,
          title: `${siteName} - ${name}`,
          categoryId,
        });
      });
    });

    return result;
  }, [cameDevices]);

  const handleSetController = useCallback(() => {
    const isDeviceSelected = !!fields.deviceId;

    if (!isDeviceSelected) {
      setErrors({
        deviceId: !isDeviceSelected,
      });
      return;
    }

    setIsSetLoading(true);
    const device = devices.find(({ id: devId }) => devId === fields.deviceId);
    setControllerApi({
      id,
      facilityId,
      type: CONTROLLER_TYPES.cameConnect,
      settings: {
        username: fields.username,
        password: fields.password,
        deviceId: fields.deviceId,
        deviceName: device ? device.title : '-',
        deviceCategoryId: device ? device.categoryId : null,
      },
    }).then(({ success }) => {
      setIsSetLoading(false);
      if (success) {
        onClose();
      }
    }).catch(() => {
      setIsSetLoading(false);
    });
  }, [devices, facilityId, fields, id, onClose, setControllerApi, setErrors]);

  return (
    <CustomModalContentWrapper
      title="Подключение CAME Connect"
      onClose={onClose}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Авторизуйтесь под учетной записью CAME Connect
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.username}
              name="username"
              label="Имя пользователя"
              value={fields.username}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputPasswordItem}
              error={errors.password}
              type="passwordMightVisible"
              name="password"
              label="Пароль"
              value={fields.password}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleNextStep}
                  disabled={isLoading}
                >
                  Далее
                  {isLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.subTitle}>
              Доступные устройства:
            </Typography>

          </Grid>
          <Grid item xs={12}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              label="Устройство"
              name="deviceId"
              error={errors.deviceId}
              items={devices}
              currentValue={fields.deviceId}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="secondary"
                  className={styles.btn}
                  onClick={() => setStep(0)}
                >
                  Назад
                </CustomBtn>
              </Grid>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleSetController}
                  disabled={isSetLoading}
                >
                  {id ? 'Сохранить' : 'Добавить'}
                  {isSetLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController: setControllerState,
    allControllers,
  } = controllers || {};

  return {
    setControllerState,
    allControllersState: allControllers,
  };
}

const ConnectedMakeCameConnectControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
  },
)(MakeCameConnectControllerModalContent);

export default ConnectedMakeCameConnectControllerModalContent;
