import throttle from 'lodash/throttle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import BlackListTable from 'components/templates/BlackListTable';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';

import { getAllBlackListAction } from 'actions/blackList';

import { useCheckFacilityRights, useCommonRights, useHasModule } from 'helpers/hooks';

import { MODULES_KEYS, PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

function getInitParams() {
  return {};
}

function prepareParam() {
  return {};
}

function getCurrentBlackLists({ allBlackListState }) {
  const blackListList = (allBlackListState || {}).list || [];
  const preparedReqParam = prepareParam();
  const currentStrParam = JSON.stringify(preparedReqParam);
  const blackList = blackListList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    blackList,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

function requestAllBlackList({ reqParam, getAllBlackListReq = () => {} }) {
  throttle(getAllBlackListReq, 300)(reqParam);
}

function AllBlackListPage({ getAllBlackListReq, allBlackListState, currentFacilityState }) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [params, setParams] = useState(getInitParams());
  const [prevParams, setPrevParams] = useState('');

  const { data: currentFacilityData } = currentFacilityState || {};

  const { id: currentFacilityId } = currentFacilityData || {};

  const hasBlackListUpdateRight = useCommonRights(RIGHT_KEYS.blackListUpdate);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasTicketShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, currentFacilityId);

  const hasPaymentModule = useHasModule(currentFacilityId, MODULES_KEYS.payments);

  useEffect(() => {
    const { blackList, reqParam, currentStrParam } = getCurrentBlackLists({
      allBlackListState,
    });

    if (currentStrParam !== prevParams) {
      if (!blackList) {
        requestAllBlackList({
          reqParam,
          getAllBlackListReq,
        });

        setPrevParams(currentStrParam);
      }
    }
  }, [params, prevParams, allBlackListState, getAllBlackListReq]);

  const { blackList } = getCurrentBlackLists({
    allBlackListState,
  });

  const { blackListData } = (blackList || {}).data || {};

  const isLoading = (allBlackListState || {}).loading;

  return (
    <Container className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          hasCustomerShowRight
            ? { to: PATH_PAGES.customers.replace(':facilityId', currentFacilityId), label: 'Клиенты' }
            : null,
          hasTicketShowRight
            ? { to: PATH_PAGES.tickets.replace(':facilityId', currentFacilityId), label: 'Пропуска' }
            : null,
          hasPassShowRight && hasPaymentModule
            ? { to: PATH_PAGES.passes.replace(':facilityId', currentFacilityId), label: 'Абонементы' }
            : null,
          { to: PATH_PAGES.blackList, label: 'Черный список' },
        ]}
      />
      <div className={styles.pageBody}>
        <Grid container spacing={2}>
          {hasBlackListUpdateRight && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomBtn
                    className={styles.btn}
                    btnType="primaryGreen"
                    onClick={() => history.push(PATH_PAGES.newBlackList)}
                  >
                    Добавить запись
                  </CustomBtn>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoading && <CircularIndeterminate style={{ minHeight: 600 }} />}
            {!isLoading && <BlackListTable blackListData={blackListData} />}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  const { blackLists, currentFacility } = state || {};
  const { allBlackList: allBlackListState } = blackLists || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    allBlackListState,
    currentFacilityState,
  };
}

const ConnectedAllBlackListPage = connect(mapStateToProps, {
  getAllBlackListReq: getAllBlackListAction,
})(AllBlackListPage);

export default ConnectedAllBlackListPage;
