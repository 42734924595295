import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Chip, Grid } from '@material-ui/core';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { formatPhoneNumber, getApiErrorMsg } from '@parkly/shared/helpers';
import SessionsContent from 'components/organisms/Sessions/SessionsContent';
import PassesContent from 'components/organisms/Passes/PassesContent';
import CustomerGatesControls from 'components/organisms/CustomerGatesControls';
import PaymentsContent from 'components/organisms/Payments/PaymentsContent';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useDeleteCustomerAccessTokenMutation, useOneCustomer } from 'api/query/customers';
import TicketsContent from 'components/organisms/Tickets/TicketsContent';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import EditIcon from '@material-ui/icons/Edit';
import { useStyles } from './styles';

function CustomerInfoPage({
  match,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();

  const customerId = ((match || {}).params || {}).id;
  const { facilityId } = (match || {}).params || {};

  const hasCustomerUpdateRight = useCheckFacilityRights(RIGHT_KEYS.customerUpdate, facilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, facilityId);
  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, facilityId);
  const hasPaymentsShowRight = useCheckFacilityRights(RIGHT_KEYS.paymentsShow, facilityId);
  const hasTicketsShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);
  const hasCustomerSessionGateControlRight = useCheckFacilityRights(RIGHT_KEYS.customerSessionGateControl, facilityId);

  useCurrentFacilityMatch(PATH_PAGES.customers);

  const queryClient = useQueryClient();

  const {
    data: currentCustomer,
    error: currentCustomerError,
    isLoading,
  } = useOneCustomer({
    variables: { facilityId, id: customerId },
    cacheTime: 0,
  });

  const deleteCustomerAccessTokenMutation = useDeleteCustomerAccessTokenMutation({
    onSuccess({ success }) {
      if (success) {
        toast.success('Выполнено');
      }
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
  });

  const { isLoading: isDeleteCustomerAccessToken } = deleteCustomerAccessTokenMutation || {};

  const createDeleteAccessTokenHandler = (id) => () => {
    deleteCustomerAccessTokenMutation.mutate({ facilityId, customerId, id });
  };

  const {
    id,
    name,
    phone,
    email,
    comment,
    sessions,
    tickets,
    passes,
    payments,
    gateControlButtons,
    hasBlacklistedTokens,
    hasTelegramBotAccess,
  } = currentCustomer || {};

  if (isLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  if (currentCustomerError) {
    const currentCustomerErrorMsg = getApiErrorMsg(currentCustomerError);
    return (
      <Container maxWidth="md" className={styles.container}>
        <HeaderWithBackBtn
          title="Клиент"
        />
        <Typography>{currentCustomerErrorMsg}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className={styles.container}>
      <HeaderWithBackBtn
        title={name}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid className={styles.gridContainer} container item spacing={3} xs={12} md={7}>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="ID"
              titleSm={5}
            >
              <Typography>{id}</Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Телефон"
              titleSm={5}
            >
              <Typography>
                {phone ? formatPhoneNumber(phone) : '-'}
              </Typography>
            </GridTitleBase>
            {email && (
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Email"
                titleSm={5}
              >
                <Typography>{email}</Typography>
              </GridTitleBase>
            )}
            {comment && (
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Комментарий"
                titleSm={5}
              >
                <Typography>{comment}</Typography>
              </GridTitleBase>
            )}
            {hasTelegramBotAccess && (
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Доступ к телеграм боту"
                titleSm={5}
              >
                <Typography>Есть</Typography>
              </GridTitleBase>
            )}
          </Grid>
        </Grid>

        {hasCustomerUpdateRight && (
          <Grid item>
            <CustomBtn
              onClick={(e) => {
                e.preventDefault();
                history.push(PATH_PAGES.oneCustomer.replace(':id', customerId).replace(':facilityId', facilityId));
              }}
            >
              <EditIcon style={{ marginRight: 5 }} fontSize="small" />
              Редактировать
            </CustomBtn>
          </Grid>
        )}

        {hasBlacklistedTokens && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                <Chip className={styles.blackListChip} label="В черном списке" size="small" />
              </Typography>
            </Grid>
          </Grid>
        )}

        {hasCustomerSessionGateControlRight && !isEmpty(gateControlButtons) && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Управление барьерами
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomerGatesControls
                customerId={customerId}
                facilityId={facilityId}
                gateControlButtons={gateControlButtons}
                onSuccess={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                }}
              />
            </Grid>
          </Grid>
        )}

        {hasSessionShowRight && !isEmpty(sessions) && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние сесcии
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SessionsContent
                hideCheckbox
                facilityId={facilityId}
                sessionsData={sessions || []}
                hideCustomer
              />
            </Grid>
          </Grid>
        )}
        {hasTicketsShowRight && !isEmpty(tickets) && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние пропуска
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TicketsContent
                facilityId={facilityId}
                ticketsData={tickets || []}
                hideCustomer
                onNeedUpdate={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                  queryClient.invalidateQueries({ queryKey: ['payments'] });
                  queryClient.invalidateQueries({ queryKey: ['tickets'] });
                }}
              />
            </Grid>
          </Grid>
        )}
        {hasPassShowRight && !isEmpty(passes) && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние абонементы
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PassesContent
                facilityId={facilityId}
                passesData={passes || []}
                hideCustomer
                hideReqValues
                onNeedUpdate={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                  queryClient.invalidateQueries({ queryKey: ['payments'] });
                }}
              />
            </Grid>
          </Grid>
        )}
        {hasPaymentsShowRight && !isEmpty(payments) && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние оплаты
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PaymentsContent
                facilityId={facilityId}
                paymentsData={payments || []}
                hideCustomer
                onNeedUpdate={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                  queryClient.invalidateQueries({ queryKey: ['payments'] });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedCustomerInfoPage = connect(
  mapStateToProps,
  {},
)(CustomerInfoPage);

export default ConnectedCustomerInfoPage;
