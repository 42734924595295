import { ANALYTIC_TYPES } from 'config/constants';
import { getStats } from 'api/analytics';
import {
  CLEAR_ANALYTICS_CACHE,
  GET_ANALYTICS_AVG_CHECK_STATS,
  GET_ANALYTICS_AVG_SESSION_DURATION_STATS,
  GET_ANALYTICS_REVENUE_STATS,
  GET_ANALYTICS_SESSIONS_STATS,
} from './types';

export function clearBlackListCacheAction() {
  function actionClearCacheAnalytics() {
    return { type: CLEAR_ANALYTICS_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheAnalytics());
  };
}

export function getAnalyticsRevenueStatsAction(param) {
  function getAnalyticsRevenueStatsRequest(payload) {
    return { type: GET_ANALYTICS_REVENUE_STATS, payload };
  }

  return (dispatch) => {
    dispatch(getAnalyticsRevenueStatsRequest({
      loading: true,
    }));

    getStats({
      ...param,
      type: ANALYTIC_TYPES.revenue,
    }).then(
      (data) => {
        dispatch(getAnalyticsRevenueStatsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAnalyticsRevenueStatsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getAnalyticsAvgCheckStatsAction(param) {
  function getAnalyticsAvgCheckStatsRequest(payload) {
    return { type: GET_ANALYTICS_AVG_CHECK_STATS, payload };
  }

  return (dispatch) => {
    dispatch(getAnalyticsAvgCheckStatsRequest({
      loading: true,
    }));

    getStats({
      ...param,
      type: ANALYTIC_TYPES.avgCheck,
    }).then(
      (data) => {
        dispatch(getAnalyticsAvgCheckStatsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAnalyticsAvgCheckStatsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getAnalyticsSessionsStatsAction(param) {
  function getAnalyticsSessionsStatsRequest(payload) {
    return { type: GET_ANALYTICS_SESSIONS_STATS, payload };
  }

  return (dispatch) => {
    dispatch(getAnalyticsSessionsStatsRequest({
      loading: true,
    }));

    getStats({
      ...param,
      type: ANALYTIC_TYPES.sessions,
    }).then(
      (data) => {
        dispatch(getAnalyticsSessionsStatsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAnalyticsSessionsStatsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getAnalyticsAvgSessionDurationStatsAction(param) {
  function getAnalyticsAvgSessionDurationStatsRequest(payload) {
    return { type: GET_ANALYTICS_AVG_SESSION_DURATION_STATS, payload };
  }

  return (dispatch) => {
    dispatch(getAnalyticsAvgSessionDurationStatsRequest({
      loading: true,
    }));

    getStats({
      ...param,
      type: ANALYTIC_TYPES.avgSessionDuration,
    }).then(
      (data) => {
        dispatch(getAnalyticsAvgSessionDurationStatsRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAnalyticsAvgSessionDurationStatsRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
