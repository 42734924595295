import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setCurrentFacilityAction } from 'actions/currentFacility';

export function useCurrentFacilityId() {
  return useSelector((state) => (state.currentFacility.currentFacility.data
    ? state.currentFacility.currentFacility.data.id : null));
}

export function useCurrentFacilityMatch(fallbackPath) {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const forceCurrentStateFacilityId = useSelector((state) => (state.currentFacility.forceCurrentFacility.data
    ? state.currentFacility.forceCurrentFacility.data.id : null));
  const currentStateFacilityId = useSelector((state) => (state.currentFacility.currentFacility.data
    ? state.currentFacility.currentFacility.data.id : null));
  const allFacilities = useSelector((state) => state.facilities.allFacilities.data || []);

  const { params, path: matchPath } = match || {};
  const { facilityId: matchFacilityId } = params || {};

  useEffect(() => {
    if (
      !matchFacilityId
      || !currentStateFacilityId
      || parseInt(matchFacilityId, 10) === parseInt(currentStateFacilityId, 10)
    ) {
      return;
    }
    const facility = allFacilities.find(({ id }) => id === parseInt(matchFacilityId, 10));

    if (!facility) {
      return;
    }

    dispatch(setCurrentFacilityAction(facility));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !matchFacilityId
      || !forceCurrentStateFacilityId
      || parseInt(matchFacilityId, 10) === parseInt(forceCurrentStateFacilityId, 10)
    ) {
      return;
    }

    if (fallbackPath) {
      history.replace(fallbackPath.replace(':facilityId', forceCurrentStateFacilityId));
    } else {
      history.replace(matchPath.replace(':facilityId', forceCurrentStateFacilityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceCurrentStateFacilityId]);
}
