import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'static',
    zIndex: 100,
    backgroundColor: theme.palette.greyC.gray,
    width: '100%',
    minWidth: 'max-content',
    overflow: 'auto',
    height: 56,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    position: 'relative',

    marginRight: theme.spacing(4),
    height: '100%',
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      fontSize: 20,
      color: theme.palette.primary.main,
    },
  },
  activeItem: {
    '& .MuiTypography-root': {
      fontWeight: '700',
      color: 'unset',
    },
  },
  arrow: {
    position: 'absolute',
    bottom: 0,
    left: 'calc(50% - 10px)',
    width: 0,
    height: 0,

    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid white',
  },
}));

export default {
  useStyles,
};
