import { createMutation, createQuery } from 'react-query-kit';

import { getBulkGates, getFacilityGates, openFacilityGate } from 'api/facilities';

export const useFacilityGates = createQuery({
  primaryKey: 'facility-gates',
  queryFn: ({ queryKey: [_, params] }) => getFacilityGates(params),
  retry: false,
});

export const useGatesBulk = createQuery({
  primaryKey: 'gates-bulk',
  queryFn: ({ queryKey: [_, params] }) => getBulkGates(params),
  retry: false,
});

export const useOpenGateMutation = createMutation({
  mutationFn: async ({ id, facilityId }) => openFacilityGate({ id, facilityId }),
});
