import { createMutation, createQuery } from 'react-query-kit';
import { createOpenVpnClient, deleteOpenVpnClient, getAllOpenVpnClients } from '../openvpn';

export const useOpenVpnClients = createQuery({
  primaryKey: 'openvpn-clients',
  queryFn: ({ queryKey: [_, { facilityId }] }) => {
    return getAllOpenVpnClients({ facilityId });
  },
  retry: false,
});

export const useCreateOpenVpnClientMutation = createMutation({
  mutationFn: async ({ facilityId }) => createOpenVpnClient({ facilityId }),
});

export const useDeleteOpenVpnClientMutation = createMutation({
  mutationFn: async ({ facilityId, id }) => deleteOpenVpnClient({ facilityId, id }),
});
