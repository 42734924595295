import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  itemModal: {
    minHeight: 'unset !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  btn: {
    height: 48,
    width: '100%',
  },
}));

export default {
  useStyles,
};
