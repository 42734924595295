import React, { useState } from 'react';
import { Checkbox, Collapse } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import P360LogoIcon from '@parkly/shared/components/atoms/icons/P360LogoIcon';
import NavigationLink from '@parkly/shared/components/atoms/NavigationLink';

import {
  MODULES_KEYS, PATH_PAGES, RIGHT_KEYS, ROLES,
} from 'config/constants';
import { useCheckFacilityRights, useHasModule, useIsOperatorRole } from 'helpers/hooks';

import SettingsNavigationLinks from '../../../organisms/SettingsNavigationLinks';

import { useStyles } from './styles';

function SettingsContainer({
  children, registrationStatus, facilityId,
}) {
  const styles = useStyles();
  const theme = useTheme();

  const {
    isRegistrationCompleted,
  } = registrationStatus || {};

  return (
    <div className={styles.root}>
      <Hidden smDown implementation="css">
        <div className={styles.container}>
          {!isRegistrationCompleted && <P360LogoIcon colorP={theme.palette.primary.main} className={styles.icon} />}
          {isRegistrationCompleted && <Typography className={styles.headerStr}>Информация о парковке</Typography>}
          <SettingsNavigationLinks facilityId={facilityId} registrationStatus={registrationStatus} />
        </div>
      </Hidden>
      <div className={styles.children}>{children}</div>
    </div>
  );
}

export default SettingsContainer;
