import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  paginationContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: 'flex',
    width: '100%',
  },
  pagination: {
    margin: 'auto',
  },
  btn: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
  statusSelectForm: {
    height: 48,
    border: 0,
  },
  statusSelect: {
    height: 48,
    borderRadius: 6,
    backgroundColor: '#F6F7FB',
  },
  clickable: {
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  sortIcon: {
    marginLeft: 7,
  },
  noDataContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 330,
  },
  noDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      fontSize: theme.typography.fontSize12,
      margin: '10px 0',
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
  nameStr: {
    fontSize: 16,
  },
  phoneStr: {
    fontSize: 14,
    fontWeight: 700,
  },
  timeStr: {

  },
  dateStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  tokenStr: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  lastActivityFail: {
    color: theme.palette.redC.red3,
  },
}));

export default {
  useStyles,
};
