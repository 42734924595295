import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
  cardItem: {
    width: '100%',
    padding: 16,
  },
  firstItemContainer: {
    minHeight: 'unset !important',
    paddingTop: '0px !important',
    paddingBottom: '8px !important',
    alignItems: 'center',
  },
  itemContainer: {
    minHeight: 'unset !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
    alignItems: 'center',
  },
  lastItemContainer: {
    minHeight: 'unset !important',
    paddingTop: '8px !important',
    paddingBottom: '0px !important',
    alignItems: 'center',
    borderBottom: 'unset',
  },
  photo: {
    height: 70,
    // filter: 'grayscale(100%)',
  },
  noDataContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 230,
  },
  noDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  idStr: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
  },
  timeStr: {

  },
  dateStr: {
    color: theme.palette.greyC.darkGrey,
  },
  statusStr: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  typeStr: {
    color: 'grey',
  },
  operatorStr: {
    color: 'grey',
    '&:visited': {
      color: 'grey',
    },
  },
  btnsContainer: {
    marginTop: 4,
  },
}));

export default {
  useStyles,
};
