import React, { memo, useMemo } from 'react';
import { Handle } from 'reactflow';
import Typography from '@material-ui/core/Typography';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import RoadIcon from '@parkly/shared/components/atoms/icons/RoadIcon';
import { SvgIcon } from '@material-ui/core';

import { useStateNodeStyles } from './styles';

export default memo(({ data, sourcePosition, targetPosition }) => {
  const styles = useStateNodeStyles(data);

  const {
    label,
    type,
  } = data || {};

  const iconComponent = useMemo(() => {
    switch (type) {
      case 'init':
        return (
          <SvgIcon>
            <RoadIcon stroke="white" />
          </SvgIcon>
        );
      case 'in_area':
      case 'in_parking':
      default:
        return (
          <LocalParkingIcon className={styles.icon} />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Handle
        className={styles.handle}
        type="target"
        position={targetPosition}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={sourcePosition}
        isConnectable={false}
      />
      <div className={styles.container}>
        {iconComponent}
        <Typography noWrap className={styles.text}>
          {label}
        </Typography>
      </div>
    </>
  );
})
