import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  controlsContainer: {
    marginTop: 16,
  },
  rangeContainer: {
    width: '100%',

    borderRadius: 6,

    '& .MuiToggleButton-root': {
      width: '100%',
      height: 48,
      textTransform: 'unset',
      fontWeight: 700,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.greyC.lightGrey,
      border: 'unset',

      '&:hover': {
        backgroundColor: theme.palette.greyC.buttonGrey,
      },
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },

  },
  datePicker: {
    width: '100%',

    '& input': {
      backgroundColor: '#F6F7FB',
    },

    '& input:focus': {

    },

    '& input:placeholder': {
      color: theme.palette.greyC.darkGrey,
    },
  },
  datePickerWithError: {
    '& input': {
      backgroundColor: `${theme.palette.redC.red4} !important`,
    },
  },
  statusSelectForm: {
    height: 48,
    border: 0,
  },
  statusSelect: {
    height: 48,
    borderRadius: 6,
    backgroundColor: '#F6F7FB',
  },
  input: {
    margin: 0,
    height: 48,
  },
  title: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: 700,
  },
  chartsContainer: {
    marginTop: 8,
  },
}));

export default {
  useStyles,
};
