import React from 'react';

import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';

import { useStyles } from './styles';

const ITEMS = [
  { id: 'success', title: 'Успешно' },
  { id: 'error', title: 'Ошибка' },
];

export default function AnalyticAccessStatusFilter({
  name,
  value,
  onChange,
}) {
  const styles = useStyles();

  return (
    <MultiItemSelectors
      classNameForm={styles.selectForm}
      className={styles.select}
      name={name}
      currentValue={value}
      items={ITEMS}
      unselectText="Все типы"
      onChange={onChange}
      limitSelected={2}
    />
  );
}
