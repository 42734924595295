import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  rangeContainer: {
    marginTop: 16,
    marginBottom: 16,

    '& .MuiToggleButton-root': {
      textTransform: 'unset',
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },

  },
  title: {
    marginTop: 16,
    fontSize: 18,
    fontWeight: 700,
  },
  chartContainer: {
    width: 1097,
    minHeight: 425,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: 8,
  }
}));

export default {
  useStyles,
};
