import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: 30,
  },
  gridContainer: {
    margin: 0,
    marginTop: 8,
  },
  itemContainer: {
    minHeight: 'unset !important',
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
  subTitle: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  editLink: {
    marginTop: 'auto',
    textAlign: 'end',
  },
  blackListChip: {
    marginLeft: 8
  },
  tokenStr: {
    fontWeight: 700,
    display: 'flex',
  },
}));

export default {
  useStyles,
};
