import { createMutation, createQuery } from 'react-query-kit';
import { createWithdrawPayment, getAccountBalance, getAccountOperations } from 'api/account';

export const useAccountOperations = createQuery({
  primaryKey: 'account-operations',
  queryFn: ({ queryKey: [_, params] }) => {
    return getAccountOperations(params);
  },
  retry: false,
});

export const useAccountBalance = createQuery({
  primaryKey: 'account-balance',
  queryFn: ({ queryKey: [_, params] }) => {
    return getAccountBalance(params);
  },
  retry: false,
});

export const useCreateWithdrawPaymentMutation = createMutation({
  mutationFn: async ({ amount }) => createWithdrawPayment({ amount }),
});
