import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme) => ({
  addBalanceInput: {
    '& .MuiFilledInput-root': {
      height: 48,
    },
  },
  balanceStr: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',

    '& span': {
      fontWeight: 700,
    },
  },
  makeInvoiceBtn: {
    width: '100%',
    height: 48,
  },
  invoiceSumStr: {
    fontWeight: 700,
  },
  boxNotice: {
    backgroundColor: theme.palette.greyC.lightGrey,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 72,
    padding: 16,
    width: '100%',
    '& > p': {
      textAlign: 'center',
      fontWeight: 700,
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default {
  useStyles,
};
