import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import WelcomeTariffPlanSelect from 'components/organisms/WelcomeTariffPlanSelect';
import { useFormFields } from '@parkly/shared/helpers';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useFacilityCustomSettings, useSetFacilityCustomSettingsMutation } from 'api/query/settings';

import { useStyles } from './styles';

const FacilityCustomSettings = ({
  facilityId,
}) => {
  const styles = useStyles();
  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      isFreeExitCarWithoutSessionEnabled: false,
      isFreeExitPhoneWithoutSessionEnabled: false,
    },
  });

  const {
    data: settingsData,
    isLoading,
    refetch,
  } = useFacilityCustomSettings({
    variables: { facilityId },
  });

  const setMutation = useSetFacilityCustomSettingsMutation({
    onSuccess({ success }) {
      if (success) {
        toast.success('Сохранено!');
      }
      refetch();
    },
    onError() {
      refetch();
    },
  });

  useEffect(() => {
    const {
      data,
    } = settingsData || {};

    updateState({
      ...data,
    });
  }, [settingsData, updateState]);

  const handleOnChange = (e) => {
    onChange(e);

    const {
      name: filedName,
      value,
      checked,
      type,
    } = e.target || {};

    setMutation.mutate({
      facilityId,
      isFreeExitCarWithoutSessionEnabled: fields.isFreeExitCarWithoutSessionEnabled,
      isFreeExitPhoneWithoutSessionEnabled: fields.isFreeExitPhoneWithoutSessionEnabled,
      [filedName]: type !== 'checkbox' ? value : checked,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WelcomeTariffPlanSelect
          facilityId={facilityId}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item>
            <Typography className={styles.label}>
              Свободный выезд по гос номеру
            </Typography>
            <Typography className={styles.subLabel}>
              Когда нет активной сессии
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            {isLoading ? (
              <div className={styles.loader}>
                <CircularProgress
                  size={25}
                />
              </div>
            ) : (
              <Switch
                checked={fields.isFreeExitCarWithoutSessionEnabled}
                onChange={handleOnChange}
                name="isFreeExitCarWithoutSessionEnabled"
                color="primary"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item>
            <Typography className={styles.label}>
              Свободный выезд по номеру телефона
            </Typography>
            <Typography className={styles.subLabel}>
              Когда нет активной сессии
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            {isLoading ? (
              <div className={styles.loader}>
                <CircularProgress
                  size={25}
                />
              </div>
            ) : (
              <Switch
                checked={fields.isFreeExitPhoneWithoutSessionEnabled}
                onChange={handleOnChange}
                name="isFreeExitPhoneWithoutSessionEnabled"
                color="primary"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FacilityCustomSettings;
