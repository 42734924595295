import React, { useState } from 'react';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import Typography from '@material-ui/core/Typography';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import { downloadOpenVpnConfig } from 'api/openvpn';
import { useStyles } from './styles';

function RenewPassModalContent({
  id,
  username,
  ip,
  facilityId,
  onClose,
}) {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadConfig = () => {
    setIsLoading(true);
    downloadOpenVpnConfig({ facilityId, id }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <CustomModalContentWrapper
      title="OpenVPN config"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GridTitleBase
              classNameContainer={styles.itemModal}
              title="Username"
              titleSm={6}
            >
              <Typography>
                {username}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemModal}
              title="IP"
              titleSm={6}
            >
              <Typography>
                {ip}
              </Typography>
            </GridTitleBase>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.btn}
              disabled={isLoading}
              onClick={handleDownloadConfig}
            >
              Скачать конфиг
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      </div>
    </CustomModalContentWrapper>
  );
}

export default RenewPassModalContent;
