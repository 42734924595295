import React from 'react';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import Grid from '@material-ui/core/Grid';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import Typography from '@material-ui/core/Typography';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { PATH_PAGES, RIGHT_KEYS } from '../../../config/constants';
import { useHistory } from 'react-router-dom';
import { useCheckFacilityRights } from '../../../helpers/hooks';

export default function SessionAccessChangeTokenModalContent({
  onClose,
  onConfirm,
  facilityId,
  type,
  customer,
  token,
  additionalData = {},
}) {

  const history = useHistory();

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId)

  const { name, id } = customer || {};

  if (!customer) {
    return null;
  }

  const customerItem = hasCustomerShowRight
    ? (
      <CustomLink
        style={{ fontSize: 16 }}
        href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', id)}
        onClick={(e) => {
          e.preventDefault();
          history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', id));
        }}
      >
        {name}
      </CustomLink>
    ) : (
      <Typography style={{ fontSize: 16 }}>
        {name}
      </Typography>
    );

  if (type === 'merge') {
    return (
      <CustomModalContentWrapper
        title="Изменение доступа"
        onClose={onClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Выбранный доступ уже привязан к
              {' '}
              {customerItem}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Данная сессия будет привязана к
              {' '}
              {customerItem}
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <CustomBtn
                style={{ height: 48, width: '100%' }}
                onClick={onClose}
              >
                Отменить
              </CustomBtn>
            </Grid>
            <Grid item xs={6}>
              <CustomBtn
                style={{ height: 48, width: '100%' }}
                btnType="primaryBlue"
                onClick={() => onConfirm({ ...additionalData, token })}
              >
                Подтвердить
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </CustomModalContentWrapper>
    );
  }

  if (type === 'active-session') {
    return (
      <CustomModalContentWrapper
        title="Изменение доступа"
        onClose={onClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Выбранный доступ уже привязан к
              {' '}
              {customerItem}
              {' '}
              c активной сессией
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Чтобы изменить доступ завершите сессию у этого клиента
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2} justifyContent="flex-end">
            <Grid item xs={6}>
              <CustomBtn
                style={{ height: 48, width: '100%' }}
                onClick={onClose}
              >
                Ок
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </CustomModalContentWrapper>
    );
  }

  if (type === 'active-pass') {
    return (
      <CustomModalContentWrapper
        title="Изменение доступа"
        onClose={onClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Выбранный доступ уже привязан к
              {' '}
              {customerItem}
              {' '}
              c активном абонементом
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Изменение доступа возможно только через редактирование абонемента
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2} justifyContent="flex-end">
            <Grid item xs={6}>
              <CustomBtn
                style={{ height: 48, width: '100%' }}
                onClick={onClose}
              >
                Ок
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      </CustomModalContentWrapper>
    );
  }

  return null;
}
