import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import { useQueryClient } from '@tanstack/react-query';

import { FAST_LOGOUT } from '@parkly/shared/actions/types';
import Copyright from '@parkly/shared/components/atoms/Copyright';
import P360LogoIcon from '@parkly/shared/components/atoms/icons/P360LogoIcon';
import { clearLocalStorage } from '@parkly/shared/helpers';

import { login } from 'actions/auth';
import { useFastLoginOptions } from 'api/query/auth';
import SelectLoginCards from 'components/molecules/SelectLoginCards';

import { useStyles } from './styles';

function FastLoginPage({ login }) {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleLoginClick = useCallback((partner) => {
    const {
      tel,
      token,
      userId,
      name,
    } = partner;

    clearLocalStorage();
    dispatch({ type: FAST_LOGOUT });
    login({
      tel,
      token,
      userId,
      partnerName: name,
    }, history);
    queryClient.invalidateQueries();
  }, [queryClient, dispatch, history, login]);

  const { data: partnersData } = useFastLoginOptions({});

  return (
    <Container
      style={{
        maxWidth: 318,
      }}
      component="main"
      maxWidth="xs"
    >
      <div className={classes.paper}>
        <P360LogoIcon className={classes.logo} colorP={theme.palette.primary.main} />
        <SelectLoginCards
          handleLoginClick={handleLoginClick}
          partnersData={partnersData}
        />
      </div>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedFastLoginPage = connect(
  mapStateToProps,
  {
    login,
  },
)(FastLoginPage);

export default ConnectedFastLoginPage;
