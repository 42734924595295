import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import CustomDateTimePicker from '@parkly/shared/components/templates/CustomDateTimePicker';
import { toNativeTimeFormat } from '@parkly/shared/helpers';
import { useStyles } from './styles';

/* -- Help functions -- */

function getDateTimeFormatted(value, format = 'YYYY-MM-DD HH:mm') {
  if (!value) {
    return '';
  }

  return moment(value).format(format);
}

/* -- Main component */

const propTypes = {};

function DesktopDateTimeInput({
  inputRef,
  className = '',
  placeholder,
  id,
  name = 'customInput000',
  value,
  min,
  max,
  popoverPosition = 'left',
  dateTimeFormat = 'D MMMM, HH:mm',
  isLoading = false,
  disabled,
  onChange = () => {},
  initStartDayTime = false,
}) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const inputId = `inputId${name}`;

  const inputClasses = [
    className,
    styles.input,
    styles.desktop,
    Boolean(anchorEl) ? styles.selected : '',
    isLoading ? styles.loading : '',
  ].join(' ');

  function onFocus(event) {
    setAnchorEl(event.currentTarget);
  }
  function onDateTimePickerClose() {
    setAnchorEl(null);
  }
  function onChangeDateTime(dateTime) {
    onChange({
      value: toNativeTimeFormat(dateTime),
      name,
    });
  }

  return (
    <>
      <input
        ref={inputRef}
        disabled={disabled}
        className={inputClasses}
        aria-describedby={inputId}
        id={id}
        name={name}
        type="text"
        placeholder={placeholder}
        autoComplete="off"
        value={getDateTimeFormatted(value, dateTimeFormat)}
        onClick={onFocus}
        onChange={() => {}}
      />
      <CustomDateTimePicker
        anchorEl={anchorEl}
        id={inputId}
        value={value}
        min={min}
        max={max}
        popoverPosition={popoverPosition}
        initStartDayTime={initStartDayTime}
        onClose={onDateTimePickerClose}
        onChange={onChangeDateTime}
      />
    </>
  );
}

DesktopDateTimeInput.propTypes = propTypes;

export default DesktopDateTimeInput;
