import React from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';

import { ACCOUNT_OPERATIONS_STATUS, ACCOUNT_OPERATIONS_STATUS_MAP } from 'config/constants';

import { useStyles } from './styles';

/* help function */

const getStatusContentByStatus = (status) => {
  switch (status) {
    case ACCOUNT_OPERATIONS_STATUS.new:
      return (
        <>
          <CircleIcon color="blue" />
          {ACCOUNT_OPERATIONS_STATUS_MAP[ACCOUNT_OPERATIONS_STATUS.new]}
        </>
      );
    case ACCOUNT_OPERATIONS_STATUS.inProgress:
      return (
        <>
          <CircleIcon color="blue" />
          {ACCOUNT_OPERATIONS_STATUS_MAP[ACCOUNT_OPERATIONS_STATUS.inProgress]}
        </>
      );
    case ACCOUNT_OPERATIONS_STATUS.canceled:
      return (
        <>
          <CircleIcon color="red" />
          {ACCOUNT_OPERATIONS_STATUS_MAP[ACCOUNT_OPERATIONS_STATUS.canceled]}
        </>
      );
    case ACCOUNT_OPERATIONS_STATUS.success:
      return (
        <>
          <CircleIcon color="#43D37D" />
          {ACCOUNT_OPERATIONS_STATUS_MAP[ACCOUNT_OPERATIONS_STATUS.success]}
        </>
      );
    case ACCOUNT_OPERATIONS_STATUS.error:
      return (
        <>
          <CircleIcon color="red" />
          {ACCOUNT_OPERATIONS_STATUS_MAP[ACCOUNT_OPERATIONS_STATUS.error]}
        </>
      );
    default:
      return (
        <>
          -
        </>
      );
  }
};

function getHeaders({
  t,
  hideStatusCol,
  hideOperatorCol,
}) {
  const headers = [
    {
      id: 'createdAt',
      label: 'Время',
    },
    {
      id: 'name',
      label: 'Операция',
    },
    {
      id: 'amount',
      label: 'Сумма',
    },
    !hideOperatorCol ?
    {
      id: 'operatorName',
      label: 'Оператор',
    } : null,
    !hideStatusCol ?
    {
      id: 'status',
      label: 'Статус',
    } : null,
    {
      id: 'documents',
      label: 'Документы',
    },
  ];

  return headers.filter((item) => !!item);
}

function getRows(operationsData = []) {
  const result = operationsData.map((item) => item);

  return result;
}

const propTypes = {};

function AccountOperationsTable({
  hideStatusCol = false,
  hideOperatorCol = false,
  operationsData,
  onDownloadDocument = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const rows = getRows(operationsData);
  const isOperationsData = operationsData && (operationsData || []).length > 0;

  const headers = getHeaders({
    t,
    hideStatusCol,
    hideOperatorCol,
  });

  const createDownloadDocumentHandler = (id) => () => {
    onDownloadDocument(id);
  };

  return (
    <Paper className={classes.root}>
      {!isOperationsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            Пока журнал операций пуст
          </Typography>
        </div>
      )}
      {isOperationsData && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id }) => (
                  <TableCell
                    key={id}
                    align="left"
                  >
                    <div className={classes.headerContainer}>
                      <Typography className={classes.headerStr}>
                        {label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  createdAt,
                  name,
                  amount,
                  operatorName,
                  status,
                  documents,
                } = row || {};
                const key = id;

                const timeStr = moment(createdAt).format('HH:mm:ss');
                const dateStr = moment(createdAt).format('DD.MM.yyyy');

                const currency = t('others.currency');
                const amountStr = `${(+amount || 0).toLocaleString()} ${currency}`;

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                  >
                    <TableCell align="left">
                      <Typography className={classes.timeStr}>
                        {timeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateStr}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {amount !== null ? amountStr : '-'}
                      </Typography>
                    </TableCell>
                    {!hideOperatorCol && (
                      <TableCell align="left">
                        <Typography>
                          {operatorName || '-'}
                        </Typography>
                      </TableCell>
                    )}
                    {!hideStatusCol && (
                      <TableCell align="left">
                        <Typography className={classes.statusStr}>
                          {getStatusContentByStatus(status)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {documents.map(({ id: docId, name: docName }, i) => (
                        <CustomLink
                          key={`${id}_${docId}_${i}`}
                          onClick={createDownloadDocumentHandler(docId)}
                        >
                          {docName || ' '}
                        </CustomLink>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

AccountOperationsTable.propTypes = propTypes;

export default AccountOperationsTable;
