import { createQuery } from 'react-query-kit';

import { getModules } from 'api/licenses';

export const useFacilityLicenceModules = createQuery({
  primaryKey: 'facility-license-modules',
  queryFn: ({ queryKey: [_, { facilityId }] }) => {
    if (!parseInt(facilityId, 10)) {
      return {};
    }

    return getModules({ facilityId });
  },
  retry: false,
});
