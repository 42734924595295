import React, { useCallback, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

const ChangeExtensionMenuItem = React.forwardRef((
  {
    onComplete = () => {},
    initValue,
  },
  ref,
) => {
  const [value, setValue] = useState(initValue);
  const [error, setError] = useState(false);

  const handleApply = useCallback(() => {
    if (value) {
      onComplete(value);
    } else {
      setError(true);
    }
  }, [onComplete, value]);

  const onChange = useCallback((e) => {
    const { target } = e || {};
    const { value: newValue } = target || {};

    setValue((`${newValue}`).replace(/\D/g, '').slice(0, 2));
    setError(false);
  }, []);

  return (
    <Grid container spacing={1} style={{ padding: '0 8px' }} alignItems="center">
      <Grid item>
        <TextField
          ref={ref}
          error={error}
          variant="outlined"
          style={{ width: 100 }}
          size="small"
          value={value}
          onChange={onChange}
        />
      </Grid>
      <Grid item>
        <CustomBtn onClick={handleApply}>Применить</CustomBtn>
      </Grid>
    </Grid>
  );
});

export default ChangeExtensionMenuItem;
