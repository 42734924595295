import { downloadAccountOperationDocument } from 'api/account';
import { DOWNLOAD_ACCOUNT_OPERATION_DOCUMENT } from './types';

export function downloadAccountOperationDocumentAction(params) {
  function downloadAccountOperationDocumentRequest(payload) {
    return { type: DOWNLOAD_ACCOUNT_OPERATION_DOCUMENT, payload };
  }

  return (dispatch) => {
    dispatch(downloadAccountOperationDocumentRequest({
      loading: true,
    }));

    downloadAccountOperationDocument(params).then(
      () => {
        dispatch(downloadAccountOperationDocumentRequest({
          loading: false,
        }));
      },
      (error) => {
        dispatch(downloadAccountOperationDocumentRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}
