import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';

import { formatPhoneNumber } from '@parkly/shared/helpers';

import { PASS_REQ_TYPES } from 'config/constants';

import { useStyles } from './styles';

function hidePhone(str) {
  return str.replace(/^(.{9}).*(.{3})$/, '$1 *** ** $2');
}

function hidePlateNumber(str) {
  return str.replace(/^(.*)(.{4})$/, '$1****');
}

export default function ReqValues({
  requiredData = [],
}) {
  const styles = useStyles();
  const [hidden, setHidden] = useState(true);

  if (isEmpty(requiredData)) {
    return (
      <Typography>-</Typography>
    );
  }

  return (
    <div
      onClick={() => setHidden(!hidden)}
      className={styles.container}
    >
      {(requiredData || []).map(({ type, value }, index) => {
        let valueStr = '';

        if ([PASS_REQ_TYPES.additionalPhone, PASS_REQ_TYPES.phone].includes(type)) {
          valueStr = formatPhoneNumber(value).replace(/\s/g, ' ');
          if (hidden) {
            valueStr = hidePhone(valueStr);
          }
        } else {
          valueStr = value;
        }

        return (
          <Typography key={index} className={styles.str}>
            {valueStr}
          </Typography>
        );
      })}
    </div>
  );
}
