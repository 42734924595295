export const GET_CURRENT_OPERATOR = 'GET_CURRENT_OPERATOR';
export const GET_LIST_P360_RIGHTS = 'GET_LIST_P360_RIGHTS';
export const CLEAR_CACHE_OPERATORS = 'CLEAR_CACHE_OPERATORS';
export const GET_OPERATORS = 'GET_OPERATORS';
export const CLEAR_CACHE_ONE_OPERATOR = 'CLEAR_CACHE_ONE_OPERATOR';
export const GET_ONE_OPERATOR_REQUEST = 'GET_ONE_OPERATOR_REQUEST';
export const ADD_NEW_OPERATOR_REQUEST = 'ADD_NEW_OPERATOR_REQUEST';
export const UPDATE_OPERATOR_REQUEST = 'UPDATE_OPERATOR_REQUEST';
export const REMOVE_OPERATOR_REQUEST = 'REMOVE_OPERATOR_REQUEST';
export const GET_ACTIONS_ONE_OPERATOR_REQUEST = 'GET_ACTIONS_ONE_OPERATOR_REQUEST';
export const GET_LIST_360_RIGHTS = 'GET_LIST_360_RIGHTS';
export const GET_LIST_360_ROLES = 'GET_LIST_360_ROLES';
export const GET_LIST_360_MODULES = 'GET_LIST_360_MODULES';

export const GET_COMPANY = 'GET_COMPANY';
export const SET_COMPANY = 'SET_COMPANY';
export const CLEAR_COMPANY_CACHE = 'CLEAR_COMPANY_CACHE';
export const GET_REGISTRATION_STATUS = 'GET_REGISTRATION_STATUS';
export const CLEAR_REGISTRATION_STATUS_CACHE = 'CLEAR_REGISTRATION_STATUS_CACHE';

export const GET_AVAILABLE_LICENSES = 'GET_AVAILABLE_LICENSES';
export const GET_FACILITY_LICENSE = 'GET_FACILITY_LICENSE';
export const CLEAR_LICENSES_CACHE = 'CLEAR_LICENSES_CACHE';

export const CLEAR_FACILITIES_CACHE = 'CLEAR_FACILITIES_CACHE';
export const CREATE_FACILITY = 'CREATE_FACILITY';
export const UPDATE_FACILITY = 'UPDATE_FACILITY';
export const GET_FACILITY = 'GET_FACILITY';
export const GET_FACILITIES = 'GET_FACILITIES';

export const SET_CURRENT_FACILITY = 'SET_CURRENT_FACILITY';
export const SET_FORCE_CURRENT_FACILITY = 'SET_FORCE_CURRENT_FACILITY';
export const SET_CURRENT_FACILITY_LOADED = 'SET_CURRENT_FACILITY_LOADED';
export const CLEAR_CURRENT_FACILITY_CACHE = 'CLEAR_CURRENT_FACILITY_CACHE';

export const GET_FACILITY_SCHEDULE = 'GET_FACILITY_SCHEDULE';
export const SET_FACILITY_SCHEDULE = 'SET_FACILITY_SCHEDULE';

export const SET_FACILITY_PLACES = 'SET_FACILITY_PLACES';
export const GET_FACILITY_PLACES = 'GET_FACILITY_PLACES';
export const GET_FACILITY_AREAS = 'GET_FACILITY_AREAS';
export const GET_FACILITY_PASS_PLACES = 'GET_FACILITY_PASS_PLACES';
export const GET_FREE_FACILITY_PLACES = 'GET_FREE_FACILITY_PLACES';

export const GET_FACILITY_GATES = 'GET_FACILITY_GATES';
export const GET_FACILITY_GATES_WITH_CONTROLLERS = 'GET_FACILITY_GATES_WITH_CONTROLLERS';
export const SET_FACILITY_GATE = 'SET_FACILITY_GATE';
export const DELETE_FACILITY_GATE = 'DELETE_FACILITY_GATE';
export const OPEN_FACILITY_GATE = 'OPEN_FACILITY_GATE';

export const CLEAR_CONTROLLERS_CACHE = 'CLEAR_CONTROLLERS_CACHE';
export const SET_CONTROLLER = 'SET_CONTROLLER';
export const REMOVE_CONTROLLER = 'REMOVE_CONTROLLER';
export const GET_ALL_CONTROLLERS = 'GET_ALL_CONTROLLERS';
export const GET_ALL_CONDITIONAL_CONTROLLERS = 'GET_ALL_CONDITIONAL_CONTROLLERS';
export const GET_GSM_CONTROLLER_SETTINGS = 'GET_GSM_CONTROLLER_SETTINGS';
export const GET_CAMERA_UUIDS = 'GET_CAMERA_UUIDS';

export const GET_FACILITY_INTEGRATION_FLOW = 'GET_FACILITY_INTEGRATION_FLOW';
export const SET_FACILITY_INTEGRATION_FLOW = 'SET_FACILITY_INTEGRATION_FLOW';
export const DELETE_FACILITY_INTEGRATION_FLOW = 'DELETE_FACILITY_INTEGRATION_FLOW';
export const GET_FACILITY_INTEGRATION_INFO = 'GET_FACILITY_INTEGRATION_INFO';

export const TEST_FACILITY_INTEGRATION_DEVICE = 'TEST_FACILITY_INTEGRATION_DEVICE';
export const TEST_CALLBACK_FACILITY_INTEGRATION_DEVICE = 'TEST_CALLBACK_FACILITY_INTEGRATION_DEVICE';
export const DOWNLOAD_QR_IMAGE_FACILITY_GATE = 'DOWNLOAD_QR_IMAGE_FACILITY_INTEGRATION_DEVICE';
export const DOWNLOAD_QR_PDF_FACILITY_GATE = 'DOWNLOAD_QR_PDF_FACILITY_INTEGRATION_DEVICE';

export const GET_FACILITY_SITE_SETTING = 'GET_FACILITY_SITE_SETTING';
export const SET_FACILITY_SITE_SETTING = 'SET_FACILITY_SITE_SETTING';
export const DOWNLOAD_QR_PDF_FACILITY_SITE = 'DOWNLOAD_QR_PDF_FACILITY_SITE';

export const GET_FACILITY_MPP_SETTING = 'GET_FACILITY_MPP_SETTING';
export const SET_FACILITY_MPP_SETTING = 'SET_FACILITY_MPP_SETTING';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_ONE_CUSTOMER = 'GET_ONE_CUSTOMER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_ACCESS_TOKEN = 'DELETE_CUSTOMER_ACCESS_TOKEN';
export const GET_CUSTOMERS_SELECT = 'GET_CUSTOMERS_SELECT';
export const CLEAR_CUSTOMERS_CACHE = 'CLEAR_CUSTOMERS_CACHE';

export const CLEAR_TARIFFS_CACHE = 'CLEAR_TARIFFS_CACHE';
export const GET_ALL_TARIFFS = 'GET_ALL_TARIFFS';
export const GET_ACTIVE_TARIFFS = 'GET_ACTIVE_TARIFFS';
export const GET_ONE_TARIFF = 'GET_ONE_TARIFF';
export const SET_ONE_TARIFF = 'SET_ONE_TARIFF';

export const GET_ALL_TARIFF_PLANS = 'GET_ALL_TARIFF_PLANS';
export const GET_ONE_TARIFF_PLAN = 'GET_ONE_TARIFF_PLAN';
export const SET_ONE_TARIFF_PLAN = 'SET_ONE_TARIFF_PLAN';
export const GET_ACTIVE_TARIFF_PLANS = 'GET_ACTIVE_TARIFF_PLANS';

export const CLEAR_PASSES_CACHE = 'CLEAR_PASSES_CACHE';
export const GET_ALL_PASSES = 'GET_ALL_PASSES';
export const GET_ONE_PASS = 'GET_ONE_PASS';
export const GET_ONE_PASS_INFO = 'GET_ONE_PASS_INFO';
export const CREATE_ONE_PASS = 'CREATE_ONE_PASS';
export const UPDATE_ONE_PASS = 'UPDATE_ONE_PASS';
export const CANCEL_ONE_PASS = 'CANCEL_ONE_PASS';
export const STOP_SESSIONS_ONE_PASS = 'STOP_SESSIONS_ONE_PASS';
export const PAY_ONE_PASS = 'PAY_ONE_PASS';
export const SEND_PASS_LINK_TO_CUSTOMER = 'SEND_PASS_LINK_TO_CUSTOMER';
export const GET_ALL_PASSES_SETTINGS = 'GET_ALL_PASSES_SETTINGS';
export const GET_ONE_PASS_SETTINGS = 'GET_ONE_PASS_SETTINGS';
export const GET_ACTIVE_PASS_SETTINGS = 'GET_ACTIVE_PASS_SETTINGS';
export const GET_ACTIVE_PASS_SETTINGS_PLACES = 'GET_ACTIVE_PASS_SETTINGS_PLACES';
export const SET_ONE_PASS_SETTINGS = 'SET_ONE_PASS_SETTINGS';

export const GET_ALL_BLACK_LIST = 'GET_ALL_BLACK_LIST';
export const GET_ONE_BLACK_LIST = 'GET_ONE_BLACK_LIST';
export const CREATE_BLACK_LIST = 'CREATE_BLACK_LIST';
export const SET_STATUS_ONE_BLACK_LIST = 'SET_STATUS_ONE_BLACK_LIST';
export const DELETE_ONE_BLACK_LIST = 'DELETE_ONE_BLACK_LIST';
export const CLEAR_BLACK_LIST_CACHE = 'CLEAR_BLACK_LIST_CACHE';

export const CLEAR_ANALYTICS_CACHE = 'CLEAR_ANALYTICS_CACHE';
export const GET_ANALYTICS_REVENUE_STATS = 'GET_ANALYTICS_REVENUE_STATS';
export const GET_ANALYTICS_AVG_CHECK_STATS = 'GET_ANALYTICS_AVG_CHECK_STATS';
export const GET_ANALYTICS_SESSIONS_STATS = 'GET_ANALYTICS_SESSIONS_STATS';
export const GET_ANALYTICS_AVG_SESSION_DURATION_STATS = 'GET_ANALYTICS_AVG_SESSION_DURATION_STATS';

export const GET_DEVICES = 'GET_DEVICES';
export const OPEN_DEVICE = 'OPEN_DEVICE';
export const CLEAR_DEVICES_CACHE = 'CLEAR_DEVICES_CACHE';

export const DOWNLOAD_ACCOUNT_OPERATION_DOCUMENT = 'DOWNLOAD_ACCOUNT_OPERATION_DOCUMENT';
