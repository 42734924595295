import { callAPI } from '@parkly/shared/api';

async function validGetAvailableLicenses({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const licensesData = curData.map((item) => {
    const { id, number, tariffType } = item;

    return {
      id,
      number,
      tariffType,
    };
  });

  return licensesData;
}

export async function getAvailableLicenses() {
  return callAPI('available-licenses').then((res) => validGetAvailableLicenses({
    res,
  }));
}

export async function validFacilityLicense({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    facilityId,
    number,
    tariffType,
    status,
    duration,
    placesCount,
    gatesCount,
    commission,
    recognizeCount,
    storeImageDuration,
    supervisorTariffType,
    phoneNumber,
    activationAt,
    endAt,
  } = resultData;

  return {
    id,
    facilityId,
    number,
    tariffType,
    status,
    duration,
    placesCount,
    gatesCount,
    commission,
    recognizeCount,
    storeImageDuration,
    supervisorTariffType,
    phoneNumber,
    activationAt,
    endAt,
  };
}

export async function getFacilityLicense({ facilityId }) {
  return callAPI(`facilities/${facilityId}/license`).then((res) => validFacilityLicense({
    res,
  }));
}

export async function validFacilityModules({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const { hasModulePayments } = resultData;

  return {
    hasModulePayments,
  };
}

export async function getModules({ facilityId }) {
  return callAPI(
    `facilities/${facilityId}/license-modules`,
    'get',
    undefined,
    {},
    false,
  ).then((res) => validFacilityModules({
    res,
  }));
}
