import React from 'react';

function HelpActiveIcon({
  size = 32,
  color = 'white',
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.25C8.95837 3.25 3.25 8.95837 3.25 16C3.25 23.0416 8.95837 28.75 16 28.75C23.0416 28.75 28.75 23.0416 28.75 16C28.75 8.95837 23.0416 3.25 16 3.25ZM15.8968 9.75C14.4212 9.75 13.1578 10.7238 12.7132 12.0904C12.585 12.4843 12.1618 12.6997 11.7679 12.5716C11.374 12.4434 11.1586 12.0202 11.2868 11.6263C11.9225 9.6724 13.7402 8.25 15.8968 8.25C18.5879 8.25 20.75 10.4619 20.75 13.1662C20.75 15.4652 19.1901 17.4042 17.07 17.9377C16.9334 17.972 16.8163 18.0436 16.7409 18.1249C16.6693 18.2021 16.6468 18.2726 16.6468 18.3325V19.5C16.6468 19.9142 16.3111 20.25 15.8968 20.25C15.4826 20.25 15.1468 19.9142 15.1468 19.5V18.3325C15.1468 17.3338 15.9462 16.6737 16.704 16.483C18.1594 16.1168 19.25 14.7758 19.25 13.1662C19.25 11.2687 17.738 9.75 15.8968 9.75ZM16 22C16.5522 22 17 22.4477 17 23V23.05C17 23.6023 16.5522 24.05 16 24.05C15.4477 24.05 15 23.6023 15 23.05V23C15 22.4477 15.4477 22 16 22Z"
        fill={color}
      />
    </svg>
  );
}

export default HelpActiveIcon;
