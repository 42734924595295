import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { CONTROLLER_TYPES } from 'config/constants';
import { testExistsController } from 'api/controllers';
import { formatPhoneNumber } from '@parkly/shared/helpers';
import { getGSMControllerSettingsAction } from 'actions/controllers';
import { useStyles } from './styles';

function getCurrentGSMSettings({
  facilityId,
  allGSMControllerSettingsState,
}) {
  const {
    loading: isLoading,
    list,
    error: gsmSettingsError,
  } = allGSMControllerSettingsState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const current = list.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: gsmSettingsData,
  } = current || {};

  return {
    isGSMSettingsLoading: isLoading,
    gsmSettingsData,
    gsmSettingsError,
  };
}

function TestNewControllerModalContent({
  facilityId,

  type,
  controllerId,
  portId,
  gateId,

  allGSMControllerSettingsState,
  getGSMControllerSettingsReg,

  testExistsControllerApi = testExistsController,

  onClose = () => {},
}) {
  const styles = useStyles();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!facilityId && type !== CONTROLLER_TYPES.gsm) {
      return;
    }

    const {
      isGSMSettingsLoading,
      gsmSettingsData,
      gsmSettingsError,
    } = getCurrentGSMSettings({
      facilityId,
      allGSMControllerSettingsState,
    });

    if (isGSMSettingsLoading || gsmSettingsData || gsmSettingsError) {
      return;
    }

    getGSMControllerSettingsReg({ facilityId });
  }, [allGSMControllerSettingsState, facilityId, getGSMControllerSettingsReg]);

  const {
    gsmSettingsData,
    isGSMSettingsLoading,
  } = getCurrentGSMSettings({
    facilityId,
    allGSMControllerSettingsState,
  });

  const handleTestController = useCallback(() => {
    setIsLoading(true);
    testExistsControllerApi({
      facilityId,
      controllerId,
      portId,
      gateId,
    }).then(({ success }) => {
      if (!success) {
        setIsError(true);
      }
      setIsLoading(false);
    }).catch((error) => {
      setIsError(true);
      setIsLoading(false);
    });
  }, [controllerId, facilityId, portId, testExistsControllerApi]);

  useEffect(() => {
    if (step !== 0) {
      return;
    }

    handleTestController();
  }, [handleTestController, step]);

  if (isLoading || isGSMSettingsLoading) {
    return (
      <CustomModalContentWrapper
        title="Тестирование"
        onClose={onClose}
      >
        <CircularIndeterminate />
      </CustomModalContentWrapper>
    );
  }

  if (isError) {
    return (
      <CustomModalContentWrapper
        title="Тестирование"
        onClose={onClose}
      >
        <Typography>
          Что-то пошло не так! Попробуйте позже
        </Typography>
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Тестирование"
      onClose={onClose}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Устройство открылось?
            </Typography>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={3}>
              <CustomBtn
                btnType="primaryGreen"
                className={styles.btn}
                onClick={onClose}
              >
                Да
              </CustomBtn>
            </Grid>
            <Grid item xs={3}>
              <CustomBtn
                btnType="primaryRed"
                className={styles.btn}
                onClick={() => setStep(1)}
              >
                Нет
              </CustomBtn>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Проверьте настройки контроллера
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {type === CONTROLLER_TYPES.gsm && (
              <Typography>
                {`Номер ${formatPhoneNumber(gsmSettingsData.lineNumber)} должен быть добавлен в устройство`}
              </Typography>
            )}
            {type === CONTROLLER_TYPES.cameConnect && (
              <Typography>
                Устройство должно быть подключено
              </Typography>
            )}
          </Grid>
          <Grid item>
            <CustomBtn
              className={styles.btn}
              btnType="primaryBlue"
              onClick={() => setStep(0)}
            >
              Тест
            </CustomBtn>
          </Grid>

        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const { allGSMControllerSettings } = controllers || {};

  return {
    allGSMControllerSettingsState: allGSMControllerSettings,
  };
}

const ConnectedTestNewControllerModalContent = connect(
  mapStateToProps,
  {
    getGSMControllerSettingsReg: getGSMControllerSettingsAction,
  },
)(TestNewControllerModalContent);

export default ConnectedTestNewControllerModalContent;
