import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  subContainer: {
    marginTop: 16,
  },
  inputItem: {
    margin: 0,
  },
  tableContainer: {
    maxHeight: 500,
    overflow: 'auto',
  },
  head: {
    border: '1px solid white',
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
    backgroundColor: theme.palette.greyC.gray2,
  },
  cell: {
    padding: '8px 16px',
  },
  divider: {
    marginTop: 16,
    backgroundColor: theme.palette.greyC.gray,
  },
  btnAdd: {
    width: '100%',
  },
  btnSubmit: {
    marginTop: 8,
    width: '100%',
    height: 48,
    display: 'block',
  },
  plusIcon: {
    marginRight: 5,
  },
}));

export default {
  useStyles,
};
