import { getAvailableLicenses, getFacilityLicense } from 'api/licenses';
import { CLEAR_LICENSES_CACHE, GET_AVAILABLE_LICENSES, GET_FACILITY_LICENSE } from './types';

export function clearLicensesCacheAction() {
  function actionClearCacheLicenses() {
    return { type: CLEAR_LICENSES_CACHE };
  }

  return (dispatch) => {
    dispatch(actionClearCacheLicenses());
  };
}

export function getAvailableLicensesAction(param) {
  function getAvailableLicensesRequest(payload) {
    return { type: GET_AVAILABLE_LICENSES, payload };
  }

  return (dispatch) => {
    dispatch(getAvailableLicensesRequest({
      loading: true,
    }));

    getAvailableLicenses(param).then(
      (data) => {
        dispatch(getAvailableLicensesRequest({
          loading: false,
          data,
          param,
        }));
      },
      (error) => {
        dispatch(getAvailableLicensesRequest({
          loading: false,
          error,
        }));
      },
    );
  };
}

export function getFacilityLicenseAction({ facilityId }) {
  function getFacilityLicenseRequest(payload) {
    return { type: GET_FACILITY_LICENSE, payload };
  }

  return (dispatch) => {
    dispatch(getFacilityLicenseRequest({
      loading: true,
    }));

    getFacilityLicense({ facilityId }).then(
      (data) => {
        dispatch(getFacilityLicenseRequest({
          loading: false,
          data,
        }));
      },
      (error) => {
        dispatch(getFacilityLicenseRequest({
          loading: false,
          error: {
            error,
            facilityId,
          },
        }));
      },
    );
  };
}
