import { updateStoreReqRes } from '@parkly/shared/helpers';
import { CLEAR_LICENSES_CACHE, GET_AVAILABLE_LICENSES, GET_FACILITY_LICENSE } from 'actions/types';

const INITIAL_STATE = {
  availableLicenses: {
    loading: false,
    list: [],
  },
  facilityLicense: {
    loading: false,
    list: [],
  },
};

function updateOneList({
  list,
  newFacilityLicense,
  error,
}) {
  const { facilityId } = newFacilityLicense || error || {};

  if (!facilityId) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).facilityId;
      const isFilter = currentId !== facilityId;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newFacilityLicense || error,
  ];

  return newList;
}

export default function licenses(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_AVAILABLE_LICENSES:
      return updateStoreReqRes(
        state,
        'availableLicenses',
        action,
      );

    case GET_FACILITY_LICENSE:
      return {
        ...state,
        facilityLicense: {
          ...state.facilityLicense,
          loading: action.payload.loading,
          list: updateOneList({
            list: state.facilityLicense.list,
            newFacilityLicense: action.payload.data,
            error: action.payload.error,
          }),
        },
      };

    case CLEAR_LICENSES_CACHE:
      return INITIAL_STATE;

    default:
      return state;
  }
}
