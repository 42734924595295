import React, { createContext, useCallback, useContext, useState } from 'react';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';

const ConfirmContext = createContext();

const useConfirm = () => {
  const confirm = useContext(ConfirmContext);
  return confirm;
};

export default useConfirm;

export const ConfirmProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState({});
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback((o = {}) => {
    return new Promise((resolve, reject) => {
      setOptions({
        async: false,
        title: 'Вы уверены?',
        content: '',
        agreeTitle: 'Да',
        disagreeTitle: 'Нет',
        ...o,
      });
      setResolveReject([resolve, reject]);
      setIsLoading(false);
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    reject();
    handleClose();
  }, [reject, handleClose]);

  const handleAsyncConfirm = useCallback(() => {
    setIsLoading(true);
    (new Promise((r) => {
      resolve(r);
    })).then(() => {
      setIsLoading(false);
      handleClose();
    });
  }, [handleClose, resolve]);

  const handleConfirm = useCallback(() => {
    if (options.async) {
      handleAsyncConfirm();

      return;
    }

    resolve();
    handleClose();
  }, [options.async, resolve, handleClose, handleAsyncConfirm]);

  const {
    title,
    content,
    agreeTitle,
    disagreeTitle,
  } = options;

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <CustomDialog
        open={resolveReject.length === 2}
        handleAgree={handleConfirm}
        handleClose={handleCancel}
        dialogTitle={title}
        dialogContent={content}
        isLoading={isLoading}
        agreeTitle={agreeTitle}
        disagreeTitle={disagreeTitle}
      />
    </>
  );
}
