import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  form: {
    marginTop: 24,
  },
  inputItem: {
    width: '100%',
    height: 48,
    margin: 0,
    '& .MuiFilledInput-root': {
      height: 48,
    }
  },
  inputNameItem: {
    width: '100%',
    height: 48,
    margin: 0,
    '& .MuiFilledInput-root': {
      height: 48,
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
  subLabel: {
    color: theme.palette.greyC.darkGrey
  },
  selectorsForm: {
    height: 48,
  },
  selector: {
    height: 48,
  },
  selectorsLabel: {

  },
  pricesContainer: {
    marginTop: 24,
    marginBottom: 24
  },
  saveBtn: {
    width: '100%',
    height: 48,
  }
}));

export default {
  useStyles,
};
