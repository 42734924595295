import React, { memo, useMemo } from 'react';
import { Handle } from 'reactflow';

import Typography from '@material-ui/core/Typography';

import { GATE_TYPES } from 'config/constants';

import { useDeviceNodeStyles } from './styles';
import DoorIcon from '@parkly/shared/components/atoms/icons/DoorIcon';
import GateIcon from '@parkly/shared/components/atoms/icons/GateIcon';
import BarrierIcon from '@parkly/shared/components/atoms/icons/BarrierIcon';

export default memo(({ data, targetPosition, sourcePosition }) => {
  const styles = useDeviceNodeStyles(data);

  const {
    label,
    type,
    direction,
  } = data || {};

  const iconComponent = useMemo(() => {
    switch (type) {
      case GATE_TYPES.door:
        return (
          <DoorIcon stroke="white" />
        );
      case GATE_TYPES.gate:
        return (
          <GateIcon stroke="white" />
        );
      case GATE_TYPES.barrier:
      default:
        return (
          <BarrierIcon stroke="white" />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Handle
        className={styles.handle}
        type="target"
        position={targetPosition}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={sourcePosition}
      />
      <div className={styles.container}>
        {iconComponent}
        <Typography noWrap className={styles.text}>
          {label}
        </Typography>
      </div>
    </>
  );
})
