import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 8,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    paddingLeft: 16,
  },
  tableNoDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 455,
  },
  tableNoDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  tableContainer: {
    maxHeight: 455,
  },
  tableHeaderStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
}));

export default {
  useStyles,
};
