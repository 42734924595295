import React, { useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import SupportSection from '@parkly/shared/components/templates/SupportSection';
import { createMailLink } from '@parkly/shared/helpers/others';

import LeftPageMenu from 'components/organisms/LeftPageMenu';

import { LS_KEYS } from '../../../config/constants';
import { useLocalStorageState } from '../../../helpers/hooks';

import { collapsedDrawerWidth, drawerWidth, useStyles } from './styles';

/* help functions */

const propTypes = {

};

function MainNavigation({
  currentOperator,
  currentFacilityState,
  registrationStatusState,
}) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useLocalStorageState(LS_KEYS.mainMenuCollapsed, false);
  const classes = useStyles({ isCollapsed });

  const {
    data: currentUserData,
    userRights,
  } = currentOperator || {};
  const {
    name: currentUserName,
    id: currentUserId,
  } = currentUserData || {};

  const {
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    id: currentFacilityId,
    title: currentFacilityTitle,
  } = currentFacilityData || {};

  const {
    data: registrationStatusData,
  } = registrationStatusState || {};

  const email = process.env.REACT_APP_SUPPORT_EMAIL;
  const subject = `Обращение от  "${currentUserName}", (${currentUserId})`;
  const message = '';

  const supportLink = createMailLink({
    email,
    subject,
    message,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {currentFacilityTitle || '   '}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <LeftPageMenu
            registrationStatus={registrationStatusData}
            userRights={userRights}
            currentFacilityId={currentFacilityId}
            drawerWidth={drawerWidth}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <LeftPageMenu
            registrationStatus={registrationStatusData}
            onToggleCollapse={handleToggleCollapsed}
            isCollapsed={isCollapsed}
            isCollapsedEnabled
            userRights={userRights}
            currentFacilityId={currentFacilityId}
            drawerWidth={isCollapsed ? collapsedDrawerWidth : drawerWidth}
            variant="permanent"
            open
          />
        </Hidden>
      </nav>
      <Hidden xsDown implementation="css">
        <SupportSection {...{
          supportLink,
        }}
        />
      </Hidden>
    </>
  );
}

MainNavigation.propTypes = propTypes;

function mapStateToProps(state) {
  const { loggedIn } = state.auth || {};
  const { operators } = state || {};
  const { companies } = state || {};
  const { registrationStatus } = companies || {};
  const { currentFacility } = state || {};
  const { currentOperator } = operators || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    isAuth: !!loggedIn,
    currentOperator,
    currentFacilityState,
    registrationStatusState: registrationStatus,
  };
}

const ConnectedMainNavigation = connect(mapStateToProps)(MainNavigation);

export default ConnectedMainNavigation;
