import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useVirtual } from 'react-virtual';
import {
  Grid, Table, TableBody, TableCell, TableContainer,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  createColumnHelper, flexRender, getCoreRowModel, useReactTable,
} from '@tanstack/react-table';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { PLACE_PURPOSES, PLACE_STATUSES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights, useDebounce } from 'helpers/hooks';

import AddNewPlaces from './AddNewPlaces';
import { useStyles } from './styles';

function getStatusesItems() {
  return [
    { id: PLACE_STATUSES.free, title: 'Свободно' },
    { id: PLACE_STATUSES.reserved, title: 'Занято' },
  ];
}

function getPurposeItems() {
  return [
    { id: PLACE_PURPOSES.unavailable, title: 'Недоступно' },
    { id: PLACE_PURPOSES.reactive, title: 'Реактивная/бронирование' },
    { id: PLACE_PURPOSES.pass, title: 'Абонемент' },
  ];
}

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    header: 'Место',
    cell: ({
      getValue, row: { index }, column: { id }, table,
    }) => {
      const { styles } = table.options.meta;
      const { hasPlacesUpdateRight } = table.options.meta;
      const { maxPlacesCnt } = table.options.meta;

      const initialValue = getValue();
      // We need to keep and update the state of the cell normally
      const [value, setValue] = useState(initialValue);

      const debouncedValue = useDebounce(value, 300);

      useEffect(() => {
        if (initialValue === debouncedValue) {
          return;
        }

        table.options.meta?.updateData(index, id, debouncedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [debouncedValue]);

      const onChange = (e) => {
        setValue(e.target.value);
      };

      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return (
        <CustomTextInput
          type="secondary"
          className={styles.inputItem}
          placeholder={`${index + 1}`}
          value={value}
          disabled={!hasPlacesUpdateRight || index >= maxPlacesCnt}
          onChange={onChange}
        />
      );
    },
  }),
  columnHelper.accessor('purpose', {
    header: 'Назначение',
    meta: {
      style: { minWidth: 100 },
    },
    cell: ({
      getValue, row: { index }, column: { id }, table,
    }) => {
      const { hasPlacesUpdateRight } = table.options.meta;
      const { purposeItems } = table.options.meta;
      const { maxPlacesCnt } = table.options.meta;

      const initialValue = getValue();
      // We need to keep and update the state of the cell normally
      const [value, setValue] = useState(initialValue);

      const onChange = (e) => {
        setValue(e.target.value);
        table.options.meta?.updateData(index, id, e.target.value);
      };

      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return (
        <ItemSelectors
          key={index + id}
          selectorsType="secondary"
          currentValue={value}
          items={purposeItems}
          disabled={!hasPlacesUpdateRight || index >= maxPlacesCnt}
          onChange={onChange}
        />
      );
    },
  }),
  columnHelper.accessor('status', {
    header: 'Статус',
    cell: ({
      getValue, row: { index }, column: { id }, table,
    }) => {
      const { statusItems } = table.options.meta;

      return (
        <ItemSelectors
          key={index + id}
          selectorsType="secondary"
          currentValue={getValue()}
          items={statusItems}
          disabled
        />
      );
    },
  }),
  columnHelper.accessor('actions', {
    header: '',
    cell: ({ row: { index }, table }) => {
      const onDelete = () => {
        table.options.meta?.deleteRow(index);
      };

      return (
        <IconButton size="small" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      );
    },
  }),
];

export default function PlacesSettingsTable({
  facilityId,
  places,
  onChange,
  maxPlacesCnt = 0,
}) {
  const styles = useStyles();

  const hasPlacesUpdateRight = useCheckFacilityRights(RIGHT_KEYS.placesUpdate, facilityId);

  const statusItems = useMemo(() => getStatusesItems(), []);
  const purposeItems = useMemo(() => getPurposeItems(), []);

  const tableContainerRef = useRef(null);

  const table = useReactTable({
    data: places,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      styles,
      hasPlacesUpdateRight,
      statusItems,
      purposeItems,
      maxPlacesCnt,
      updateData: (rowIndex, columnId, value) => {
        onChange(places.map((place, placeIndex) => {
          if (rowIndex === placeIndex) {
            return {
              ...place,
              [columnId]: value,
            };
          }
          return place;
        }));
      },
      deleteRow: (rowIndex) => {
        places.splice(rowIndex, 1);

        onChange([...places]);
      },
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize, scrollToIndex } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0
    ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
    : 0;

  const handleAddPlaces = (newPlacesCount, newPlacesPurpose) => {
    for (let i = 0; i < newPlacesCount; i++) {
      places.push({
        status: PLACE_STATUSES.free,
        purpose: newPlacesPurpose,
        name: '',
        isOwned: false,
      });
    }

    onChange([...places]);

    setTimeout(() => {
      scrollToIndex(places.length, { align: 'start' });
    }, 500);
  };

  return (
    <Grid container spacing={2}>
      {(places || []).length > 0 && (
        <Grid item xs={12}>
          <TableContainer className={styles.tableContainer} ref={tableContainerRef}>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
            >
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id} className={styles.head} align="center">
                        {header.column.columnDef.header}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {paddingTop > 0 && (
                  <TableRow>
                    <TableCell style={{ height: paddingTop }} />
                  </TableRow>
                )}
                {virtualRows.map((virtualRow) => {
                  const row = rows[virtualRow.index];

                  return (
                    <>
                      <TableRow key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            align="center"
                            className={styles.cell}
                            style={cell.column.columnDef.meta?.style}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {maxPlacesCnt > 0 && virtualRow.index + 1 === maxPlacesCnt && (
                        <TableRow key="max-places-row" style={{ height: 57 }}>
                          <TableCell colSpan="4" align="center" className={styles.cell}>
                            <Typography>
                              Максимальное количество машиномест по лицензии -
                              {maxPlacesCnt}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
                {paddingBottom > 0 && (
                  <TableRow>
                    <TableCell style={{ height: paddingBottom }} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {hasPlacesUpdateRight && (
        <AddNewPlaces
          onAdd={handleAddPlaces}
          purposeItems={purposeItems}
        />
      )}
    </Grid>
  );
}
