import React from 'react';
import moment from 'moment-timezone';

import DesktopDateTimeInput from '@parkly/shared/components/atoms/DesktopDateTimeInput';

import { checkMinMaxTimeAndGetValid } from '@parkly/shared/helpers';

import { useStyles } from './styles';

/* -- Help functions -- */

/* -- Main component */

function DateTimeInput({
  className = '',
  label = '',
  placeholder,
  id,
  name = 'customInput000',
  type = 'datetime-local',
  inputRef,
  isError = false,
  value,
  min,
  max,
  rightIcon,
  popoverPosition = 'left',
  dateTimeFormat = 'D MMMM, HH:mm',
  initStartDayTime = false,
  isLoading = false,
  disabled,
  onChange = () => {},
  ...props
}) {
  const styles = useStyles();

  const containerClass = [
    styles.container,
    className,
  ].join(' ');

  const labelClass = [
    styles.label,
    placeholder ? styles.labelWithPlaceholder : '',
  ].join(' ');

  const {
    isValid: isMinMaxValid,
  } = checkMinMaxTimeAndGetValid({
    value,
    min,
    max,
  });

  const inputClass = [
    styles.input,
    (isError || !isMinMaxValid) ? styles.error : '',
  ].join(' ');

  const identifier = id || name;

  function onInputChange(param) {
    onChange(param);
  }

  return (
    <div
      className={containerClass}
    >
      {rightIcon && (
        <div className={styles.rightIcon}>
          {rightIcon}
        </div>
      )}
      {/*{isMobile && (*/}
      {/*  <>*/}
      {/*    <input*/}
      {/*      ref={inputRef}*/}
      {/*      className={[inputClass, styles.inputMobile].join(' ')}*/}
      {/*      id={identifier}*/}
      {/*      name={name}*/}
      {/*      type="datetime-local"*/}
      {/*      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"*/}
      {/*      placeholder={placeholder}*/}
      {/*      autoComplete="off"*/}
      {/*      value={value}*/}
      {/*      onChange={onInputChange}*/}
      {/*      min={min}*/}
      {/*      max={max}*/}
      {/*      /* eslint-disable-next-line react/jsx-props-no-spreading */}
      {/*      {...props}*/}
      {/*    />*/}
      {/*    <label*/}
      {/*      htmlFor={identifier}*/}
      {/*      className={[*/}
      {/*        styles.mobileTimeLabel,*/}
      {/*        isLoading ? styles.mobileTimeLabelLoading : '',*/}
      {/*      ].join(' ')}*/}
      {/*    >*/}
      {/*      {moment(value).format(dateTimeFormat)}*/}
      {/*    </label>*/}
      {/*  </>*/}
      {/*)}*/}
      {true && ( // TODO add mobile input
        <DesktopDateTimeInput
          inputRef={inputRef}
          className={inputClass}
          id={identifier}
          name={name}
          placeholder={placeholder}
          value={value}
          min={min ? moment(min) : min}
          max={max ? moment(max) : max}
          popoverPosition={popoverPosition}
          dateTimeFormat={dateTimeFormat}
          isLoading={isLoading}
          disabled={disabled}
          onChange={onInputChange}
          initStartDayTime={initStartDayTime}
        />
      )}
      <label
        htmlFor={identifier}
        className={labelClass}
      >
        {label}
      </label>
    </div>
  );
}

export default DateTimeInput;
