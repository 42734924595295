import React from 'react';

function SettingsInactiveIcon({
  fill = 'white',
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71 13.97L6.91 16.04C6.99 16.17 7.14 16.22 7.28 16.17L8.77 15.57C9.08 15.81 9.41 16.01 9.78 16.16L10 17.75C10.03 17.89 10.15 18 10.3 18H12.7C12.85 18 12.97 17.89 13 17.74L13.22 16.15C13.58 16 13.92 15.8 14.23 15.56L15.72 16.16C15.86 16.21 16.01 16.16 16.09 16.03L17.29 13.96C17.37 13.83 17.33 13.67 17.22 13.57L15.95 12.58C15.98 12.39 15.99 12.19 15.99 12C15.99 11.8 15.97 11.61 15.95 11.41L17.22 10.42C17.33 10.33 17.37 10.16 17.29 10.03L16.09 7.96C16.01 7.83 15.86 7.78 15.72 7.83L14.23 8.43C13.92 8.19 13.59 7.99 13.22 7.84L13 6.25C12.97 6.11 12.85 6 12.7 6H10.3C10.15 6 10.03 6.11 10 6.26L9.78 7.85C9.42 8 9.07 8.19 8.77 8.43L7.28 7.83C7.14 7.78 6.99 7.83 6.91 7.96L5.71 10.03C5.63 10.16 5.67 10.32 5.78 10.42L7.05 11.41C7.02 11.61 7 11.8 7 12C7 12.2 7.02 12.39 7.04 12.59L5.77 13.58C5.66 13.68 5.63 13.84 5.71 13.97ZM11.5 10.29C12.44 10.29 13.21 11.06 13.21 12C13.21 12.94 12.44 13.71 11.5 13.71C10.56 13.71 9.79 12.94 9.79 12C9.79 11.06 10.56 10.29 11.5 10.29ZM19 3.00001H4C2.89 3.00001 2.5 3.4 2.5 4.5V19.5C2.5 20.6 2.89 21 4 21H19C20.11 21 20.5 20.6 20.5 19.5V4.5C20.5 3.4 20.11 3.00001 19 3.00001ZM19.5 20H3.5V4H19.5V20Z"
        fill={fill}
      />
    </svg>

  );
}

export default SettingsInactiveIcon;
