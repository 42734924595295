import { callAPI } from '@parkly/shared/api/base';

export async function validatePhone(phone) {
  const response = await callAPI(
    'phone-validation',
    'POST',
    { phone },
    {},
    true,
    true,
    false,
  );

  /* - result validation - */
  const {
    error,
  } = response || {};

  if (error) {
    const { response: resError } = error || {};
    const { data } = resError || {};

    return {
      error: data,
    };
  }

  const {
    data: {
      success,
      pin,
    }
  } = response || {};

  return {
    success,
    pin,
  };
}

export async function confirmPhone(phone, code, isShowErrorNotification = false) {
  const response = await callAPI(
    'phone-confirm',
    'POST',
    {
      phone,
      pin: code,
    },
    {},
    true,
    true,
    false,
  );

  /* - result validation - */

  const {
    error,
  } = response || {};

  if (error) {
    const { response: errorResponse } = error;
    const { data: errorData } = errorResponse || {};

    return {
      error: errorData,
    };
  }

  const {
    data: {
      success,
      data: partners,
    },
  } = response || {};

  return {
    success,
    partners,
  };
}

async function validGetFastLoginOptions({ res }) {
  const { data } = res || {};

  const { data: result } = data || {};
  return result;
}

export async function getFastLoginOptions() {
  return callAPI('fast-login')
    .then((res) => validGetFastLoginOptions({
      res,
    }));
}

export default {
  validatePhone,
  confirmPhone,
};
