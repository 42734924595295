import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  cardStr: {
    fontWeight: 700,
    overflow: 'hidden',
    width: '100%'
  },
  cardSubStr: {

  },
}));

export default {
  useStyles,
};
