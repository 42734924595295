import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { useFormFields } from '@parkly/shared/helpers';
import moment from 'moment-timezone';
import { DatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { renewalPassInitInfo, renewalPassPriceInfo, renewalPassRenew } from 'api/passes';
import { clearPassesCacheAction } from 'actions/passes';
import { useStyles } from './styles';


function RenewPassModalContent({
  passId,
  facilityId,
  onClose = () => {},
  onComplete = () => {},
}) {
  const dispatch = useDispatch();
  const priceInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState(false);
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      to: '',
      from: '',
      suggestPrice: 0,
      isPaid: false,
    },
  });
  const styles = useStyles();

  useEffect(() => {
    if (!passId || !facilityId) {
      return;
    }

    setIsInitLoading(true);
    renewalPassInitInfo({ facilityId, id: passId })
      .then(({ price, to, from }) => {
        updateState({
          suggestPrice: price,
          to: moment(to),
          from: moment(from),
        });
      }).catch(() => {})
      .finally(() => setIsInitLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passId, facilityId]);

  useEffect(() => {
    if (!passId || !facilityId || !fields.to) {
      return;
    }

    setIsPriceLoading(true);
    renewalPassPriceInfo({ facilityId, id: passId, to: fields.to })
      .then(({ price, to, from }) => {
        updateState({
          suggestPrice: price,
        });
      }).catch(() => {})
      .finally(() => setIsPriceLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId, fields.to, passId]);

  const handlePassRenew = useCallback(() => {
    if (!passId || !facilityId) {
      return;
    }

    if (fields.to < fields.from) {
      setErrors({ to: true });
      return;
    }

    if (fields.price < 0) {
      setErrors({
        price: true,
      });
      return;
    }

    setIsLoading(true);
    renewalPassRenew({
      facilityId,
      id: passId,
      to: fields.to,
      price: fields.price,
      isPaid: fields.isPaid,
    })
      .then(({ success }) => {
        setIsLoading(false);
        if (success) {
          onComplete();
          dispatch(clearPassesCacheAction());
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [dispatch, facilityId, fields, onComplete, passId, setErrors]);

  if (isInitLoading) {
    return (
      <CustomModalContentWrapper
        title="Продление абонемента"
        onClose={onClose}
        paperClassname={styles.paper}
        bodyClassname={styles.modalBody}
      >
        <CircularIndeterminate />
      </CustomModalContentWrapper>
    );
  }

  return (
    <CustomModalContentWrapper
      title="Продление абонемента"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              disableToolbar
              disabled
              label="От"
              invalidDateMessage=""
              maxDateMessage=""
              minDateMessage=""
              className={styles.inputItem}
              value={fields.from}
              onChange={() => {}}
              format="DD-MM-YYYY"
              variant="inline"
              inputVariant="filled"
              TextFieldComponent={CustomTextInput}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              disableToolbar
              autoOk
              required
              label="До"
              invalidDateMessage=""
              maxDateMessage=""
              minDateMessage=""
              error={errors.to}
              className={styles.inputItem}
              value={fields.to}
              onChange={(date) => updateState({ to: date })}
              minDate={fields.from}
              format="DD-MM-YYYY HH:mm"
              variant="inline"
              inputVariant="filled"
              disabled={isPriceLoading}
              InputProps={{
                endAdornment: (
                  (isPriceLoading && (
                    <InputAdornment position="start">
                      <CircularProgress
                        size={20}
                        color="inherit"
                      />
                    </InputAdornment>
                  ))
                ),
              }}
              TextFieldComponent={CustomTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.tariffPriceStr}>
              Сумма продления по тарифу:
              {` ${(+fields.suggestPrice || '').toLocaleString()} ₽`}
            </Typography>
            <CustomLink
              onClick={() => {
                priceInputRef.current.focus();
                updateState({ price: fields.suggestPrice });
              }}
            >
              Заполнить
            </CustomLink>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              inputRef={priceInputRef}
              required
              InputLabelProps={{ shrink: true }}
              className={styles.inputItem}
              error={errors.price}
              name="price"
              label="Сумма к оплате"
              type="number"
              value={fields.price}
              autoComplete="off"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className={styles.checkbox}
              control={(
                <Checkbox
                  checked={fields.isPaid}
                  name="isPaid"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label={(
                <Tooltip title="При отметке продление будет создано без физической оплаты. В таком случае оплата должна быть проведена сотрудниками парковки">
                  <Typography style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    Создать оплаченное продление
                    <InfoIcon fontSize="small" />
                  </Typography>
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.saveBtn}
              onClick={onClose}
            >
              Отменить
            </CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.saveBtn}
              btnType="primaryBlue"
              onClick={handlePassRenew}
              disabled={isLoading}
            >
              Продлить
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      </div>
    </CustomModalContentWrapper>
  );
}

export default RenewPassModalContent;
