import { PASS_REQ_TYPE_NEED } from 'config/constants';

export function parseReqType(requiredData, type) {
  if (!requiredData) {
    return { need: false, required: false };
  }

  const res = requiredData[type];

  const need = [PASS_REQ_TYPE_NEED.optional, PASS_REQ_TYPE_NEED.required].includes(res);
  const required = res === PASS_REQ_TYPE_NEED.required;

  return { need, required };
}

export function toReqType(need, required) {
  if (required && need) {
    return PASS_REQ_TYPE_NEED.required;
  }

  if (need) {
    return PASS_REQ_TYPE_NEED.optional;
  }

  return PASS_REQ_TYPE_NEED.notNeed;
}
