import { createMutation, createQuery } from 'react-query-kit';
import {
  deleteCustomer,
  deleteCustomerAccessToken,
  fastActionCustomer,
  getCustomer,
  getCustomers,
  setCustomer,
} from 'api/customers';

export const useCustomers = createQuery({
  primaryKey: 'customers',
  queryFn: ({
    queryKey: [_, {
      facilityId,
      search,
      defaultTariffPlan,
      guest,
      page,
      perPage,
      sortBy,
      sortDirection,
    }],
  }) => getCustomers({
    facilityId,
    search,
    defaultTariffPlan,
    guest,
    page,
    perPage,
    sortBy,
    sortDirection,
  }),
  retry: false,
});

export const useOneCustomer = createQuery({
  primaryKey: 'customers',
  queryFn: ({
    queryKey: [_, {
      facilityId,
      id,
    }],
  }) => getCustomer({
    facilityId,
    id,
  }),
  retry: false,
});

export const useSetCustomerMutation = createMutation({
  mutationFn: async ({
    facilityId,
    id,
    name,
    phone,
    email,
    comment,
    tokens,
    defaultTariffPlanId,
    hasTelegramBotAccess,
  }) => setCustomer({
    facilityId,
    id,
    name,
    phone,
    email,
    comment,
    tokens,
    defaultTariffPlanId,
    hasTelegramBotAccess,
  }),
});

export const useDeleteCustomerMutation = createMutation({
  mutationFn: async ({
    facilityId,
    id,
  }) => deleteCustomer({
    facilityId,
    id,
  }),
});

export const useDeleteCustomerAccessTokenMutation = createMutation({
  mutationFn: async ({
    facilityId,
    customerId,
    id,
  }) => deleteCustomerAccessToken({
    facilityId,
    customerId,
    id,
  }),
});

export const useCustomerFastActionMutation = createMutation({
  mutationFn: async ({
    facilityId,
    gatewayEventId,
    type,
  }) => fastActionCustomer({
    facilityId,
    gatewayEventId,
    type,
  }),
});
