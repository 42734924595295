import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import Typography from '@material-ui/core/Typography';
import { useCancelPaymentMutation } from 'api/query/payments';
import { useStyles } from './styles';

function CancelPaymentModalContent({
  paymentId,
  facilityId,
  onClose = () => {},
  onComplete = () => {},
}) {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const mutation = useCancelPaymentMutation({
    onSuccess({ success }) {
      if (success) {
        queryClient.invalidateQueries({ queryKey: ['sessions'] });
        queryClient.invalidateQueries({ queryKey: ['payments'] });
        onComplete();
      }
    },
    onError() {
      //
    },
  });

  const { isLoading } = mutation;

  const handlePassRenew = useCallback(() => {
    if (!paymentId || !facilityId) {
      return;
    }

    mutation.mutate({ id: paymentId, facilityId });

  }, [mutation, facilityId, paymentId]);

  return (
    <CustomModalContentWrapper
      title="Отмена оплаты"
      onClose={onClose}
      paperClassname={styles.paper}
      bodyClassname={styles.modalBody}
    >
      <div className={styles.container}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography className={styles.title}>
              Вы уверены, что хотите отменить оплату?
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.saveBtn}
              onClick={onClose}
            >
              Нет
            </CustomBtn>
          </Grid>
          <Grid item xs={6}>
            <CustomBtn
              className={styles.saveBtn}
              btnType="primaryBlue"
              onClick={handlePassRenew}
              disabled={isLoading}
            >
              Да, отменить
              {isLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      </div>
    </CustomModalContentWrapper>
  );
}

export default CancelPaymentModalContent;
