import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  codeSendTo: {
    textAlign: 'center',
  },
  submit: {
    width: '100%',
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    color: '#004BF6 !important',
    fontSize: '40px !important',
    fontWeight: '700 !important',

    '& span': {
      fontWeight: 500,
      color: '#02c173',
    },
  },
  cardContainer: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  cardStr: {
    fontWeight: 700,
  },
  userAgreement: {
    textAlign: 'center',
  },
  userAgreementLink: {
    cursor: 'pointer',
  },
}));

export default {
  useStyles,
};
