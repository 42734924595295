import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function CircleIcon({
  color = '#43D37D',
  className = '',
}) {
  return (
    <svg
      className={className}
      width={8}
      height={8}
      viewBox="0 0 8 8"
      fill="none"
    >
      <rect
        width={8}
        height={8}
        rx={4}
        fill={color}
      />
    </svg>
  );
}

export default CircleIcon;
